import React from "react";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface StyledTabProps {
  label: string;
}

const WingoTabs = styled(Tabs)({
  padding: "2px",
  width: "fit-content",
  border: "1px solid #747474",
  borderRadius: "50px",
  overflow: "clip",
  color: '#e8e8e8',
  '& .MuiTabs-indicator': {
    height: "0",
    backgroundColor: '#1b1b1b',
  },
});

const WingoTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: '#fff',
    borderRadius: "50px !important",
    fontFamily: [
      'Barmeno',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1b1b1b',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#fff !important',
      backgroundColor: '#1b1b1b !important',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#fff',
    },
  }),
);

export { WingoTabs, WingoTab };
