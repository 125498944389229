import React, {Component } from 'react';
import { Box, Button } from '@material-ui/core';
import { updateDetail } from './assets';
import PhoneInput from 'react-phone-input-2';
import Modal from '../../../components/src/Modal';
export const configJSON = require("./config");

interface UpdateDetailsProps {
    updatePhoneNumber:(value:string)=> void;
    updatePopup:boolean;
    navigateToPages:()=>void;
    phoneNumberUpdateError:string;
    changeErrorPhoneNumber:()=>void
}
interface S {
  enablePasswordField: boolean;
  phoneNumber: string;
  invalidPhone: boolean;
}

export class UpdateMobileDetailsPopup extends Component<UpdateDetailsProps, S> {
  constructor(props: UpdateDetailsProps) {
    super(props);
    this.state = {
      enablePasswordField: true,
      phoneNumber: '',
      invalidPhone: false
    }
  }

  checkEmailValidation=(Phone:string)=>{
    const PhoneRegex = configJSON.phoneRegex;
    this.setState({invalidPhone: !PhoneRegex.test(Phone)})
  }

  render() {
    return (
        <Modal maxWidth="xs" open={this.props.updatePopup}>
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "3vh", alignItems: "center" }}>
            <img style={{ height: '250px' }} src={updateDetail} alt="Delete account" />
            <div style={{display:"flex", gap:"2vh", flexDirection:"column", width:"100%"}}>
             <div style={{ fontFamily: "Barmeno", fontWeight: 700, fontSize: "24px" }}>Update Mobile Number</div>
              <div style={{ fontFamily: "Barmeno", fontWeight: 600, fontSize: "18px", color:"#747474" }}>Enter your new mobile number to update</div>
            <div className="password_field__wrapper" style={{ width: '100%', alignItems: "start" }}>
              <label style={{ fontFamily: "Barmeno", fontWeight:600, color:"#747474"}} htmlFor="mobile" > Mobile Number </label>
              <div style={{ display: "flex", width: "100%" }} className='contact_form-field_popup'>
              <PhoneInput
              inputStyle={{borderRadius: "8px", fontFamily: "Barmeno", width: "100%", height: "56px", border:"1px solid black", fontWeight:600 }}
              dropdownStyle={{textAlign:"start", maxHeight:'100px'}}
                 inputProps={{
                     name: "phoneNumber",
                     required: true,
                 }}
                 country={"gb"}
                 value={this.state.phoneNumber}
                 onChange={(event) => { this.setState({ phoneNumber: event, invalidPhone: false });
                 this.props.changeErrorPhoneNumber();}}
                 onBlur={(event)=>{this.checkEmailValidation(event.target.value)}}
                    />
                 </div>
              {this.state.invalidPhone && <div className='errors' style={{ fontFamily:"Barmeno" }}>The mobile number you entered is not in a valid format</div>}
              {<div className='errors' style={{fontFamily:"Barmeno" }}>{this.props.phoneNumberUpdateError}</div>}
            </div>
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}  >
              <Button className="cancelButton"
              onClick={() => {this.props.navigateToPages(); this.props.changeErrorPhoneNumber();this.setState({invalidPhone:false, phoneNumber:""}) }}
              id="submit" style={{ fontFamily: "Barmeno" }} >
                Cancel
              </Button>
              <Button className={this.state.invalidPhone  || (this.state.phoneNumber==="" || !!this.props.phoneNumberUpdateError)?"submitButtonDisabledDet":"submitButtonDet"} disabled={this.state.invalidPhone || (this.state.phoneNumber==="" || !!this.props.phoneNumberUpdateError)}
              onClick={() => {  this.props.updatePhoneNumber(this.state.phoneNumber)
                  }}
              id="submit" style={{ fontFamily: "Barmeno" }}  >
                Update
              </Button>
            </div>
          </Box>
          </Modal>
    );
  }
}

export default UpdateMobileDetailsPopup;
