import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { resendEmail } from "../../../components/src/apiCall";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  loggedInUser?: (loggeIn: boolean) => {}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  invalidEmail: boolean;
  invalidPassword: boolean;
  emailUnregistered: boolean;
  loginSignUpType: string;
  countryCodeSelected: string;
  phone: string;
  isFormValid: boolean;
  inValidOtp: boolean;
  openOtpPopup: boolean;
  authToken: string;
  openPopup: boolean;
  accountNotActivatedPopup:boolean;
  openOtpVerificationPopup:boolean;
  smsToken:string;
  openSuccessPopup:boolean;
  content:string;
  button:string;
  accountLoginFailed:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  passwordReg: RegExp;
  labelTitle: string = "";
  otpCallId: any;
  authenticationCallId: string = ""
  sendVerificationEmailCallId:string="";
  otpCallIdVerification:string="";
  apiAppleLoginCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.otpAuthentication = this.otpAuthentication.bind(this);
    this.closeOtpPopup = this.closeOtpPopup.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: localStorage.getItem("email")||"",
      password: localStorage.getItem("password")||"",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      invalidEmail: false,
      invalidPassword: false,
      emailUnregistered: false,
      loginSignUpType: "email_account",
      countryCodeSelected: "",
      phone: localStorage.getItem("full_phone_number")||"",
      isFormValid: false,
      inValidOtp: false,
      openOtpPopup: false,
      authToken: "",
      openPopup:false,
      accountNotActivatedPopup:false,
      openOtpVerificationPopup:false,
      smsToken:"",
      openSuccessPopup:false,
      content:"",
      button:"",
      accountLoginFailed:"",
    };

    this.emailReg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    this.passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const token = localStorage.getItem("authToken");

    if (token) {
      const roles = localStorage.getItem("roles");
      if (roles && roles.includes("Buyer")) this.openLoginPage();
      if (roles && roles.length > 0 && !roles.includes("Buyer")) this.props.navigation.navigate("Dashboard");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  onCloseAccountNotActivatedPopup=()=>{
    this.setState({accountNotActivatedPopup:false})
  }

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  closeEmailPopup=()=>{
    this.setState({openPopup: false})
  }

  onAccountTypeChange = (type: string) => {
    this.setState({loginSignUpType : type, email: '', countryCodeSelected: '', phone: '', isFormValid: false, emailUnregistered: false, invalidEmail: false, invalidPassword: false});
  }

  handleInputChange = (fieldName: keyof S, value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
      isFormValid: this.validateField(fieldName, value)
    };
    this.setState({...updatedState, invalidPassword: false} as S);
  }

  validateField = (loginFieldName: keyof S, value: string | boolean): boolean => {
    if(!value) {
      return false;
    }

    const loginFields: Set<keyof S> = new Set(['password']);
    if(this.state.loginSignUpType === 'sms_account') {
      loginFields.add('phone');
      loginFields.delete('email');
    } else {
      loginFields.delete('countryCodeSelected');
      loginFields.delete('phone');
      loginFields.add('email');
    }
    loginFields.delete(loginFieldName);
    for(let field of loginFields) {
       if(!this.state[field]) {
        return false;
      }
    }
    if(loginFieldName === 'password' && (typeof value === "string") && (!this.passwordReg.test(value) || value.length < 8) ) {
      return false;
    }
    return true;
  }


  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    checkAndLogin=(): boolean=> {
      if(this.state.loginSignUpType === "email_account" && !this.emailReg.test(this.state.email)) {
        this.setState({invalidEmail: true})
        return false;
      } else {
        this.setState({invalidEmail: false, content:`To activate the account associated with ${this.state.email}, click the "Send Activation Link" button.`,button:"Send Activation Link"});
      }

      if(!this.passwordReg.test(this.state.password)) {
        this.setState({invalidPassword: true});
        return false;
      }
      this.setState({emailUnregistered: false});

      return this.doEmailLoggingIn();
    }

    doEmailLoggingIn(): boolean {

      if (this.state.password === null || this.state.password.length === 0) {
        this.showAlert("Error", configJSON.errorPasswordNotValid);
        return false;
      }
      if(this.state.loginSignUpType !== "email_account"){
        this.setState({content:`To activate the account associated with ${this.state.phone}, click the "Send Activation OTP" button.`,button:"Send Activation OTP"});
      }
      if(this.state.checkedRememberMe){
        localStorage.setItem(this.state.loginSignUpType === "email_account" ? "email" : "full_phone_number",this.state.loginSignUpType === "email_account" ? this.state.email : `+${this.state.phone}`); localStorage.setItem("password", this.state.password)
      } else{
        localStorage.setItem(this.state.loginSignUpType === "email_account" ? "email" : "full_phone_number",""); localStorage.setItem("password", "")
      }

      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const attrs = {
        [`${this.state.loginSignUpType === "email_account" ? "email" : "full_phone_number"}` ]: this.state.loginSignUpType === "email_account" ? this.state.email : "+" + this.state.phone,
        password: this.state.password,
      };

      const data = {
        type: this.state.loginSignUpType,
        attributes: attrs,
      };

      const httpBodyData = {
        data: data,
      };

      const requestMessages = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessages.messageId;
      requestMessages.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPoint
      );

      requestMessages.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessages.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBodyData)
      );

      requestMessages.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessages.id, requestMessages);

      return true;
    }

    errorResponse(responseJson: any) {
      if (responseJson && responseJson.errors) {
        const error = responseJson.errors[0];
        if (error['failed_login'] && error['failed_login'] === "Account not found.") {
          this.setState({ emailUnregistered: true,accountLoginFailed:"" });
        } else if(error['failed_login'] && error['failed_login'] === "Login Failed") {
          this.setState({ invalidPassword: true,accountLoginFailed:"" });
        } else if(error['pin'] && error['pin'] === "Invalid Pin for Phone Number or email") {
          this.setState({ inValidOtp: true,accountLoginFailed:"" });
        }else if(error['failed_login'] && error['failed_login'] === "Account not activated.") {
          this.setState({ accountNotActivatedPopup: true,accountLoginFailed:"" });
        } else if(error['failed_login']){
            this.setState({accountLoginFailed:error['failed_login']})
        }
        else {
          this.parseApiErrorResponse(responseJson);
          this.sendLoginFailMessage();
        }
      }
    }

    openLoginPage = async()=> {
      // Merge Engine - Navigation - btnEmailLogIn - Start
      let orderCreated = await getStorageData('order_created',true)
      if(orderCreated){
        const msg: Message = new Message(getName(MessageEnum.NavigationMenuMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      }else{
      const msg: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
      // Merge Engine - Navigation - btnEmailLogIn - End
    }

    closeOtpPopup=()=>{
      this.setState({openOtpPopup: false})
    }

    closeOtpVerificationPopup=()=>{
      this.setState({openOtpVerificationPopup: false})
    }


  otpAuthentication=(otp:string)=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };

    const httpBody = {
      pin: otp,
      otp_type: this.state.loginSignUpType === "email_account" ? "email" : "sms"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.authenticationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.twoFactorOTPVerify
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

    TwoFAuth=()=>{
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const httpBody = {
        [`${this.state.loginSignUpType === "email_account" ? "email" : "full_phone_number"}` ]: this.state.loginSignUpType === "email_account" ? this.state.email : "+" + this.state.phone
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.twoFactorAuthentication
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    otpVerifyToken=(id: string, responseJson: any)=>{
      if(id === this.otpCallId) {
        if(responseJson && responseJson.token) {
          this.setState({openOtpPopup: true, authToken: responseJson.token});
        } else {
          this.errorResponse(responseJson);
        }
      } else if(id === this.otpCallIdVerification){
        this.handleCreateAccountApiResponse(responseJson)
      }
    }

setResendOtpResponseValue=(id:string, responseJson:{data:{message?:string;id?:string;type?:string};meta?:{token:string}})=>{
  if(id === this.sendVerificationEmailCallId){
    if(responseJson.data.message ==="Verification email has been sent."){
    this.setState({accountNotActivatedPopup:false, openPopup:true})
    } else if(responseJson.data.type === "sms_otp" && responseJson.meta){
      this.setState({accountNotActivatedPopup:false, openOtpVerificationPopup:true, smsToken:responseJson.meta.token})
    }
  }
}
closeConfirmationPopup=()=>{
  this.setState({openSuccessPopup:false})
}

    loginUser=(id: string, responseJson: any)=>{
      if(id === this.authenticationCallId) {
        if(responseJson && responseJson.meta && responseJson.meta.token) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.props.loggedInUser && this.props.loggedInUser(true)
          this.saveLoggedInUserData(responseJson);
          this.sendLoginSuccessMessage();

          const roles = responseJson.data?.attributes?.user_roles ?? [];
          localStorage.setItem("roles", JSON.stringify(roles));

          if (roles[0] === "Buyer") this.openLoginPage();
          else if (roles.length > 0) this.props.navigation.navigate("Dashboard");
          else this.openLoginPage();
        } else {
          this.errorResponse(responseJson);
        }
    }
    }

    handleCreateAccountApiResponse=(responseJson: any)=> {
      if(typeof responseJson === 'object' && responseJson.hasOwnProperty('status') && responseJson.status === 500) {
        return false
      } else if (!responseJson.errors) {
        const msg: Message = new Message(
          getName(MessageEnum.AccoutResgistrationSuccess)
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        if (msg.id === "AccoutResgistrationSuccess") {
          if(responseJson.data.type === 'sms_account') {
            this.setState({openOtpPopup: true, smsToken: responseJson.meta.token})
          } else if(responseJson.data.id === 'account_block/sms_otp' && responseJson.data.type === 'validate_available'){
            this.setState({openSuccessPopup: true,openOtpVerificationPopup:false })
          }
        }
      } else if(responseJson.errors){
        this.setState({ inValidOtp:true });
      }
    }
    changeInValidOtp=()=>{
      this.setState({inValidOtp:false})
    }

    otpAuthenticationForVerification=(otp:string):void=> {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const httpBody = {
        pin: otp,
        "token": this.state.smsToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpCallIdVerification = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.smsValidationApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    sendActivationLink=()=>{
      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };
      let attributes, endPoint
      if(this.state.email){
        attributes = {"email": this.state.email}
        endPoint = `/account_block/accounts/resend_link`
        this.setState({content:`To activate the account associated with ${this.state.email}, click the "Send Activation Link" button.`,button:"Send Activation Link"})
      } else if(this.state.phone){
        attributes = {"full_phone_number": this.state.phone}
        endPoint = `/account_block/accounts/send_otps`
        this.setState({content:`To activate the account associated with ${this.state.phone}, click the "Send Activation OTP" button.`,button:"Send Activation OTP"})
      }
      const httpBody = {
        data:{
          attributes
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sendVerificationEmailCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  handleDashboardLogin = (token: string, userRoles: string[]) => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("roles", JSON.stringify(userRoles));
    if (userRoles.length > 0) {
      this.props.navigation.navigate("Dashboard");
    }
  }
  appleLoginApiCall=(email:string,id_token:string)=>{
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: email,
      unique_auth_id: id_token,
    };

    const data = {
      type: "social_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAppleLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appleLoginSocialEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setApiRequestToken=(responseJson:any)=>{
    if (responseJson) {
      const roles = responseJson.data.attributes.user_roles;
        localStorage.setItem("roles", JSON.stringify(roles));
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.openLoginPage();
        this.props.loggedInUser && this.props.loggedInUser(true)
    } else {
      //Check Error Response
      this.errorResponse(responseJson)
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            this.setState({accountLoginFailed:""})
            if (responseJson.meta.user_role.length > 0 && responseJson.meta.user_role[0] !== "Buyer") {
              this.handleDashboardLogin(responseJson.meta.token, responseJson.meta.user_role);
            } else this.TwoFAuth();
          } else {
            //Check Error Response
            this.errorResponse(responseJson)
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if(apiRequestCallId === this.apiAppleLoginCallId){
          this.setApiRequestToken(responseJson)
        }

        this.otpVerifyToken(apiRequestCallId, responseJson)
        this.loginUser(apiRequestCallId, responseJson);
        this.setResendOtpResponseValue(apiRequestCallId,responseJson)

      }
    }
    return true;
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  navigateToForgotPassword=()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  navigateToSignUp=()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
}
