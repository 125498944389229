Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT"
exports.contentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfposordermanagementfe";
exports.labelBodyText = "cfposordermanagementfe Body";

exports.btnExampleTitle = "CLICK ME";
exports.menuDataEndPoint="bx_block_catalogue/catalogues"
exports.dealsDataEndPoint="bx_block_catalogue/deals_list"
exports.allergensListApiEndPoint = "bx_block_catalogue/show_all_allergens"
exports.getSpiceLevelApiEndPoint = 'bx_block_catalogue/show_all_spice_level'
exports.getSidesApiEndPoint = "bx_block_order_management/orders/show_sides?restaurant_id="
exports.getCustomProductsApiEndPoint = "bx_block_order_management/orders/show_custom_product?restaurant_id="
exports.addItemsToCartApiEndPoint = "bx_block_order_management/pos_orders/create_order_by_pos_user"
exports.getAllCartItemsApiEndPoint = "bx_block_order_management/pos_orders/cart_details?account_id="
exports.updateItemsApiEndPoint = "bx_block_shopping_cart/carts/"
exports.apiMethodTypeDelete = "DELETE"
exports.cancelOrderApiEndPoint = "bx_block_order_management/pos_orders"
exports.updateCartItemsEdit = "bx_block_shopping_cart/carts"

exports.discountReasonEndpoint = "bx_block_promo_codes/coupons/coupon_reasons"
exports.calculateDiscountEndpoint = "bx_block_promo_codes/coupons"
exports.applyDiscountEndpoint = "bx_block_promo_codes/coupons/apply_coupon"
exports.removeDiscountEndpoint = "bx_block_promo_codes/coupons/remove_coupon"
exports.orderListingApiEndPoint = "bx_block_order_management/pos_orders/order_listing"
exports.driverListingApiEndPoint = "bx_block_order_management/pos_orders/drivers_list"
exports.getIndividualOrderApiEndPoint = "bx_block_order_management/pos_orders"
// Customizable Area End
exports.cashOffApi = {
  endpoint: "bx_block_order_management/pos_orders",
  method: "PUT"
};

exports.contentType = "application/json";
exports.postMethod = "POST";
exports.getMethod = "GET";

exports.orderActionAPI = {
  endPoint: "bx_block_dashboard/store_manage_dashboard",
}

exports.orderAction = {
  accept: "order_accept_by_store",
  cancel: "order_cancel_by_store",
  skip: "skip_order_status"
}

exports.orderInfoEndpoint = "bx_block_order_management/orders";
exports.posOrderEndpoint = "bx_block_order_management/pos_orders";
exports.orderManagementEndpoint = "order_management/orders";

exports.customerInfoEndpoint = "get_personal_details";
exports.updateCustomerInfoEndpoint = "edit_personal_details";
exports.deliveryAddressEndpoint = "get_delivery_address";
exports.addDeliveryChargeEndpoint = "add_delivery_charge";
exports.deliveryTypeEndpoint = "add_address_to_order_delivery";
exports.collectionTimeEndpoint = "add_collection_time";
exports.giftCardEndpoint = "apply_gift_card";
exports.couponEndpoint = "apply_coupon";
exports.removeCouponEndpoint = "remove_coupon_code";
exports.removeGiftCardEndpoint = "remove_gift_card";
exports.getOrderDetailsApiEndPoint = "bx_block_order_management/orders/"
exports.getCancelationReason = "bx_block_cfposordermanagementfe/cancellation_reasons"
exports.cancelOrderInitiateApiEndPoint = "bx_block_cfposordermanagementfe/orders"
exports.refundInitiationApiEndPoint = "bx_block_cfposordermanagementfe/orders"
exports.bulkRefundApiEndPoint = "bx_block_payment_admin/bulk_refunds"

exports.getAddressListApi = "bx_block_address/addresses";
exports.orderPaymentApi = "bx_block_discount/order_payments";
exports.orderPaymentEndpoint = "order_payment";

exports.posOrdersApi = "bx_block_order_management/pos_orders";
exports.getChefNoteEndpoint = "get_chef_notes";
exports.addChefNoteEndpoint = "add_chef_notes";
// Customizable Area End
