import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  FilterListRounded,
  SearchRounded,
} from "@material-ui/icons";
import ChefDashboardController, { Props } from "./ChefDashboardController.web";
import DashboardWrapper from "../../../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../../../components/src/Dashboard/Header/Header";
import { ContainedButton, OutlinedButton } from "../../../../../components/src/Button";
import { ContainedIconButton, OutlinedIconButton } from "../../../../../components/src/IconButton";
import CustomGreenCheckbox from "../../../../../components/src/CustomGreenCheckBox.web";
import Modal from "../../../../../components/src/Modal";
import { AllergenIcon, CollectionIcon, DeliveryIcon, CollapseIcon, ExpandIcon, RefreshIcon } from "../../../../../components/src/Icons";
import { BaseInput } from "../../../../../components/src/Inputs/CustomInputs";
import Timer from "../../../../../components/src/Dashboard/ChefDashboard/Timer";
import CustomLoader from "../../../../../components/src/CustomLoader.web";
import { Order, CartItem } from "../types";
import "./ChefDashboard.web.css";

export default class ChefDashboard extends ChefDashboardController {
  searchRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
  }

  renderNoOrders = () => {
    return (
      <div className="chef__no_orders__main">
        <p className="chef__no_orders__main__text">No Current Orders. Tap Refresh for Updates</p>
      </div>
    );
  };

  renderOrderType = (orderType: string) => {
    switch (orderType) {
      case "delivery":
        return <DeliveryIcon />;
      case "collection":
        return <CollectionIcon />;
      default:
        return null;
    };
  };

  searchStaff = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search Order"
          value={this.state.search}
          onChange={this.handleSearchInput}
        />
      </div>
    );
  };

  orderCard = (order: Order) => {
    const allSelected = order?.cart_items?.data?.every(item => item.attributes.ready_for_delivery === true);
    const status = order?.order_details?.data?.attributes?.status;
    const confirmed_at = order?.order_details?.data?.attributes?.confirmed_at;
    const cooking_at = order?.order_details?.data?.attributes?.cooking_at;

    return (
      <div
        data-testid="order-card"
        key={order.order_number}
        className="chef__order__card"
      >
        <div className="chef__order__card__header">
          <p className="chef__order__card__header__order_number">
            Order Number
            <br />
            {order.order_number}
          </p>

          <p className="chef__order__card__header__order_type">
            {this.renderOrderType(order.order_type)}
          </p>
        </div>

        <Timer
          data-testid="order-timer"
          start={
            status === "cooking" ?
            cooking_at :
            confirmed_at
          }
        />

        {order.chef_note && (
          <div className="chef__order__card__chef_note">
            <p>
            <span className="chef__order__card__chef_note__title">Customer:</span> {order.chef_note}
            </p>
          </div>
        )}

        <div className="chef__order__card__body">
          {order.cart_items.data.map((cartItem: CartItem) => {
            const isSelected = cartItem.attributes.ready_for_delivery;

            return (
              <div
                key={cartItem.id}
                data-testid="order-item"
                className="chef__order__card__body__item"
                data-selected={isSelected}
              >
                <p className="chef__order__card__body__item__quantity">{cartItem.attributes.quantity}x</p>

                <div className="chef__order__card__body__item__details">
                  <p className="chef__order__card__body__item__name">{cartItem.attributes.product_name}</p>

                  <div className="chef__order__card__body__item__details__attributes">
                    {cartItem.attributes.sub_category && <p>{cartItem.attributes.sub_category}</p>}
                    {cartItem.attributes.choose_your_type && <p>{cartItem.attributes.choose_your_type.toLowerCase().split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</p>}
                    {this.renderAttributes(cartItem.attributes)}
                  </div>

                  {cartItem.attributes.allergen_excluded && (
                    <div
                    data-testid="allergen-excluded"
                    className="chef__order__card__body__item__allergen_excluded">
                      <AllergenIcon width={24} height={24} />
                      <p>{cartItem.attributes.allergen_excluded}</p>
                    </div>
                  )}
                </div>

                <div className="chef__order__card__body__item__checkbox">
                  <CustomGreenCheckbox
                    disabled={!this.enableMarkReady}
                    checked={isSelected}
                    onChange={() => this.markReady(cartItem.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="chef__order__card__footer">
          <OutlinedButton disabled={!this.enableMarkAllReady} onClick={() => this.markAllReady(order.order_details.data.attributes.id)}>ALL READY</OutlinedButton>

          <OutlinedButton disabled={!allSelected || !this.enableCompleted} onClick={() => this.completeOrder(order.order_details.data.attributes.id)}>COMPLETED</OutlinedButton>
        </div>
      </div>
    );
  };

  renderOrdersInCooking = () => {
    const { ordersInCooking } = this.state;
    return (
      <div
        data-testid="orders-in-cooking"
        className="chef__main"
      >
        <div className="chef__orders">
          {ordersInCooking.map((order: Order) => {
            return this.orderCard(order);
          })}
        </div>
      </div>
    );
  };

  renderOrdersInQueue = () => {
    const { ordersinQueue } = this.state;
    return (
      <div
        data-testid="orders-in-queue"
        className="chef__main"
      >
        <div className="chef__orders">
          {ordersinQueue.map((order: Order) => {
            return this.orderCard(order);
          })}
        </div>
      </div>
    );
  };

  renderFiltersModal = () => {
    return (
      <Modal
        maxWidth="sm"
        open={this.state.openFilters}
        onClose={this.handleCloseFilters}
      >
        <div className="chef__filters">
          <div className="chef__filters__header">
            <span className="chef__filters__title">Filters</span>
            <IconButton data-testid="close-filters-button" onClick={this.handleCloseFilters}>
              <CloseRounded />
            </IconButton>
          </div>

          <div className="chef__filters__body">
            <FormControl
              component="fieldset"
              className="chef__filters__form"
            >
              <FormLabel
                component="legend"
                className="chef__filters__form__title"
              >
                Category
              </FormLabel>
              <Select
                labelId="roles"
                id="roles"
                MenuProps={{
                  classes: { paper: "chef__select__menu", list: "chef__select__list" },
                }}
                value={this.state.filters.category ?? ""}
                onChange={this.handleCategoryChange}
                classes={{
                  root: "chef__select",
                  select: "chef__select__input",
                }}
              >
                {this.state.categoryOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    classes={{ root: "chef__select__item", selected: "chef__select__item selected" }}
                  >
                    <p className="chef__role_selection__label">{option.title}</p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {this.state.filters.category && (
              <FormControl
                component="fieldset"
                className="chef__filters__form"
              >
                <FormLabel
                  component="legend"
                  className="chef__filters__form__title"
                >
                  Item Name
                </FormLabel>
                <Select
                  labelId="roles"
                  id="roles"
                  MenuProps={{
                    classes: { paper: "chef__select__menu", list: "chef__select__list" },
                  }}
                  value={this.state.filters.itemName ?? ""}
                  onChange={this.handleItemChange}
                  classes={{
                    root: "chef__select",
                    select: "chef__select__input",
                  }}
                >
                  {this.state.itemNameOptions.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      classes={{ root: "chef__select__item", selected: "chef__select__item selected" }}
                    >
                      <p className="chef__role_selection__label">{option.title}</p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl
              component="fieldset"
              className="chef__filters__form"
            >
              <FormLabel
                component="legend"
                className="chef__filters__form__title"
              >
                Order Type
              </FormLabel>
              <FormGroup className="chef__filters__form__options">
                <FormControlLabel
                  data-testid="delivery-checkbox"
                  control={
                    <CustomGreenCheckbox
                      checked={this.state.filters.orderType === null}
                      onChange={(_event, checked) => this.handleOrderTypeChange(checked ? null : false, "delivery")}
                      name="orderType"
                    />
                  }
                  classes={{ label: "chef__filters__form__options__label" }}
                  label="All"
                />
                <FormControlLabel
                  data-testid="delivery-checkbox"
                  control={
                    <CustomGreenCheckbox
                      checked={this.state.filters.orderType === "delivery"}
                      onChange={(_event, checked) => this.handleOrderTypeChange(checked, "delivery")}
                      name="orderType"
                    />
                  }
                  classes={{ label: "chef__filters__form__options__label" }}
                  label="Delivery"
                />

                <FormControlLabel
                  data-testid="collection-checkbox"
                  control={
                    <CustomGreenCheckbox
                      checked={this.state.filters.orderType === "collection"}
                      onChange={(_event, checked) => this.handleOrderTypeChange(checked, "collection")}
                      name="orderType"
                    />
                  }
                  classes={{ label: "chef__filters__form__options__label" }}
                  label="Collection"
                />
              </FormGroup>
            </FormControl>
          </div>

          <div className="chef__filters__footer">
            <OutlinedButton
              onClick={this.state.filterApplied ? this.clearFilter : this.handleCloseFilters}
            >
              {this.state.filterApplied ? "Clear" : "Cancel"}
            </OutlinedButton>
            <ContainedButton onClick={this.applyFilter}>Apply Filter</ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { navigation } = this.props;
    const { selectedTab, ordersInCooking, ordersinQueue, isFullscreen } = this.state;

    const ordersInCookingTab = ordersInCooking.length > 0 ? this.renderOrdersInCooking() : this.renderNoOrders();

    const ordersInQueueTab = ordersinQueue.length > 0 ? this.renderOrdersInQueue() : this.renderNoOrders();

    return (
      <div ref={this.elementRef}>
        <DashboardWrapper
          navigation={navigation}
          hideSidebar={isFullscreen}
        >
          {!this.state.isFullscreen && (
            <Header
              navigation={navigation}
              headerTitle="Kitchen Dashboard"
              search={this.searchStaff()}
            />
          )}

          <div className="chef__wrapper">
            <div className="chef__header">
              <Tabs
                value={selectedTab}
                onChange={this.handleTabChange}
                classes={{
                  root: "chef__tab__wrapper",
                  indicator: "chef__tab__hidden_indicator",
                }}
              >
                <Tab
                  label="Cooking"
                  classes={{ root: "chef__tab", selected: "chef__tab__selected" }}
                />
                <Tab
                  label="In Queue"
                  classes={{ root: "chef__tab", selected: "chef__tab__selected" }}
                />
              </Tabs>

              <div className="chef__header__right">
                <OutlinedButton
                  data-testid="filter-button"
                  startIcon={<FilterListRounded />}
                  onClick={this.handleOpenFilters}
                >
                  Filters
                </OutlinedButton>

                <OutlinedIconButton data-testid="refetch-order-list-button" onClick={this.refreshOrders}>
                  <RefreshIcon />
                </OutlinedIconButton>

                <OutlinedIconButton data-testid="fullscreen-button" onClick={isFullscreen ? this.handleExitFullscreen : this.handleEnterFullscreen}>
                  {isFullscreen ? <CollapseIcon data-testid="collapse-icon" /> : <ExpandIcon data-testid="expand-icon" />}
                </OutlinedIconButton>
              </div>
            </div>

            {selectedTab === 0 ? ordersInCookingTab : ordersInQueueTab}

            {selectedTab === 1 && (
              <div className="chef__pagination">
                <ContainedIconButton
                  disabled={this.state.currentPage === 1}
                  onClick={this.handlePreviousPage}
                >
                  <ChevronLeftRounded />
                </ContainedIconButton>

                <span>
                  {this.state.currentPage} of {this.state.totalPages}
                </span>

                <ContainedIconButton
                  disabled={this.state.currentPage === this.state.totalPages}
                  onClick={this.handleNextPage}
                >
                  <ChevronRightRounded />
                </ContainedIconButton>
              </div>
            )}
          </div>
        </DashboardWrapper>

        {this.renderFiltersModal()}

        <CustomLoader loading={this.state.loading} />
      </div>
    );
  }
}
