import React, { ChangeEvent, Component } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import "../../blocks/catalogue/src/Menu.css"

interface PopupProps {
    open: boolean;
    onClose:() => void
    orderTypeChange:(value:string) => void
    statusChange:(value:string) => void
    orderTypeSelected: string[],
    orderStatusSelected:string[]
    applyFilter:() => void
    clearFilter:() => void
}

export default function FilterPopupOrderManagement(props: PopupProps) {
    const {open,onClose,orderTypeSelected,orderStatusSelected,orderTypeChange,statusChange,applyFilter,clearFilter} = props
    return(
    <Dialog open={open} onClose={onClose}
    PaperProps={{ 
      style: { 
        borderRadius: '16px',
      } 
    }} >
      <DialogTitle style={{borderBottom:"1px solid #D4D4D4"}}>
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="0px 16px 0px 32px">
            <div style={{fontFamily:"Barmeno", fontSize:"16px", fontWeight:"bolder"}}>Filters</div>
          <IconButton onClick={clearFilter}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{padding: "0px"}}>
        <Box display="flex" flexDirection="column" padding="24px 40px" style={{gap:"24px", fontFamily:"Barmeno",fontWeight:"bolder"}}>

            <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
            Select the Order Type
            <div className='filter'>
                  {[{name:"All Orders", value:""},{name:"Collection", value:"collection"},{name:"Delivery", value:"delivery"}].map((item,key)=>(
                      <label key={key} style={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", color:'#747474'}}>
                          <input onChange={(event)=>orderTypeChange(item.value)} className='allergenCheckbox' type="checkbox" name="allergen" checked={orderTypeSelected.includes(item.value)} value={item.value}/>
                          {item.name}
                      </label>
                  ))}
              </div>
            </div>

            
            <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
            Select the Order Status
              <div className='filter'>
                  {[{name:"Placed", value:"placed"},{name:"Ready", value:"ready"},{name:"Cooking", value:"cooking"},{name:"Delivered", value:"delivered"},{name:"Collected", value:"collected"},{name:"Cancelled", value:"cancelled"}].map((item,key)=>(
                      <label key={key} style={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", color:'#747474'}}>
                          <input onChange={(event)=>statusChange(item.value)} className='allergenCheckbox' type="checkbox" name="allergen" checked={orderStatusSelected.includes(item.value)} value={item.value}/>
                          {item.name}
                      </label>
                  ))}
              </div>
            </div>

        </Box>
      </DialogContent>
      <DialogActions style={{boxShadow: '0px -4px 15px 0px #4848481F', justifyContent:"space-evenly", padding:'16px'}}>
      <Button id='clear' onClick={clearFilter} style={{width: '220px',height: '56px',border: '1px solid #E11B22',color: '#E11B22'}} >
      CLEAR FILTERS
      </Button>
      <Button id='apply' onClick={applyFilter}  style={{width: '220px',height: '56px',backgroundColor:'#E11B22',color: '#fff'}}>APPLY FILTERS</Button>
    </DialogActions>
    </Dialog>
    )
  }
  