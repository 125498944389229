Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.restaurantListApi = 'bx_block_content_management/restaurants'
exports.btnExampleTitle = "CLICK ME";
exports.selectiveBannerApiEndPoint = "bx_block_content_management/content_managements"
exports.deliveryCollectionApiEndPoint = "bx_block_content_management/order_to_delivers"
exports.discountsApiEndPoint = "bx_block_content_management/special_discounts"
exports.aboutUsPageEndPoint="bx_block_content_management/about_us"
exports.appleStoreURL="https://apps.apple.com/us/app/wingos/id1202229550"
exports.googleStoreURL="https://play.google.com/store/apps/details?id=ie.flipdish.fd8239"

exports.apiContentType = "application/json";
exports.apiMethodType = "GET";
exports.getUserDetailsEndPoint = "bx_block_profile_bio/user_deatils";
exports.getContentRewardsEndPoint="bx_block_loyaltysystem/reward_sections"
// Customizable Area End