export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const allergenImage = require("../assets/image_.svg")
export const sellImage = require("../assets/sell.svg")
export const collectionImage = require("../assets/collection.svg")
export const deliveryImage = require("../assets/delivery.png")
export const deliverySwapImage = require("../assets/delivery_swap.svg")
export const cashOffImage = require("../assets/cashoff.png")
export const PrintIcon = require("../assets/print_icon.svg")
export const cancelledImg = require("../assets/cancelledImg.svg")
export const cancelledOrderPos = require("../assets/cancelled_order_pos.svg")
export const refundButtonIcon = require("../assets/refundIcon.svg")
export const deliverySwapImageDisabled = require("../assets/assignDriverDisabled.svg")
export const refundButtonDisabledIcon = require("../assets/refundIconDisabled.svg")
