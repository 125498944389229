import React from "react";

import {
  Container,
  Box,
  Typography, // Customizable Area Start
  Card,
  CardActionArea,
  CardContent,// Customizable Area End
  Grid,
  IconButton,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import "./RecommendationEngineStyles.css"
import { CatalogueDataUnSorted } from "./../../cfposordermanagementfe/src/CfposordermanagementfeController";
import CustomLoader from "../../../components/src/CustomLoader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RecommendationEngineController, {
  Props,
} from "./RecommendationEngineController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class RecommendationEngine extends RecommendationEngineController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderProductItem = (productItem: CatalogueDataUnSorted) => {
    return (productItem.attributes.sub_sub_category &&(
      
      <div className="stylingCartItems">
        <div style={{ width: "203.5px", height: "180px", borderRadius: "4px", backgroundImage: `url(${productItem.attributes.images.url})`, backgroundRepeat: " no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} />
        <div className="itemDescContainerrecommend">
          <div className="itemNameFavContainer">
            <div>{productItem.attributes.sub_sub_category.title}</div>
            {productItem.attributes.favourited ?
              <IconButton data-testid="remove_favourite" id="remove_favourite" className="favourites_btn" aria-label="add to favorites" onClick={()=>this.removeFromFavourite((Number(productItem.id)))}>
                <FavoriteIcon />
              </IconButton> :
              <IconButton data-testid="add_favourite" style={{ color: "black" }} id="remove_favourite" aria-label="add to favorites" onClick={()=>this.addToFavourite(Number(productItem.id))} >
                <FavoriteBorderOutlinedIcon />
              </IconButton>}

          </div>
          <div style={{fontSize: "14px", color: "#747474",fontWeight: 400}}>{productItem.attributes.sub_sub_category.serves}</div>
          <div>
            £{productItem.attributes.on_its_own_price}
          </div>
        </div>
      <button className="recommendedItemAdd" onClick={()=>this.props.openItemDesc(productItem.id)} >{this.props.buttonText}</button>
      </div>
    ))

  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{display: 'flex', padding: '16px 0px',overflow:"auto", flexDirection:"column",gap:"16px"}}>
            {this.state.productList.length ? 
            <div style={{fontSize:"24px",fontWeight:700}} >You may also like</div>:<></>}
      <div style={{ display: "flex", flexDirection: "row", gap: "16px" }} >
        {this.state.productList.map((productItem: CatalogueDataUnSorted) =>
          this.renderProductItem(productItem)
        )}
      </div>
      <CustomLoader loading={this.state.loading}/>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
