import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import moment from "moment-timezone";
import { closeConnection, connection } from "../../../components/src/Dashboard/WebSockets";
import { notificationConfig } from "../../../components/src/Dashboard/WebSockets/Notification";
import { createMessage, getSessionStorage } from "../../../components/src/utils";
import { notificationSound } from "./assets";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export type RealtimeOrder = {
  id: number;
  order_number: string;
  amount: string;
  created_at: string;
  updated_at: string;
  account_id: number;
  customer_name: string | null;
  coupon_code_id: string | null;
  delivery_address_id: string | null;
  sub_total: number;
  total: number;
  status: string;
  applied_discount: number;
  cancellation_reason: string | null;
  order_date: string | null;
  is_gift: boolean;
  placed_at: string;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string;
  shipment_id: string | null;
  delivery_charges: string | null;
  tracking_url: string | null;
  payment_failed_at: string | null;
  returned_at: string | null;
  tax_charges: number;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  payment_pending_at: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: string | null;
  shipping_discount: string | null;
  shipping_net_amt: string | null;
  shipping_total: string | null;
  total_tax: string | null;
  razorpay_order_id: string | null;
  charged: string | null;
  invoiced: boolean;
  invoice_id: string;
  custom_label: string | null;
  promo_code_id: string | null;
  order_type: string;
  prepare_immediately: boolean;
  redeem_point: string | null;
  points_worth: number;
  notes_to_chef: string;
  gift_card_amount: string | null;
  gift_card_id: string | null;
  collected_at: string;
  order_medium: string;
  payment_attempts: number;
  ready_at: string | null;
  order_completed_at: string;
  restaurant_id: number;
  receipt_printed: boolean;
  receipt_count: number;
  cooking_at: string | null;
  is_allocated_to_driver: boolean;
  pos_user_id: string | null;
  is_priority: boolean;
  cancel_reason_id: number;
  partial_cancel: boolean;
  cancel_initiated_at: string | null;
  refund_initiated_at: string;
  refund_amount: number;
  original_total: number;
  refund_payment_type: string | null;
  request_sent_to_manager: string | null;
  cashed_off: boolean;
  parent_id: number;
  schedule_time: string | null;
  payment_method_used_amount: number;
  refund_reward_point_amount: number;
  refund_e_gift_card_amount: number;
};

export interface RealtimeNotification {
  sucess_message: string;
  order: RealtimeOrder;
};

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  activeRole: string;
  userDetail: any;
  notificationsQueue: RealtimeOrder[];
  notificationModal: {
    open: boolean;
  };
  successModal: {
    open: boolean;
    message: string;
  };
  errorModal: {
    open: boolean;
    message: string;
  };
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserDetailsApiCallId: string = "";
  getRestaurantDetailsCallId: string = "";
  getUserPermissionsCallId: string = "";
  getAcceptOrderCallId: string = "";
  getCancelOrderCallId: string = "";

  subscription: WebSocket | null = null;
  audio: HTMLAudioElement | null;
  audioIntervalId: any | null = null;

  settings = getSessionStorage("restaurant_settings");
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.audio = new Audio(notificationSound);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      activeRole: "",
      userDetail: {},
      notificationsQueue: [],
      notificationModal: {
        open: false,
      },
      successModal: {
        open: false,
        message: "",
      },
      errorModal: {
        open: false,
        message: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    await this.getToken();
    this.setState({ activeRole: sessionStorage.getItem("role") || "" });

    this.getUserDetails();
    this.getUserPermissions();
    this.getRestaurantDetails();

    this.subscribe();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!response) return;

    this.expiredToken(response);
    this.notAuthorised(response);

    const handler = this.getMessageHandler(callId);

    if (handler) handler(response);
    if (error) runEngine.debugLog("API Error", error);
    // Customizable Area End
  }
  // Customizable Area Start
  getMessageHandler(callId: string) {
    const messageHandlers = {
      [this.getUserDetailsApiCallId]: this.handleUserDetails,
      [this.getRestaurantDetailsCallId]: this.handleRestaurantDetails,
      [this.getUserPermissionsCallId]: this.handleUserPermissions,
      [this.getAcceptOrderCallId]: this.handleAcceptedOrder,
      [this.getCancelOrderCallId]: this.handleCancelOrder,
    };

    return messageHandlers[callId];
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (this.state.notificationsQueue.length > 0 && this.state.notificationsQueue !== prevState.notificationsQueue)
      this.openNotification();
  };

  expiredToken = (response: any) => {
    if (response?.errors?.[0]?.token) {
      this.showAlert("token", "token");
      return;
    }
  };

  notAuthorised = (response: any) => {
    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }
  };

  handleAcceptedOrder = (response: any) => {
    const data = response;

    if (data?.message) {
      this.closeNotification();
      this.setState({
        successModal: {
          open: true,
          message: data.message,
        }
      });
    };

    if (data?.error) {
      this.setState({
        errorModal: {
          open: true,
          message: data.error,
        }
      });
    };
  };

  handleCancelOrder = (response: any) => {
    const data = response;

    if (data?.message) {
      this.closeNotification();
      this.setState({
        successModal: {
          open: true,
          message: data.message,
        }
      });
    };

    if (data?.error) {
      this.setState({
        errorModal: {
          open: true,
          message: data.error,
        }
      });
    };
  };

  handleUserDetails = (response: any) => {
    localStorage.setItem("restaurant_information",JSON.stringify(response))
    this.setState({
      userDetail: response
    });
  };

  handleRestaurantDetails = (response: any) => {
    sessionStorage.setItem("restaurant_settings", JSON.stringify(response));
    response?.time_zone && moment.tz.setDefault(response?.time_zone);
  };

  handleUserPermissions = (response: any) => {
    if (response?.success) {
      sessionStorage.setItem("permissions", JSON.stringify(response?.data));
    };
  };

  async componentWillUnmount() {
    this.unsubscribe();
  };

  subscribe = () => {
    const allowedRoles = ["In Store Operator", "In Store Manager", "Online Store Manager"];
    if (
      allowedRoles.includes(this.state.activeRole)
    ) {
      const ws = connection(this.state.token);
      this.subscription = ws.getWebSocket();

      notificationConfig({
        subscription: this.subscription,
        addNotification: this.enqueue,
      });
    }
  };

  unsubscribe = () => {
    if (this.subscription) {
      closeConnection(this.subscription);
      this.subscription = null;
    }
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");

    if (token) this.setState({ token: token });
    else this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  getUserPermissions = () => {
    const header = {
      "Content-Type": webConfigJSON.userDetails.contentType,
      token: this.state.token
    };

    const getDataMsg = createMessage(
      "GET",
      webConfigJSON.getUserPermissionsEndpoint,
      header
    );

    this.getUserPermissionsCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  acceptOrder = () => {
    const header = {
      "Content-Type": webConfigJSON.userDetails.contentType,
      token: this.state.token
    };

    const order = this.state.notificationsQueue[0];

    const getDataMsg = createMessage(
      "GET",
      `${webConfigJSON.orderManagerEndpoint}/${order?.id}/${webConfigJSON.acceptOrder}`,
      header
    );

    this.getAcceptOrderCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  cancelOrder = () => {
    const header = {
      "Content-Type": webConfigJSON.userDetails.contentType,
      token: this.state.token
    };

    const order = this.state.notificationsQueue[0];

    const getDataMsg = createMessage(
      "GET",
      `${webConfigJSON.storeManagerEndpoint}/${order?.id}/${webConfigJSON.cancelOrder}`,
      header
    );

    this.getCancelOrderCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getUserDetails = () => {
    const header = {
      "Content-Type": webConfigJSON.userDetails.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.userDetails.endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.userDetails.method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRestaurantDetails = () => {
    const header = {
      "Content-Type": webConfigJSON.restaurantDetails.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRestaurantDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.restaurantDetails.endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.restaurantDetails.method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openNotification = () =>
    this.setState({ notificationModal: { open: true } }, this.play);

  closeNotification = () =>
    this.setState({ notificationModal: { open: false } }, () => {
      this.pause();
      this.dequeue();
    });

  closeSuccessModal = () =>
    this.setState({
      successModal: {
        open: false,
        message: ""
      }
    });

  closeErrorModal = () =>
    this.setState({
      errorModal: {
        open: false,
        message: ""
      }
    });

  viewDetails = () => {
    const id = this.state.notificationsQueue[0]?.id;

    this.props.navigation.navigate("OrderNumber", { id });
    this.closeNotification();
  };

  // Enqueue: Add an item to the queue
  enqueue = (item: RealtimeOrder) => {
    this.setState((prevState) => ({
      notificationsQueue: [...prevState.notificationsQueue, item],
    }));
  };

  // Dequeue: Remove the first item from the queue
  dequeue = () => {
    if (this.state.notificationsQueue.length === 0) {
      return;
    }
    this.setState((prevState) => ({
      notificationsQueue: prevState.notificationsQueue.slice(1), // Remove first item
    }));
  };

  // Clear the queue
  clearQueue = () => {
    this.setState({ notificationsQueue: [] });
  };

  play = () => {
    this.audioIntervalId = setInterval(() => this.audio?.play(), 1000);
  };
  pause = () => {
    this.audioIntervalId && clearInterval(this.audioIntervalId);
    this.audio?.pause();
  };
  toggle = () => (this.audio?.paused ? this.play() : this.pause());
  // Customizable Area End
}
