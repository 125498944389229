import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Dashboard/Header/Header";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import "../../customform/src/CustomForm.css"
// Customizable Area End

import StockManagementReportController, {
  Props,
} from "./StockManagementReportController";
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";

export default class StockManagementReport extends StockManagementReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rows = [
    this.createData('Banotti cream pie', "Sweets", 6, "In Stock"),
    this.createData('Ben & Jery’s ice cream', "Sweets", 9, "Out of Stock"),
    this.createData('Breaded mozzarella stciks', "Light Bites", 24, "In Stock"),
    this.createData('Breaded chicken breast strips', "Light Bites", 3, "Out of Stock"),
    this.createData('Carrot Cake', "Sweets", 16, "In Stock"),
  ];
  createData(
    name: string,
    category: string,
    quantity: number,
    status: string,
  ) {
    return { name, category, quantity, status };
  }

  tableHeader = () => {
    return (
      <TableHead style={{
        backgroundColor: "#FCE8E9", borderRadius: "8px",
        overflow: "hidden",
      }}>
        <TableRow>
          <TableCell className="stockTableCell">Item Name</TableCell>
          <TableCell className="stockTableCell" align="center">Category</TableCell>
          <TableCell className="stockTableCell" align="center">Quantity</TableCell>
          <TableCell className="stockTableCell" align="center">Status</TableCell>
          <TableCell className="stockTableCell" align="center">Action</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  tableBody = () => {
    return (
      <TableBody style={{ backgroundColor: "#FFFFFF" }}>
        {this.rows.map((row) => (
          <TableRow
            key={row.name}
            style={{ border: "0px" }}
          >
            <TableCell className="stockTableCell" >{row.name}</TableCell>
            <TableCell className="stockTableCell" align="center">{row.category}</TableCell>
            <TableCell className="stockTableCell" align="center">{row.quantity}</TableCell>
            <TableCell style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="stockTableCell" align="center"><div className={row.status === "In Stock" ? "stockRetportStatusDivInStock" : "stockRetportStatusDivOutOfStock"}>{row.status}</div></TableCell>
            <TableCell className="stockTableCell" align="center"><IconButton onClick={this.editAction}><EditOutlinedIcon /></IconButton></TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  dialogContent = () => {
    return (
      <div className="actionDialogStockDivDIsplayCol" style={{ gap: "24px" }}>
        <div className="actionDialogStockTitle"><div style={{ fontWeight: 700, fontSize: "18px" }}>
          {this.state.action === "edited" ? 'Action' : 'Add new items'}</div>
          <IconButton onClick={this.closeAction}><Close /></IconButton>
        </div>
        <div className="actionDialogStockDivDIsplayCol" style={{ gap: "24px" }}>
          <div className="actionDialogStockDivDIsplayCol" style={{ gap: "8px" }}>
            <label className="stockActionLabel">Item Name</label>
            <input className="stockActionInput" placeholder="Item Name" type="text" name="selectedItemName" value={this.state.selectedItemName} onChange={(event) => { this.handleInputChange("selectedItemName", event.target.value) }} />
          </div>
          <div className="actionDialogStockDivDIsplayCol" style={{ gap: "8px" }}>
            <label className="stockActionLabel">Category</label>
            <input className="stockActionInput" placeholder="Category" type="text" name="selectedCategory" value={this.state.selectedCategory} onChange={(event) => { this.handleInputChange("selectedCategory", event.target.value) }} />
          </div>
          <div className="actionDialogStockDivDIsplayCol" style={{ gap: "8px" }}>
            <label className="stockActionLabel">Status</label>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px", flexWrap: 'wrap' }}>
              {["In stock", "Out of stock"].map((item) => (
                <label style={{
                  cursor: 'pointer', display: 'inline-flex', alignItems: 'center', padding: "6px 12px 6px 8px", borderRadius: "26px",
                  color: '#1D1D1D', backgroundColor: '#F1F5F9', border: this.state.status === item ? "1px solid #404040" : "0px", fontWeight: 700
                }} key={item} >
                  <input onChange={this.statusChange} checked={this.state.status === item} type='radio' name={item} value={item} style={{ display: 'none' }} />
                  {item}
                </label>
              ))}
            </div>
          </div>
          <div className="actionDialogStockDivDIsplayCol" style={{ gap: "8px" }}>
            <label className="stockActionLabel">Quantity in stock</label>
            <input className="stockActionInput" placeholder="Quantity in stock" type="text" name="selectedQuantity" value={this.state.selectedQuantity} onChange={(event) => { this.handleInputChange("selectedQuantity", event.target.value) }} />
          </div>
        </div>
      </div>
    )
  }

  dialogAction = () => {
    return (
      <div className="actionDialogStockActions">
        <button className="cancelStockButton" onClick={this.closeAction}>Cancel</button>
        <button className="addNewStockButton">{this.state.action === "edited" ? 'Update' : 'Add new item'}</button>
      </div>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardWrapper navigation={this.props.navigation}>
        <Header navigation={this.props.navigation} headerTitle={"Stock Report"}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>

              <div className="custom-select" style={{ width: "220px", maxWidth: "220px", height: "44px" }}>
                <div tabIndex={0} className={`${this.state.isActive ? 'select-active' : ''}`}>
                  <div style={{ color: "#1D1D1D" }} id="selection-drop" className={`select-selected ${this.state.isActive && 'categories-select-selected'}`} onClick={() => this.toggleDropdown('isActive')}>
                    {this.state.categories ? `${this.state.categories}` : 'Categories'}
                  </div>
                  <div className={`select-items ${this.state.isActive ? '' : 'select-hide'}`} style={{ top: '46px' }}>
                    {this.categoriesToselect().map(value =>
                      <div key={value} style={{ height: "38px", borderBottom: "1px solid #F1F1F1" }} className="optionSelected" onClick={(event) => { this.handleInputChange('categories', value) }}>{value}</div>
                    )}</div>
                </div>
              </div>

              <div className="custom-select" style={{ width: "220px", maxWidth: "220px", height: "44px" }}>
                <div tabIndex={0} className={`${this.state.isActive2 ? 'select-active' : ''}`}>
                  <div style={{ color: "#1D1D1D" }} id="selection-drop" className={`select-selected ${this.state.isActive2 && 'categories-select-selected'}`} onClick={() => this.toggleDropdown('isActive2')}>
                    {this.state.status ? `${this.state.status}` : 'Status'}
                  </div>
                  <div className={`select-items ${this.state.isActive2 ? '' : 'select-hide'}`} style={{ top: '46px' }}>
                    {this.statusToselect().map(value =>
                      <div style={{ height: "38px", borderBottom: "1px solid #F1F1F1" }} key={value} className="optionSelected" onClick={(event) => { this.handleInputChange('status', value) }}>{value}</div>
                    )}</div>
                </div>
              </div>

              <div className="custom-select" style={{ width: "220px", maxWidth: "220px", height: "44px" }}>
                <div tabIndex={0} className={`${this.state.isActive3 ? 'select-active' : ''}`}>
                  <div style={{ color: "#1D1D1D" }} id="selection-drop" className={`select-selected ${this.state.isActive3 && 'categories-select-selected'}`} onClick={() => this.toggleDropdown('isActive3')}>
                    {this.state.quantity ? `${this.state.quantity}` : 'Quantity'}
                  </div>
                  <div className={`select-items ${this.state.isActive3 ? '' : 'select-hide'}`} style={{ top: '46px' }}>
                    {this.quantitiesToselect().map(value =>
                      <div key={value} style={{ height: "38px", borderBottom: "1px solid #F1F1F1" }} className="optionSelected" onClick={(event) => { this.handleInputChange('quantity', value) }}>{value}</div>
                    )}</div>
                </div>
              </div>

            </div>
            <button className="addNewStockButton" onClick={this.addAction}>Add new item</button>
          </div>

          <TableContainer style={{ borderRadius: "4px" }}>
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              {this.tableHeader()}
              {this.tableBody()}
            </Table>
          </TableContainer>

        </div>
        <Dialog open={this.state.addEditStockPopup} onClose={this.closeAction} className="actionDialogStock">
          {this.dialogContent()}
          {this.dialogAction()}
        </Dialog>
      </DashboardWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
