import React from "react";
import moment from "moment-timezone";
import { Popover } from "@mui/material";
import { CloseRounded, EditRounded } from "@material-ui/icons";
import { Event, View } from "react-big-calendar";
import { getEventColor, getSessionStorage, hasPermission } from "../../../../utils";
import { ChevronRight, InfoIcon, TrashIcon } from "../../../../Icons";
import { UnstyledIconButton } from "../../../../IconButton";
import { WingosPreview } from "../../../../Dropzone";
import "./Event.css";

interface EventComponentProps {
  event: Event;
  selectedStaff: number | null;
  selectedRole: string | null;
  activeView: View;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  style?: any;
}

export const EventComponent = (props: EventComponentProps) => {
  const { selectedStaff, selectedRole, activeView, openShiftPopup, event, style } = props;

  // permissions
  const enableLogin = hasPermission("StaffScheduleAccount", "staff_login");
  const enableLogout = hasPermission("StaffScheduleAccount", "staff_logout");
  const enableEditShift = hasPermission("StaffScheduleAccount", "update_shift");
  const enableDeleteShift = hasPermission("StaffScheduleAccount", "delete_shift");
  const enableDeleteTimeoff = hasPermission("StaffScheduleAccount", "deleteTimeoff");

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // settings
  const settings = getSessionStorage("restaurant_settings");
  const time_format = settings?.time_format;
  const is24HourFormat = time_format === "24-hour";

  const { start, end, title, resource } = event;
  const { status, is_on_leave, login_details, openLogin } = handleResource(resource);
  const statusLabel = status.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

  // start and end time
  const startTime = moment(start).format(is24HourFormat ? "HH:mm" : "hh:mm A");
  const endTime = moment(end).format(is24HourFormat ? "HH:mm" : "hh:mm A");
  const startDate = moment(start).format("yyyy-MM-DD");
  const isToday = moment(moment(start)).isSame(moment(), "day");
  const before = moment(moment(start)).isSameOrBefore(moment(), "day");

  // login details and total hours
  const diffInMinutes = moment(login_details?.logout).diff(login_details?.login, "minutes");
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const totalHours = `${hours} hours ${minutes} mins`;

  // functions
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div
      className="calendar__event"
      data-view={activeView}
      data-status={status}
      data-after={!before}
      style={style}
    >
      {selectedStaff ? (
        <>
          {before ? (
            <EventStatusBefore
              handleOpen={handleOpen}
              statusLabel={statusLabel}
              isOnLeave={is_on_leave}
              activeView={activeView}
              isToday={isToday}
              resource={resource}
              openLogin={openLogin}
              event={event}
              enableLogin={enableLogin}
              enableLogout={enableLogout}
            />
          ) : (
            <EventStatusAfter
              selectedRole={selectedRole}
              openShiftPopup={openShiftPopup}
              event={event}
              statusLabel={statusLabel}
              handleOpen={handleOpen}
              startTime={startTime}
              endTime={endTime}
              is24HourFormat={is24HourFormat}
              isOnLeave={is_on_leave}
              activeView={activeView}
              isToday={isToday}
              resource={resource}
              openLogin={openLogin}
              enableEditShift={enableEditShift}
            />
          )}
        </>
      ) : (
        <DefaultEventView
          activeView={activeView}
          resource={resource}
          title={title}
          startTime={startTime}
          endTime={endTime}
          is24HourFormat={is24HourFormat}
        />
      )}
      <EventPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        status={status}
        statusLabel={statusLabel}
        startDate={startDate}
        startTime={startTime}
        endTime={endTime}
        is24HourFormat={is24HourFormat}
        isOnLeave={is_on_leave}
        resource={resource}
        totalHours={totalHours}
        enableDeleteShift={enableDeleteShift}
        enableDeleteTimeoff={enableDeleteTimeoff}
      />
    </div>
  );
};

// EventStatusBefore Component
interface EventStatusBeforeProps {
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  statusLabel: string;
  isOnLeave: boolean;
  activeView: View;
  isToday: boolean;
  resource: any;
  openLogin: (event: Event) => void;
  event: Event;
  enableLogin: boolean;
  enableLogout: boolean;
};

const EventStatusBefore = ({ handleOpen, statusLabel, isOnLeave, activeView, isToday, resource, openLogin, event, enableLogin, enableLogout }: EventStatusBeforeProps) => {
  const disableLogin = (() => {
    return !isToday || isOnLeave || (resource?.login_details?.is_logged_in ? !enableLogout : !enableLogin);
  })();

  return (
    <>
      <div className="calendar__event__status">
        <UnstyledIconButton onClick={handleOpen}>
          <InfoIcon width={16} height={16} />
        </UnstyledIconButton>
        <p className="calendar__event__title">{statusLabel}</p>
      </div>
      {(activeView !== "month" && !isOnLeave) && (
        <button disabled={disableLogin} className="calendar__event__login" onClick={() => openLogin(event)}>
          {resource?.login_details?.is_logged_in && resource?.login_details?.is_logged_out ? (
            <span>Logged</span>
          ) : (
            <>
              <span>{resource?.login_details?.is_logged_in ? "Log Out" : "Log in"}</span>
              <ChevronRight width={16} height={16} />
            </>
          )}
        </button>
      )}
    </>
  )
};

// EventStatusAfter Component
interface EventStatusAfterProps {
  selectedRole: string | null;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  event: Event;
  statusLabel: string;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  startTime: string;
  endTime: string;
  is24HourFormat: boolean;
  isOnLeave: boolean;
  activeView: View;
  isToday: boolean;
  resource: any;
  openLogin: (event: Event) => void;
  enableEditShift: boolean;
};

const EventStatusAfter = ({ selectedRole, openShiftPopup, event, statusLabel, handleOpen, startTime, endTime, is24HourFormat, isOnLeave, activeView, isToday, resource, openLogin, enableEditShift }: EventStatusAfterProps) => {
  return (
    <>
      {!isOnLeave ? (
        <div className="calendar__event__status">
          <UnstyledIconButton disabled={!enableEditShift} onClick={() => selectedRole && openShiftPopup(event.resource.id, selectedRole, event)}>
            <EditRounded fontSize="small" />
          </UnstyledIconButton>
          <p className="calendar__event__timestamp" onClick={handleOpen}>
            {moment(startTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")} - {moment(endTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")}
          </p>
        </div>
      ) : (
        <div className="calendar__event__status">
          <UnstyledIconButton onClick={handleOpen}>
            <InfoIcon width={16} height={16} />
          </UnstyledIconButton>
          <p className="calendar__event__timestamp" onClick={handleOpen}>
            {statusLabel}
          </p>
        </div>
      )}
      {(activeView !== "month" && !isOnLeave) && (
        <button disabled={!isToday || isOnLeave} className="calendar__event__login" onClick={() => openLogin(event)}>
          {resource?.login_details?.is_logged_in && resource?.login_details?.is_logged_out ? (
            <span>Logged</span>
          ) : (
            <>
              <span>{resource?.login_details?.is_logged_in ? "Log Out" : "Log in"}</span>
              <ChevronRight width={16} height={16} />
            </>
          )}
        </button>
      )}
    </>
  );
};

// DefaultEventView Component
interface DefaultEventViewProps {
  activeView: View;
  startTime: string;
  endTime: string;
  is24HourFormat: boolean;
  resource: any;
  title: React.ReactNode;
};

const DefaultEventView = ({ activeView, resource, title, is24HourFormat, endTime, startTime }: DefaultEventViewProps) => (
  <div className="calendar__event__default">
    {activeView !== "week" ? (
      <>
        {resource?.role && (
          <div
            className="calendar__event__role_marker"
            data-role={getEventColor(resource.role).role}
          />
        )}
        <p className="calendar__event__title">{title}</p>
      </>
    ) : (
      <>
        {resource?.role && (
          <div
            className="calendar__event__role_marker"
            data-role={getEventColor(resource.role).role}
          />
        )}
        <p className="calendar__event__title">{moment(startTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")} - {moment(endTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</p>
      </>
    )}
  </div>
);

// EventPopover Component
interface EventPopoverProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  status: string;
  statusLabel: string;
  startDate: string;
  startTime: string;
  endTime: string;
  is24HourFormat: boolean;
  isOnLeave: boolean;
  resource: any;
  totalHours: string;
  enableDeleteShift: boolean;
  enableDeleteTimeoff: boolean;
};

export const EventPopover = ({ anchorEl, handleClose, status, statusLabel, startDate, startTime, endTime, is24HourFormat, isOnLeave, resource, totalHours, enableDeleteShift, enableDeleteTimeoff }: EventPopoverProps) => {
  const { staff_id, shift_id, timeoff_details, login_details, deleteTimeoff, deleteShift } = handleResource(resource);
  const before = moment(moment(startDate)).isBefore(moment(), "day");

  const showDelete = (() => {
    if (isStoreManager) return true;
    return !before;
  })();

  return (
    <Popover
      disableRestoreFocus
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: "event_info__wrapper"
      }}
    >
      <div className="event_info__header">
        <span>{startDate}</span>
        <CloseRounded fontSize="small" style={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <div className="event_info__body">
        <div className="event_info__body__title">
          <div className="event_info__title_header">
            <span className="event_info__title" data-status={status}>{statusLabel}</span>
            {showDelete && (
              <UnstyledIconButton
                disabled={isOnLeave ? !enableDeleteTimeoff : !enableDeleteShift}
                onClick={() => isOnLeave ? deleteTimeoff(timeoff_details?.id, staff_id) : deleteShift(shift_id, staff_id)}
                color="error"
              >
                <TrashIcon />
              </UnstyledIconButton>
            )}
          </div>
          {!isOnLeave ? (
            <span className="event_info__timestamp">{moment(startTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")} - {moment(endTime, ["hh:mm A"]).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</span>
          ) : (
            <span className="event_info__timestamp">{timeoff_details?.description}</span>
          )}
        </div>
        {!isOnLeave && (login_details?.login || login_details?.logout) && (
          <div className="event_info__login_logout">
            {login_details?.login && <span>Log in - {moment(login_details?.login).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</span>}
            {login_details?.logout && <span>Log out - {moment(login_details?.logout).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</span>}
          </div>
        )}
        {!isOnLeave && (login_details?.login && login_details?.logout) && (
          <div className="event_info__total_hours">
            <span>Total Hours</span>
            <span>{totalHours}</span>
          </div>
        )}
        {(isOnLeave && timeoff_details?.attachment) && (
          <WingosPreview download files={[timeoff_details?.attachment]} />
        )}
      </div>
    </Popover>
  );
};

const handleResource = (resource: any) => {
  return {
    id: resource?.id,
    staff_id: resource?.staff_id,
    shift_id: resource?.shift_id,
    type: resource?.type,
    name: resource?.name,
    role: resource?.role,
    status: resource?.status,
    login_details: resource?.login_details,
    timeoff_details: resource?.timeoff_details,
    is_on_leave: resource?.is_on_leave,
    openLogin: resource?.openLogin,
    deleteTimeoff: resource?.deleteTimeoff,
    deleteShift: resource?.deleteShift,
  };
};

const role = sessionStorage.getItem("role");
const isStoreManager = role === "In Store Manager";
