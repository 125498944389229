import * as React from "react";
import DatePicker, {registerLocale}  from 'react-datepicker'
import { enGB } from 'date-fns/locale';
import "./CustomDate.css"
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import { format } from 'date-fns';
import { convertToDate } from "./utils";

registerLocale('en-GB', enGB);

interface MobileNumberFieldProps<S> {
  onDateChange: (fieldName: keyof S, value: string |null) => void;
  dateFromParent: string |null;
  name?:string|undefined;
  dateFormat?: string;
  immediate?:boolean;
  dob?: boolean;
}
export default function CustomCalendar<S>({
  onDateChange,
  dateFromParent,
  name='',
  dateFormat,
  immediate = false,
  dob = false
}: MobileNumberFieldProps <S>) {

  const [isOpen, setIsOpen] = React.useState(false);
  const [date, setDate] = React.useState<Date|null>(new Date());

  const toggleCalendarView = () => {
    setIsOpen(!isOpen)
  };

  React.useEffect(() => {
    if (dateFromParent) {
      const parsedDate = new Date(dateFromParent);
      if (!isNaN(parsedDate.getTime())) {
        setDate(dob ? parsedDate : convertToDate(dateFromParent));
      } else {
        setDate(convertToDate(dateFromParent));
      }
    } else {
      setDate(null);
    }
  }, [dateFromParent]);

  const handleAction = (date: Date | null): void => {
    if (!date || isNaN(date.getTime())) {
      onDateChange("editDOB" as keyof S, null);
      setDate(null);
      return;
    }

    let newValue;
    try {
      newValue = format(date, dateFormat || 'yyyy-MM-dd');
    } catch (error) {
      return;
    }

    onDateChange("editDOB" as keyof S, newValue);
    setDate(dob ? date : convertToDate(newValue));
  };

  return (
    <div id={name} className="date-picker-container">
    <DatePicker
      selected={date !== null ? date : undefined}
      onChange={handleAction}
      onSelect={handleAction}
      dateFormat={"yyyy-MM-dd"}
      className="dateCustom"
      open={isOpen}
      placeholderText={name}
      onBlur={toggleCalendarView}
      onFocus={toggleCalendarView}
      locale="en-GB"
      maxDate={dob ? new Date() : undefined}
      minDate={dob ? new Date(0) : new Date()}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      onCalendarClose={toggleCalendarView}
      onClickOutside={toggleCalendarView}
      disabled={immediate}
    />
    <CalendarTodayRoundedIcon  className="calendar-icon" />
    </div>
  );
}
