import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { debounce } from "lodash";
import { extractZodErrors, getCurrentRole, hasPermission } from "../../../components/src/utils";
import { customerSchema } from "../../../components/src/Schemas/CustomerSchema";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  currentRole: string | null;
  customers: any[];
  totalCount: number;
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  loading: boolean;
  error?: any;
  selectedCustomer: any | null; // Stores the details of the selected customer
  viewModalOpen: boolean; // Controls the visibility of the modal
  addModalOpen: boolean; // Controls the visibility of the add customer modal
  editModalOpen: boolean,
  marketingModalOpen: boolean,
  successPopupOpen: boolean,
  successText: string,
  isEditing: boolean, // Differentiates between Add and Edit
  editingCustomerId: any | null, // To store the ID of the customer being edited
  newCustomer: { // Stores the form data for the new customer
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    gender:string|number;
    address: {

      city:string;
      country: string;
      address: string;
      postCode: string;
      addressDirection: string;
      defaultAddress: boolean;
    };
    email_communication?: boolean,
    mobile_communication?: boolean,
  };
  // errors: {}, // Field-specific errors
  errors: Partial<{
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    address: string;
    postCode: string;
  }>;
  query?: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfposcustomermanagementfeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  customerListCallId: string = "";
  subscription: any | null = null;
  currentRoleParams: string = "";

  enableManageCustomer: boolean = hasPermission("CustomerAccount", "index");
  enableAddCustomer: boolean = hasPermission("CustomerAccount", "create");
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [

      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      currentRole: null,
      customers: [],
      totalCount: 0,
      currentPage: 1,
      nextPage: null,
      prevPage: null,
      loading: false,
      error: null,
      selectedCustomer: null,
      viewModalOpen: false,
      addModalOpen: false,
      editModalOpen: false,
      marketingModalOpen: false,
      successPopupOpen: false,
      successText: '',
      isEditing: false, // Differentiates between Add and Edit
      editingCustomerId: null, // To store the ID of the customer being edited
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        gender:"",
        countryCode:'44',
        phoneNumber: "",
        date_of_birth: "",
        address: {
        city:"ranchi",

          country: "UK",
          address: "",
          postCode: "",
          addressDirection: "test",
          defaultAddress: true,
        },


      },

      errors: {}, // Field-specific errors
      query:null
      // Customizable Area End

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

     // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }

    const messageHandlers: Record<string, (response: any) => void> = {
      [this.customerListCallId]: this.handleCustomerListResponse,
    };

    const handler = messageHandlers[callId];
    if (handler) handler(response);

    if (errorMessage) {
      console.error("API Error:", errorMessage);
      this.setState({ loading: false, error: errorMessage });
    }
    return Promise.resolve();
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };


  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount=async() =>{
    let token = localStorage.getItem("authToken")||"";
    if (!token) {
      this.setState({ error: "Authentication token is missing." });
    }
    !this.enableManageCustomer && this.props.navigation.navigate("PosNotAuthorized");
    this.setState({ token })
    const currentRole = getCurrentRole();
    this.setState({ currentRole });

  }


componentDidUpdate = async(prevProps: Readonly<Props>, prevState: Readonly<S>)=>{
  if(this.state.token !== prevState.token){
    const changeEvent = {
      target: {value:"test value"},
    } as React.ChangeEvent<HTMLInputElement>;
    this.fetchCustomers(changeEvent,1)
  }
  if (prevState.currentRole !== this.state.currentRole)
    this.currentRoleParams = this.state.currentRole ? `current_user_role=${this.state.currentRole}` : "";
}
componentWillUnmount=async()=> {
    if (this.subscription) {
      this.unsubscribe();
    }
  }

  handleCustomerListResponse = (response: any) => {
    if (response) {
      const { customers, total_count, current_page, next_page, prev_page } = response;

      this.setState({
        customers: customers.map((c: any) => ({
          id: c.id,
          firstName: c.attributes.first_name,
          lastName: c.attributes.last_name,
          email: c.attributes.email,
          phoneNumber: c.attributes.full_phone_number,
          customerName: c.attributes.customer_name,
          customerID: c.attributes.customer_id,
          postcode: c.default_address_postcode,
          gender: c.attributes.gender,
          date_of_birth: c.attributes.date_of_birth,
          email_communication:c.attributes.email_communication,
          mobile_communication: c.attributes.mobile_communication
        })),
        totalCount: total_count,
        currentPage: current_page,
        nextPage: next_page,
        prevPage: prev_page,
        loading: false,
      });
    } else {
      this.setState({ customers: [], loading: false });
    }
  };


  fetchCustomerDetails = (customerId: string) => {
    if (!this.state.token) {
      console.error("Token is missing. Unable to fetch customer details.");
      this.setState({ error: "Unable to fetch customer details. Please log in again." });
      return;
    }

    this.setState({ loading: true });

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerList.endPoint}/${customerId}` + this.currentRoleParams
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.customerList.contentType,
        token: this.state.token,
      })
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.customerList.method);

    runEngine.sendMessage(message.id, message);

    // Add a handler to process the response
    const handleCustomerDetailsResponse = (response: any) => {
      if (response) {
        this.setState({
          selectedCustomer: response.customer,
          viewModalOpen: true,
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Failed to fetch customer details." });
      }
    };

    const messageHandlers: Record<string, (response: any) => void> = {
      [callId]: handleCustomerDetailsResponse,
    };

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const handler = messageHandlers[receivedCallId];
      if (handler) handler(response);

      if (errorMessage) {
        console.error("API Error:", errorMessage);
        this.setState({ loading: false, error: errorMessage });
      }
    };
  };

  openViewModal = (customerId: string) => {
    this.props.navigation.navigate("ViewCustomer", { customerId })
    this.fetchCustomerDetails(customerId);
  };

  closeViewModal = () => {
    this.setState({ viewModalOpen: false, selectedCustomer: null });
  };

  openAddModal = () => {
    this.setState({ addModalOpen: true, isEditing: false});

  };

  closeAddModal = () => {
    this.setState({
      addModalOpen: false,
      // isEditing: false,
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        date_of_birth: "",
        gender:"",
        address: {
          city:"ranchi",

          country: "UK",
          address: "",
          postCode: "",
          addressDirection: "test",
          defaultAddress: true,
        },
      },
      errors: {}, // Field-specific errors

    });
  };


    fetchCustomers = (event:React.ChangeEvent<unknown>,page: number) => {
    if (!this.state.token) {
      console.error("Token is missing. Unable to fetch customers.");
      this.setState({ error: "Unable to fetch customers. Please log in again." });
      return;
    }

    this.setState({ loading: true, error: null });

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.customerListCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerList.endPoint}?page=${page}&${this.currentRoleParams}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.customerList.contentType,
        token: this.state.token,
      })
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.customerList.method);

    runEngine.sendMessage(message.id, message);

    const handleResponse = (response: any) => {
      if (response) {
       this.handleCustomerListResponse(response)
      } else {
        console.warn("No customers found.");
        this.setState({ customers: [], loading: false });
      }
    };

    const messageHandlers: Record<string, (response: any) => void> = {
      [this.customerListCallId]: handleResponse,
    };

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (response?.error === "unauthorized") {
        this.props.navigation.navigate("PosNotAuthorized");
        return;
      }

      if (messageHandlers[receivedCallId]) {
        messageHandlers[receivedCallId](response);
      }

      if (errorMessage) {
        console.error("API Error:", errorMessage);
        this.setState({ loading: false, error: errorMessage });
      }
    };
  };


  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    nestedField?: string
  ) => {
    const value = event.target.value;

    if (nestedField) {
      this.setState((prevState: any) => ({
        newCustomer: {
          ...prevState.newCustomer,
          [field]: {
            ...prevState.newCustomer[field],
            [nestedField]: value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        newCustomer: {
          ...prevState.newCustomer,
          [field]: value,
        },
      }));
    }
  };

  handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({ query: value }, () => {
      const cleanedValue = value?.startsWith("+") ? value.substring(1) : value;
      this.handleSearchInputChange(cleanedValue);
    });
  };

  handleSearchInputChange = debounce((value: string) => {
    if (value) {
      this.searchQuery(value);
    } else {
      const changeEvent = {
        target: {value:"test value"},
      } as React.ChangeEvent<HTMLInputElement>;
      this.fetchCustomers(changeEvent,1);
    }
  }, 300);


  searchQuery = (search: string) => {
    if (!this.state.token) {
      console.error("Token is missing for search query.");
      this.setState({ error: "Token is missing for search query.", loading: false });
      return;
    }

    this.setState({ loading: true, error: null });

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.customerListCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerList.endPoint}?${this.currentRoleParams}&query=${encodeURIComponent(search)}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.customerList.contentType,
        token: this.state.token,
      })
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.customerList.method);

    runEngine.sendMessage(message.id, message);

    const handleResponse = (response: any) => {
      if (response && response.customers) {
        this.setState({
          customers: response.customers.map((c: any) => ({
            id: c.id,
            firstName: c.attributes.first_name,
            lastName: c.attributes.last_name,
            email: c.attributes.email,
            phoneNumber: c.attributes.full_phone_number,
            customerName: c.attributes.customer_name,
            customerID: c.attributes.customer_id,
            postcode: c.default_address_postcode,
          })),
          totalCount: response.total_count,
          loading: false,
        });
      } else {
        console.warn("No matching customers found.");
        this.setState({ customers: [], totalCount: 0, loading: false });
      }
    };

    const handleError = (errorMessage: any) => {
      console.error("Search API Error:", errorMessage);
      this.setState({ loading: false, error: errorMessage });
    };

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (receivedCallId === this.customerListCallId) {
        if (response) {
          handleResponse(response);
        }
        if (errorMessage) {
          handleError(errorMessage);
        }
      }
    };
  };


  validateCustomerForm = (newCustomer: any) => {
    this.setState({ errors: {} });
    const result = customerSchema.safeParse({
      ...newCustomer,
      ...newCustomer.address
    });

    if (!result.success) {
      const formattedErrors = result.error.format();

      this.setState({ errors: extractZodErrors(formattedErrors) });
      return false;
    }
    return true;
  };


  addOrUpdateCustomer = (formdata: any) => {
    const { token, isEditing, editingCustomerId } = this.state;

    if (!token) {
      console.error("Token is missing. Unable to add or update customer.");
      this.setState({ error: "Unable to proceed. Please log in again." });
      return;
    }

    const newCustomer = {
      firstName: formdata.firstName,
      lastName: formdata.lastName,
      gender: formdata.gender,
      email: formdata.email,
      countryCode: formdata.countryCode,
      phoneNumber: formdata.phoneNumber,
      date_of_birth: formdata.date_of_birth,
      address: {
        address: formdata.address.addressDirection,
        postCode: formdata.address.postCode,
        city: "ghaziabad",
        country: "UK",
        addressDirection: formdata.address.addressDirection,
        defaultAddress: true,
      },
    };

    this.setState({ newCustomer });

    if (!this.validateCustomerForm(newCustomer)) {
      console.error("Form validation failed.");
      return;
    }

    this.setState({ loading: true });

    const customerData = {
      data: {
        attributes: {
          first_name: formdata.firstName,
          last_name: formdata.lastName,
          email: formdata.email,
          gender: formdata.gender,
          full_phone_number: formdata.phoneNumber,
          date_of_birth: formdata.date_of_birth,
          // addresses_attributes: [
          //   {
          //     city: formdata.address?.city || "a",
          //     country: "",
          //     address: formdata.address.addressDirection ?? "Silverstone",
          //     post_code: formdata.address.postCode,
          //     address_direction: formdata.address.addressDirection,
          //     default_address: true,
          //   },
          // ],
        },
        device: "web",
      },
    };

    const endpoint = isEditing
      ? `${configJSON.customerList.endPoint}/${editingCustomerId}?${this.currentRoleParams}`
      : `${configJSON.customerList.endPoint}?${this.currentRoleParams}`;

    const method = isEditing ? "PUT" : "POST";

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = message.messageId;

    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({
      "Content-Type": "application/json",
      token,
    }));
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(customerData));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    runEngine.sendMessage(message.id, message);

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (receivedCallId === callId) this.handleAddOrUpdateResponse(isEditing, response, errorMessage);
    };
  };

  handleAddOrUpdateResponse = (isEditing: boolean, response: any, errorMessage: any) => {
    if (response?.errors) {
      const errors: Record<string, string> | string[] = {};
      const apiErrors = response.errors[0]?.account || response.errors;

      if (apiErrors.includes("Email")) errors.email = apiErrors;
      if (apiErrors[0].includes("Phone number")) errors.phoneNumber = apiErrors[0];

      this.setState({ loading: false, errors });
    } else if (response) {
      this.fetchCustomers({ target: { value: "test value" } } as React.ChangeEvent<HTMLInputElement>, this.state.currentPage);

      this.setState({
        loading: false,
        addModalOpen: false,
        editModalOpen: false,
        marketingModalOpen: false,
        isEditing: false,
      });

      this.handleSuccessPopupOpen(isEditing ? "Customer Information has been updated successfully!" : "New Customer has been added successfully!");
    } else if (errorMessage) {
      console.error("API Error:", errorMessage);
      this.setState({ loading: false, error: errorMessage });
    }
  };

  openEditModal = (customer: any) => {
    this.setState({
      addModalOpen: true,
      isEditing: true,
      editingCustomerId: customer.id,
      newCustomer: {
        firstName: customer.firstName || "",
        lastName: customer.lastName || "",
        email: customer.email || "",
        countryCode: customer.countryCode||'44',
        phoneNumber: customer.phoneNumber || 0,
        date_of_birth: customer.date_of_birth ||"",
        gender:customer.gender||"",
        address: {
          address:  "",
          postCode: customer.postcode || "",
          city: "",
          country: "",
          addressDirection: customer.address ||  "",
          defaultAddress:  true,
        },
      },
      errors: {},
    })
  };

  closeEditModal = () => {
    this.setState({
      addModalOpen: false,
      isEditing: false,
      editingCustomerId: null,
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        gender:"",

        date_of_birth: "",
        address: {
          city: "",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
      },
      errors: {},
    });
  };

  openMarketingInfoModal = (customer: any) => {
    this.setState({
      addModalOpen: false,
      isEditing: true,
      marketingModalOpen: true,
      successPopupOpen: false,
      successText:'',
      editingCustomerId: customer.id,
      newCustomer: {
        firstName: customer.firstName || "name",
        lastName: customer.lastName || "name",
        email: customer.email || "name",
        countryCode:customer.countryCode ||'44',
        gender:customer.gender || "",
        phoneNumber: customer.phoneNumber || 1234567876,
        date_of_birth: "",
        email_communication:customer.email_communication,
        mobile_communication:customer.mobile_communication,
        address: {
          address:  "",
          postCode: customer.postcode || "",
          city: "",
          country: "",
          addressDirection:  "",
          defaultAddress:  false,
        },
      },
      errors: {},
    });
  };

  closeMarketingInfoModal = () => {
    this.setState({
      addModalOpen: false,
      isEditing: false,
      editingCustomerId: null,
      marketingModalOpen: false,
      successPopupOpen: false,
      successText:'',
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        date_of_birth: "",
        gender:"",
        address: {
          city: "",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
      },
      errors: {},
    });
  };

  handleSuccessPopupClose = () => this.setState({ successPopupOpen: false , successText:''});
  handleSuccessPopupOpen = (successTxt: string) => this.setState({ successPopupOpen: true, successText: successTxt});


  addMarketingInfoModal = (body: any,id:any) => {
    const customerId = this.state.editingCustomerId;

    if (!this.state.token) {
      console.error("Token is missing. Unable to update marketing information.");
      this.setState({ error: "Please log in again to proceed." });
      return;
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = message.messageId;

    // Use the config object for endpoint and headers
    const endpoint = configJSON.customerMarketInfo.endpoints.updateMarketingInfo(customerId);
    const headers = configJSON.customerMarketInfo.headers(this.state.token);

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");

    runEngine.sendMessage(message.id, message);

    const handleResponse = (response: any) => {
      if (response) {
        const updatedCustomers = this.state.customers.map((c: any) => {
          if (c.id === id) {
            return {
              ...c,
              email_communication: body.body.email_communication,
              mobile_communication: body.body.mobile_communication,
            };
          }
          return c;
        });
        this.setState({customers:updatedCustomers})
        this.closeMarketingInfoModal();
      } else {
        this.setState({ error: "Failed to update marketing information." });
      }
    };

    const handleError = (errorMessage: any) => {
      console.error("API Error:", errorMessage);
      this.setState({ error: errorMessage });
    };

    const messageHandlers: Record<string, (response: any) => void> = {
      [callId]: handleResponse,
    };

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (receivedCallId === callId) {
        if (response) {
          handleResponse(response);
          this.handleSuccessPopupOpen('Marketing Information has been saved successfully!');
        } else if (errorMessage) {
          handleError(errorMessage);
        }
      }
    };
  };
  unsubscribe = () => {
    if (this.subscription) {
      console.log("Unsubscribing from WebSocket...");
      this.subscription = null;
    }
  };
   // Customizable Area End
}


