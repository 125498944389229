import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Chip from "../components/Chip"; // Reuse the Chip component for styled labels

export type Order = {
  id: string;
  order_number: string;
  date_time: string;
  store: string;
  order_type: string;
  amount: string;
  payment_type: string;
  status: string;
  delivery_addresses:any;
};

interface Props {
  actionCell: (info: CellContext<Order, any>) => JSX.Element;
}

export const CustomerOrdersColumns = ({ actionCell }: Props) => {
  const columns: ColumnDef<Order, any>[] = [
    {
      header: "Order Number",
      accessorKey: "order_number", 
      cell: (info) => <span>{info.getValue() || "N/A"}</span>,
    },
    {
      header: "Date & Time",
      accessorKey: "placed_at", 
      cell: (info) => {
        const value = info.getValue();
        if (!value) return <span>N/A</span>;
    
        const dateObject = new Date(value);
    
        
        const formattedDate = dateObject.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
    
        
        const formattedTime = dateObject.toLocaleTimeString("en-GB", { hour12: false });
    
        return (
          <div>
            <span>{formattedDate}</span>
            <br />
            <span style={{ color: "green", fontSize: "0.9em" }}>
              {formattedTime}
            </span>
          </div>
        );
      },
    },
    {
      header: "Store",
      accessorKey: "restaurant", 
      cell: (info) => {
        const deliveryAddresses = info.getValue();
        return <span>{deliveryAddresses}</span>;
      },
    },
    
    
    {
      header: "Order Type",
      accessorKey: "order_type", 
      cell: (info) => {
        const type = info.getValue();
        switch (type?.toLowerCase()) {
          case "delivery":
            return <span>{"Delivery"}</span>;
          case "collection":
            return <span>{"Collection"}</span>;

          default:
            return <Chip label={type || "N/A"} />;
        }
      },
    },
    {
      header: "Amount",
      accessorKey: "total", 
      cell: (info) => <span>£{info.getValue() || "0.00"}</span>,
    },
    {
      header: "Payment Type",
      accessorKey: "source", 
      cell: (info) => {
      const status = info.getValue();
              return <span>{status}</span>;
        }
        
          
    },
    {
      header: "Status",
      accessorKey: "status", 
      cell: (info) => {
        const status = info.getValue();
        switch (status?.toLowerCase()) {
          case 'placed':
            return <Chip label="ORDER PLACED" style={{ color: "#662EB2", backgroundColor: "#D4B6FF" }} />
          case 'confirmed':
            return <Chip label="CONFIRMED" style={{ color: "#662EB2", backgroundColor: "#D4B6FF" }} />
          case 'ready':
            return <Chip label="READY" style={{ color: "#7C71FE", backgroundColor: "#E2E8FE" }} />
          case 'in_transit':
            return <Chip label="PICKED UP" style={{ color: "#E73777", backgroundColor: "#FFD6E5" }} />
          case 'collected':
            return <Chip label="COLLECTED" style={{ color: "#059669", backgroundColor: "#D1FAE5" }} />
          case 'delivered':
            return <Chip label="DELIVERED" style={{ color: "#059669", backgroundColor: "#D1FAE5" }} />
          case 'cooking':
            return <Chip label="COOKING" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }} />
          case 'cancelled':
            return <Chip label="CANCELLED" style={{ color: "#E11B22", backgroundColor: "rgb(244, 159, 162)" }} />
          case 'cancel_initiated':
            return <Chip label="CANCEL INITIATED" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }} />
          case 'refund_initiated':
            return <Chip label="REFUND INITIATED" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }} />
          case 'refunded':
            return <Chip label="REFUNDED" style={{ color: "#059669", backgroundColor: "#D1FAE5" }} />
          case 0:
            return <Chip label="AVAILABLE" style={{ color: "#059669", backgroundColor: "#D1FAE5" }} />
          default:
            return <Chip label={status?.toUpperCase() || "Unknown"} />;
        }
      },
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (info) => actionCell(info), 
    },
  ];

  return columns;
};
