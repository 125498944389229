// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import React from "react";
import qz from "qz-tray"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { createMessage } from "../../../components/src/utils";
import { ItemDetailData, ResponseItems } from "./types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
}

export interface PrintSettings{
  automatic_printing_instore_order: boolean;
    automatic_printing_online_order: boolean;
    double_printing_all_order: boolean
}

interface S {
  token: string;
  order_id: string;
  certification_key: string | null;
  data: ResponseItems | null;
  items: Array<ItemDetailData>;
  connected: boolean;
  printerList: Array<string>;
  selectedPrinter: string;
  openPrinterPopup: boolean;
  error: string | null;
  loading:boolean;
  printSettings:PrintSettings;
}

interface SS {
  id: number;
}

export default class ReceiptCustomisationController extends BlockComponent<
  Props,
  S,
  SS
> {
  orderDetailsCallId: string = "";
  getCertificateKeyCallId: string = "";
  getPrintSettingsCallId: string = "";

  qzCreatedConnection: any;
  contentRef: React.RefObject<HTMLDivElement>;
  chefContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.contentRef = React.createRef<HTMLDivElement>();
    this.chefContentRef = React.createRef<HTMLDivElement>();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      order_id: "",
      certification_key: null,
      data: null,
      items: [],
      connected: false,
      printerList: [],
      openPrinterPopup: false,
      selectedPrinter: "",
      error: null,
      loading:false,
      printSettings:{
        automatic_printing_instore_order: false,
        automatic_printing_online_order: false,
        double_printing_all_order: false
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (this.state.printerList !== prevState.printerList) {
      this.openPrinterDialogue();
    };
  }

  async receive(_from: string, message: Message) {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (error || !response) runEngine.debugLog("API Error", error);

    const handler = this.getMessageHandler(callId);

    if (handler) handler(response);
  }

  getMessageHandler(callId: string) {
    const messageHandlers = {
      [this.orderDetailsCallId]: this.handleOrderDetails,
      [this.getCertificateKeyCallId]: this.handleCertificateKey,
      [this.getPrintSettingsCallId]: this.handlePrintSettings,
    };

    return messageHandlers[callId];
  };

  getOrderDetails = (id?:string) => {
    const { order_id } = this.state;
    this.setState({loading:true})
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.getOrderDetailsApi}${id ? id : order_id}`,
      { "Content-Type": configJSON.contentType }
    );

    this.orderDetailsCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleOrderDetails = (response: any) => {
    try {
      if (response && response.data) {
        this.setState({ items: response.data.attributes.items_detail.data, data: response.data ,loading:false})
        this.openPrinterDialogue();
        this.getCertificateKey();
      };

      if (response && response.error) {
        this.setState({ error: response.error, loading:false });
      };
    } catch (error) {
      runEngine.debugLog("Failed to fetch order details", error)
    };
  };

  getCertificateKey = () => {
    const getDataMsg = createMessage(
      "GET",
      configJSON.getQzTrayKeysApi,
      { "Content-Type": configJSON.contentType }
    );

    this.getCertificateKeyCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getPrintSettings = () => {
    const getDataMsg = createMessage(
      "GET",
      configJSON.getPrintSettingsApi,
      { "Content-Type": configJSON.contentType,
        token:localStorage.getItem('authToken')
       }
    );

    this.getPrintSettingsCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleCertificateKey = (response: any) => {
    try {
      if (response && response.success) {
        this.setState({ certification_key: response.certification_key });
        this.qzCreatedConnection = this.printSocketFunction(this.changeConnectionState, response.certification_key)
      };
    } catch (error) {
      runEngine.debugLog("Failed to fetch certfiication", error);
    }
  };

  changeConnectionState = (connected: boolean) => {
    this.setState({ connected })
  }

  printSocketFunction = (changeConnectionState: (value: boolean) => void, certification_key: string) => {
    qz.security.setCertificatePromise((resolve: any, _reject: any) => {
      resolve(
        certification_key
      );
    });

    if (!qz.websocket.isActive()) {
      console.log("THRU HERE");
      qz.websocket
        .connect({
          host: ["localhost"],
          port: {
            secure: [8181],
            insecure: [8182]
          },
          usingSecure: true,
          retries: 0,
          keepAlive: 60,
          delay: 0
        })
        .then(() => {
          changeConnectionState(true);
          qz.printers
            .find()
            .then((found: any) => this.setPrinterList(found));
        })
        .catch((error: any) => console.log(error));
    } else {
      changeConnectionState(true);
      runEngine.debugLog("QZ Tray Websocket Connection", qz.websocket.getConnectionInfo());
    }

    return qz;
  };

  setPrinterList = (value: string[]) =>
    this.setState({ printerList: value })

  openPrinterDialogue = () => {
    if (this.state.printerList.length > 0) {
      this.setState({ openPrinterPopup: true })
    }
  }
  closePrinterDialogue = () => {
    this.setState({ openPrinterPopup: false })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      ...prevState, [name]: value
    }))
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { name } = event.target;

    this.setState(prevState => ({
      ...prevState, [name]: value
    }))
  };

  printHTML = async () => {
    const config = this.qzCreatedConnection.configs.create(this.state.selectedPrinter);
    if(this.contentRef.current && this.chefContentRef.current){
      const canvas = await html2canvas(this.contentRef.current,{scale:2});
      const chefCanvas = await html2canvas(this.chefContentRef.current,{scale:2})
      const imgData = canvas.toDataURL("image/png");
      const chefImgData = chefCanvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [canvas.width, canvas.height]
      })
      const chefDoc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [chefCanvas.width, chefCanvas.height]
      })
      const pdfWidth = doc.internal.pageSize.getWidth()
      const pdfHeight = canvas.height
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      chefDoc.addImage(chefImgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const pdfDataUri: string = doc.output("datauristring");
      const pdfBase64: string = pdfDataUri.split(",")[1];
      const chefPdfDataUri: string = chefDoc.output("datauristring");
      const chefPdfBase64: string = chefPdfDataUri.split(",")[1];
      let data: qz.PrintData[] = [
        {
          data: pdfBase64,
          flavor: "base64",
          type:"pixel",
          format: "pdf",
        },
        {
          data: chefPdfBase64,
          flavor: "base64",
          type:"pixel",
          format: "pdf",
        }
      ];
      if(this.state.printSettings.double_printing_all_order){
        data.push(...data);
      }

      this.qzCreatedConnection.print(config, data)
      .then(() => console.log("Print job successful"))
      .catch((error: string) => console.error("Print job failed for chef", error))
    }
  };

  componentDidMount=async()=> {
    const urlPath = location.pathname;
    const segments = urlPath.split("/");
    const orderId = segments[segments.length - 1];
    this.getPrintSettings()
    if(orderId){
      this.getOrderDetails(orderId)
    }
}

navigateBack=()=>{
  this.props.history.goBack()
}

handlePrintSettings=(responseJson:any)=>{
  if(!responseJson.error)
    this.setState({printSettings:responseJson})
}

};
// Customizable Area End
