Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PATCH";
exports.markAllAsReadMethod = "PATCH";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.markAllAsReadText = "Mark all as read"
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.accountCTA = ["Email Verification", "Phone Number Verification", "Update Email", "Update Phone Number"];
exports.mockNotifications = [
    {
        id: "1",
        type: "notifications",
        attributes: {
            id: 1,
            created_by: 1,
            notification_for: "order",
            image: {
                url: "https://www.w3schools.com/w3images/avatar2.png",
            },
            headings: "Today's Notification",
            contents: "This is a notification for today.",
            app_url: "test/url",
            is_read: false,
            read_at: null,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        },
    },
    {
        id: "2",
        type: "notifications",
        attributes: {
            id: 2,
            created_by: 1,
            notification_for: "Update Email",
            image: "",
            headings: "Yesterday's Notification",
            contents: "This is a notification for yesterday.",
            app_url: "test/url",
            is_read: false,
            read_at: null,
            created_at: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
            updated_at: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
            order_details: {
                order_id: "123",
                order_number: "79483714031"
            }
        },
    },
    {
        id: "3",
        type: "notifications",
        attributes: {
            id: 3,
            created_by: 1,
            notification_for: "Update Phone Number",
            image: "",
            headings: "Notification from a few days ago",
            contents: "This is a notification from a few days ago.",
            app_url: "test/url",
            is_read: false,
            read_at: null,
            created_at: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString(),
            updated_at: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString(),
        },
    },
    {
        id: "4",
        type: "notifications",
        attributes: {
            id: 4,
            created_by: 1,
            notification_for: "",
            image: "",
            headings: "Read Notification",
            contents: "This is a read notification.",
            app_url: "test/url",
            is_read: true,
            read_at: new Date().toISOString(),
            created_at: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000).toISOString(),
            updated_at: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000).toISOString(),
        },
    },
    {
        id: "5",
        type: "notifications",
        attributes: {
            id: 5,
            created_by: 1,
            notification_for: "",
            image: "",
            headings: "Another Read Notification",
            contents: "This is another read notification.",
            app_url: "test/url",
            is_read: true,
            read_at: new Date().toISOString(),
            created_at: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).toISOString(),
            updated_at: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).toISOString(),
        },
    },
]
// Customizable Area End
