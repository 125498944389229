import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SearchIcon from '@material-ui/icons/Search';
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded"
import "./CatalogueStyles.css";
import{
  CatalogueDataSorted,
  CatalogueDataUnSorted
} from "./CfposordermanagementfeController";
import { IconButton, InputAdornment, Tab, Tabs, TextField } from "@material-ui/core";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import FilterPopup from "../../catalogue/src/FilterPopup.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import PosProductDialogPopup from "../../../components/src/PosProductDialogPopup";
import DialogPopup from "../../../components/src/DialogPopup.web";
import EmptyCartItemAlert from "./../../../components/src/EmptyCartItemAlert/EmptyCartItemAlert.web";
import { FormControl, FormHelperText } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import Modal from "../../../components/src/Modal";
import { WingoTab, WingoTabs } from "../../../components/src/Tabs/Tabs";
import { WingoInput } from "../../../components/src/Inputs/CustomInputs";
import { ContainedButton, OutlinedButton } from "../../../components/src/Button";
import FormLabel from "../../../components/src/FormLabel";
import Select from "../../../components/src/Select";
// Customizable Area End

import CfposordermanagementfeController, {
  Props,
  configJSON,
} from "./CfposordermanagementfeController";
import CfposPrductDescription from "./CfposPrductDescription.web";
import { AllergenInItem, CartDetails, CartItem } from "./CfposPrductDescriptionController";
import { allergenImage, sellImage } from "./assets";

export default class Cfposordermanagementfe extends CfposordermanagementfeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  catalogueHeaderSection=()=>{
    return(
      <div className="catalogueHeaderSection">
         <IconButton className="menuIconButton" onClick={this.navigateBackToNewOrder}>
          <ArrowBackIosRoundedIcon/>
        </IconButton>
        <IconButton className="menuIconButton">
          <MenuRoundedIcon/>
        </IconButton>
        {this.catalogueHeaderMenuDealsTabBar()}
        {this.catalogueHeaderMenuDealsSearchBar()}
        {this.catalogueHeaderMenuDealsFilter()}
      </div>
    )
  }


  catalogueHeaderMenuDealsTabBar=()=>{
    return(
      <Tabs
      value={this.state.menuDealsOption}
      onChange={this.handleMenuDealOpionChange}
      variant="fullWidth"
      aria-label="scrollable prevent tabs example"
      className="catalogueHeaderMenuDealsTabBar"
    >
      <Tab className="tabsOptionTablist" label="Deals" />
      <Tab className="tabsOptionTablist" label="Menu" />
    </Tabs>
    )
  }

  catalogueHeaderMenuDealsSearchBar = () => {
    return (
      <TextField
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search for an item here"
        className="searchField"
        style={{ height: '44px', maxWidth:"326px"}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          endAdornment:(this.state.searchValue &&
            <InputAdornment onClick={this.handleSearchClear} style={{cursor:"pointer"}} position="end">
              <ClearRoundedIcon style={{color:"#A2A2A2"}} />
            </InputAdornment>
          ),
          className: 'posCatalogueSearchInput'
        }}
        onChange={this.handleSearchChange}
        value={this.state.searchValue}
        onKeyDown={this.getSearchResults}
      />
    )
  }

  catalogueHeaderMenuDealsFilter = ()=> {
    return(
      <IconButton className="catalogueHeaderMenuDealsFilter" onClick={this.handleFilterPopupOpen} >Filters <FilterListRoundedIcon style={{marginLeft:"6px"}}/></IconButton>
    )
  }

  catalogueCategoryContainer=()=>{

    return(
      <Tabs
      value={this.state.categoryOption}
      onChange={this.handleCategoryOptionChange}
      variant="scrollable"
      aria-label="scrollable prevent tabs example"
      className="catalogueCategoryContainerTabBar"
    >
      {this.getCategoryOptions().map((value,index)=>(
        <Tab key={`${value}${index}`} className="catalogueCategoryContainerTabList" label={value} onClick={()=>this.scrollToSection(index)} />
      ))}
    </Tabs>
    )
  }

  getLoadingData=()=>{
    return(
        <>
        { this.state.loading?
         <div className="noResultFoundContainer">
          <div style={{fontSize:"32px", fontWeight:700, color:"#1d1d1d"}}>Loading...</div>
         </div>:
         <div className="noResultFoundContainer">
          <div style={{fontSize:"32px", fontWeight:700, color:"#1d1d1d"}}>No results found</div>
          <div style={{fontSize:"18px",fontWeight:500,color:"#747474"}}>Try searching for other items or check your input.</div>
         </div>
         }
         </>
    )
  }


  catalogueItemContainer=()=>{
    const {catalogueCategoryData,DealCategoryData,menuDealsOption} = this.state
    const categoryTocheck = menuDealsOption ===1 ? catalogueCategoryData : DealCategoryData
    return(
      <div className="catalogueItemContainer" ref={(ref) => (this.catalogueItemContainerRef = ref)}>
         {categoryTocheck.length>0 ? categoryTocheck.map((value,index)=>
        this.categoryContainer(value,index)
         ):
         this.getLoadingData()
         }
      </div>
    )
  }

  categoryContainer=(value:CatalogueDataSorted,index:number)=>{
    return(
      <div className="categoryContainer">
        <div className="categoryHeaderContainer" id={`categoryHeaderContainer${index%3}`} data-scroll-test-id={`section${index+1}`}
        ref={(ref) => (this.categoryRefs[index+1] = ref)}>{value.sub_category}</div>
        {this.categoryMultipleItemsContainer(value.data)}
      </div>
    )
  }

  categoryMultipleItemsContainer=(data:CatalogueDataUnSorted[])=>{
    return(
      <div className="categoryItemContainer" >
        {data.map(value=>
        this.categoryItemContainer(value)
        )}
      </div>
    )
  }

  categoryItemContainer=(data:CatalogueDataUnSorted)=>{
    return(
      <div className="itemInfoContainer" tabIndex={0} onClick={()=>{this.setIndividualItemData(data)}}>
          <div className="itemImageContainer" style={{backgroundImage:`url(${data.attributes.images.url})`}} ></div>
          <div className="itemInfo">
            <div  className="itemInfoTitle">{data.attributes.sub_sub_category.title}
              {!!data.attributes.sub_sub_category.serves && <div className="itemInfoDescription">Serves {data.attributes.sub_sub_category.serves}</div>}
            </div>
            <div className="itemInfoDescriptionPrice">£{data.attributes.on_its_own_price}</div>
          </div>
        </div>
    )
  }

  catalogueContainer=()=>{
    return(
      <div className="catalogueContainer">
        {this.catalogueHeaderSection()}
        {this.catalogueCategoryContainer()}
        {this.catalogueItemContainer()}
      </div>
    )
  }

  getCartItems=(cartDetails:CartDetails)=>{
    const {data} = cartDetails
    return(
      data.map((cartDetail:CartItem)=>(
        <div className="getcartItemContainer" >
        <div className="itemDescContainer" >
          <div className="itemDescPicContainer">
            <div className="posItemImage" style={{backgroundImage:`url(${cartDetail.attributes.images.url})`}} ></div>
            <div className="posItemInfo">
                <div className="itemTitlePosCart" >{cartDetail.attributes.product_name}</div>
                <div className="itemSidesExtra">{this.getCartExtraDetails(cartDetail)}</div>
                {cartDetail.attributes.allergen_excluded&&
                        <div className="allergen_exclusion_info"> <img src={allergenImage} alt="allergen_icons" style={{width:"16px", height:"16px"}} /> <span style={{display:"flex", height:"16px"}} > {cartDetail.attributes.allergen_excluded}</span></div>
                        }
            </div>
          </div>
          <div className="itemPriceInfo">
            {cartDetail.attributes.is_discounted ? (
              <div className="itemPriceInfo__wrapper">
                <span className="itemPriceInfo__price discounted">{cartDetail.attributes.total_item_price_after_discount}</span>
                <span className="itemPriceInfo__price original_price">{cartDetail.attributes.total_item_price}</span>
              </div>
            ) : (
              <span className="itemPriceInfo__price">{cartDetail.attributes.total_item_price}</span>
            )}
          </div>
        </div>
        {this.editQuantityInfo(cartDetail)}
      </div>
      ))
    )
  }

  applyDiscountModal = () => {
    const { discountError, applyDiscountModal } = this.state;
    const { open, item } = applyDiscountModal;

    return (
      <Modal
        closeButton
        maxWidth="sm"
        open={open}
        onClose={this.closeApplyDiscountModal}
      >
        <div className="apply_discount__wrapper">
          <h2>{item?.attributes.is_discounted ? "Remove" : "Apply"} Discount</h2>

          <WingoTabs
            value={this.state.discountTab}
            onChange={item?.attributes.is_discounted ? undefined : this.handeDiscountTabChange}
          >
            <WingoTab label="Cash" />
            <WingoTab label="Percentage" />
          </WingoTabs>

          <div className="apply_discount__inputs">
            <FormControl fullWidth>
              <FormLabel>Discount ({this.state.discountTab === 0 ? <span>&pound;</span> : <span>%</span>})</FormLabel>
              <WingoInput
                disabled={item?.attributes.is_discounted}
                name="discount"
                placeholder="Enter discount"
                value={this.state.discount}
                onChange={this.handleInputChange}
              />
              <FormHelperText error>
                {discountError ?? " "}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Reason</FormLabel>
              <Select
                options={this.state.discountReasons.map(reason => reason.reason)}
                selectProps={{
                  disabled: item?.attributes.is_discounted,
                  name: "discountReason",
                  value: this.state.discountReason,
                  onChange: this.handleInputChange,
                  placeholder: "Select reason"
                }}
              />
              <FormHelperText error>
                {" "}
              </FormHelperText>
            </FormControl>
          </div>

          <div className="apply_discount__details">
            <div className="apply_discount__details__row">
              <p className="apply_discount__details__label">Total Amount</p>
              <p className="apply_discount__details__price">{this.state.totalPrice ?? 0}</p>
            </div>

            <div className="apply_discount__details__row">
              <p className="apply_discount__details__label">Discounted Amount</p>
              <p className="apply_discount__details__price discount_price">
                {this.state.discountedPrice ?? 0}
              </p>
            </div>
          </div>

          <div className="apply_discount__buttons">
            <OutlinedButton onClick={this.resetDiscountPopup}>CANCEL</OutlinedButton>
            <ContainedButton onClick={() => item?.attributes.is_discounted ? this.removeDiscount(item.id) : this.applyDiscount()}>
              {item?.attributes.is_discounted ? "REMOVE" : "APPLY"}
            </ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  editQuantityInfo=(cartDetail:CartItem)=>{
    return(
        <div className="editQuantityInfo">
          <div className="editAllegenInfo">
          <IconButton className='edit_button' onClick={()=>{this.editCartItem(cartDetail)}} ><EditRoundedIcon style={{ color: "#2C6F37" }} /></IconButton>
          <IconButton className='edit_button' onClick={() => this.openApplyDiscountModal(cartDetail)}><img src={sellImage} alt="sellImage" /></IconButton>
          <IconButton className='edit_button' onClick={()=>this.openAllergenPopup(cartDetail.attributes)} ><img src={allergenImage} alt="sellImage" /></IconButton>
          <IconButton className='edit_button' onClick={()=>this.deleteCartClicked(cartDetail.attributes.id)} ><DeleteOutlineRoundedIcon style={{color: "#E11B22"}} /></IconButton>
          </div>
          <div className="quantityInfo">
              <IconButton
              onClick={()=>{this.confirmAddRemoveCartItems(cartDetail,'removeItem')}}
              data-test-id={`deleteOrderItem`}
               disabled={cartDetail.attributes.quantity<1}
              className='edit_button'><RemoveRoundedIcon style={{color:"#286532"}}/></IconButton>
              <div style={{fontWeight:700}}>{cartDetail.attributes.quantity}</div>
              <IconButton
              data-test-id={`addOrderItem`}
              onClick={()=>{this.confirmAddRemoveCartItems(cartDetail,'addItem')}}
              className='edit_button'><AddRoundedIcon style={{color:"#286532"}}/></IconButton>
            </div>
        </div>
    )
  }

  renderConfirmQuantityChange = () => {
    const { confirmQuantityChangeModal } = this.state;
    const { open, item, action } = confirmQuantityChangeModal;

    if (!item) {
      return;
    };

    return (
      <Modal
        closeButton
        maxWidth="xs"
        open={open}
        onClose={this.closeConfirmationModal}
      >
        <div className="confirm_quantity__wrapper">
          <h3>Remove applied discount?</h3>
          <p>If you change the quantity then the applied discount will be removed.</p>

          <div className="confirm_quantity__buttons">
            <OutlinedButton onClick={this.closeConfirmationModal}>
              CANCEL
            </OutlinedButton>

            <ContainedButton onClick={() => this.addRemoveCartItems(item, action)}>
              PROCEED
            </ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  itemContainer=()=>{
    return(
      <div className="itemContainer">
      <div className="CheckoutHeadingConntainer">
        <div className="CheckoutHeading">Checkout Items
          {this.state.cartDetails.data.length>0 && <div className="itemCartCount">{this.calculateTotalCount(this.state.cartDetails.data)}</div>}</div>
        <div className="posOrderAccountName"><PermIdentityOutlinedIcon/> {this.state.orderDetails?.data.attributes.personal_detail.first_name}</div>
      </div>
      <div className="itemCartContainerDiplay" >
      { this.state.cartDetails.data.length ? this.getCartItems(this.state.cartDetails):this.getEmptyCart()}
      </div>
      </div>
    )
  }

  getEmptyCart=()=>{
    return(
      <div className="noItemsFoundText">No items have been added</div>
    )
  }

  getPricesCoupon=()=>{
    return(
<div className="pricesAndCouponContainer" >
          <div className="subTotalContainer">
            <div className="subTotalKeyPos">Sub Total</div>
            <div className="subTotalValuePos" >£{this.state.cartDetails.total_price}</div>
          </div>
          <div className="subTotalContainer" style={{borderTop:"1px solid #D4D4D4",padding:"16px 12px",height:"54px"}}>
            <div className="subTotalKeyPos" style={{fontSize:"18px"}} >Total Amount</div>
            <div className="subTotalValuePos" style={{fontSize:"18px"}} >£{this.state.cartDetails.total_price}</div>
          </div>
        </div>
    )
  }

  getPriceContainer=()=>{
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get("custId");
    return(
      <div className="priceContainer" >
        {this.getPricesCoupon()}
        <div className="buttonContainer">
        <button onClick={this.cancelOrderApiCall} disabled={this.buttonDisabled()} style={{ height: '56px', color: "#E11B22", backgroundColor: "#fff", width:"400px", padding:"16px", boxSizing:"border-box",borderRadius: "8px", border: "1px solid #E11B22"}}>CANCEL</button>
        <button disabled={this.buttonDisabled()} style={{ height: '56px', color:  this.buttonDisabled()?"#909090":"#fff", backgroundColor: this.buttonDisabled()?"#BFBFBF":"#E11B22", width:"400px", padding:"16px", boxSizing:"border-box",borderRadius: "8px", border: "0px solid transparent"}} onClick={()=>location.href=`/Dashboard/Checkout?account_id=${account_id}`}>CHECKOUT</button>
        </div>
      </div>
    )
  }

  excludeAllergenInput=()=>{
    return(
      <div className='exclude_allergen_container'>
        <textarea style={{ borderColor: this.state.exclude_allergen_word_count_error ? "#E11B22" : "#BFBFBF" }}
            className='notesAccordainTextArea' placeholder='Specify allergens' onChange={this.excludeAllergenChange} value={this.state.exclude_allergen||""}/>
        <div className='wordCount_excludeAllergen_container'>
        <div className='wordCount_excludeAllergen'>{this.state.exclude_allergen_word_count}/20</div>
        <div className='wordCount_excludeAllergen_error'>{this.state.exclude_allergen_word_count_error}</div>
        </div>

      </div>
    )
  }


  shoppingCartContainer=()=>{
    return(
      <div className="shoppingCartContainer">
        {this.itemContainer()}
        {this.getPriceContainer()}
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="cataloguepageContainer">
        {this.catalogueContainer()}
        {this.shoppingCartContainer()}
        <FilterPopup key='menu' open={this.state.filterPopupOpen} onClose={this.handleFilterPopupClose} allergen={this.state.allergenList} spiceLevel={this.state.spiceLevelList} dietType={this.state.dietType}
                spiceChange={this.handleSpiceChange}
                dietChange={this.handleDietChange}
                allergenChange={this.handleAllergenChange}
                selectedAllergen={this.state.allergenSelectedPopup}
                selectedSpice={this.state.spiceLevelSelectedPopup}
                selectedDiet={this.state.dietSelectedPopup}
                applyFilter={this.handleApplyFilters}
                clearFilter={this.handleClearFilters}/>
        <PosProductDialogPopup open={this.state.orderDescriptionPopupOpen} onClose={this.closeDescriptionPopup} closeIcon={true} >
          <CfposPrductDescription navigation={this.props.navigation} id={this.props.id} spiceLevelList={this.state.spiceLevelList} catalogueItemData={this.state.singleCatalogueData} token={this.token} locationSearch={this.props.locationSearch} updateCartItemsOnAdding={this.updateCartItemsOnAdding} cartItem={this.state.cartItemToEdit} />
        </PosProductDialogPopup>
        <CustomLoader loading={this.state.loading}/>
        <DialogPopup open={this.state.emptyCartPopup} onClose={this.closeEmptyCart}><EmptyCartItemAlert deleteCartItem={this.deleteCart} onClose={this.closeEmptyCart} /></DialogPopup>

        <DialogPopup open={this.state.allergenPopup} onClose={this.closeAllergenPopup} closeIcon={false} className="allergenPopopDialog" >
          <div className="allergenPopupContainer" >

            <div className="allergenHeader" >
                <div className="HeaderTitleAllergen" >Allergens on this item</div>
                <IconButton onClick={this.closeAllergenPopup} >
              <CloseIcon />
            </IconButton>
            </div>

            <div className="allergenDescContainer">
              <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
              <div style={{fontWeight:400, fontSize:"16px"}} >Please check the allergen info each time you visit</div>
              <div className="allergenContainer" >
                {this.state.allergenOnItem.map((value:AllergenInItem,index:number)=>
                    <div className="allergenName" key={`${value.id}${index}`} > {value.name}</div>
                )}
              </div>
              {this.excludeAllergenInput()}
              </div>
            </div>
            <div className="buttonContainer">
        <button onClick={this.closeAllergenPopup} style={{ height: '56px', color: "#E11B22", backgroundColor: "#fff", width:"200px", padding:"16px", boxSizing:"border-box",borderRadius: "8px", border: "1px solid #E11B22"}}>CANCEL</button>
        <button onClick={this.addAllergens} style={{ height: '56px', color: "#fff", backgroundColor: "#E11B22", width:"200px", padding:"16px", boxSizing:"border-box",borderRadius: "8px", border: "0px solid transparent"}}>SAVE</button>
        </div>
          </div>
        </DialogPopup>
        {this.applyDiscountModal()}
        {this.renderConfirmQuantityChange()}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
