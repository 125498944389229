import React from "react";
import { Autocomplete, AutocompleteInputChangeReason, InputAdornment, MenuItem, TextField } from "@mui/material";
import { SearchRounded } from "@material-ui/icons";

interface Props {
  options: any[];
  handleInputChange: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  handleClick: (option: any) => void;
}

const Search = ({ options, handleInputChange, handleClick }: Props) => {
  return (
    <Autocomplete
      freeSolo
      disableClearable
      noOptionsText="No results found"
      options={options}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.label ?? ""}
      onInputChange={handleInputChange}
      renderGroup={(params) => {
        return [
          <MenuItem
            key={params.key}
            classes={{
              root: "autocomplete__menu__group",
            }}
          >
            {params.group}
          </MenuItem>,
          params.children,
        ];
      }}
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            classes={{
              root: "autocomplete__menu__item",
              selected: "autocomplete__menu__item__selected",
            }}
            onClick={() => {
              handleClick(option);
            }}
          >
            {option.label}
          </MenuItem>
        );
      }}
      slotProps={{
        popper: {
          className: "autocomplete__popper",
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search here"
          classes={{
            root: "autocomplete__wrapper",
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
            classes: {
              root: "autocomplete__wrapper__input__root",
              input: "autocomplete__wrapper__input",
              notchedOutline: "autocomplete__wrapper__input__outline",
            },
          }}
        />
      )}
    />
  );
};

export default Search;
