export const configJSON = require("../../../../framework/src/config");
const baseURL = configJSON.baseURL.replace('https://', '');

export const connection = (token: string, retryInterval = 60000) => {
  let ws: WebSocket | null = null;
  let retryTimeout: any = null;

  const connect = () => {
    ws = new WebSocket(`wss://${baseURL}/cable?token=${token}`);

    ws.onopen = () => {
      console.log("WebSocket connection established.");
      if (retryTimeout) {
        clearTimeout(retryTimeout);
        retryTimeout = null;
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket connection error:", error);
      retry();
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
      retry();
    };
  };

  const retry = () => {
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      if(ws.readyState !== WebSocket.CONNECTING){
        ws.close();
      }
    }
    if (!retryTimeout) {
      retryTimeout = setTimeout(connect, retryInterval);
      console.log(`Retrying WebSocket connection in ${retryInterval / 1000} seconds.`);
    }
  };

  connect(); // Initial connection attempt

  return {
    getWebSocket: () => ws,
    close: () => {
        if(ws){
            ws.close();
        }
        if(retryTimeout){
            clearTimeout(retryTimeout);
        }
    }
  };
};

export const closeConnection = (ws: WebSocket) => {
    ws.onclose = () => {
        console.log('WebSocket connection closed');
    }
}
