import React from "react";
import DashboardWrapper from "../DashboardWrapper";
import Header from "../Header/Header";
import { lockedOut } from "../../../../blocks/dashboard/src/assets";
import "./NotAuthorized.css";

interface Props {
  navigation: any;
};

const NotAuthorized = ({ navigation }: Props) => {
  return (
    <DashboardWrapper navigation={navigation}>
      <Header
        navigation={navigation}
        headerTitle=""
      />
      <div
        data-testid="unauthorised"
        className="unauthorised__wrapper"
      >
        <img
          src={lockedOut}
          alt="unauthorised"
          className="unauthorised__image"
        />
        <span className="unauthorised__text">
          You don&apos;t have permission to access this feature. Reach out to admin for further assistance.
        </span>
      </div>
    </DashboardWrapper>
  );
};

export default NotAuthorized;
