import React from "react";
import moment from "moment";
import { Event, View, ViewsProps } from "react-big-calendar";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../Accordian";
import { StaffDayScheduler } from "../../Scheduler";
import ListView from "../../Scheduler/Views/List";
import TimelineView from "../../Scheduler/Views/Timeline";
import { getSessionStorage } from "../../../utils";
import TimeOff from "../TimeOff";
import "./StaffSchedule.css";
import MonthView from "../../Scheduler/Views/Month";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

interface Props {
  staff: {
    Chef: any[];
    "Online Order Manager": any[];
    "In Store Operator": any[];
    "Rider/Driver": any[];
    "In Store Manager": any[];
  },
  events: Event[];
  updateEvents: (events: Event[]) => void;
  getShift: (id: number, startDate?: string, endDate?: string) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  openWeeklyNotes: (start: string, end: string) => void;
  timeOff: (id: number, data: FormData) => void;
  handleClickOpen: () => void;
};

const StaffSchedule = ({ handleClickOpen, staff, events, updateEvents, getShift, openShiftPopup, totalShiftHours, gaps, conflicts, openWeeklyNotes, timeOff }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectedStaff, setSelectedStaff] = React.useState<number | null>(null);
  const [selectedRole, setSelectedRole] = React.useState<string | null>(null);
  const [storeHours, setStoreHours] = React.useState({ open: "", close: "" });
  const [range, setRange] = React.useState<{ start?: string; end?: string }>({ });

  const timeLabels = [
    { title: "Scheduled Time", color: "#F59E0B" },
    { title: "Logged Time", color: "#2C6F37" },
    { title: "Null", color: "#A2A2A2" }
  ];

  const sidebar = [
    { id: "Chef", label: "Chef", options: staff["Chef"] },
    { id: "Rider/Driver", label: "Delivery Rider", options: staff["Rider/Driver"] },
    { id: "In Store Operator", label: "In Store Operator", options: staff["In Store Operator"] },
    { id: "In Store Manager", label: "In Store Manager", options: staff["In Store Manager"] },
    { id: "Online Order Manager", label: "Online Order Manager", options: staff["Online Order Manager"] },
  ];

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelectStaff = (id: number, label: string) => {
    setSelectedStaff(id);
    setSelectedRole(label);
    getShift(id, range.start, range.end);
  }

  const setView = (): View => {
    if (selectedStaff) return "week";
    return "month";
  };

  const setViews = (): ViewsProps<Event, object> => {
    if (selectedStaff === null) return { month: MonthView, week: TimelineView } as any;
    return { month: MonthView, week: true, list: ListView } as any;
  };

  const handleScheduleClick = () => {
    setSelectedStaff(null);
    setExpanded(false);
    getShift(0, range.start, range.end);
  };

  React.useEffect(() => {
    const settings = getSessionStorage("restaurant_settings");
    const is24HourFormat = settings?.time_format === "24-hour";
    const today = moment().format("dddd");
    const open: string = settings?.store_hours?.[today]?.open;
    const close: string = settings?.store_hours?.[today]?.close;

    setStoreHours({ open: moment(open, ["HH:mm"]).format(is24HourFormat ? "HH:mm" : "hh:mm A"), close: moment(close, ["HH:mm"]).format(is24HourFormat ? "HH:mm" : "hh:mm A") });
  }, []);

  return (
    <section className="staff_schedule__wrapper">
      {selectedStaff ? (
        <div className="staff_schedule__toolbar">
          {timeLabels.map((label, index) => (
            <div
              key={index}
              className="staff_schedule__toolbar__item"
            >
              <div
                className="staff_schedule__toolbar__item__circle"
                style={{ backgroundColor: label.color }}
              />
              <span className="staff_schedule__toolbar__item__label">{label.title}</span>
            </div>
          ))}

          <TimeOff selectedStaff={selectedStaff} timeOff={timeOff} />
        </div>
      ) : (
          <div style={{ display: 'flex' }}>
            <div className="staff_schedule__toolbar">
              <p className="staff_schedule__toolbar__store_hours">Store Hours: {storeHours.open} - {storeHours.close}</p>
            </div>
            <div onClick={handleClickOpen} style={{margin:'10px'}}>
              <SystemUpdateAltIcon />
            </div>
          </div>
      )}

      <div className="staff_schedule__scheduler">
        <div className="staff_schedule__staff_list">
          <div
            className="staff_schedule__staff_list__schedule"
            data-selected={selectedStaff === null}
            onClick={handleScheduleClick}
          >
            Schedule
          </div>

          {sidebar.map((item, itemIdx) => (
            <Accordion
              key={itemIdx}
              expanded={expanded === item.label}
              onChange={handleChange(item.label)}>
              <AccordionSummary>
                {item.label}
              </AccordionSummary>
              {item.options && item.options.map((option) => (
                <AccordionDetails
                  key={option.id + "+" + option.role}
                  className={`staff_schedule__staff_list__option ${selectedStaff === option.id && selectedRole === option.role ? "selected" : ""}`}
                  onClick={() => handleSelectStaff(option.id, item.id)}
                >
                  <div className="staff_schedule__staff_list__option__index">
                    {option.label.split("")[0]?.toUpperCase()}
                  </div>
                  <span className="staff_schedule__staff_list__option__label">
                    {option.label}
                  </span>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </div>
        {/* Scheduler */}
        <StaffDayScheduler
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          recievedView={setView()}
          recievedViews={setViews()}
          range={range}
          setRange={setRange}
          events={events}
          getShift={getShift}
          updateEvents={updateEvents}
          openShiftPopup={openShiftPopup}
          totalShiftHours={totalShiftHours}
          gaps={gaps}
          conflicts={conflicts}
          openWeeklyNotes={openWeeklyNotes}
        />
      </div>
    </section>
  );
};

export default StaffSchedule;
