import React from "react";
import moment from "moment-timezone";
import { DateLocalizer, Navigate, ViewProps } from "react-big-calendar";
import DataGrid from "../../../DataGrid";
import ScheduleListColumns from "../../../DataGrid/columns/ScheduleListColumns";

const daysOrder: string[] = Array.from({ length: 7 }, (_, i) => moment().day(i).format("dddd"));
daysOrder.push(daysOrder.shift() as string);

const ListView = ({
    date,
    localizer,
    onView,
    events,
}: ViewProps) => {
    const columns = ScheduleListColumns();

    const restaurant_details = JSON.parse(sessionStorage.getItem("restaurant_details") ?? "{}");
    const time_format = restaurant_details?.time_format;
    const is24HourFormat = time_format === "24-hour";

    const from = localizer.startOf(date, "week", 0);
    const to = localizer.add(from, 7, "days");

    const filteredEvents = events?.filter(event => {
        const eventStart = new Date(event?.start ?? "");
        const eventEnd = new Date(event?.end ?? "");

        if (eventStart && eventEnd)
            return eventStart >= from && eventEnd <= to;
    });

    const data = filteredEvents?.map((event) => ({
        day: moment(event.start).format("dddd DD"),
        start: moment(event.start).format(is24HourFormat ? "HH:mm" : "hh:mm A"),
        end: moment(event.end).format(is24HourFormat ? "HH:mm" : "hh:mm A"),
        login: event.resource?.login_details?.login ? moment(event.resource?.login_details?.login).format(is24HourFormat ? "HH:mm" : "hh:mm A") : "",
        logout: event.resource?.login_details?.logout ? moment(event.resource?.login_details?.logout).format(is24HourFormat ? "HH:mm" : "hh:mm A") : "",
        resource: {
            ...event.resource,
            event
        },
    })).sort((a, b) => {
        const dayIndexA = daysOrder.indexOf(a.day.split(" ")[0]); // Get day name and find its index
        const dayIndexB = daysOrder.indexOf(b.day.split(" ")[0]);

        return dayIndexA - dayIndexB; // Sort based on the day index
    });

    return (
        <DataGrid
            data={data ?? []}
            columns={columns}
            emptyRowsText="No Shifts Found"
            tableOptions={{
                getRowId: (_row, index) => index.toString()
            }}
        />
    );
};

ListView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    const start = localizer.startOf(date, "week", 0);
    const end = localizer.endOf(date, "week", 0);

    const range = [];
    let current = start;

    while(localizer.lte(current, end, "week")) {
        range.push(current);
        current = localizer.add(current, 1, "week");
    }

    return range;
};

ListView.navigate = (
    date: Date,
    action: any,
    { localizer }: { localizer: DateLocalizer }
) => {
    if (action instanceof Date) return action;

    switch(action) {
        case Navigate.NEXT:
            return localizer.add(date, 1, "week");
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "week");
        default:
            return date;
    }
};

ListView.title = (
    date: Date,
    { localizer }: { localizer: DateLocalizer }
) => {
  const start = localizer.startOf(date, "week", 0);
  const end = localizer.add(start, 6, "day");

  const startMonth = moment(start).format("MMMM")
  const from = moment(start).format("DD");
  const endMonth = moment(end).format("MMMM")
  const to = moment(end).format("DD");

  return `${startMonth} ${from} - ${startMonth !== endMonth ? `${endMonth} ` : ""}${to}`;
};

export default ListView;
