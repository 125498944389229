import React from 'react'
import GooglePayController, { configJSON, Props } from './GooglePayController'
import GooglePayButton from "@google-pay/button-react"
import { Dialog } from '@material-ui/core'
import CustomLoader from '../../../components/src/CustomLoader.web'
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
export const baseURL = require("../../../framework/src/config.js").baseURL


const stripePromise = loadStripe(configJSON.stripePromise)
export default class GooglePay extends GooglePayController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.initializeGooglePay();
        this.initializeStripe();
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.walletType !== this.props.walletType) {
            this.fetchPaymentIntent();
        }
        if (prevProps.amount !== this.props.amount) {
            this.setupGooglePay();
        }
    }

    async initializeStripe() {
        const stripeInstance = await stripePromise;
        this.setState({ stripe: stripeInstance });
    }

    async fetchPaymentIntent() {
        try {
            this.setState({ loading: true });
            const response = await axios.post(`${baseURL}/stripe_integration/payment_intents`, {
                order_id: this.props.order_id,
            },
                {
                    headers: {
                        "token": `${localStorage.getItem("authToken")}`
                    }
                });

            if (response.data.data.attributes) {
                this.setState({ clientSecret: response.data.data.attributes.client_secret }, () => {
                    this.initializeStripe()
                });
            }
        } catch (error) {
            console.error("Error fetching PaymentIntent:", error);
        } finally {
            this.setState({ loading: false });
        }
    }

    initializeGooglePay() {
        if (window.google && window.google.payments) {
            this.setupGooglePay();
        } else {
            const script = document.createElement("script");
            script.src = "https://pay.google.com/gp/p/js/pay.js";
            script.async = true;
            script.onload = () => this.setupGooglePay();
            document.body.appendChild(script);
        }
    }

    setupGooglePay() {
        try {
            const paymentsClient = new google.payments.api.PaymentsClient({
                environment: "TEST",
            });

            const paymentDataRequest: any = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                    {
                        type: "CARD",
                        parameters: {
                            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                            allowedCardNetworks: ["VISA", "MASTERCARD"],
                        },
                        tokenizationSpecification: {
                            type: "PAYMENT_GATEWAY",
                            parameters: {
                                gateway: "stripe",
                                "stripe:publishableKey": "pk_test_51POdVTLcQILBXFg4WEifpfXCwnAbBLFz7jXv4CjMmmN275b7esY8Y84mDBdofbLDGEaCPqi5xt8nzQQJXaHWa7gx00RzX2G0jo",
                                "stripe:version": "2020-08-27",
                            },
                        },
                    },
                ],
                merchantInfo: {
                    merchantId: "BCR2DN4T76F55UJO",
                    merchantName: "Tops Pizza Limited",
                },
                transactionInfo: {
                    totalPriceStatus: configJSON.totalPriceStatus,
                    totalPriceLabel: configJSON.totalPriceLabel,
                    totalPrice: this.props.amount,
                    currencyCode: configJSON.currencyCode,
                    countryCode: configJSON.countryCode
                },
                shippingAddressRequired: false,
            };

            paymentsClient.isReadyToPay(paymentDataRequest)
                .then((response) => {
                    if (response.result) {
                        const button: any = paymentsClient.createButton({
                            onClick: () => this.handlePayment(paymentsClient, paymentDataRequest),
                        });
                        this.props.googlePayButtonRef.current.innerHTML = "";
                        this.props.googlePayButtonRef.current.appendChild(button);
                    }
                })


        } catch (error) {
            console.error("Failed to initialize Google Pay:", error);
        }
    }
    async NemPayment(stripe: any, token: any) {
        if(stripe.createPaymentMethod){
            return await stripe.createPaymentMethod({
                type: "card",
                card: { token: token.id },
                billing_details: { name: "Test User" },
            });
        }
    }

    async confirmErrorNewPayment(confirmError: any, paymentIntent: any) {
        
        if (confirmError) {
            this.props.openPaymentPopup(
                "failure", "TRY AGAIN", 'Payment Failed!', confirmError.message
            );
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            this.setState({payment_id:paymentIntent.id},()=>{
                this.doGpayPayment();
            })
            this.props.openPaymentPopup(
                "success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!"
            );
        }
    }

    handlePayment = async (paymentsClient: any, paymentDataRequest: any) => {

        const paymentData = await paymentsClient.loadPaymentData(
            paymentDataRequest
        );
        const token = JSON.parse(
            paymentData.paymentMethodData.tokenizationData.token
        );
        const { stripe, clientSecret } = this.state;
        const { paymentMethod }: any = await this.NemPayment(stripe, token);
        const { error: confirmError, paymentIntent } =
            await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod?.id,
            });
        await this.confirmErrorNewPayment(confirmError, paymentIntent)

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <div style={{ padding: "40px", display: "none" }} ref={this.props.cardPayButtonRef}>
                    <button id="buttonTopay" onClick={this.doPaymentViaCard}>Trigger Card Pay</button>
                </div>
                <div style={{ padding: "40px", display: "none" }} ref={this.props.cashPayButtonRef}>
                    <button id="buttonTopayCash" onClick={this.doPaymentViaCash}>Trigger Card Pay</button>
                </div>
                <div ref={this.props.googlePayButtonRef} style={{ padding: "20px", display: 'none' }}></div>
                <Dialog open={this.state.openStripe}>
                    <iframe src={this.state.location} />
                </Dialog>
                <CustomLoader loading={this.state.loading} />
            </>
        )
        // Customizable Area End
    }
}
