import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { z } from "zod";
import { convertLogsToMessages, createMessage, getSessionStorage } from "../../../components/src/utils";
import { PermitSchema, StaffGeneralInfoSchema } from "../../../components/src/Schemas/AddNewStaff";
import { ReceivedFile, Role } from "../../dashboard/src/dashboards/StaffInformation/StaffInformationController.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
};

export interface S {
  token: string;
  loading: boolean;
  userId: string | null;
  availableIdentityTypes: { id: number; identity_type: string }[];
  availableRoles: { id: number; name: string }[];
  details: any | null;
  expiryPopupAnchorEl: HTMLElement | null;
  activityLog: {
    message: string;
    timestamp: string;
    updatedAttributes: Record<string, any>;
    changedAttributes: any;
    changes: any;
    timeAgo: string;
  }[];
  openUpdateModal: boolean;
  selectedTab: number;
  openUpdateStaff: boolean;
  errors?: string[];
  validationErrors: Record<string, string>;
  openConfirmation: boolean;
};

interface SS {};

export default class PosProfileController extends BlockComponent<Props, S, SS> {
  getStaffCallId: string = "";
  activityLogCallId: string = "";
  updateStaffCallId: string = "";
  availableIdentityCallId: string = "";
  availableRolesCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      userId: null,
      details: null,
      availableIdentityTypes: [],
      availableRoles: [],
      expiryPopupAnchorEl: null,
      activityLog: [],
      openUpdateModal: false,
      selectedTab: 0,
      openUpdateStaff: false,
      validationErrors: {},
      openConfirmation: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  async componentDidMount() {
    super.componentDidMount();

    this.setState({ loading: true });

    await this.getToken();
    await this.getCurrentUserId();

    await this.getAvailableRoles();
    await this.getAvailableIdentityTypes();

    this.getStaffInfo();
  };

  receive = async (_from: string, message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (error || !response) {
      runEngine.debugLog("API Error", error);
      return;
    };

    this.expiredToken(response);
    this.notAuthorised(response);

    const handler = this.getMessageHandler(callId);

    if (handler) {
      handler(response);
      this.setState({ loading: false });
    };
  };

  getMessageHandler(callId: string) {
    const messageHandlers: Record<string, any> = {
      [this.getStaffCallId]: this.handleStaffDetails,
      [this.availableIdentityCallId]: this.handleAvailableIdentity,
      [this.availableRolesCallId]: this.handleAvailableRoles,
      [this.activityLogCallId]: this.handleActivityLog,
      [this.updateStaffCallId]: this.handleUpdateStaff,
    };

    return messageHandlers[callId];
  };

  expiredToken = (response: any) => {
    if (response?.errors?.[0]?.token) {
      this.showAlert("token", "token");
      return;
    };
  };

  notAuthorised = (response: any) => {
    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    };
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) return;
    this.setState({ token });
  };

  getCurrentUserId = async () => {
    const userDetail = getSessionStorage("userDetail");

    if (!userDetail) return;

    this.setState({ userId: userDetail?.id });
  };

  getStaffInfo = () => {
    const { userId } = this.state;

    const getDataMsg = createMessage(
      "GET",
      `${configJSON.getStaffInfo}/${userId}`
    );

    this.getStaffCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleStaffDetails = (response: any) => {
    if (response.staff_member) {
      const staff = response.staff_member;
      const attributes = staff.attributes;
      const { identity_document, visa, staff_id } = attributes;
      if (!staff_id) {
        attributes.staff_id = "xxxx-xxxx"
      };
      if (attributes.user_roles) {
        attributes.user_roles = attributes.user_roles
          .map((role: any) => {
            const foundRole = this.state.availableRoles.find((availableRole) => availableRole.name === role);

            return foundRole ? { id: foundRole.id, name: foundRole.name } : null;
          })
          .filter(Boolean) as Role[];
      }
      if (identity_document) {
        attributes.identity_document = [identity_document] as ReceivedFile[];
      }
      if (visa) {
        attributes.visa = [visa] as ReceivedFile[];
      } else {
        attributes.visa = [] as ReceivedFile[];
      }

      this.setState({ details: attributes }, this.getActivityLog);
    }
  };

  getAvailableIdentityTypes = async () => {
    const getDataMsg = createMessage(
      "GET",
      configJSON.availableIdentityTypes
    );

    this.availableIdentityCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleAvailableIdentity = (response: any) => {
    if (response.identity_types)
      this.setState({ availableIdentityTypes: response.identity_types });
  };

  getAvailableRoles = async () => {
    const getDataMsg = createMessage(
      "GET",
      configJSON.availableRoles
    );

    this.availableRolesCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleAvailableRoles = (response: any) => {
    if (response.roles)
      this.setState({ availableRoles: response.roles });
  };

  getActivityLog = () => {
    const { userId } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.getStaffInfo}/${userId}/activity_logs`
    );

    this.activityLogCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleActivityLog = (response: any) => {
    if (response.activity_logs) {
      const logs = convertLogsToMessages(response.activity_logs);
      this.setState({ activityLog: logs });
    }
  };

  updateStaff = (data: any) => {
    const { userId, token } = this.state;
    const validation = this.validateData(data);
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data);

    const postDataMsg = createMessage(
      "PUT",
      `${configJSON.updateStaff}/${userId}`,
      { token },
      formData,
    );

    this.updateStaffCallId = postDataMsg.messageId;
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  handleUpdateStaff = (data: any) => {
    if (data?.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleUpdateStaffClose();
    this.getStaffInfo();
  }

  validateData = (data: any) => {
    let errorMap: Record<string, string> = {};

    const schemas = [
      { schema: StaffGeneralInfoSchema, tab: 0 },
      { schema: PermitSchema, tab: 0, condition: data.permitRequired },
    ];

    for (let { schema, tab, condition } of schemas) {
      if (condition === false) continue;
      const validation = schema.safeParse(data);
      if (!validation.success) {
        if (validation.error instanceof z.ZodError) {
          validation.error.errors.forEach((err) => {
            errorMap[err.path[0]] = err.message;
          });
        }
        this.setState({ validationErrors: errorMap });
        return { success: false, tab };
      }
    }

    return { success: true };
  };

  createFormData = (data: any) => {
    const formData = new FormData();
    formData.append(
      "account[staff_id]",
      data.staffId
    );
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    formData.append("account[first_name]", data.firstName);
    formData.append("account[last_name]", data.lastName);
    formData.append("account[country_code]", data.countryCode);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, ""));
    formData.append("account[full_phone_number", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType);

    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);

    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode);
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[email]", data.email);
    formData.append("account[password]", data.password);

    return formData;
  };

  openExpiryPopup = (event: React.MouseEvent<HTMLElement>) =>
    this.setState({ expiryPopupAnchorEl: event.currentTarget });

  closeExpiryPopup = () =>
    this.setState({ expiryPopupAnchorEl: null });

  openUpdateModal = () =>
    this.setState({ openUpdateModal: true });

  closeUpdateModal = () =>
    this.setState({ openUpdateModal: false });

  changeTab = (_event: React.SyntheticEvent, value: number) =>
    this.setState({ selectedTab: value });

  handleUpdateStaffOpen = () =>
    this.setState({ openUpdateStaff: true });

  handleUpdateStaffClose = () =>
    this.setState({ openUpdateStaff: false, errors: undefined, validationErrors: {} });

  confirmClose = () =>
    this.setState({ openConfirmation: true });

  handleConfirmationClose = () =>
    this.setState({ openConfirmation: false });

  discardChanges = () => {
    this.handleUpdateStaffClose();
    this.handleConfirmationClose();
  }
};
