export const statisticsImage = require("../assets/chart.svg")
export const reportImageDisabled = require("../assets/report_disabled.svg")
export const statisticsImageDisabled = require("../assets/charts_disabled.svg")
export const reportImage = require("../assets/reports.svg")
export const ExportImage = require("../assets/image_.png")
export const ExportImage2 = require("../assets/image_ (1).png")
export const ExportImage3 = require("../assets/image_warning_amber_24px.png")
export const ExportImg1 = require("../assets/image_ (2).png")
export const ExportImg2 = require("../assets/image_ (3).png")
export const ExportImg3 = require("../assets/image_ (4).png")
export const ExportImg4 = require("../assets/image_ (5).png")
export const ExportImgDisabled= require("../assets/export.svg")