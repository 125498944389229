import React from "react";
import { ArrowBackRounded, CloseRounded, ErrorOutline } from "@material-ui/icons";
import { FormControlLabel, IconButton, Popover, SvgIcon } from "@mui/material";
import ViewStaffController, { Props } from "./ViewStaffController.web";
import { ReceivedFile } from "../StaffInformationController.web";
import DashboardWrapper from "../../../../../../components/src/Dashboard/DashboardWrapper";
import { UnStyledButton } from "../../../../../../components/src/Button";
import { WingosSwitch } from "../../../../../../components/src/Switch";
import { WingosPreview } from "../../../../../../components/src/Dropzone";
import ViewStaffHeader from "../../../../../../components/src/Dashboard/ViewStaff/ViewStaffHeader";
import UpdateStaffInfo from "../../../../../../components/src/Dashboard/UpdateStaffInfo";
import { isWithinThreeMonths } from "../../../../../../components/src/utils";
import ConfirmationPopup from "../../../../../../components/src/Dashboard/ConfirmationPopup";
import ManageNote from "../../../../../../components/src/Dashboard/ManageNote";
import NoteSuccess from "../../../../../../components/src/Dashboard/ManageNote/NoteSuccess";
import "./ViewStaff.web.css";

class ViewStaff extends ViewStaffController {
  constructor(props: Props) {
    super(props);
  }

  convertDate(dateString: string) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const [year, month, day] = dateString.split("-");
    return `${year}-${months[parseInt(month, 10) - 1]}-${day}`;
  }

  showNearExpiryAlert = () => {
    const open = Boolean(this.state.expiryPopupAnchorEl);

    return (
      <div data-testid="expiry-alert">
        <IconButton data-testid="expiry-alert-button" size="small" color="error" onClick={this.openExpiryPopup}>
          <ErrorOutline />
        </IconButton>
        <Popover
          open={open}
          anchorEl={this.state.expiryPopupAnchorEl}
          onClose={this.closeExpiryPopup}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          classes={{
            paper: "view_staff__expiry_popup__popover"
          }}
        >
          <div className="view_staff__expiry_popup">
            <div className="view_staff__expiry_popup__header">
              <span className="view_staff__expiry_popup__title">Visa Renewal</span>
              <IconButton size="small" onClick={this.closeExpiryPopup}>
                <CloseRounded fontSize="small" />
              </IconButton>
            </div>
            <span className="view_staff__expiry_popup__message">
              Visa expiration is approaching. Renew to avoid complications.
            </span>
          </div>
        </Popover>
      </div>
    );
  };

  render() {
    const { navigation } = this.props;
    const generalInfo = [
      { label: "Staff ID", value: this.state.data?.staff_id },
      { label: "Role", value: this.state.data?.user_roles?.map((role: any) => role.name).join(", ") },
      { label: "Name", value: this.state.data?.full_name },
      { label: "Mobile Number", value: this.state.data?.full_phone_number },
      { label: "Address", value: "" },
      {
        label: "Identity Type",
        value: this.state.data?.identity_type,
        attachment: {
          document: this.state.data?.identity_document[0],
        },
      },
      {
        label: "Attachments",
        attachment: {
          document: this.state.data?.visa !== "" ? this.state.data?.visa[0] : null,
          expiryDate: this.state.data?.expiry_date,
        },
        hidden: !this.state.data?.permit_require
      },
    ];
    const accountInfo = [{ label: "Email Address", value: this.state.data?.email }];

    return (
      <DashboardWrapper navigation={this.props.navigation}>
        <div
          data-testid="view-staff"
          className="view_staff__wrapper"
        >
          <div
            data-testid="header"
            className="view_staff__header"
          >
            <UnStyledButton
              data-testid="back-button"
              startIcon={<ArrowBackRounded />}
              onClick={() => navigation.goBack()}
            >
              Back
            </UnStyledButton>

            <div className="view_staff__info">
              <div className="view_staff__info__title">
                Staff ID {this.state.data?.staff_id} | {this.state.data?.full_name}
              </div>
              <FormControlLabel
                data-testid="account-status-switch"
                disabled={this.state.account_status ? !this.enableDeactivate : !this.enableActivate}
                checked={this.state.account_status}
                control={<WingosSwitch />}
                onChange={(_event, checked) => this.toggleAccountStatus(checked)}
                label="Active"
                labelPlacement="start"
                classes={{
                  root: "view_staff__info__switch",
                  label: "view_staff__info__label",
                }}
              />
            </div>
          </div>

          <div className="view_staff__section__wrapper">
            <section className="view_staff__content">
              <div className="view_staff__personal_info">
                <ViewStaffHeader
                  showEditButton={this.enableUpdate}
                  icon={
                    <SvgIcon>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16 8c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4m-2 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2m-2 5c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4m-6 4.01V18h12v-1c-.2-.71-3.3-2-6-2s-5.8 1.29-6 2.01"
                          fill="#1D1D1D"
                        />
                      </svg>
                    </SvgIcon>
                  }
                  title="Staff Information"
                  editAction={this.handleUpdateStaffOpen}
                />

                <div className="view_staff__info__wrapper">
                  <div className="view_staff__info__general_info">
                    <span className="view_staff__info__general_info__title">General Information</span>

                    {generalInfo.map((info) => (
                      <div
                        key={info.label}
                        className={`view_staff__info__general_info__row ${info.hidden ? "hidden" : ""}`}
                      >
                        <span className="view_staff__info__general_info__row__label">{info.label}:</span>
                        <div className="view_staff__info__general_info__row__value">
                          {info.attachment?.document ? (
                            <>
                              <WingosPreview files={[info.attachment?.document as ReceivedFile]} />
                              {info.attachment?.expiryDate && (
                                <div className="view_staff__info__general_info__row__value__expiry">
                                  <span>Expiry Date: {this.convertDate(info.attachment?.expiryDate)}</span>

                                  {isWithinThreeMonths(info.attachment?.expiryDate) && this.showNearExpiryAlert()}
                                </div>
                              )}
                            </>
                          ) : (
                            <>{info.value}</>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="view_staff__info__general_info">
                    <span className="view_staff__info__general_info__title">Account Information</span>

                    {accountInfo.map((info) => (
                      <div
                        key={info.label}
                        className="view_staff__info__general_info__row"
                      >
                        <span className="view_staff__info__general_info__row__label">{info.label}:</span>
                        <span className="view_staff__info__general_info__row__value">{info.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="view_staff__personal_info notes">
                <ViewStaffHeader
                  showEditButton={this.enableNoteAdd}
                  editAction={this.handleManageNoteOpen}
                  icon={
                    <SvgIcon>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="a"
                          style={{
                            maskType: "alpha",
                          }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={24}
                          height={24}
                        >
                          <path
                            fill="#D9D9D9"
                            d="M0 0h24v24H0z"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            d="M5 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 19V5q0-.824.587-1.412A1.93 1.93 0 0 1 5 3h14q.824 0 1.413.587Q21 4.176 21 5v6.7a8 8 0 0 0-.975-.387A6 6 0 0 0 19 11.075V5H5v14h6.05q.076.55.237 1.05.163.5.388.95zm0-2V5v6.075V11zm2-2h4.075q.076-.525.238-1.025t.362-.975H7zm0-4h6.1q.8-.75 1.787-1.25A7 7 0 0 1 17 11.075V11H7zm0-4h10V7H7zm11 14q-2.075 0-3.537-1.462Q13 20.074 13 18q0-2.075 1.463-3.537Q15.926 13 18 13q2.075 0 3.538 1.463T23 18t-1.462 3.538Q20.074 23 18 23m-.5-2h1v-2.5H21v-1h-2.5V15h-1v2.5H15v1h2.5z"
                            fill="#1D1D1D"
                          />
                        </g>
                      </svg>
                    </SvgIcon>
                  }
                  title="Notes"
                />

                <div className="view_staff__info___notes">
                  {this.state.notes.length > 0 ? (
                    this.state.notes.map((note) => (
                      <div
                        key={note.id}
                        className="view_staff__info__note"
                        onClick={() => this.enableNoteUpdate && this.handleEditNote({ id: note.id, note: note.notes })}
                      >
                        <p className="view_staff__info__note__text">{note.notes}</p>

                        <div className="view_staff__info__note__timestamps">
                          <span>Updated on {this.formatDateTime(note.updated_at)}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="view_staff__info__note">
                      <p className="view_staff__info__note__text">{!this.enableNotes ? "You are not authorised to view notes" : "No notes available"}</p>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="view_staff__activity_log">
              <ViewStaffHeader
                icon={
                  <SvgIcon>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.964 5.643v-2.79c0-.45-.54-.67-.85-.35l-3.8 3.79c-.2.2-.2.51 0 .71l3.79 3.79c.32.31.86.09.86-.36v-2.79c3.73 0 6.68 3.42 5.86 7.29-.47 2.27-2.31 4.1-4.57 4.57-3.57.75-6.75-1.7-7.23-5.01a1 1 0 0 0-.98-.85c-.6 0-1.08.53-1 1.13.62 4.39 4.8 7.64 9.53 6.72 3.12-.61 5.63-3.12 6.24-6.24.99-5.13-2.91-9.61-7.85-9.61"
                        fill="#1D1D1D"
                      />
                    </svg>
                  </SvgIcon>
                }
                title="Activity Log"
              />

              <div className="view_staff__log__stack">
                {this.state.activityLog.map((log, index) => (
                  <div
                    key={index}
                    className="view_staff__log"
                  >
                    <div className="view_staff__log__decorator">
                      <div className="view_staff__log__decorator__circle" />
                      <div className="view_staff__log__decorator__line" />
                    </div>
                    <div className="view_staff__log__time">{log.timeAgo}</div>
                    <div className="view_staff__log__message_wrapper">
                      <span className="view_staff__log__message">{log.message}</span>

                      {log.changes && (
                        <span className="view_staff__log__change">
                          {Object.keys(log.changes).map((key) => (
                            <div key={key}>
                              Changed {key} from {log.changes[key].from} to {log.changes[key].to}
                            </div>
                          ))}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {this.state.openUpdateStaff && this.state.data && (
              <UpdateStaffInfo
                data-testid="update-staff-info-modal"
                open={this.state.openUpdateStaff}
                onClose={this.handleUpdateStaffClose}
                identityTypes={this.state.availableIdentityTypes}
                roles={this.state.availableRoles}
                onSubmit={this.updateStaff}
                errors={this.state.errors}
                validations={this.state.validationErrors}
                data={this.state.data as any}
                confirmClose={this.confirmClose}
              />
            )}

            <ConfirmationPopup
              open={this.state.openConfirmation}
              onClose={this.discardChanges}
              goBack={this.handleConfirmationClose}
            />

            <ManageNote
              id={this.state.data?.id}
              open={this.state.openManageNote}
              onClose={this.handleManageNoteClose}
              submit={this.state.editNote ? this.updateNote : this.addNote}
              edit={this.state.editNote}
            />

            <NoteSuccess
              open={this.state.openNoteSuccess}
              onClose={this.handleNoteSuccessClose}
            />
          </div>
        </div>
      </DashboardWrapper>
    );
  }
}

export default ViewStaff;
