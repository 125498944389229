// Customizable Area Start
import React from "react";
import { FormControl, FormControlLabel, FormHelperText, IconButton, RadioGroup } from "@mui/material";

import { WingoInput } from "../../../components/src/Inputs/CustomInputs";
import { ContainedButton } from "../../../components/src/Button";
import WingoRadio from "../../../components/src/Radio/Radio";
import FormLabel from "../../../components/src/FormLabel";
import Modal from "../../../components/src/Modal";
import DriverReceipt from "../../../components/src/receipt/templates/Driver";

import "./ReceiptCustomisation.css";

import ReceiptCustomisationController, {
  Props,
} from "./ReceiptCustomisationController.web";
import ChefReceipt from "../../../components/src/receipt/templates/ChefReceipt";
import CustomLoader from "../../../components/src/CustomLoader.web";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

export default class ReceiptCustomisation extends ReceiptCustomisationController {
  constructor(props: Props) {
    super(props);
  };

  renderPrinterList = (): React.ReactNode => {
    const { openPrinterPopup: open, printerList: printers } = this.state;

    return (
      <Modal
        closeButton
        maxWidth="xs"
        open={open}
        onClose={this.closePrinterDialogue}
      >
        <div className="receipt__printers__wrapper">
          <h3>Select a Printer</h3>

          <FormControl>
            <FormLabel>Please choose one</FormLabel>

            <RadioGroup
              name="selectedPrinter"
              onChange={this.handleRadioChange}
            >
              {printers.map((printer) => (
                <FormControlLabel
                  key={printer}
                  value={printer}
                  label={printer}
                  control={<WingoRadio />}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <ContainedButton onClick={this.printHTML}>
            Print
          </ContainedButton>
        </div>
      </Modal>
    );
  };

  render(): React.ReactNode {
    const { order_id, data, items, error } = this.state;

    return (
      <div className="receipt__wrapper" style={{minHeight:"100vh"}}>
        
        {!this.state.loading && <IconButton className="printIconBackButton" onClick={this.navigateBack}> 
          <ArrowBackRoundedIcon /> Back
          </IconButton>}

        {data && (
          <>
            <DriverReceipt contentRef={this.contentRef} response={data} items={items} />
            <ChefReceipt contentRef={this.chefContentRef} response={data} items={items}/>
          </>
        )}

        {this.renderPrinterList()}
        <CustomLoader loading={this.state.loading} />
      </div>
    );
  };
};
// Customizable Area End
