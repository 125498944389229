import React from "react";
const TimeKeeper = require("react-timekeeper").default;
import { Popover } from "@mui/material";
import { ClockIcon } from "../Icons";
import { getSessionStorage } from "../utils";
import "./TimeInput.css";

export interface TimeOutput {
  hour: string;
  minute: string;
  meridiem?: "AM" | "PM";
  formatted24: string;
  formatted12: string;
};

interface Props {
  is24HourFormat?: boolean;
  value: TimeOutput;
  onChange: (value: TimeOutput) => void;
  disabledTimeRange?: null | {
    from: string;
    to: string;
  };
  disabled?: boolean;
};

const ReactTimeInput: React.FC<Props> = ({
  is24HourFormat,
  value,
  onChange,
  disabledTimeRange,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const formattedVal = React.useMemo(() => typeof value === "string" ? value : value.formatted24, [value]);
  const settings = React.useMemo(() => getSessionStorage("restaurant_settings"), []);
  const format = settings?.time_format;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="time_input__wrapper">
      <div data-disabled={disabled} className="time_input" onClick={handleClick}>
        <p>{formattedVal}</p>
        <ClockIcon className="time_input__icon" />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: "time_input__popover"
        }}
      >
        <TimeKeeper
          hour24Mode={is24HourFormat ?? format === "24-hour"}
          time={value}
          onChange={onChange}
          disabledTimeRange={disabledTimeRange}
        />
      </Popover>
    </div>
  );
};

export default ReactTimeInput;
