import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getSessionStorage } from "../../../components/src/utils";

export type Account = {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: null | string;
  phone_number: null | string;
  email: string;
  activated: boolean;
  device_id: null | string;
  unique_auth_id: null | string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: null | string;
  platform: null | string;
  user_type: null | string;
  app_language_id: null | string;
  last_visit_at: null | string;
  is_blacklisted: boolean;
  suspend_until: null | string;
  status: string;
  role_id: null | string;
  full_name: string;
  user_role: null | string;
  notification: boolean;
  gender: null | string;
  date_of_birth: null | string;
  age: null | string;
};

export type OrderDetails = {
  order_id: number;
  order_number: string;
}

export type NotificationAttributes = {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string;
  is_read: boolean;
  notification_for: string;
  order_details?: OrderDetails;
  read_at: null | string;
  created_at: string;
  updated_at: string;
  account: Account;
  image: {
    url: string;
    content_type: string;
    file_name: string;
  };
};

export type NotificationData = {
  id: string;
  type: string;
  attributes: NotificationAttributes;
};

export type Notification = NotificationData;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  anchorEl?: null | HTMLElement;
  handleClose?: () => void;
  navigate?: (navigator: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: Notification[];
  selectedData: any;
  token: any;
  notifications: {
    [key: string]: NotificationAttributes[];
  };
  loadingNotifications: boolean;
  latestNotifications: NotificationAttributes[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  markAllAsReadCallId: string = "";
  deleteCallId: string = "";
  settings: any = {};
  time_format: "HH:mm" | "hh:mm A" = this.settings?.time_format === "24-hour" ? "HH:mm" : "hh:mm A";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notifications: {},
      loadingNotifications: false,
      latestNotifications: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.markAllAsRead = this.markAllAsRead.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const token = localStorage.getItem("authToken");
    const restaurant_settings = getSessionStorage("restaurant_settings");
    this.setState({ token: token });
    this.settings = restaurant_settings;

    if (this.isPlatformWeb() === true) {
      this.getNotifications();

      if (this.state.data) {
        this.formatNotifications();
      }
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const messageHandlers = {
      [getName(MessageEnum.SessionResponseMessage)]: () => this.getNotifications(),
      [this.getDataCallId]: () => {
        const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiResponse) {
          this.setState({ data: apiResponse.data, loadingNotifications: false });
          this.formatNotifications();
          this.updateLatestNotifications();
        }
      },
      [this.markAsReadCallId]: () => this.getNotifications(),
      [this.deleteCallId]: () => {
        this.setState({ selectedData: null });
        this.getNotifications();
      },
      [this.markAllAsReadCallId]: () => this.getNotifications(),
    };

    const handler = messageHandlers[message.getData(getName(MessageEnum.RestAPIResponceDataMessage))];
    if (handler) {
      handler();
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      messageHandlers[getName(MessageEnum.SessionResponseMessage)]();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.anchorEl && !prevProps.anchorEl) {
      this.getNotifications();
    }

    if (this.state.data !== prevState.data) {
      this.updateLatestNotifications();
    }
  }

  iconBellProps = {
    source: imgBell,
  };

  updateLatestNotifications() {
    if (this.state.data) {
      const unreadNotifications = this.getLatestNotifications();

      this.setState({ latestNotifications: unreadNotifications });
    }
  }

  formatNotifications(): void {
    let notifications: {[key: string]: NotificationAttributes[]} = {};

    if (this.state.data) {
      this.state.data.forEach((notification: Notification) => {
        let date = this.convertDate(notification.attributes.created_at);

        if (notifications[date]) {
          notifications[date].push(notification.attributes);
        } else {
          notifications[date] = [notification.attributes];
        }

        notifications[date].sort((a, b) => {
          if (a.is_read !== b.is_read) {
            return a.is_read ? 1 : -1;
          }

          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime();
        });
      });
    }

    this.setState({ notifications: this.sortNotificationTimeline(notifications) });
  }

  sortNotificationTimeline(notifications: { [key: string]: NotificationAttributes[] }): { [key: string]: NotificationAttributes[] } {
    const todayNotifications: { [key: string]: NotificationAttributes[] } = notifications["Today"] ? { Today: notifications["Today"] } : {};
    const yesterdayNotifications: { [key: string]: NotificationAttributes[] } = notifications["Yesterday"] ? { Yesterday: notifications["Yesterday"] } : {};
    const sortedNotifications: { [key: string]: NotificationAttributes[] } = {};

    Object.keys(notifications)
      .filter((key) => key !== "Today" && key !== "Yesterday")
      .sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
      })
      .forEach((key) => {
      sortedNotifications[key] = notifications[key];
      });

    return {...todayNotifications, ...yesterdayNotifications, ...sortedNotifications};
  }

  getLatestNotifications(): NotificationAttributes[] {
    let latestNotifications: NotificationAttributes[] = [];

    if (this.state.data) {
      this.state.data.forEach((notification: Notification) => {
        if (notification.attributes.is_read === false) {
          latestNotifications.push(notification.attributes);
        }
      });
    }


    return latestNotifications;
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    const token = localStorage.getItem("authToken");

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    this.setState({ loadingNotifications: true, notifications: {} });
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}/mark_as_read`
    );

    const token = localStorage.getItem("authToken");

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  markAllAsRead() {
    const markAllAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAllAsReadCallId = markAllAsReadMsg.messageId;

    markAllAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/mark_all_as_read`
    );

    const token = localStorage.getItem("authToken");

    markAllAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAllAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAllAsReadMethod
    );

    runEngine.sendMessage(markAllAsReadMsg.id, markAllAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    const token = localStorage.getItem("authToken");

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
    } else {
        return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    }
  }
  // Customizable Area End
}
