import { BlockComponent } from "../../framework/src/BlockComponent";
import React, { Suspense, lazy } from "react";
import CustomLoader from "./CustomLoader.web";

const LazyLoadDealsPage = lazy(()=>import('../../blocks/catalogue/src/DealPage.web'))

interface Props {
    id: any;
    navigation:any;
    location?:any;
    locationSearch?:any;
    history:any;
  }

interface S {
  }
interface SS {
  }

export default class MenuPageLazyLoad extends BlockComponent<
Props,
S,
SS
>{
    render(){
    return(
        <Suspense fallback={<CustomLoader loading={true}/>}>
            <LazyLoadDealsPage navigation={this.props.navigation} id="LazyLoadId" location={this.props.location} locationSearch={this.props.locationSearch} history={this.props.history}    />
        </Suspense>
    )
}
}