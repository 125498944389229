import React from "react";
import moment from "moment";
import "moment-timezone";
import { Calendar, Event, EventProps, HeaderProps, momentLocalizer, ToolbarProps, View, ViewsProps } from 'react-big-calendar'
import Toolbar from "./components/Toolbar";
import { Header as MonthHeader } from "./components/Month";
import { Header as WeekHeader } from "./components/Week";
import { EventComponent } from "./components/Event";
import { getSessionStorage } from "../../utils";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  recievedView?: View;
  recievedViews?: ViewsProps<Event, object>;
  events: Event[];
  getShift: (id: number, startDate?: string, endDate?: string) => void;
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  openWeeklyNotes: (start: string, end: string) => void;
  range: {
    start?: string;
    end?: string;
  };
  setRange: React.Dispatch<React.SetStateAction<{
    start?: string;
    end?: string;
  }>>;
};

const WingosCalendar = React.memo(({ selectedStaff, selectedRole, events, getShift, recievedView, recievedViews, openShiftPopup, totalShiftHours, gaps, conflicts, openWeeklyNotes, range, setRange }: Props) => {
  const [view, setView] = React.useState<View>("month");
  const [views, setViews] = React.useState<ViewsProps<Event, object>>({ month: true });
  const shifts = React.useMemo<Event[]>(
    () =>
      events.filter(event =>
        selectedRole ? event.resource?.role === selectedRole || event.resource?.role === "all" : true
      ),
    [events, selectedRole]
  );

  React.useEffect(() => {
    if (recievedView) {
      setView(recievedView);
    }
  }, [recievedView]);

  React.useEffect(() => {
    if (recievedViews) {
      setViews(recievedViews);
    }
  }, [recievedViews]);

  const components = {
    toolbar: (props: React.PropsWithChildren<ToolbarProps<Event, object>>) => <Toolbar
      {...props}
      getShift={getShift}
      selectedStaff={selectedStaff}
      selectedRole={selectedRole}
      openAddShift={openShiftPopup}
      totalShiftHours={totalShiftHours}
      gaps={gaps}
      conflicts={conflicts}
      openWeeklyNotes={openWeeklyNotes}
      range={range}
      setRange={setRange}
    />,
    month: { header: MonthHeader },
    week: { header: (props: React.PropsWithChildren<HeaderProps>) => <WeekHeader {...props} selectedStaff={selectedStaff} gaps={gaps} conflicts={conflicts} /> },
    day: { header: MonthHeader },
    event: (props: React.PropsWithChildren<EventProps<Event>>) => <EventComponent {...props} selectedStaff={selectedStaff} selectedRole={selectedRole} activeView={view} openShiftPopup={openShiftPopup} />,
    data: { gaps, conflicts, selectedStaff, selectedRole, activeView: view, openShiftPopup },
  };

  return (
    <Calendar
      defaultView="month"
      view={view}
      views={views}
      onView={(view: View) => setView(view)}
      onShowMore={() => setView("week")}
      tooltipAccessor={null}
      events={shifts}
      localizer={localizer}
      className="calendar__view"
      components={components}
    />
  );
});

const settings = getSessionStorage("restaurant_settings");
const time_zone = settings?.time_zone;

moment.tz.setDefault(time_zone);
moment.updateLocale('en', {
  week: {
    dow: 1 // 0 represents Sunday as the start of the week,
  },
});

const localizer = momentLocalizer(moment)

export default WingosCalendar;
