import React from "react";
import { SvgIcon } from "@mui/material";
import Menu from "../../Menu";
import { CellContext } from "@tanstack/react-table";

interface Props {
  anchorEl: any | null;
  navigation: any;
  onClose: () => void;
  id: string | null;
  role: string;
  info: CellContext<any, any>;
  handlePrintReceipt?: (id:string) => void;
  handleAcceptOrder?: (id: string) => void;
  handleCancelOrder?: (id: string) => void;
  handleSkipStatus?: (id: string) => void;
  orders: any[];
}

const ActionCellMenu = ({ anchorEl, navigation, onClose, id, role, info, handlePrintReceipt, handleAcceptOrder, handleCancelOrder, handleSkipStatus, orders }: Props) => {
  const open = Boolean(anchorEl);
  const isOnlineOrderManager = role === "Online Order Manager";
  const isInStoreManager = role === "In Store Manager";
  const status = React.useMemo(() => {
    const order = orders.find((order) => String(order.id) === String(id));
    const orderStatus = order?.attributes.status;
    return orderStatus;
  }, [id]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      options={[
        {
          label: "Print Receipt",
          onClick:() => id !== null && handlePrintReceipt && handlePrintReceipt(id),
          hidden: isOnlineOrderManager || !handlePrintReceipt,
          icon: (
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path d="M18 17H6v-2h12zm0-4H6v-2h12zm0-4H6V7h12zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2z" />
              </svg>
            </SvgIcon>
          ),
        },
        {
          label: "View Details",
          onClick: () => navigation.navigate("OrderNumber", { id }),
          icon: (
            <SvgIcon>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 12q1.406 0 2.39-.984t.985-2.391q0-1.405-.984-2.391Q10.406 5.25 9 5.25t-2.391.984q-.984.986-.984 2.391 0 1.407.984 2.391t2.39.984m0-1.35q-.844 0-1.434-.591a1.95 1.95 0 0 1-.591-1.434q0-.843.59-1.435Q8.156 6.6 9 6.6q.843 0 1.434.59.59.59.59 1.435 0 .844-.59 1.434A1.95 1.95 0 0 1 9 10.65m0 3.6q-2.607 0-4.763-1.378A8.77 8.77 0 0 1 .975 9.15.6.6 0 0 1 .9 8.915a2.2 2.2 0 0 1 0-.581.6.6 0 0 1 .075-.234 8.76 8.76 0 0 1 3.262-3.721Q6.393 2.999 9 3t4.762 1.378A8.76 8.76 0 0 1 17.025 8.1q.056.094.075.234a2.2 2.2 0 0 1 0 .581.6.6 0 0 1-.075.235 8.77 8.77 0 0 1-3.263 3.722Q11.606 14.25 9 14.25m0-1.5q2.119 0 3.89-1.116a7.33 7.33 0 0 0 2.71-3.009 7.33 7.33 0 0 0-2.71-3.01A7.16 7.16 0 0 0 9 4.5q-2.12 0-3.891 1.115a7.33 7.33 0 0 0-2.71 3.01 7.33 7.33 0 0 0 2.71 3.009 7.16 7.16 0 0 0 3.89 1.116"
                  fill="#1D1D1D"
                />
              </svg>
            </SvgIcon>
          ),
        },
        {
          label: "Accept Order",
          onClick: () => id !== null && handleAcceptOrder && handleAcceptOrder(id),
          hidden: !handleAcceptOrder || status !== "placed",
          icon: (
            <SvgIcon>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.596 11.903 3.994 9.301a.747.747 0 1 0-1.058 1.057l3.135 3.135a.747.747 0 0 0 1.058 0l7.935-7.935a.747.747 0 1 0-1.058-1.057z"
                  fill="#1D1D1D"
                />
              </svg>
            </SvgIcon>
          ),
        },
        {
          label: "Cancel Order",
          onClick: () => id !== null && handleCancelOrder && handleCancelOrder(id),
          hidden: !isInStoreManager || !handleCancelOrder,
          icon: (
            <SvgIcon>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="m11.093 2.468.532.532H13.5c.412 0 .75.337.75.75 0 .412-.338.75-.75.75h-9a.75.75 0 0 1-.75-.75c0-.413.338-.75.75-.75h1.875l.532-.533a.76.76 0 0 1 .526-.217h3.135c.194 0 .39.083.524.217M4.5 14.25c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-7.5c0-.825-.675-1.5-1.5-1.5H6c-.825 0-1.5.675-1.5 1.5zm2.25-7.5h4.5c.412 0 .75.338.75.75v6c0 .412-.338.75-.75.75h-4.5A.75.75 0 0 1 6 13.5v-6c0-.412.338-.75.75-.75"
                  fill="#1D1D1D"
                />
              </svg>
            </SvgIcon>
          ),
        },
        {
          label: "Skip Status",
          onClick: () => id !== null && handleSkipStatus && handleSkipStatus(id),
          hidden: !handleSkipStatus || isOnlineOrderManager,
          icon: (
            <SvgIcon>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 12.75v-7.5c0-.412.338-.75.75-.75s.75.338.75.75v7.5c0 .412-.338.75-.75.75a.75.75 0 0 1-.75-.75m-1.988-3.135-4.327 3.053a.754.754 0 0 1-1.185-.615V5.947c0-.607.683-.96 1.185-.615l4.328 3.06a.75.75 0 0 1 0 1.223"
                  fill="#1D1D1D"
                />
              </svg>
            </SvgIcon>
          ),
        },
      ]}
    />
  );
};

export default ActionCellMenu;
