import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OrderStatistics } from "../../dashboard/src/dashboards/types";
import { debounce } from "lodash";
import { SvgIcon } from "@mui/material";
import { checkPercentageStatus } from "../../../components/src/utils";
export const baseURL = require("../../../framework/src/config.js").baseURL;


// Customizable Area Start

export interface TopPerformingProductData{
  title: string;
  total_quantity:number|null
  id:string|number|null
}

export interface TopPerformingCustomerData{
  first_name: string;
  last_name:string;
  total_quantity:number|null
  id:string|number|null
}

export interface TopPerformingPaymentData{
  source: string;
  total_quantity:number|null
  id:string|number|null
}

export interface OrderData {
  total_orders_today: number;
  today_report: Record<string, number>;
  total_orders_week: number;
  week_array: Record<string, number>;
  total_orders_month: number;
  month_array: Record<string, number>;
  total_orders_year: number;
  year_array: Record<string, number>;
  today	:string;
  today_message: string;
    week_message: string;
    month_message: string;
    year_message: string
}

export interface EarningReport {
  today_report: Record<string, number>;
  total_orders_today: string;
  total_orders_week: string;
  week_array: Record<string, number>;
  total_orders_month: string;
  month_array: Record<string, string | number>;
  total_orders_year: string;
  year_array: Record<string, string>;
  today_message: string;
    week_message: string;
    month_message: string;
    year_message: string
}

export interface OrderPieChartData {
  today_report: Record<string, number>;
  total_orders_today: number;
  week_array: Record<string, number>;
  total_orders_week: number;
  month_array: Record<string, number>;
  total_orders_month: number;
  year_array: Record<string, number>;
  total_orders_year: number;
  today_message: string;
  week_message: string;
  month_message: string;
  year_message: string;
}

export interface StatisticsGraphData {
  order_data: OrderData;
  earning_report: EarningReport;
  top_5_selling_product:TopPerformingProductData[];
  top_5_customer: TopPerformingCustomerData[];
  top_5_payments: TopPerformingPaymentData[];
}

export interface CustomerGrowthData {
  week_array: Record<string, number>;
  week_message: string;
  change_percentage: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  selectedDate1: Date | null;
  paymentData: any;
  paymentDataError: any;
  googleChartData: any;
  reportStatsOption: number;
  orderStatistics: StatisticsGraphData;
  activeTabOrder: number;
  activeTabEarning: number;
  selectedTab: number;
  riderReportPopup:boolean;
  searchQuery:string;
  orderStatisticsPie:OrderPieChartData;
  customerGrowthGraph:CustomerGrowthData;
  disaleRiderReportExport:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  apiGetDataCallId1: string = "";
  apiGetDataCallId2: string = "";
  visualAnalyticsStatisticsApiCallId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      selectedDate1: new Date(),
      paymentData: {},
      paymentDataError: {},
      selectedTab: 0,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      reportStatsOption: 0,
      orderStatistics: {
        order_data: {
          total_orders_today: 0,
          today_report: {},
          total_orders_week: 0,
          week_array: {},
          total_orders_month: 0,
          month_array: {},
          total_orders_year: 0,
          year_array: {},
          today: "",
          today_message: "",
          week_message: "",
          month_message: "",
          year_message: ""
        },
        earning_report: {
          today_report: {},
          total_orders_today: "",
          total_orders_week: "",
          week_array: {},
          total_orders_month: "",
          month_array: {},
          total_orders_year: "",
          year_array: {},
          today_message: "",
          week_message: "",
          month_message: "",
          year_message: ""
        },
        top_5_customer:[],
        top_5_selling_product:[],
        top_5_payments:[],
      },
      activeTabOrder: 3,
      activeTabEarning: 3,
      riderReportPopup:false,
      searchQuery:"",
      orderStatisticsPie:{
        "today_report": {
       
      },
      "total_orders_today": 0,
      "week_array": {
         
      },
      "total_orders_week": 0,
      "month_array": {
          
      },
      "total_orders_month":0,
      "year_array": {
         
      },
      "total_orders_year": 0,
      "today_message": "failed",
      "week_message": "failed",
      "month_message": "failed",
      "year_message": "failed"
      },
    customerGrowthGraph:{
      week_array: {},
      week_message: "failed",
      change_percentage: "00.00"
    },
    disaleRiderReportExport:false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.visualAnalyticsStatisticsApiCallId = ""
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.dayRavenue();
    } if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.visualAnalyticsStatisticsApiCallId) {
        this.setOrderstatisticsData(responseJson)
      } if (apiRequestCallId === this.apiGetDataCallId1) {
        
        this.setState({ paymentData: responseJson.data })
        this.setState({ paymentDataError: responseJson.data})
      } else if (apiRequestCallId === this.apiGetDataCallId2) {
        const token = localStorage.getItem("authToken") || "";
        let NewResponse = `${baseURL}/bx_block_salesreporting/salesreportings?date=24/02/2025&export=true&token=${token}`
        const a = document.createElement("a");
        a.href = NewResponse;
        a.download = "schedule_February_2025.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getAnalyticsStatsticData();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=>{
    if(prevState.selectedDate1 !== this.state.selectedDate1){
        this.getAnalyticsStatsticData()
    }
  }

  onChangeDate = (date: Date | null) => {
    if (date) {
      this.setState({ selectedDate1: date }, () => {
        this.dayRavenue1()
      });
    }
  }

  handleTabChange = (_event: React.SyntheticEvent, value: any) => {
    this.setState({ selectedTab: value }, () => {
      if (this.state.selectedTab === 1) {
        this.dayRavenue1()
      }
    });
  };

  dayRavenue1 = async () => {
    const token = localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId1 = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_salesreporting/salesreportings?date=${this.state.selectedDate1}&export=false`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      requestMessage,
      this.apiGetDataCallId1
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  ExoprtData = async () => {
    const token = localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId2 = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_salesreporting/salesreportings?date=${this.state.selectedDate1}&export=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId2
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleReportStatsOptionChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ reportStatsOption: newValue })
  }

  getAnalyticsStatsticData = () => {
    let token = localStorage.getItem('authToken')
    const formattedDate = this.state.selectedDate1 ?  this.state.selectedDate1.toLocaleDateString("en-GB") : ""

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.visualAnalyticsStatisticsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.visualAnalyticsStatisticsApiEndPoint}?date=${formattedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setOrderstatisticsData = (responseJson: { order_data: OrderData, earning_report: EarningReport,top_5_selling_product:TopPerformingProductData[], top_5_customer:TopPerformingCustomerData[], top_5_payments:TopPerformingPaymentData[],pie_data:OrderPieChartData,customer_growth_data:CustomerGrowthData }) => {
    if (responseJson.order_data && responseJson.earning_report) {
      this.setState({ orderStatistics: responseJson, orderStatisticsPie:responseJson.pie_data,customerGrowthGraph:responseJson.customer_growth_data })
    }
  }

  onChangeActiveTabOrders = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTabOrder: newValue })
  }
  onChangeActiveTabEarnings = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTabEarning: newValue })
  }

  getOrderCount = () => {
    const { order_data } = this.state.orderStatistics
    switch (this.state.activeTabOrder) {
      case 3:
        return order_data.total_orders_today;
      case 2:
        return order_data.total_orders_week;
      case 1:
        return order_data.total_orders_month;
      case 0:
        return order_data.total_orders_year;
      default:
        return 0;
    }
  }

  getEarningTotal = () => {
    const { earning_report } = this.state.orderStatistics
    switch (this.state.activeTabEarning) {
      case 3:
        return earning_report.total_orders_today;
      case 2:
        return earning_report.total_orders_week;
      case 1:
        return earning_report.total_orders_month;
      case 0:
        return earning_report.total_orders_year;
      default:
        return "0.00";
    }
  }
  openRiderReportPopup=()=>{
    this.setState({riderReportPopup:true})
  }
  closeRiderReportPopup=()=>{
      this.setState({riderReportPopup:false})
  }
  handleSearchQuery=debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  }, 300);

  prepareOrderData = (order_data: OrderPieChartData, activeTab: number) => {
    const dataMap: Record<number, Record<string, number>> = {
      3: order_data.today_report,
      2: order_data.week_array,
      1: order_data.month_array,
      0: order_data.year_array,
    };
  
    return Object.entries(dataMap[activeTab] || {}).map(([key, value]) => ({
      name: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
      value,
    }));
  };

  returnTotalOrder = (order_data: OrderPieChartData, activeTab: number) => {
    const dataMap: Record<number, number> = {
      3: order_data.total_orders_today,
      2: order_data.total_orders_week,
      1: order_data.total_orders_month,
      0: order_data.total_orders_year,
    };
    return dataMap[activeTab] || 0
  };

  orderDate=(today:string) =>{return  new Date(today)};
  todayDate = new Date();

  reurnDataSucces = (order_data:OrderData,activeTab: number) => {

    switch (activeTab) {
      case 3:
        return order_data.today_message;
      case 2:
        return order_data.week_message;
      case 1:
        return order_data.month_message;
      case 0:
        return order_data.year_message;
      default:
        return "No data found";
    }
  };

  reurnEarningDataSucces = (order_data:EarningReport,activeTab: number) => {

    switch (activeTab) {
      case 3:
        return order_data.today_message;
      case 2:
        return order_data.week_message;
      case 1:
        return order_data.month_message;
      case 0:
        return order_data.year_message;
      default:
        return "No data found";
    }
  };

  disbleExportRiderReport=(value:boolean)=>{
      this.setState({disaleRiderReportExport:value})
  }
  // Customizable Area End
}
