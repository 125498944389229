import { z } from "zod";
import { isValidPhoneNumber } from "react-phone-number-input";

export const ContactUsSchema = z.object({
  firstName: z
    .string()
    .nonempty("First name is required")
    .regex(/^[a-zA-Z\s]*$/, "This field should not contain special characters or numbers")
    .max(15, "First name should contain a maximum of 15 characters"),

  lastName: z
    .string()
    .nonempty("Last name is required")
    .regex(/^[a-zA-Z\s]*$/, "This field should not contain special characters or numbers")
    .max(15, "Last name should contain a maximum of 15 characters"),

  phoneNumber: z
    .string()
    .nonempty("Phone number is required")
    .refine(value => isValidPhoneNumber("+" + value), {
      message: "The mobile number you entered is not in a valid format. Please enter a valid mobile number",
    }),

  email: z
    .string()
    .email("The email address you entered is not in a valid format. Please enter a valid email address"),

  subject: z
    .string()
    .nonempty("Subject is required!")
    .max(30, "Subject should contain a maximum of 30 characters"),

  message: z
    .string()
    .nonempty("This field is required and can’t be left empty")
    .max(100, "Message should contain a maximum of 100 characters"),
});
