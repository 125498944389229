import React from "react";
import { ArrowBackRounded, CloseRounded, ErrorOutline } from "@material-ui/icons";
import { FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Pagination, Popover, SvgIcon } from "@mui/material";
import ViewCustomerController, { Props } from "./ViewCustomerController.web";
import DashboardWrapper from "../../DashboardWrapper";
import { ContainedButton, OutlinedButton, UnStyledButton } from "../../../Button";
import ViewCustomerHeader from "../../ViewCustomer/ViewCustomerHeader";
import ConfirmationPopup from "../../ConfirmationPopup";
import "./ViewCustomer.web.css";
import { imagePound, voucherIcon } from "../../../../../blocks/shoppingcart/src/assets";
import DataGrid from "../../DataGrid";
import { CellContext } from "@tanstack/react-table";
import { CustomerOrdersColumns } from "../../DataGrid/columns/CustomerOrdersColumns";
import Modal from "../../../Modal";
import CustomGreenCheckbox from "../../../CustomGreenCheckBox.web";
import AddOrUpdateCustomer from "../../AddOrUpdateCustomer";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import InfoSuccess from "../../CustomerMarketingInfo/InfoSuccess";
import CustomLoader from "../../../CustomLoader.web";



class ViewCustomer extends ViewCustomerController {
  constructor(props: Props) {
    super(props);

  }

  customerId = ''
  async componentDidMount(): Promise<void> {
    await super.componentDidMount(); // Ensure the parent implementation is called
    const customerId = this.props.navigation?.getParam("customerId");
    this.customerId = customerId;
    if (customerId) {
      this.controller.fetchCustomerDetails(customerId); // Use `fetchCustomerDetails` here
    }
  }

  convertDate(dateString: string) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const [year, month, day] = dateString.split("-");
    return `${year}-${months[parseInt(month, 10) - 1]}-${day}`;
  }

  showNearExpiryAlert = () => {
    const open = Boolean(this.state.expiryPopupAnchorEl);

    return (
      <div data-testid="expiry-alert">
        <IconButton data-testid="expiry-alert-button" size="small" color="error" onClick={this.openExpiryPopup}>
          <ErrorOutline />
        </IconButton>
        <Popover
          open={open}
          anchorEl={this.state.expiryPopupAnchorEl}
          onClose={this.closeExpiryPopup}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          classes={{
            paper: "view_customer__expiry_popup__popover"
          }}
        >
          <div className="view_customer__expiry_popup">
            <div className="view_customer__expiry_popup__header">
              <span className="view_customer__expiry_popup__title">Visa Renewal</span>
              <IconButton size="small" onClick={this.closeExpiryPopup}>
                <CloseRounded fontSize="small" />
              </IconButton>
            </div>
            <span className="view_customer__expiry_popup__message">
              Visa expiration is approaching. Renew to avoid complications.
            </span>
          </div>
        </Popover>
      </div>
    );
  };


  wingoRewardSection = () => {
    const { wingo_total_reward_points = 1520, point_conversion = {}, gift_card_balance = "£100" } = this.state.data || {};

    return (
      <div className="rewardPointsContainer" style={{height:"100%"}}>
        <div className="rewardBox" style={{width:"100%"}}>
        <div className="rewardBox">
          <div className="rewardIcon">
            <img style={{width:"60px"}} src={imagePound} alt="reward_icon" />
          </div>
          <div className="rewardPointsContainer" style={{gap:"4px", alignItems:"start",height:"94px"}}>
            <div className="giftCardLabel">{"Wingo’s Reward Points"}</div>
            <div className="rewardPoints" style={{color:"#8BB7F0", fontSize:"36px"}}>{wingo_total_reward_points}</div>
            <div className="rewardWorth" style={{color:"#2C6F37", fontSize:"14px"}}>Points Worth <span style={{fontWeight:700}}>- £ {point_conversion?.points_worth}</span></div>
          </div>
        </div>
        </div>
        <div className="rewardBox" style={{width:"100%"}}>
        <div className="rewardBox">
          <div className="giftCardIcon">
            <img src={voucherIcon} alt="gift_card_icon" />
          </div>
          <div className="rewardPointsContainer" style={{gap:"4px",alignItems:"start",height:"94px"}}>
            <div className="giftCardLabel">e-gift Card Balance</div>
            <div className="giftCardAmount" style={{color:"#059669",fontSize:"36px"}}>£{this.state.data.gift_card_balance}</div>
          </div>
        </div>
</div>
      </div>
    );
  };

  renderOrderGridHeader = () => {
    return (
      <div className="orderGridHeader">
        <div className="orderTitle">Orders <div style={{width:"99px",height:"32px",background:"linear-gradient(99.09deg, #F6B8BB 2.64%, #E11B23 100%)",borderRadius: "8px",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"}} className="orderSubTitle">Past 30 Days</div></div>
        <div className="filterActions">
          <input
            type="text"
            placeholder="Search by Order Number"
            className="searchInput"
            onChange={this.handleSearch}

          />
          <button className="filterButton" onClick={this.handleFiltersOpen}> <FilterListRoundedIcon style={{marginLeft:"6px"}}/> Filters</button>

        </div>
      </div>
    );
  };

  actionCell = (info: CellContext<any, any>) => {
    return (
      <div>
        <IconButton onClick={() => { }}>

          <SvgIcon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 7.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5m0 9a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5m0-4.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5"
                fill="inherit"
              />
            </svg>
          </SvgIcon>
        </IconButton>

      </div>
    );
  };

  render() {
    const { navigation } = this.props;
    const customer = this.state.data
    
    const { attributes } = customer
    
    const customerInfo = [
      { label: "First Name", value: this.state.data.attributes?.first_name },
      { label: "Last Name", value: this.state.data.attributes?.last_name},
      { label: "Email", value: this.state.data.attributes?.email },
      { label: "Mobile Number", value: `${this.state.data.attributes?.full_phone_number && "+"}${this.state.data.attributes?.full_phone_number}`	 },
      { label: "DOB", value: this.state.data.attributes?.date_of_birth	 },
      { label: "Gender", value: this.state.data.attributes?.gender },
      { label: "Address", value: this.state.addresses?.length>0 ? this.state.addresses[0].attributes.address : ""},
    ];
    
    const columns = CustomerOrdersColumns({ actionCell: this.actionCell });


    return (
      <div style={{fontFamily:"Barmeno"}}>
        <CustomLoader loading={this.state.loading}/>
        <DashboardWrapper navigation={this.props.navigation}>
          <div
            data-testid="view-customer"
            className="view_customer__wrapper"
          >
            <div
              data-testid="header"
              className="view_customer__header"
            >
              <UnStyledButton
                data-testid="back-button"
                startIcon={<ArrowBackRounded />}
                onClick={navigation.goBack}
              >
                Back
              </UnStyledButton>

              <div className="view_customer__info">
                <div className="view_customer__info__title">
                  Customer ID {attributes?.customer_id} | {attributes?.first_name?.concat(' ', attributes?.last_name)}

                </div>
              </div>
            </div>

            <div className="view_customer__section__wrapper" style={{backgroundColor:"transparent"}}>
              <section className="view_customer__content"  style={{backgroundColor:"#fff", padding:"24px 16px"}}>
                <div className="view_customer__personal_info">
                  <ViewCustomerHeader
                    showEditButton
                    icon={
                      <SvgIcon>
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16 8c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4m-2 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2m-2 5c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4m-6 4.01V18h12v-1c-.2-.71-3.3-2-6-2s-5.8 1.29-6 2.01"
                            fill="#1D1D1D"
                          />
                        </svg>
                      </SvgIcon>
                    }
                    title="Customer Information"
                    editAction={()=>{this.handleEditAction(customer)}}

                  />

                  <div className="view_customer__info__wrapper">
                    <div className="view_customer__info__general_info">

                      {customerInfo.map((info) => (
                        <div
                          key={info.label}
                          className={`view_customer__info__general_info__row`}
                        >
                          <span className="view_customer__info__general_info__row__label">{info.label}:</span>
                          <div className="view_customer__info__general_info__row__value">

                            <>{info.value}</>

                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>


              </section>

              <section className="view_customer__activity_log" style={{height:"100%",backgroundColor:"transparent"}}>
                {this.wingoRewardSection()}

              </section>



              <ConfirmationPopup
                open={this.state.openConfirmation}
                onClose={this.discardChanges}
                goBack={this.handleConfirmationClose}
              />

            </div>
            <section className="dashboard__datagrid" style={{padding:"24px 16px", background:"white"}}>
              {this.renderOrderGridHeader()}
              <DataGrid
                data={this.state.filteredOrders.length ? this.state.filteredOrders : []}
                tableOptions={{
                  getRowId: (row: any) => row?.id,
                }}
                columns={columns}
                emptyRowsText="No order history available for this customer."
              />

              <Pagination
                hidePrevButton={this.state.orders_pagination.prev_page === null}
                hideNextButton={this.state.orders_pagination.next_page === null}
                count={Math.ceil(this.state.orders_pagination.total_count/5)}
                page={Number(this.state.page)}
                onChange={this.handlePageChange}
              />

            </section>
          </div>
          <Modal
            maxWidth="sm"
            open={this.state.openFilters}
            onClose={this.handleFiltersClose}
            style={{boxSizing:"border-box"}}
          >
            <div className="filterModal">
              <div className="filterModal__header">
                <span className="filterModal__title">Filters</span>
                <IconButton
                  data-testid="close-filters-button"
                  onClick={this.handleFiltersClose}
                >
                  <CloseRounded />
                </IconButton>
              </div>

              <div className="filterModal__filterTabs">
                {this.activeInActiveFilter.map((activeInActive,index)=>(
                  <button key={index}
                  className={`filterTab ${this.state.activeFilterTab === activeInActive.value ? 'active' : ''
                    }`}
                  onClick={() => this.handleOrderStatus(activeInActive.value )}
                >
                 {activeInActive.label}
                </button>
                ))}
                <div className="filterTab__dateRange">
                </div>

              </div>


              <div className="filterModal__body">

                <FormControl component="fieldset" className="filterGroup">
                  <FormLabel component="legend" className="filterGroup__title">
                    Order Type
                  </FormLabel>
                  <FormGroup className="filterGroup__options">
                    {this.orderTypeFilter.map((orderType,index)=>(
                      <FormControlLabel
                      key={index}
                      control={<CustomGreenCheckbox checked={this.state.orderTypeFilters[orderType.value]}
                        onChange={() => this.handleFilterChange("orderTypeFilters", orderType.value)} />}
                      label={orderType.label}
                    />
                    ))}
                  </FormGroup>
                </FormControl>


                <FormControl component="fieldset" className="filterGroup">
                  <FormLabel component="legend" className="filterGroup__title">
                    Payment Type
                  </FormLabel>
                  <FormGroup className="filterGroup__options">
                    {this.paymentFilterStatus.map((source, index) => (
                      <FormControlLabel
                        key={index}
                        control={<CustomGreenCheckbox checked={this.state.paymentTypeFilters[source.value]}
                          onChange={() => this.handleFilterChange("paymentTypeFilters", source.value)} />}
                        label={source.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>


                <FormControl component="fieldset" className="filterGroup">
                  <FormLabel component="legend" className="filterGroup__title">
                    Order Statuses
                  </FormLabel>
                  <FormGroup className="filterGroup__options">
                    {(this.state.activeFilterTab === 'all' || this.state.activeFilterTab === 'active') &&
                      <>
                        {this.activeOrderStatus.map((orderStatus, index) => (
                          <FormControlLabel
                            key={index}
                            control={<CustomGreenCheckbox checked={this.state.orderStatusFilters[orderStatus.value]}
                              onChange={() => this.handleFilterChange("orderStatusFilters", orderStatus.value)} />}
                            label={orderStatus.label}
                          />
                        ))}
                      </>
                    }
                    {(this.state.activeFilterTab === 'all' || this.state.activeFilterTab === 'past') &&
                      <>
                        {
                          this.pastOrderStatus.map((orderStatus, index) => (
                            <FormControlLabel
                              key={index}
                              control={<CustomGreenCheckbox checked={this.state.orderStatusFilters[orderStatus.value]}
                                onChange={() => this.handleFilterChange("orderStatusFilters", orderStatus.value)} />}
                              label={orderStatus.label}
                            />
                          ))
                        }
                      </>
                    }

                  </FormGroup>
                </FormControl>
              </div>


              <div className="filterModal__footer">
                <OutlinedButton style={{width:"200px"}} onClick={() => this.handleFiltersReset()}>
                  Clear Filters
                </OutlinedButton>
                <ContainedButton style={{width:"200px"}} onClick={() => this.handleFiltersApply()}>
                  Apply Filters
                </ContainedButton>
              </div>
            </div>
          </Modal>



        </DashboardWrapper>
        <InfoSuccess
              open={this.state.successPopupOpen}
              onClose={this.handleSuccessPopupClose}
              successMsg={this.state.successText}
            />
        {this.state.addModalOpen && <div
          data-testid="staff-info"
          className="staff_info__wrapper"
        >
          <AddOrUpdateCustomer
            open={this.state.addModalOpen}
            onClose={this.closeEditModal}
            onSubmit={this.addOrUpdateCustomer}
            initialCustomerData={ this.state.newCustomer}
            isEditing={this.state.isEditing}
            validationErrors={this.state.errors}
          />
        </div>}
      </div>
    );
  }
}

export default ViewCustomer;
