import React from "react";
import Sidebar from "../../../components/src/Dashboard/Sidebar/Sidebar";
import Header from "../../../components/src/Dashboard/Header/Header";
import Rolesandpermissions2 from "../../rolesandpermissions2/src/Rolesandpermissions2.web";
import PosOrderManagementController, {
  DriverListDataType,
  OrderManagementDataType,
  Props,
} from "./PosOrderManagementController";
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, InputAdornment, Pagination, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import CustomLoader from "../../../components/src/CustomLoader.web";
import SearchIcon from '@material-ui/icons/Search';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded"
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { cashOffImage, deliverySwapImage, deliverySwapImageDisabled, refundButtonDisabledIcon, refundButtonIcon } from "./assets";
import FilterPopupOrderManagement from "../../../components/src/OrderManagementFilter";
import Modal from "../../../components/src/Modal";
import { ContainedButton, OutlinedButton } from "../../../components/src/Button";
import ErrorPopup from "../../../components/src/ErrorPopup";
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import CustomGreenCheckbox from "../../../components/src/CustomGreenCheckBox.web";

export default class PosOrderManagement extends PosOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tableHeader = () => {
    return (
      <Grid container style={{ textAlign: 'center', borderBottom: "1px solid #909090" }}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <div className="orderManagementTableCell">Order Number</div>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">Order Type</div>
        </Grid>
        {this.state.value === 4 ?
                <Grid container item lg={3} md={3} sm={3} xs={3}>
                <Grid item lg={4} md={4} sm={4} xs={4}> <div className="orderManagementTableCell">Refund amount</div></Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}> <div className="orderManagementTableCell">Payment</div></Grid>
              </Grid>:
              <Grid item lg={3} md={3} sm={3} xs={3}>
               <div className="orderManagementTableCell">Name</div>
            </Grid>
                }
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">Order Status</div>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">Details</div>
        </Grid>
      </Grid>
    )
  }

  driverTableHeader = () => {
    return (
      <Grid container style={{ textAlign: 'center', borderBottom: "1px solid #909090" }}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className="orderManagementTableCell">Rider Name</div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <div className="orderManagementTableCell">Number of Orders</div>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">Details</div>
        </Grid>
      </Grid>
    )
  }

  ChildDriverData = (data: DriverListDataType[]) => {
    return (
      <Box>
        <Grid container style={{ textAlign: 'center' }}>
          {data.map((item: DriverListDataType) => (
            <Box style={{ width: "100%" }}>
              <Grid container item lg={12} style={{ height: '48px', alignItems: 'center', borderBottom: "1px solid #D4D4D4" }}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div>{item.name}</div>
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "center" }} item lg={6} md={6} sm={6} xs={6}>
                  <div> {item.assigned_orders_count}</div>
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "center" }} item lg={2} md={2} sm={2} xs={2}>
                  <IconButton onClick={() => this.GetRiderDetails(item)}><VisibilityOutlinedIcon /></IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: { width: "65%", height: '95%', maxWidth: "none", overflow: 'hidden', borderRadius: '25px' },
          }}
          open={this.state.OpenDriverRequest1}
          onClose={() => this.handleCloseDriverRequest1()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box style={{ display: "flex", justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 600, fontFamily: "Barmeno", display: 'flex' }}>Cameron Williamson <Box style={{ marginLeft: '10px',
                 backgroundColor:'#D1FAE5',
                 width:'30px', 
                 height:'30px', 
                 textAlign:'center',
                 borderRadius:'20px',
                 display:'flex',
                 alignItems: 'flex-end',
                 justifyContent: 'center'  }}>{this.state.GetDriverDetailsData.orders?.length}</Box></Typography>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.handleCloseDriverRequest1()} />
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ overflowY: 'auto', padding: '8px 0px' }}>
            <Box>
              <Box style={{
                width: '100%',
                height: '60px',
                backgroundColor: 'rgb(241 241 241)',
                borderRadius: '8px',
                borderBottom: '1px solid #909090',
              }}>
                <Grid container spacing={2} style={{ padding: '10px 20px' }}>
                  <Grid item lg={3} md={4} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Order Number</Typography>
                  </Grid>
                  <Grid item lg={2} md={4} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Customer Name</Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Address</Typography>
                  </Grid>
                  <Grid style={{ textAlign: "center" }} item lg={2} md={1} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Item Qty</Typography>
                  </Grid>
                  <Grid item lg={2} md={1} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Status</Typography>
                  </Grid>
                  <Grid item lg={1} md={1} sm={3} xs={3}>
                    <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>EDT</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box style={{ padding: '15px 0px', width: '100%' }}>
                {this.state.GetDriverDetailsData.orders?.map((item: any) => (
                  <>
                    <Grid style={{ padding: "15px 15px" }} container spacing={2}>
                      <Grid item lg={3} md={4} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.order_id}</Typography>
                      </Grid>
                      <Grid item lg={2} md={4} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.name}</Typography>
                      </Grid>
                      <Grid item lg={2} md={2} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.address.address}</Typography>
                      </Grid>
                      <Grid style={{ textAlign: "center" }} item lg={2} md={1} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.itm_qty}</Typography>
                      </Grid>
                      <Grid item lg={2} md={1} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.status}</Typography>
                      </Grid>
                      <Grid item lg={1} md={1} sm={3} xs={3}>
                        <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>EDT</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  catalogueHeaderMenuDealsFilter = () => {
    return (
      <IconButton onClick={this.openFilter} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "44px", height: "44px", borderRadius: "8px", backgroundColor: "#FAF4F4" }}><FilterListRoundedIcon /></IconButton>
    )
  }

  handleBackgroundColor = (item: any): string => {
    if (this.state.selectedOrder?.id === item?.id) return "#EAEAEA";
    else if (item.cashed_off) return "rgb(209, 250, 229)";

    return "transparent";
  };

  Child = (data: any) => {
    return (
      data.orders && data.orders.length>0?
      <Box>
        <Grid container style={{ textAlign: 'center' }}>
          {data.orders && data.orders.map((item: any) => (
            <Box style={{ width: "100%", backgroundColor: this.handleBackgroundColor(item), cursor: "pointer" }} onClick={() => this.selectOrder(item)}>
              <Grid container item lg={12} style={{ height: '48px', alignItems: 'center' }}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                    {this.state.value === 4 && <CustomGreenCheckbox disabled={this.refundDisabled(item.status,item.payment_source)} onChange={(event)=>{event.stopPropagation();this.changeRefundOrder(item)}} checked={this.state.orderIdForRefund.includes(item)} />}
                     {item.order_number}</div>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <div><img src={this.getOrderTypeIcon(item.order_type)} /></div>
                </Grid>
                {this.state.value === 4 ?
                <Grid container item lg={3} md={3} sm={3} xs={3}>
                <Grid item lg={4} md={4} sm={4} xs={4}>£{item.refund_amount}</Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>{item.payment_source && item.payment_source.replace(/_/g, " ").replace(/\b\w/g, (char:any) => char.toUpperCase())	}</Grid>
              </Grid>:
              <Grid item lg={3} md={3} sm={3} xs={3}>
              <div>{item.user_name}</div>
            </Grid>
                }
                <Grid item lg={2} md={2} sm={2} xs={2} className="statusValueChipContainer">
                  {this.getOnValueOfStatus(item.status)}
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <div>
                    <IconButton accessKey={item.id} className="viewOrderIconButtonDetailedView" onClick={this.navigateToNewPage}><VisibilityOutlinedIcon />
                    </IconButton>
                   {this.state.value === 4 ? <IconButton onClick={(event)=>{this.cancelIndividualOrder(event,item.id,item.refund_amount)}}  disabled={this.refundDisabled(item.status,item.payment_source)}>
                      <img src={this.refundDisabled(item.status,item.payment_source)?refundButtonDisabledIcon:refundButtonIcon} />
                    </IconButton>:
                    <IconButton onClick={() => this.handleOpenDriverRequest(item.id)} disabled={item.order_type ==="collection"} >
                      <img src={item.order_type ==="delivery"? deliverySwapImage : deliverySwapImageDisabled} />
                    </IconButton>
                    }
                    <Dialog
                        fullWidth
                        maxWidth="lg"
                        PaperProps={{
                          style: { width: "65%", height: '95%', maxWidth: "none", overflow: 'hidden', borderRadius: '25px' },
                        }}
                        open={this.state.OpenDriverRequest}
                        onClose={() => this.handleCloseDriverRequest()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Box style={{ display: "flex", justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Available Delivery Riders</Typography>
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.handleCloseDriverRequest()} />
                          </Box>
                        </DialogTitle>
                        <Divider />
                        <DialogContent style={{ overflowY: 'auto', padding: '8px 0px' }}>
                          <Box>
                            <Box style={{
                              width: '100%',
                              height: '60px',
                              backgroundColor: 'rgb(241 241 241)',
                              borderRadius: '8px',
                              borderBottom: '1px solid #909090'
                            }}>
                              <Grid container spacing={2} style={{ padding: '10px 20px' }}>
                                <Grid item lg={4} md={4} sm={3} xs={3}>
                                  <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Rider ID</Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={3} xs={3}>
                                  <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Name</Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={3} xs={3}>
                                  <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Last Order</Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={3} xs={3}>
                                  <Typography style={{ fontWeight: 600, fontFamily: "Barmeno" }}>Action</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider />
                            <Box style={{ padding: '15px 0px', width: '100%' }}>
                              {this.state.driverDataToShow1?.available_drivers?.map((item: any) => (
                                <>
                                  <Grid container spacing={2} style={{ padding: '0px 20px' }}>
                                    <Grid item lg={4} md={4} sm={3} xs={3}>
                                      <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.rider_id}</Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={3} xs={3}>
                                      <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.name}</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={3} xs={3}>
                                      <Typography style={{ fontWeight: 500, fontFamily: "Barmeno" }}>{item.last_order}</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={3} xs={3}>
                                      <Typography onClick={() => this.NewDriverAssign(item)} style={{ cursor: 'pointer', fontWeight: 600, color: item.can_assign ? "#E11B22" : "#A2A2A2" }}>Assign Rider</Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              ))}
                            </Box>
                            <Divider />
                          </Box>
                        </DialogContent>
                        <DialogActions>

                        </DialogActions>
                      </Dialog>
                  </div>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          ))}
        </Grid>
        <Box style={{ float: "right", marginTop: '25px' }}>
          <Pagination
            count={data.total_pages}
            page={data.current_page}
            onChange={this.handlePageChange} />
        </Box>
      </Box>:<div style={{display:"flex",flexDirection:"row",fontWeight:700,fontSize:"24px",color:"#747474",justifyContent:"center",padding:"24px 10px"}}>No information to be shown</div>
    );
  };

  renderChild = (data: OrderManagementDataType) => {
    switch (this.state.value) {
      case 0:
        return this.Child(data.in_store_orders);
      case 1:
        return this.Child(data.on_road_orders);
      case 2:
        return this.Child(data.completed_orders);
      case 3:
        return this.Child(data.future_orders);
      case 4:
        return this.Child(data.cancelled_orders);
      default:
        return null;
    }
  };

  catalogueHeaderMenuDealsSearchBar = () => {
    return (
      <TextField
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search by Order Number"
        className="searchField"
        style={{ height: '44px', maxWidth: "230px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          endAdornment: (
            this.state.searchParameterOnUpdate &&
            <InputAdornment
              onClick={this.handleSearchClear}
              style={{ cursor: "pointer" }} position="end">
              <ClearRoundedIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          className: 'posOrderNumberSearchInput'
        }}
        onChange={this.handleSearchChange}
        value={this.state.searchParameterOnUpdate}
      />
    )
  }

  searchDriver = () => {
    return (
      <TextField
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search by name"
        className="searchField"
        style={{ height: '44px', maxWidth: "230px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          endAdornment: (
            this.state.driverPreSearchQuery &&
            <InputAdornment
              onClick={this.handleSearchDriverClear}
              style={{ cursor: "pointer" }} position="end">
              <ClearRoundedIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          className: 'posOrderNumberSearchInput'
        }}
        onChange={this.handleDriverSearch}
        value={this.state.driverPreSearchQuery}
      />
    )
  }

  searchStaff = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
        onClick={this.handleFocus}
      >
        <SearchIcon fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search here"
          value={this.state.searchPreupdateValue}
          onChange={this.handleSearchQuery}
          data-test-id={"searchInput"}
        />
      </div>
    );
  };

  orderDropDown = () => {
    const { isActive } = this.state
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
        <div>Wingo’s Orders</div>
        <div className="custom-select" style={{ width: "160px", fontSize: "14px" }}>
          <div tabIndex={0} onBlur={this.toggleDropdown} className={`${isActive ? 'select-active' : ''}`}>
            <div style={{ color: this.state.orderMediumName ? "#1d1d1d" : "#A2A2A2" }} id="selection-drop" className={`select-selected ${isActive && 'select-selected-disabled'}`} onClick={this.toggleDropdown}>
              {this.state.orderMediumName}
            </div>
            <div className={`select-items ${isActive ? '' : 'select-hide'}`} style={{ top: '58px' }}>
              {this.getOrderMediumPlacement().map((value: { name: string; value: string }) =>
                <div key={value.name} style={{ height: "34px" }} className="optionSelected" onClick={(event) => { this.handleChange(value) }}>{value.name}</div>
              )}</div>
          </div>
        </div>
      </div>
    )
  }

  renderCashOffModal = () => {
    const { cashOffModal, selectedOrder } = this.state;
    return (
      <Modal
        closeButton
        maxWidth="sm"
        open={cashOffModal}
        onClose={this.closeCashOffModal}
      >
        <div className="cashoff__wrapper">
          <img src={cashOffImage} alt="Cash off" className="cashoff__image" />
          <h2>Are you sure you want to mark this order as paid?</h2>
          <p>Please confirm that you have received the payment from the customer/rider.</p>
          <p>
            Order ID: <span className="cashoff__order_id">{selectedOrder?.order_number}</span>
            <br />
            Amount: <span className="cashoff__order_id">&pound;{selectedOrder?.total}</span>
          </p>
          <div className="cashoff__buttons">
            <OutlinedButton onClick={this.closeCashOffModal}>CANCEL</OutlinedButton>
            <ContainedButton onClick={this.cashOff}>CASH OFF</ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { selectedOrder ,orderIdForRefund} = this.state;
    return (
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main" style={{ padding: "12px 24px" }}>
          <Header navigation={this.props.navigation} headerTitle={this.orderDropDown()}
            search={this.searchStaff()} />
          <div className="driverOrderContainer">
            <Grid container spacing={2}>
              <Grid item lg={7}>
                <div className="orderContainer">
                  <div className="orderSelectionTableContainer">
                    <Grid container style={{ justifyContent: "space-between", padding: "0px 16px", fontSize: "20px", fontWeight: 700 }}>
                      <Grid item lg={6} style={{ display: "flex", alignItems: "center" }}><div style={{ fontSize: "20px", fontWeight: 700, fontFamily: "Barmeno" }} >{this.state.headerText}</div></Grid>
                      <Grid item lg={6} style={{ display: "flex", flexDirection: "row", gap: "4px", justifyContent: "flex-end" }}>{this.catalogueHeaderMenuDealsSearchBar()} {this.catalogueHeaderMenuDealsFilter()}</Grid>
                    </Grid>
                    <div className="orderButtonAndTableContainer">

                      <TableContainer className="tableContainerMaterial">
                        <div className="tabTableContainer">
                          <div className={this.getValueForTab(0) ? "tabTableContainerTabActive" : "tabTableContainerTab"} onClick={() => this.setActiveTab(0, "Orders in store")}>Orders in store <div className={this.getValueForTab(0) ? "active_number_order" : "inactive_number_order"}>{this.state.orderListToDisplay.data.in_store_orders.total_count}</div></div>
                          <div className={this.getValueForTab(1) ? "tabTableContainerTabActive" : "tabTableContainerTab"} onClick={() => this.setActiveTab(1, "Orders on road")}>Orders on road <div className={this.getValueForTab(1) ? "active_number_order" : "inactive_number_order"}>{this.state.orderListToDisplay.data.on_road_orders.total_count}</div></div>
                          <div className={this.getValueForTab(2) ? "tabTableContainerTabActive" : "tabTableContainerTab"} onClick={() => this.setActiveTab(2, "Completed Orders")}>Completed Orders <div className={this.getValueForTab(2) ? "active_number_order" : "inactive_number_order"}>{this.state.orderListToDisplay.data.completed_orders.total_count}</div></div>
                          <div className={this.getValueForTab(3) ? "tabTableContainerTabActive" : "tabTableContainerTab"} onClick={() => this.setActiveTab(3, "Future Orders ")}>Future Orders <div className={this.getValueForTab(3) ? "active_number_order" : "inactive_number_order"}>{this.state.orderListToDisplay.data.future_orders.total_count}</div></div>
                          <div className={this.getValueForTab(4) ? "tabTableContainerTabActive" : "tabTableContainerTab"} onClick={() => this.setActiveTab(4, "Cancelled Orders")}>Cancelled Orders <div className={this.getValueForTab(4) ? "active_number_order" : "inactive_number_order"}>{this.state.orderListToDisplay.data.cancelled_orders.total_count}</div></div>
                        </div>
                        <Table style={{ minWidth: 572 }} aria-label="simple table">
                          {this.tableHeader()}
                          {this.renderChild(this.state.orderListToDisplay.data)}
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                  <div style={{ padding: '20px', marginTop: '10px' }}>
                    <Grid container spacing={8} justifyContent="center" style={{ gap: "16px" }}>

                      <button disabled={!selectedOrder || this.state.value === 4} className={`actionButtonsOrderListing`} onClick={this.navigateToOrderPrinting}>
                        REPRINT
                      </button>

                      <button disabled={!selectedOrder || selectedOrder?.status === "cancelled" || selectedOrder?.status === "collected" || selectedOrder?.status === "delivered" || this.state.value === 4} className={`actionButtonsOrderListing`} onClick={() => this.orderAction("cancel")}>
                        CANCEL ORDER
                      </button>

                      {this.state.value===4 ? <button disabled={!orderIdForRefund.length} className={`actionButtonsOrderListing`} onClick={this.bulkRefundOrder}>
                      REFUND
                    </button>
                    :<button disabled={!selectedOrder || selectedOrder?.status === "confirmed" || selectedOrder.status !== "placed"} className={`actionButtonsOrderListing`} onClick={() => this.orderAction("accept")}>
                        ACCEPT ORDER
                      </button>
                      
                      }

                      <button disabled={!selectedOrder || selectedOrder?.status === "collected" || selectedOrder?.status === "delivered" || selectedOrder?.status === "in_transit"} className={`actionButtonsOrderListing`} onClick={() => this.orderAction("skip")}>
                        SKIP STATUS
                      </button>

                      <button data-testid="cashoff" disabled={!selectedOrder || selectedOrder?.cashed_off || selectedOrder.payment_source !== "cash" || this.state.value !== 2} className={`actionButtonsOrderListing`} onClick={this.openCashOffModal}>
                        CASH OFF
                      </button>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item lg={5}>
                <div className="driverContainer" style={{ height: "100%", overflowY: "auto" }}>
                  <Grid container style={{ justifyContent: "space-between", padding: "0px 16px", fontSize: "20px", fontWeight: 700 }}>
                    <Grid item lg={7} style={{ display: "flex", alignItems: "center" }}><div style={{ fontSize: "20px", fontWeight: 700, fontFamily: "Barmeno" }} >Delivery Riders</div></Grid>
                    <Grid item lg={5} style={{ display: "flex", flexDirection: "row", gap: "4px" }}>{this.searchDriver()}</Grid>
                  </Grid>
                  <div className="orderButtonAndTableContainer">

                    <TableContainer className="tableContainerMaterial">
                      <Table aria-label="simple table">
                        {this.driverTableHeader()}
                        {this.state.driverDataToShow.length ? this.ChildDriverData(this.state.driverDataToShow) :
                          <div style={{ display: "flex", flexDirection: "row", fontWeight: 700, fontSize: "24px", color: "#747474", justifyContent: "center", padding: "24px 10px" }}>No information to be shown</div>}
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </main>
        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
        <FilterPopupOrderManagement open={this.state.openFilter} onClose={this.closeFilter} orderTypeSelected={this.state.orderTypeSelected} orderStatusSelected={this.state.statusChangeSelected} orderTypeChange={this.handleOrderTypeChange} statusChange={this.handleStatusChange} applyFilter={this.applyFilter} clearFilter={this.clearFilter} />
        <CustomLoader loading={this.state.loading} />
        <CustomLoader loading={this.state.loadingOrder} />
        {this.renderCashOffModal()}
        <ErrorPopup
          open={this.state.errorPopup}
          onClose={this.closeErrorPopup}
          message={this.state.errorPopupMessage}
        />
        <SuccessPopup
          open={this.state.successPopup}
          onClose={this.closeSuccessPopup}
          message={this.state.successPopupMessage}
        />
      </div>
    )
  }
  // Customizable Area End
}
