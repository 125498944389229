import React from "react";

// Customizable Area Start
import { Box, Typography, Tabs, Tab, SvgIcon } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";
import Header from "../../../components/src/Dashboard/Header/Header";
import Sidebar from "../../../components/src/Dashboard/Sidebar/Sidebar";
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Divider from '@material-ui/core/Divider';
import "./VisualAnalytics.css"
import {
  reportImage, reportImageDisabled, statisticsImage, statisticsImageDisabled, ExportImage, ExportImage2, ExportImage3,
  ExportImg1, ExportImg2, ExportImg3, ExportImg4,
  ExportImgDisabled
} from "./assets";
import OrderStatistics from "../../../components/src/Dashboard/VisualAnalyticsCharts/OrderStatisticsChart";
import EarningStatistics, { getXAxisInterval, renderCustomAxisTick } from "../../../components/src/Dashboard/VisualAnalyticsCharts/EarningStatisticsChart";
import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import SalesReporting from "../../salesreporting/src/SalesReporting.web";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Analytics from "../../analytics/src/Analytics.web";
import { Area, AreaChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { checkPercentageStatus } from "../../../components/src/utils";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  orderDropDown = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
        <div style={{ width: '100%' }}>Statistics & Reports</div>
        <DatePicker
          dateFormat="EEEE, d MMMM yyyy"
          className="custom-datepicker"
          customInput={
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardArrowDownIcon style={{ color: "#747474" }} />
                  </InputAdornment>
                ),
                style: {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontFamily: "barmeno"
                },
              }}
              value={this.state.selectedDate1 ? format(this.state.selectedDate1, "EEEE, d MMMM yyyy") : ""}
              onChange={() => { }}
              style={{
                color: '#747474',
                fontWeight: 600,
                fontSize: '16px',
                minWidth: "260px",
                maxWidth: "100%",
              }}
            />
          }
          selected={this.state.selectedDate1 || new Date(this.state.orderStatistics.order_data.today)}
          onChange={this.onChangeDate}
          maxDate={new Date()}
        />
        {this.searchStaff()}
      </div>
    )
  }
  searchStaff = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
      // onClick={this.handleFocus}
      >
        <SearchIcon fontSize="small" />
        <BaseInput
          // inputRef={this.searchRef}
          placeholder="Search here"
          // value={this.state.searchPreupdateValue}
          // onChange={this.handleSearchQuery}
          data-test-id={"searchInput"}
        />
      </div>
    );
  };

  searchRider = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
        style={{ height: "44px" }}
      >
        <SearchIcon fontSize="small" />
        <BaseInput
          placeholder="Search by Staff ID/Name"
          onChange={this.handleSearchQuery}
          data-test-id={"searchInput"}
        />
      </div>
    );
  };

  renderArrowSign = (percentage: number) => {
    const status = checkPercentageStatus(percentage);

    switch (status) {
      case "positive":
        return (
          <SvgIcon
            className="card_footer__icon"
            style={{ color: "#2C6F37" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              data-name="Flat Color"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.87 19.49A1 1 0 0 1 21 20a1 1 0 0 1-.49-.13L12 15.14l-8.51 4.73a1 1 0 1 1-1-1.74l9-5a1 1 0 0 1 1 0l9 5a1 1 0 0 1 .38 1.36M3.49 10.87 12 6.14l8.51 4.73A1 1 0 0 0 21 11a1 1 0 0 0 .49-1.87l-9-5a1 1 0 0 0-1 0l-9 5a1 1 0 1 0 1 1.74"
                fill="inherit"
              />
            </svg>
          </SvgIcon>
        );
      case "negative":
        return (
          <SvgIcon
            className="card_footer__icon"
            style={{ color: "#E11B22" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              data-name="Flat Color"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.13 4.51A1 1 0 0 1 3 4a1 1 0 0 1 .49.13L12 8.86l8.51-4.73a1 1 0 0 1 1 1.74l-9 5a1 1 0 0 1-1 0l-9-5a1 1 0 0 1-.38-1.36m18.38 8.62L12 17.86l-8.51-4.73A1 1 0 0 0 3 13a1 1 0 0 0-.49 1.87l9 5a1 1 0 0 0 1 0l9-5a1 1 0 1 0-1-1.74"
                fill="inherit"
              />
            </svg>
          </SvgIcon>
        );
      case "neutral":
        return (
          <SvgIcon
            className="card_footer__icon"
            style={{ color: "#7d7d7d" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 56 56"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13.797 22.691h28.43c1.125 0 2.086-.96 2.086-2.109 0-1.148-.961-2.086-2.086-2.086h-28.43c-1.125 0-2.11.938-2.11 2.086s.985 2.11 2.11 2.11m0 14.813h28.43c1.125 0 2.086-.938 2.086-2.086s-.961-2.11-2.086-2.11h-28.43c-1.125 0-2.11.962-2.11 2.11s.985 2.086 2.11 2.086" />
            </svg>
          </SvgIcon>
        );
    }
  };
  reportHeaderTabBar = () => {
    return (
      <Tabs
        value={this.state.reportStatsOption}
        onChange={this.handleReportStatsOptionChange}
        variant="fullWidth"
        aria-label="scrollable prevent tabs example"
        className="statisticReportTabBar"
      >
        <Tab className="tabsOptionTablistReports" label={<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img style={{ width: "24px", height: "24px" }} src={this.state.reportStatsOption === 0 ? statisticsImage : statisticsImageDisabled} /> {"Statistics"}</div>} />
        <Tab className="tabsOptionTablistReports" label={<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img style={{ width: "24px", height: "24px" }} src={this.state.reportStatsOption === 1 ? reportImage : reportImageDisabled} /> {"Report"}</div>} />
      </Tabs>
    )
  }

  renderCustomizedLegend = (props: any) => {
    const { payload } = props;
    const totalOrders = this.returnTotalOrder(this.state.orderStatisticsPie, this.state.activeTabOrder);
    return (
      <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: 0 }}>
        {payload.map((entry: any, index: number) => (
          <li key={`item-${index}`} style={{ display: "flex", alignItems: "center", marginRight: 10, textTransform: "capitalize", fontSize:"14px" }}>
            <span
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: entry.color,
                display: "inline-block",
                marginRight: 5,
              }}
            ></span>
            {`${entry.value.replace(/_/g, " ")} (${entry.payload.percent ? (entry.payload.percent * totalOrders).toFixed(0) : "0"})`}
          </li>
        ))}
      </ul>
    );
  };
  statisticsRender = () => {
    const COLORS = [
      "#662EB2", "#E73777", "#7C71FE", "#2C6F37", "#F59E0B", "#E11B22",
      "#8A2BE2",
      "#D72638",
      "#5E60CE",
      "#198754",
      "#FFB703",
      "#D00000",
      "#8338EC",
      "#FF006E",
      "#06D6A0",
      "#FF6700"
    ];
    const totalOrders = this.returnTotalOrder(this.state.orderStatisticsPie, this.state.activeTabOrder);
    const orders = this.prepareOrderData(this.state.orderStatisticsPie, this.state.activeTabOrder);
    const displayColors = COLORS;
    return (
      <div className="orderStatisticsContainer" style={{ maxWidth: "350px", width: "100%" }}>
        <div style={{ fontWeight: 700, fontSize: "20px" }}>Order Status Statistics</div>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={orders}
              cx="50%"
              cy="50%"
              fill="#d3d3d3"
              dataKey="value"
              innerRadius={52}
              outerRadius={75}
            >
              {orders.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={displayColors[index % displayColors.length]} />
              ))}
            </Pie>
            <text x="50%" y="34%" style={{ textAnchor: "middle", dominantBaseline: "middle", fontSize: "16px", fontWeight: 500 }}>
              {totalOrders ? `${totalOrders} Order${totalOrders === 1 ? '' : 's'}` : "No Order Found"}
            </text>
            <Tooltip />
            <Legend wrapperStyle={{ bottom: "0px", left: "0px", width: "100%" }} content={this.renderCustomizedLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  customerGrowthStatistics = () => {
    const customers = Object.entries(this.state.customerGrowthGraph.week_array).map(([time, value]) => ({
      time,
      Customer: value,
    }))
    const isDecreasingTrend = customers.length >= 2 && customers[customers.length - 1].Customer < customers[customers.length - 2].Customer;
    const gradientId = isDecreasingTrend ? 'colorOrdersRed' : 'colorOrdersViolet';
    const strokeColor = isDecreasingTrend ? '#E11B22' : '#2C6F37';
    return (
      <div className="orderStatisticsContainer" style={{ maxWidth: "350px", width: "100%" }}>
        <div style={{ fontWeight: 700, fontSize: "20px" }}>Customer Growth</div>
        <ResponsiveContainer >
          <AreaChart data={customers}>
            <defs>
              <linearGradient id="colorOrdersViolet" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#6200EA" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#6200EA" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorOrdersRed" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#E11B22" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#E11B22" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" minTickGap={5} interval={getXAxisInterval(customers.length)}
              tick={renderCustomAxisTick} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey="Customer" stroke={strokeColor} fill={`url(#${gradientId})`} />
          </AreaChart>
        </ResponsiveContainer>
        <div className="analytics__card__footer">
          <div className="analytics__footer__items">
            {this.renderArrowSign(Number(this.state.customerGrowthGraph.change_percentage))}

            <span className="footer__label">{customers.reduce((sum, item) => sum + item.Customer, 0)}</span>
            <span style={{ color: checkPercentageStatus(Number(this.state.customerGrowthGraph.change_percentage)) === "negative" ? "#E11B22" : "#2C6F37" }} className="footer__subtext">{checkPercentageStatus(Number(this.state.customerGrowthGraph.change_percentage)) === "positive" && "+"} {this.state.customerGrowthGraph.change_percentage}%</span>
          </div>

          <span className="footer__caption">Compared from Last Week</span>
        </div>
      </div>
    )
  }

  statisticsToShow = () => {
    return (
      <div className="statisticsContainerOnly">
        <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
          {this.orderStatistics()}
          {this.statisticsRender()}
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
          {this.earningStatistics()}
          {this.customerGrowthStatistics()}
        </div>
        {this.renderTopPerformingReports()}
      </div>
    )
  }

  orderStatistics = () => {
    return (
      <div className="orderStatisticsContainer">
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ fontWeight: 700, fontSize: "20px" }}>Order Statistics ({this.getOrderCount()})</div>
          <Tabs
            value={this.state.activeTabOrder}
            onChange={this.onChangeActiveTabOrders}
            variant="fullWidth"
            aria-label="scrollable prevent tabs example"
            className="statisticReportTabBar"
            style={{ maxWidth: "400px", width: "100%" }}
          >
            <Tab className="tabsOptionTablistReports" label={"This Year"} />
            <Tab className="tabsOptionTablistReports" label={"This Month"} />
            <Tab className="tabsOptionTablistReports" label={"This Week"} />
            <Tab className="tabsOptionTablistReports" label={this.orderDate(this.state.orderStatistics.order_data.today).getDate() == this.todayDate.getDate() ? "Today" : this.state.orderStatistics.order_data.today} />
          </Tabs>
        </div>
        <div style={{ width: "100%", height: 300, display: "flex", flexDirection: "row", gap: "24px", position: "relative" }}>
          {this.reurnDataSucces(this.state.orderStatistics.order_data, this.state.activeTabOrder) === "No data found" && <Box
            sx={{
              position: "absolute",
              top: "33%",
              left: "46%",
              width: 150,
              bgcolor: "#fff",
              boxShadow: 24,
              p: 2,
              borderRadius: 2,
              textAlign:"center"
            }}
          >
            <div>No Data Found</div>
          </Box>}
          <OrderStatistics orderStatistics={this.state.orderStatistics} activeTab={this.state.activeTabOrder} />
        </div>
      </div>
    )
  }

  earningStatistics = () => {
    return (
      <div className="orderStatisticsContainer">
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ fontWeight: 700, fontSize: "20px" }}>Earning Statistics (£{this.getEarningTotal()})</div>
          <Tabs
            value={this.state.activeTabEarning}
            onChange={this.onChangeActiveTabEarnings}
            variant="fullWidth"
            aria-label="scrollable prevent tabs example"
            className="statisticReportTabBar"
            style={{ maxWidth: "400px", width: "100%" }}
          >
            <Tab className="tabsOptionTablistReports" label={"This Year"} />
            <Tab className="tabsOptionTablistReports" label={"This Month"} />
            <Tab className="tabsOptionTablistReports" label={"This Week"} />
            <Tab className="tabsOptionTablistReports" label={this.orderDate(this.state.orderStatistics.order_data.today).getDate() == this.todayDate.getDate() ? "Today" : this.state.orderStatistics.order_data.today} />
          </Tabs>
        </div>
        <div style={{ width: "100%", height: 300, position: "relative" }}>
          {console.log(this.reurnEarningDataSucces(this.state.orderStatistics.earning_report, this.state.activeTabEarning), "return")}
          {this.reurnEarningDataSucces(this.state.orderStatistics.earning_report, this.state.activeTabEarning) === "No data found" &&
            <Box
              sx={{
                position: "absolute",
                top: "33%",
                left: "46%",
                width: 150,
                bgcolor: "#fff",
                boxShadow: 24,
                p: 2,
                borderRadius: 2,
              }}
            >
              <div>No Data Found</div>
            </Box>
          }
          <EarningStatistics orderStatistics={this.state.orderStatistics} activeTab={this.state.activeTabEarning} />
        </div>
      </div>
    )
  }


  orderReportsToShow = (selectedTab: number) => {
    return (
      <>
        {selectedTab === 0 ? <SalesReporting navigation={this.props.navigation} id={this.props.id} riderReportPopup={this.state.riderReportPopup} closeRiderReportPopup={this.closeRiderReportPopup} searchQuery={this.state.searchQuery} selectedDate1={this.state.selectedDate1}
          disbleExportRiderReport={this.disbleExportRiderReport} /> :
          <div className="reportsContainerOnly">
            {this.state.paymentDataError === null ?
              <Box>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ width: '400px', height: "400px", padding: '15px' }} src={ExportImage2} />
                </Box>
                <Typography style={{
                  fontSize: '32px',
                  color: '#1D1D1D',
                  fontFamily: 'Barmeno',
                  fontWeight: 700,
                  textAlign: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}><img style={{ marginRight: '15px' }} src={ExportImage3} />Pending orders Detected</Typography>
                <Typography style={{
                  fontSize: '18px',
                  color: '#747474',
                  fontFamily: 'Barmeno',
                  marginTop: '20px',
                  fontWeight: 500,
                  textAlign: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}>Please wait until all orders are processed to view detailed information.</Typography>
              </Box> :
              <Box>
                <Box style={Style.mainBox}>
                  <Grid container style={{ textAlign: 'center' }}>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Payment Type</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Delivery</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Collection</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Instore</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Total Orders</Typography>
                    </Grid>
                    <Grid style={{ marginTop: '15px' }} item lg={2} md={2} sm={2} xs={2}>
                      <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1D1D1D', fontFamily: 'Barmeno' }}>Amount (£)</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  {this.state.paymentData?.source_wise &&
                    Object.entries(this.state.paymentData.source_wise).map(([key, value]: any) => (
                      <Box>
                        <Grid style={{ padding: '10px 0px' }} container key={key}>
                          <Grid style={{ marginTop: '10px' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D', marginLeft: '24%', fontFamily: 'Barmeno', textTransform: 'capitalize' }}> {key.replace(/_/g, ' ')}</Typography>
                          </Grid>
                          <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D' }}>{value.delivery}</Typography>
                          </Grid>
                          <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D' }}>{value.collection}</Typography>
                          </Grid>
                          <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D' }}>{value.in_store}</Typography>
                          </Grid>
                          <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D' }}>{value.total_orders}</Typography>
                          </Grid>
                          <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                            <Typography style={{ fontSize: '14px', color: '#1D1D1D' }}>£{value.amount}</Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                      </Box>
                    ))}
                  {this.state.paymentData?.total_column && (
                    <Box>
                      <Grid style={{ padding: '10px 0px' }} container>
                        <Grid style={{ marginTop: '10px' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', marginLeft: '24%', fontFamily: 'Barmeno', textTransform: 'capitalize', fontWeight: 700 }}>
                            Total
                          </Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', fontWeight: 700 }}>{this.state.paymentData.total_column.delivery}</Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', fontWeight: 700 }}>{this.state.paymentData.total_column.collection}</Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', fontWeight: 700 }}>{this.state.paymentData.total_column.in_store}</Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', fontWeight: 700 }}>{this.state.paymentData.total_column.total_orders}</Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px', textAlign: 'center' }} item lg={2} md={2} sm={2} xs={2}>
                          <Typography style={{ fontSize: '14px', color: '#1D1D1D', fontWeight: 700 }}>£{this.state.paymentData.total_column.amount}</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  )}
                </Box>
              </Box>
            }
          </div >
        }
      </>

    )
  }

  renderTopBar(selectedTab: number) {
    return (
      <div data-testid="top-bar" className="staff_info__topbar">
        <Grid container style={{ gap: "16px", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item lg={5}>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              classes={{
                root: "staffInformation__tab__wrapper",
                indicator: "staffInformation__tab__hidden_indicator",
              }}
            >
              <Tab
                label="Rider Report"
                classes={{
                  root: "staffInformation__tab",
                  selected: "staffInformation__tab__selected",
                }}
              />
              <Tab
                label="End of Night Report"
                classes={{
                  root: "staffInformation__tab",
                  selected: "staffInformation__tab__selected",
                }}
              />
            </Tabs>
          </Grid>
          <Grid item lg={4}>
            {this.state.selectedTab === 0 ? this.searchRider() : <></>}
          </Grid>
          <Grid item lg={2}>
            <Button disabled={this.state.disaleRiderReportExport || this.state.paymentDataError === null} onClick={this.state.selectedTab === 0 ? this.openRiderReportPopup : this.ExoprtData} style={{
              border: this.state.disaleRiderReportExport || this.state.paymentDataError === null ? '0px solid' : '1px solid #e11b22',
              color: this.state.disaleRiderReportExport || this.state.paymentDataError === null ? '#909090' : '#e11b22',
              width: '100%', 
              height: '44px',
              float: 'inline-end',
              fontFamily: 'Barmeno',
              fontWeight: 700,
              borderRadius: '10px',
              backgroundColor: this.state.disaleRiderReportExport || this.state.paymentDataError === null ? '#BFBFBF' : '#fff',
            }}><img style={{ display: 'flex', margin: '10px' }} src={this.state.disaleRiderReportExport || this.state.paymentDataError === null ? ExportImgDisabled : ExportImage} />Export</Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderTopPerformingReports = () => {
    return (
      <Analytics navigation={this.props.navigation} id={this.props.id} topPermingProducts={this.state.orderStatistics.top_5_selling_product} topPerformingCustomer={this.state.orderStatistics.top_5_customer} topPerformingPayments={this.state.orderStatistics.top_5_payments} />
    )
  }

  // Customizable Area End

  render() {
    const { selectedTab } = this.state;
    return (
      // Customizable Area Start
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main" style={{ padding: "12px 24px" }}>
          <Header navigation={this.props.navigation} headerTitle={this.orderDropDown()} display="flex" />
          <div className="statisticReportContainer">
            {this.reportHeaderTabBar()}
            {this.state.reportStatsOption === 0 ? "" : this.renderTopBar(selectedTab)}
            {this.state.reportStatsOption === 0 ? this.statisticsToShow() : this.orderReportsToShow(selectedTab)}
          </div>
        </main>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
const Style = {
  mainBox: {
    width: '100%',
    height: '60px',
    backgroundColor: 'rgb(241 241 241)',
    borderRadius: '8px',
    borderBottom: '1px solid #909090'
  }
}
// Customizable Area End
