import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { collectionImage, deliveryImage } from "./assets";
import { collectionImageDone, cookingImage, cookingImageDone, deliveredImage, orderAcceptedImage, orderAcceptedImageDone, orderPickedImage, orderPickedImageDone, orderPlacedImage, orderPlacedImageDone, orderReadyImage, orderReadyImageDone } from "../../ordermanagement/src/assets";
import { accepted, orderPlaced } from "../../tasks/src/assets";
import { createMessage } from "../../../components/src/utils";
import { CountryData } from "react-phone-input-2";
import { CancelOrderOption } from "../../ordermanagement/src/OrderHistoryController";
export const configJSON1 = require("../../../framework/src/config")
export const configJSON = require("./config");


export interface OrderDataWrapper {
    data: OrderData;
}

export interface OrderData {
    id: string;
    type: string;
    attributes: OrderAttributes;
}

export interface OrderAttributes {
    id: number;
    order_number: string;
    payment_source: string;
    placed_at: string;
    delivered_at: string | null;
    collected_at: string | null;
    scheduled_time: string | null;
    order_type: string;
    account_id: string;
    estimated_time: string;
    status: string;
    items_count: number;
    redeem_point: string;
    points_worth: string;
    gift_card_amount: string;
    applied_discount: string;
    sub_total: string;
    total: string;
    refund_amount: string | null;
    original_total: string | null;
    reward_points: string | null;
    notes_to_chef: string;
    delivery_addresses: DeliveryAddress[];
    items_detail: ItemsDetail;
    personal_detail: PersonalDetail;
    driver_full_name: string | null;
    driver_details: DriverDetails | null;
}

export interface DriverDetails {
    "account": number;
    "full_phone_number": string;
    "country_code": number;
    "phone_number": number;
    "email": string
}

export interface DeliveryAddress {
    id: number;
    account_id: number;
    address: string;
    name: string;
    flat_no: string | null;
    zip_code: string;
    phone_number: string;
    deleted_at: string | null;
    latitude: number;
    longitude: number;
    residential: boolean;
    city: string;
    state_code: string | null;
    country_code: string | null;
    state: string | null;
    country: string;
    address_line_2: string | null;
    address_type: string;
    address_for: string;
    is_default: boolean;
    landmark: string | null;
    created_at: string;
    updated_at: string;
}

export interface ItemsDetail {
    data: CartItem[];
}

export interface CartItem {
    id: string;
    type: string;
    attributes: CartItemAttributes;
}

export interface CartItemAttributes {
    id: number;
    cart_id: number | null;
    catalogue_id: number;
    spice_level: string;
    choose_your_type: string;
    quantity: number;
    price: string;
    original_price: string;
    allergen_excluded: string;
    ready_for_delivery: boolean;
    product_name: string;
    allergen_in_item: Allergen[];
    total_item_price: string;
    total_item_price_after_discount: string;
    is_discounted: boolean;
    sub_category: string;
    images: ImageData;
    sides: Topping[];
    drinks: Topping[];
    nibbles: Topping[];
    toppings: Topping[];
    wraps_product: Topping[];
}

export interface Allergen {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    description: string;
}

export interface ImageData {
    url: string;
    content_type: string;
    file_name: string;
}

export interface Topping {
    catalogue_id: number;
    name: string;
    price: number;
    category_type: string;
    dealable_type: string;
    dealable_id: number;
}

export interface PersonalDetail {
    id: number;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    order_id: number;
    created_at: string;
    updated_at: string;
}



type Timer = ReturnType<typeof setTimeout>;

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history:any
    // Customizable Area End
}

interface S {
    loading: boolean;
    // Customizable Area Start
    orderDetails: OrderData;
    account_id: string | null;
    currentPage: number;
    rowsPerPage: number;
    orderStatusesDelivery: string[];
    orderStatusesCollection: string[];
    token: string;
    successPopup: {
        open: boolean;
        message: string;
    };
    editPersonalInfo: {
        open: boolean;
        data?: {
            customer_id?: string;
            address?: string;
            first_name?: string;
            last_name?: string;
            full_phone_number?: string;
        }
    }
    orderToBeCancelled: boolean;
    isActive:boolean;
    cancelationOptions:CancelOrderOption[];
    reasonString:string;
    reasonId:number;
    reasonCancellationCountError:string;
    reasonCancellationString:string;
    reasonCancellationCount:number;
    orderId:string;
    cartIds:number[];
    cancellationSuccess:{
        message	:string;
        sub_message: string;
        open:boolean;
      },
      cancellationError:{
        message:string;
        open:boolean;
      },
      isActiveRefund:boolean;
      role:string;
      totalPrice:number;
      initiateRefund:boolean;
      paymentMethodUsedAmount:string;
      rewardPointAmount:string;
      eGiftCardAmount:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class OrderManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAllOrdersDataFilterWiseApiCallId: string;
    orderActionCallId: string = "";
    updateCustomerInfoCallId: string = "";
    getAllCancellationReasonApiCallId:string;
    setCancellationInitiationApiCallId:string;
    timeoutId: null | Timer
    searchRef = React.createRef<HTMLInputElement>();
    setRefundInitiationApiCallId:string;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.getAllOrdersDataFilterWiseApiCallId = "";
        this.timeoutId = null;
        this.getAllCancellationReasonApiCallId="";
        this.setCancellationInitiationApiCallId="";
        this.setRefundInitiationApiCallId="";
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            loading: false,
            // Customizable Area Start
            orderDetails: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    order_number: "",
                    payment_source: "",
                    placed_at: "",
                    delivered_at: null,
                    collected_at: null,
                    order_type: "",
                    estimated_time: "",
                    status: "",
                    items_count: 0,
                    account_id: "",
                    redeem_point: "",
                    points_worth: "",
                    gift_card_amount: "",
                    applied_discount: "",
                    sub_total: "",
                    total: "",
                    refund_amount: null,
                    original_total: null,
                    reward_points: null,
                    notes_to_chef: "",
                    delivery_addresses: [],
                    items_detail: {
                        data: []
                    },
                    personal_detail: {
                        id: 0,
                        first_name: "",
                        last_name: "",
                        full_phone_number: "",
                        order_id: 0,
                        created_at: "",
                        updated_at: ""
                    },
                    driver_full_name: null,
                    driver_details: null,
                    scheduled_time: null
                }
            },
            currentPage: 1,
            rowsPerPage: 3,
            orderStatusesDelivery: ['placed', 'confirmed', 'cooking', 'ready', 'in_transit', 'delivered'],
            orderStatusesCollection: ['placed', 'confirmed', 'cooking', 'ready', 'collected'],
            token: "",
            successPopup: {
                open: false,
                message: ""
            },
            editPersonalInfo: {
                open: false,
            },
            account_id: null,
            orderToBeCancelled:false,
            isActive:false,
            cancelationOptions:[],
            reasonString:"",
            reasonId:0,
            reasonCancellationCountError:"",
            reasonCancellationCount:0,
            reasonCancellationString:"",
            orderId:"",
            cartIds:[],
            cancellationSuccess:{
                message:"",
                sub_message:"",
                open:false,
              },
              cancellationError:{
                message:"",
                open:false
              },
              isActiveRefund:false,
              role:"",
              totalPrice:0,
              initiateRefund:false,
              paymentMethodUsedAmount:"0.00",
              rewardPointAmount:"0.00",
              eGiftCardAmount:"0.00",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    getMessageHandler(callId: string, _response: any) {
        const messageHandlers = {
            [this.getAllOrdersDataFilterWiseApiCallId]: this.setOrderDetailsIndividual,
            [this.orderActionCallId]: this.handleOrderAction,
            [this.updateCustomerInfoCallId]: this.handleUpdateCustomerInfo,
            [this.getAllCancellationReasonApiCallId]: this.setOrderCancellationReason,
            [this.setCancellationInitiationApiCallId]: this.getCancellationResponse,
            [this.setRefundInitiationApiCallId]: this.getRefundResponse
        };

        return messageHandlers[callId];
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (!response || errorMessage) {
            runEngine.debugLog("API Error", errorMessage);
            return;
        }

        const handler = this.getMessageHandler(callId, response);
        if (handler) {
            handler(response);
            this.setState({ loading: false });
        };
    };
    componentDidMount = async () => {
        const urlPath = location.pathname;
        const segments = urlPath.split("/");
        const orderId = segments[segments.length - 1];
        const token = localStorage.getItem("authToken")
        const role = sessionStorage.getItem('role')
        console.log("roleTotets",role)
        this.setState({role:role ||""})

        this.getOrderCancellationReason()
        this.setState({ token: token || "", orderId }, () => {
            if (orderId) {
                this.getOrderDataIndividual(orderId)
            }
        })

    }

    componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<S>) => {

    }

    setOrderDetailsIndividual = (responseJson: { data?: OrderDataWrapper, errors?: any }) => {
        if (responseJson.data) {
            this.setState({
                orderDetails: responseJson.data.data,
                account_id: responseJson.data.data.attributes.account_id,
                editPersonalInfo: {
                    open: false,
                    data: {
                        first_name: responseJson.data.data.attributes.personal_detail.first_name,
                        last_name: responseJson.data.data.attributes.personal_detail.last_name,
                        full_phone_number: responseJson.data.data.attributes.personal_detail.full_phone_number,
                        address: responseJson.data.data.attributes.delivery_addresses.length > 0 ? responseJson.data.data.attributes.delivery_addresses[0].address : undefined,
                    }
                }
            })
        }
    }

    getOrderDataIndividual = (id: string) => {
        let token = localStorage.getItem("authToken")
        if (token) {
            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getAllOrdersDataFilterWiseApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getIndividualOrderApiEndPoint}/${id}/order_details`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

        }
    }

    generateOrderActionUrl = (orderId: string, action: string, configJSON: any) => {
        const endpoint = configJSON.orderActionAPI.endPoint;
        const actionPath = configJSON.orderAction[action];

        return `${endpoint}/${orderId}/${actionPath}`;
    }

    orderAction = (action: "accept" | "cancel" | "skip") => {
        const orderId = this.state.orderDetails.id;

        if (!orderId) return;

        const getDataMsg = createMessage(
            action === "skip" ? "POST" : "GET",
            this.generateOrderActionUrl(orderId, action, configJSON)
        );
        this.setState({ loading: true });
        this.orderActionCallId = getDataMsg.messageId;
        runEngine.sendMessage(getDataMsg.id, getDataMsg);
    };

    handleOrderAction = (response: any) => {
        if (response && response.message)
            this.setState({
                successPopup: {
                    open: true,
                    message: response.message
                }
            }, () => this.getOrderDataIndividual(this.state.orderDetails.id))
    };

    getOrderTypeIcon = (order_type: string) => {
        return order_type === "delivery" ? deliveryImage : collectionImage
    }

    convertToDate = (dateStr: string) => {
        const date = new Date(dateStr);

        const formattedDate = date.toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        return formattedDate
    }


    getCartExtraDetails = (cartDetail: CartItem) => {
        const { sides, drinks, nibbles, toppings, choose_your_type, spice_level } = cartDetail.attributes;
        const items = [
            ...sides.map((value) => value.name),
            ...drinks.map((value) => value.name),
            ...nibbles.map((value) => value.name),
            ...toppings.map((value) => value.name)
        ];
        const formattedItems = items.filter(item => item).join(', ');
        const showItems = (spice_level ? spice_level + " | " : "") + choose_your_type + (formattedItems ? " | " + formattedItems : "");
        return showItems
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ currentPage: newPage });
    };

    getPaginatedData = (data: CartItem[]) => {
        const { currentPage, rowsPerPage } = this.state;
        const startIndex = (currentPage - 1) * rowsPerPage;
        return data.slice(startIndex, startIndex + rowsPerPage);
    };

    displayIndex = (index: number) => {
        return (this.state.currentPage - 1) * this.state.rowsPerPage + (index + 1)
    }

    getImageCollection = [{ status: 'placed', active: orderPlacedImageDone, inactive: orderPlacedImage, name: "Order Placed" },
    { status: 'confirmed', active: orderAcceptedImageDone, inactive: orderAcceptedImage, name: "Order Accepted" },
    { status: 'cooking', active: cookingImageDone, inactive: cookingImage, name: "Cooking" },
    { status: 'ready', active: orderReadyImageDone, inactive: orderReadyImage, name: "Ready for pickup" },
    { status: 'collected', active: collectionImageDone, inactive: collectionImage, name: "Collected" }]
    getImageGroupDelivery = [{ status: 'placed', active: orderPlacedImageDone, inactive: orderPlacedImage, name: "Order Placed" },
    { status: 'confirmed', active: orderAcceptedImageDone, inactive: orderAcceptedImage, name: "Order Accepted" },
    { status: 'cooking', active: cookingImageDone, inactive: cookingImage, name: "Cooking" },
    { status: 'ready', active: orderReadyImageDone, inactive: orderReadyImage, name: "Ready for pickup" },
    { status: 'in_transit', active: orderPickedImageDone, inactive: orderPickedImage, name: "Order picked up" },
    { status: 'delivered', active: deliveredImage, inactive: deliveredImage, name: "Delivered" }]
    onStatusImages = (status: string) => {
        switch (status) {
            case 'accepted':
                return { image: accepted, status: 'Order Can be collected when the food is ready for Collection' };
            case 'confirmed':
                return { image: accepted, status: 'Order Can be collected when the food is ready for Collection' };
            case 'cooking':
                return { image: accepted, status: 'Order Can be collected when the food is ready for Collection' };
            default:
                return { image: orderPlaced, status: 'This order will automatically start cooking 30 minutes before the scheduled order time.' };
        }
    }

    handleCall = (driver_details: DriverDetails | null) => {
        if (driver_details && driver_details.full_phone_number) {
            location.href = `tel:+${driver_details.full_phone_number}`;
        }
    };
    navigateToBack = () => {
        this.props.navigation.navigate('PosOrderManagementManager')
    }

    disabledDownloadInvoice = () => {
        const status = this.state.orderDetails.attributes.status;

        switch(status) {
            case "delivered":
                return false;
            case "collected":
                return false;
            default:
                return true;
        }
    };

    disabledSkipStatus = () => {
        const status = this.state.orderDetails.attributes.status;

        switch(status) {
            case "delivered":
                return true;
            case "collected":
                return true;
            case "cancelled":
                return true;
            default:
                return false;
        }
    };

    closeSuccessPopup = () =>
        this.setState({
            successPopup: {
                open: false,
                message: ""
            }
        });

    downloadInvoice=()=> {
        const id = this.state.orderDetails.id;
        const url = `${configJSON1.baseURL}/${configJSON.getOrderDetailsApiEndPoint}/${id}/download_invoice?token=${this.state.token}`
        location.href= url
    }

    openEditPersonalDetails = () =>
        this.setState(prevState => ({
            ...prevState,
            editPersonalInfo: {
                open: true,
                data: prevState.editPersonalInfo.data
            }
        }))

    closeEditPersonalEdtails = () =>
        this.setState(prevState => ({
            ...prevState,
            editPersonalInfo: {
                open: false,
                data: prevState.editPersonalInfo.data
            }
        }))

    handleMobileInputChange = (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
        const { name } = event.target;

        if ("dialCode" in data) {
            this.setState(prevState => ({ ...prevState, editPersonalInfo: {
                open: prevState.editPersonalInfo.open,
                data: {
                    ...prevState.editPersonalInfo.data,
                    [name]: value
                }
            } }));
        }
    };

    updateCustomerInfo = () => {
        const { token, editPersonalInfo, account_id } = this.state;
        const getDataMsg = createMessage(
            "PUT",
            `${configJSON.orderInfoEndpoint}/${this.state.orderDetails.id}/${configJSON.updateCustomerInfoEndpoint}?account_id=${account_id}`,
            { "Content-Type": configJSON.validationApiContentType, token },
            { personal_detail: { first_name: editPersonalInfo.data?.first_name, last_name: editPersonalInfo.data?.last_name, full_phone_number: editPersonalInfo.data?.full_phone_number }}
        );

        this.updateCustomerInfoCallId = getDataMsg.messageId;
        runEngine.sendMessage(getDataMsg.id, getDataMsg)
    };

    handleUpdateCustomerInfo = (response: any) => {
        if (response && response.message)
            this.setState((prevState) => ({
                ...prevState,
                editPersonalInfo: {
                    open: false,
                }
            }), () => this.getOrderDataIndividual(this.state.orderDetails.id));
    };

    handleCustomerInfoChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            ...prevState,
            editPersonalInfo: {
                open: prevState.editPersonalInfo.open,
                data: {
                    ...prevState.editPersonalInfo.data,
                    [name]: value
                }
            }
        }));
    };

    orderToBecnacelled = ()=>{
        this.setState({
            orderToBeCancelled:true
        })
    }

    orderToBecnacelledClose = ()=>{
        this.setState({
            orderToBeCancelled:false, reasonCancellationString:"", reasonId:0, reasonString:""
        })
    }

    toggleDropdownClose =()=>{
        this.setState({isActive:false})
      }

      toggleDropdown = () => {
        this.setState({isActive:true});
      };

    cancelledButtonDisabled =()=>{
        const {orderDetails} = this.state
        return (orderDetails.attributes.status !== "placed" && orderDetails.attributes.status !== "confirmed" && orderDetails.attributes.status !== "cooking") ||this.state.orderToBeCancelled 
    }

    cancelledButtonDisabled2 =()=>{
        const {orderDetails} = this.state
        return (orderDetails.attributes.status !== "placed" && orderDetails.attributes.status !== "confirmed" && orderDetails.attributes.status !== "cooking") ||this.state.orderToBeCancelled 
    }
    setReasonString=(reasonString:string,reasonId:number)=>{
        this.setState({reasonString,reasonId,isActive:false})
        if(reasonString !== "Other"){
            this.setState({reasonCancellationString:""})
        }
      }

      handleChangeCancellationOtherReason = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const reasonCancellationString = event.target.value
        const reasonCancellationCount = event.target.value.length
        if (reasonCancellationCount > 50) {
          const reasonCancellationCountError = "Cannot use more than 50 characters."
          this.setState({ reasonCancellationCountError })
        } else {
          this.setState({ reasonCancellationCountError: "" })
        }
        this.setState({ reasonCancellationString, reasonCancellationCount })
      }

      getOrderCancellationReason = () => {
        let token = localStorage.getItem("authToken")
        if (token) {
            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getAllCancellationReasonApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getCancelationReason}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

        }
    }

    setOrderCancellationReason=(responseJson:any)=>{
    if(responseJson && (!responseJson.errors || !responseJson.error) ){
        this.setState({cancelationOptions:responseJson})
      }
    }

    setOrderCancelInitiation = () => {
        let token = localStorage.getItem("authToken")
        const { reasonId, role, cartIds, orderDetails,orderId,paymentMethodUsedAmount,rewardPointAmount,eGiftCardAmount } = this.state;
        const allItems = orderDetails.attributes.items_detail.data;
        const isPartialCancel = cartIds.length !== allItems.length;
        const cartItemIds = isPartialCancel ? { cart_item_ids: cartIds } : {};

        const bodyRefund = {
            cancel_reason_id: reasonId,
            refund_amount: this.getTotalRefundAmount(),
            ...cartItemIds,
            ...(this.getRewardAmountStatus(paymentMethodUsedAmount) ? { payment_method_used_amount: paymentMethodUsedAmount } : {}),
            ...(this.getRewardAmountStatus(rewardPointAmount) ? { refund_reward_point_amount: rewardPointAmount } : {}) ,
            ...(this.getRewardAmountStatus(eGiftCardAmount) ? { refund_e_gift_card_amount: eGiftCardAmount } : {}) ,
        };

        const body = {
            cancel_reason_id: reasonId,
            current_user_role: role,
            ...cartItemIds
        };

        if (token) {
            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.setCancellationInitiationApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.cancelOrderInitiateApiEndPoint}/${orderId}/${role === "In Store Manager"?"cancelled_and_refund_initiated":"confirm_cancellation"}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(role === "In Store Manager"?bodyRefund:body))
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            
            runEngine.sendMessage(requestMessage.id, requestMessage);

        }
    }

    confirmCancelButtonDisabled = ()=>{
        return this.state.cartIds.length<1 || this.state.reasonId === 0
    }

    confrimOrderCancelledDisabledManager=()=>{
        return  this.state.role === "In Store Manager" && this.getTotalRefundAmount() === "0.00"
    }

    changeCartIds = (id: number, price: string) => {
        this.setState((prevState) => {
            const isAlreadyInCart = prevState.cartIds.includes(id);
            const updatedCartIds = isAlreadyInCart
                ? prevState.cartIds.filter((cartId) => cartId !== id)
                : [...prevState.cartIds, id];
    
            const priceNumber = parseFloat(price) || 0;
            const updatedTotalPrice = isAlreadyInCart
                ? prevState.totalPrice - priceNumber
                : prevState.totalPrice + priceNumber;
    
            return {
                cartIds: updatedCartIds,
                totalPrice: parseFloat(updatedTotalPrice.toFixed(2)),
            };
        });
    };

    getCancellationResponse = (responseJson:any) =>{
        if(responseJson.error){
            this.setState({loading:false,cancellationError:{open:true,message:responseJson.error}})
    }else if(responseJson.message && responseJson.sub_message){
           if(responseJson.message === "Initiated refund request!"){
                this.setState({loading:false,cancellationSuccess:{open:true,message:responseJson.message,sub_message:responseJson.sub_message}})
            }else if(responseJson.message === "Order Cancelled!"){
                this.setState({loading:false,cancellationSuccess:{open:true,message:responseJson.message,sub_message:responseJson.sub_message}})
            }
        }else{
            this.setState({loading:false,cancellationError:{open:true,message:"Something went wrong!"}})
        }
    }

    getRefundResponse=(responseJson:any)=>{
        if(responseJson.message && responseJson.sub_message){
            if(responseJson.message === "Order Cancelled!"){
                this.setState({loading:false,cancellationSuccess:{open:true,message:responseJson.message,sub_message:responseJson.sub_message}})
            }else if(responseJson.message === "Initiated refund request!"){
                this.setState({loading:false,cancellationSuccess:{open:true,message:responseJson.message,sub_message:responseJson.sub_message}})
            }
        }else if(responseJson.error){
                this.setState({loading:false,cancellationError:{open:true,message:responseJson.error}})
        }else{
            this.setState({loading:false,cancellationError:{open:true,message:"Something went wrong!"}})
        }
    }

    closeCancelError=()=>{
        this.setState({cancellationError:{open:false,message:""}})
    }

    closeCancellationSuccess=()=>{
        this.setState({cancellationSuccess:{open:false,message:"",sub_message:""}})
        this.props.navigation.navigate('PosOrderManagementManager')
    }
    statusDisbaled=(status:string)=>{
        return !(status === 'cancel_initiated' || status === 'cancelled' || status === 'refund_initiated' || status === 'refunded')
      }
      navigateToOrderPrinting=()=>{
        const {orderId} = this.state
        if(orderId){
          this.props.history.push(`/Dashboard/Receipt/${orderId}`, { orderId })
        }
      }

      helpIconDisabled=(status:string)=>{
        return status === 'cancel_initiated' || status === 'cancelled' || status === 'refund_initiated' || status === 'refunded'
      }

      refundIconDisabled=(status:string)=>{
        return status === 'cancel_initiated' || status === 'cancelled'  
      }

      initiateRefundActive=()=>{
        this.setState({initiateRefund:true})
      }
      initiateRefundClose = ()=>{
        this.setState({
            initiateRefund:false, reasonCancellationString:"", reasonId:0, reasonString:""
        })
    }

    onChangeRefundValue=(fieldName:keyof S,value:string)=>{
        let numericValue = value.replace(/[^0-9.]/g, "");
        const dotCount = (numericValue.match(/\./g) || []).length;
        if (dotCount > 1) {
            numericValue = numericValue.slice(0, numericValue.lastIndexOf("."));
        }
        
    
        // Update state correctly
        this.setState((prevState) => ({
            ...prevState,
            [fieldName]: numericValue, // Store as number for correct calculations
        }));
    }

    getTotalRefundAmount = () => {
        const total =
        (parseFloat(this.state.paymentMethodUsedAmount || "0.00")) +
        (parseFloat(this.state.rewardPointAmount || "0.00")) +
        (parseFloat(this.state.eGiftCardAmount || "0.00"));

    
        return total.toFixed(2)
    };

    getRewardAmountStatus=(amount:string)=>{
        return amount !== "0.00" && amount !== "0" && amount !== ".00" && amount !== "";

    }

    setOrderRefundInitiation = () => {
        let token = localStorage.getItem("authToken")
        const { paymentMethodUsedAmount,orderId,eGiftCardAmount,rewardPointAmount } = this.state;

        const body = {
           "refund_amount": this.getTotalRefundAmount(),
            ...(this.getRewardAmountStatus(paymentMethodUsedAmount) ? { payment_method_used_amount: paymentMethodUsedAmount } : {}),
            ...(this.getRewardAmountStatus(rewardPointAmount) ? { refund_reward_point_amount: rewardPointAmount } : {}) ,
            ...(this.getRewardAmountStatus(eGiftCardAmount) ? { refund_e_gift_card_amount: eGiftCardAmount } : {}) ,
        };

        if (token) {
            this.setState({ loading: true })
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.setCancellationInitiationApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "PUT"
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.refundInitiationApiEndPoint}/${orderId}/refund_initiated`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
            runEngine.sendMessage(requestMessage.id, requestMessage);

        }
    }
}
