import React from "react";
import {Props, } from "./ForgotPasswordController";
import { ThemeProvider,createTheme } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Box, Button, DialogTitle, FormLabel, IconButton, OutlinedInput, Typography } from "@material-ui/core";
import { CheckCircle, InfoOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { bgImage } from "./assets";
import './NewPassword.css'
import NewPasswordController from "./NewPasswordController";
import RestPasswordPopup from "../../../components/src/ResetPasswordPopup.web";


const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
export default class NewPassword extends NewPasswordController{
    constructor(props: Props){
        super(props);
    }
    componentDidMount(): any{
        const queryParams = new URLSearchParams(location.search)
        const token = queryParams.get('token')
        if(token){
        this.setState({token: token})
    }
    }
    render(){
        return(
            <ThemeProvider theme={theme}>
            <div style={{backgroundColor: 'rgb(223, 223, 223)', padding:"2 0"}}>
            <Box className="container" style={{fontFamily:'Barmeno'}}>
                <Box className="heading">
                    <Typography style={{fontFamily:'Barmeno'}} className="headingTextT" variant="h4" component="h2">
                        Reset Password
                    </Typography>
                    <Typography style={{fontFamily:'Barmeno'}} className="headingInfoT" variant="subtitle1" component="div">
                    Set your new account password, so that you can sign in and access all features.
                    </Typography>
                    <div style={{marginTop:'46px'}}>
                    <FormLabel style={{fontFamily:'Barmeno'}} className="passwordTextT" >New Password</FormLabel>
                    <div>
                        <OutlinedInput
                            style={{fontFamily:'Barmeno'}}
                            itemID="itmPassword" 
                            value={this.state.password} 
                            onChange={(e)=> this.setState({password: e.target.value})}
                            className="passwordInputT"
                            type={!this.state.enablePasswordField ? "text" : "password"} />
                            <IconButton
                            className="eyeIcon"
                                itemID="enablePass"
                                style={{ margin: '0px 0px 0px -50px' }}
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enablePasswordField: !this.state.enablePasswordField })}

                            >
                                {!this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton></div>
                            <Box className="infoBox">
                            <Typography className="infoTextPoints" variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{fontFamily:'Barmeno',marginRight:'5px', color:this.state.password.match(/[A-Z]/gm) ? "#409046" : "#e11b23"}} fontSize="small" />{"   "} Atleast one capital letter (A-Z)
                            </Typography>
                            <Typography className="infoTextPoints" variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{fontFamily:'Barmeno',marginRight:'5px', color:this.state.password.match(/[a-z]/gm) ? "#409046" : "#e11b23"}} fontSize="small" />{"   "}  Atleast one lowercase letter (a-z)
                            </Typography>
                            <Typography className="infoTextPoints" variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{fontFamily:'Barmeno',marginRight:'5px',  color: this.state.password.match(/\d/gm) ? "#409046" : "#e11b23" }} fontSize="small" />{"   "}  Atleast one number (0-9)
                            </Typography>
                            <Typography className="infoTextPoints" variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{fontFamily:'Barmeno',marginRight:'5px', color:this.state.password.length >= 8 ? "#409046" : "#e11b23" }} fontSize="small" /> {"   "} Minimum 8 characters length
                            </Typography>
                        </Box>
                        <FormLabel style={{fontFamily:'Barmeno'}} className="passwordTextT" >Confirm New Password</FormLabel>
                        <div>
                            <OutlinedInput
                                style={{fontFamily:'Barmeno'}}
                                itemID="itmCnfPass"
                                value={this.state.reTypePassword}
                                onChange={e => this.setState({ reTypePassword: e.target.value, passwordNotMatched: false })}
                                className={this.state.passwordNotMatched ? 'passwordInputNotMatched' : 'passwordInputT'}
                                type={!this.state.enableReTypePasswordField ? "text" : "password"}

                            />
                            <IconButton
                            className="eyeIcon"
                                style={{ margin: "0px 0px 0px -50px" }}
                                itemID="testABC"
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enableReTypePasswordField: !this.state.enableReTypePasswordField })}
                            >
                                {!this.state.enableReTypePasswordField ? <Visibility /> : <VisibilityOff />}

                            </IconButton>
                        </div>{ this.state.passwordNotMatched ?
                        <Typography id='alertMessage' style={{...webStyle.passwordNotMatched, position:"absolute"}} >Password and confirm password does not match. Please try again!</Typography>: null}
                        {this.state.goToSignIn?<><Button style={{fontFamily:'Barmeno'}} id="btnBackToSignIn" variant="contained" color="primary" size="small" onClick={() => this.goToLogin()} className="resetButton">
                           BACK TO SIGN IN
                        </Button>
                        </>:
                        <Button style={{fontFamily:'Barmeno'}} id="btnResetPassword" variant="contained" color="primary" size="small" onClick={() => this.passwordNotMatched(this.state.token,this.state.password,this.state.reTypePassword)} className={this.disableButton() ?"button" : "resetButton"} disabled={this.disableButton()}>
                        RESET PASSWORD
                    </Button>}
                </div>
                        
                </Box>
                <Box className="bgContainer">
                <img className="bgImage" alt="background" src={bgImage} />
        </Box>
            </Box>
            </div>
            <RestPasswordPopup open={this.state.goToSignIn} onClose={this.goToSignInMethod} button={'BACK TO SIGN IN'}/>
            </ThemeProvider>
        )
    }
} 

// Customizable Area Start
const webStyle = {
  passwordNotMatched:{ color: "#E42328", fontSize: "12px", fontWeight: "500", fontFamily: "Barmeno", marginTop: '5px' },
}
// Customizable Area End