import {useJsApiLoader} from '@react-google-maps/api';

export const configJSON = require("../../framework/src/config")
export type DirectionsRenderer = google.maps.DirectionsRenderer
export type MapsMap = google.maps.Map;
export type DirectionResultResponse =  google.maps.DirectionsResult

export const scaledSize =  typeof window !== "undefined" && window.google
? new window.google.maps.Size(60, 60): undefined
export const scaledSizeRestaurant =  typeof window !== "undefined" && window.google
? new window.google.maps.Size(56, 56): undefined
export const scaledDriverLocation =  typeof window !== "undefined" && window.google
? new window.google.maps.Size(50, 58): undefined
export const scaledOrderLocation =  typeof window !== "undefined" && window.google
? new window.google.maps.Size(22, 28): undefined
export const origin = typeof window !== "undefined" && window.google
?new window.google.maps.Point(0, 0) :undefined
export const originDriver = typeof window !== "undefined" && window.google
?new window.google.maps.Point(0, 0) :undefined
export const anchor= typeof window !== "undefined" && window.google
? new window.google.maps.Point(30, 30): undefined
export const anchorDriver= typeof window !== "undefined" && window.google
? new window.google.maps.Point(0, 0): undefined


export const calculateRoute = async(setDirectionRederer:(value:google.maps.DirectionsResult)=>void,origins:{lat:number,long:number}, destinations:{lat:number,long:number}) => {
    const origin = new google.maps.LatLng(origins.lat,origins.long)
    const destination = new google.maps.LatLng(destinations.lat,destinations.long)
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin,
      destination,
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionRederer(results)
  };

  export const calculateRouteReturning = async(origins:{lat:number,long:number}, destinations:{lat:number,long:number}) => {
    const origin = new google.maps.LatLng(origins.lat,origins.long)
    const destination = new google.maps.LatLng(destinations.lat,destinations.long)
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin,
      destination,
      travelMode: google.maps.TravelMode.DRIVING,
    });
   return results
  };


  export const calculateRouteMultiple = (origin: google.maps.LatLngLiteral, destination: google.maps.LatLngLiteral, waypoints: google.maps.DirectionsWaypoint[]) => {
    const directionsService = new google.maps.DirectionsService();
  
    return new Promise<DirectionResultResponse>((resolve, reject) => {
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,  // Optional: Optimize stop sequence
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            resolve(result);
          } else {
            console.error("Directions request failed due to", status);
            reject(status);
          }
        }
      );
    });
  };

  export const webSocketFunction = (token:string) =>{
    const baseURL =  configJSON.baseURL.replace('https://', '');
    const socket = new WebSocket(`wss://${baseURL}/cable?token=${token}`);
    return socket
}

export const trackingWebSocket = (cableSubscription:any | null, order_id:number,setOriginLat:(value:{lat:number,long:number})=>void,setMessageDriver:(message:string)=>void)=>{
  if(cableSubscription){
    const socket = cableSubscription
    socket.onopen = () => {
        console.log('WebSocket connection established for tracking driver');
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "BxBlockMaps3::OrderDriverLocationChannel",
            order_id
          })
        };
      
        socket.send(JSON.stringify(message));
      };
      socket.onmessage = (event:any) => {
        const response = event.data;
        const message = JSON.parse(response);

        if (message.type === 'confirm_subscription') {
          console.log('Successfully subscribed to driver tracking channel:', message.identifier);
        } else if (message.type === 'ping') {
          console.log("ping")
          return;
        }
        console.log('WebSocket driver tracking channel message received:', message);
        if (message.message) {
          const driverLocation = message.message.driver_location
          const successMessage = message.message.success_message
          if (driverLocation) {
            if(successMessage === "Your order is on its way! Our driver is currently delivering another order but will be heading straight to you next. Thank you for your patience!"){
              setMessageDriver(successMessage)
            } else if(successMessage === "Your order has been picked up! Tracking the driver."){
              console.log(`Driver's location: Latitude: ${driverLocation.latitude}, Longitude: ${driverLocation.longitude}`);
              setOriginLat({lat:driverLocation.latitude,long:driverLocation.longitude})
              setMessageDriver(successMessage)
            }
            
          }
        }else {
          console.warn('Unexpected data received:', message);
        }
      };
      
      socket.onerror = (error:any) => {
        console.error('WebSocket error:', error);
        
      };
    }
}

export const actionCableConfigCloseConnection = (cableSubscription:any | null)=>{
  cableSubscription.onclose = () => {
      console.log('WebSocket connection closed');
    };
}