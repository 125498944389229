import React from "react";
import { CalendarTodayRounded } from "@material-ui/icons";
import { InputAdornment, InputBaseProps } from "@mui/material";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { WingoInput } from "../Inputs/CustomInputs";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

const preventUserInput = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement> | React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
  e.preventDefault();
};

interface Props extends ReactDatePickerProps {
  preventEdit?: boolean;
  inputProps?: InputBaseProps;
};

const DateInput = (props: Props) => {
  const { preventEdit } = props;
  const getDateFormat = () => {
    const { value } = props;

    if (value && value.includes("-")) {
      return "yyyy-MM-dd";
    }

    return "yyyy/MM/dd";
  };

  return (
    <DatePicker
      showIcon
      popperClassName="date_picker_popper"
      wrapperClassName="date_picker_wrapper"
      dateFormat={getDateFormat()}
      value={props.value}
      onKeyDown={e => preventEdit && preventUserInput(e)}
      customInput={
        <WingoInput
          endAdornment={
            <InputAdornment position="end">
              <CalendarTodayRounded />
            </InputAdornment>
          }
        />
      }
      {...props}
    />
  );
};

const TimeInput = (props: Props) => {
  const { preventEdit, ...rest } = props;

  return (
    <DatePicker
      {...rest}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
      value={props.value}
      onKeyDown={e => preventEdit && preventUserInput(e)}
      customInput={
        <WingoInput
        {...props.inputProps}
        />
      }
    />
  );
};

export default DateInput;
export { TimeInput };
