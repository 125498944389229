import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import moment from "moment";
import { SelectChangeEvent } from "@mui/material";
import { createMessage, getSessionStorage } from "../../utils";
import {
  logoutIcon,
  settingsIcon,
  supportIcon,
} from "../../../../blocks/dashboard/src/assets";
import { UserIcon } from "../../Icons";
import { actionCableConfigCloseConnection, headerCountConfig, webSocketFunction } from "../../websocketConfig";
import { Notification, NotificationAttributes } from "./types";

export const configJSON = require("./config");

interface Props {
  navigation: any;
  headerTitle?: any;
  dateFilter?: React.ReactNode;
  search?: any;
  filter?: React.ReactNode;
  headerRight?: React.ReactNode;
  notToShow?: string;
  padding?: string;
  display?:string;
  fullWidthTitle?: boolean;
};

interface S {
  token: string;
  search: string;
  selectedRole: string;
  anchorEl: null | HTMLElement;
  logOut: boolean;
  loadingNotifications: boolean;
  notifications: {
    [key: string]: NotificationAttributes[];
  };
  notificationCount: number;
  notificationsAnchorEl: HTMLButtonElement | null;
  update: number;
};

interface SS {};

export default class HeaderController extends BlockComponent<Props, S, SS> implements IBlock {
  cableSubscription: WebSocket | null = null;
  getNotificationsCallId: string = "";
  markAsReadCallId: string = "";
  markAllAsReadCallId: string = "";
  deleteCallId: string = "";
  roles: string[] = [];
  settings: any = {};
  accountMenu: { icon: JSX.Element; label: string; onClick: () => void; }[] = [];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      search: "",
      selectedRole: sessionStorage.getItem("role") || "",
      anchorEl: null,
      logOut: false,
      loadingNotifications: false,
      notifications: {},
      notificationCount: 0,
      update: 0,
      notificationsAnchorEl: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  componentDidMount = async (): Promise<void> => {
    await this.getToken();
    this.getSettings();
    this.getUserRoles();
    this.getAccountMenu();
    this.subscribeToStatusUpdates();
  }

  async componentWillUnmount() {
    this.unsubscribeFromStatusUpdates();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!response) return;
    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler(response);
    if (error) runEngine.debugLog("API Error", error);
  };

  getMessageHandler(callId: string, response: any) {
    const messageHandlers: Record<string, any> = {
      [this.getNotificationsCallId]: this.handleReceivedNotifications,
      [this.markAsReadCallId]: this.getNotifications,
      [this.markAllAsReadCallId]: this.getNotifications,
    };

    return messageHandlers[callId];
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");

    if (token) this.setState({ token });
  };

  getSettings = () =>
    this.settings = getSessionStorage("restaurant_settings");

  getUserRoles = () =>
    this.roles = JSON.parse(localStorage.getItem("roles") || "[]");

  getAccountMenu = () =>
    this.accountMenu = [
      {
        icon: <UserIcon width={24} height={24} />,
        label: "My Profile",
        onClick: () => this.handleNavigation("PosProfile"),
      },
      {
        icon: <img src={supportIcon} alt="supportIcon" />,
        label: "Contact & Support",
        onClick: () => this.handleNavigation("PosSupport"),
      },
      {
        icon: <img src={settingsIcon} alt="settingsIcon" />,
        label: "Settings",
        onClick: () => this.handleNavigation("PosSettings"),
      },
      {
        icon: <img src={logoutIcon} alt="logoutIcon" />,
        label: "Log Out",
        onClick: this.logout,
      },
    ]

  subscribeToStatusUpdates = () => {
    const { token } = this.state;
    this.cableSubscription = webSocketFunction(token)
    headerCountConfig({
      cableSubscription: this.cableSubscription,
      setCartCount: (_cartCount: number) => {},
      setNotificationCount: (notificationCount: number) => this.setState({ notificationCount }),
      forceUpdate: this.forceUpdate,
    })
  }

  forceUpdate() {
    this.setState({ update: this.state.update + 1 });
  }

  unsubscribeFromStatusUpdates() {
    if(this.cableSubscription){
      actionCableConfigCloseConnection(this.cableSubscription)
    }
  }

  getNotifications = () => {
    const getDataMsg = createMessage(
      "GET",
      configJSON.getNotificationsEndpoint,
      {
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      }
    );

    this.getNotificationsCallId = getDataMsg.messageId;
    this.setState({ loadingNotifications: true, notifications: {} });
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleReceivedNotifications = (response: any) => {
    if (response?.errors)
      this.setState({
        notifications: {}
      });

    if (response?.data)
      this.setState({
        notifications: this.formatNotifications(
          response.data.filter((notification: Notification) => !notification.attributes.is_read)
        ),
      });

    this.setState({ loadingNotifications: false });
  };


  formatNotifications(data: any): {[key: string]: NotificationAttributes[]} {
    let notifications: {[key: string]: NotificationAttributes[]} = {};

    if (data) {
      data.forEach((notification: Notification) => {
        let date = this.convertDate(notification.attributes.created_at);

        if (notifications[date]) {
          notifications[date].push(notification.attributes);
        } else {
          notifications[date] = [notification.attributes];
        }

        notifications[date].sort((a, b) => {
          if (a.is_read !== b.is_read) {
            return a.is_read ? 1 : -1;
          }

          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime();
        });
      });
    }

    return this.sortNotificationTimeline(notifications);
  }

  sortNotificationTimeline(notifications: { [key: string]: NotificationAttributes[] }): { [key: string]: NotificationAttributes[] } {
    const todayNotifications: { [key: string]: NotificationAttributes[] } = notifications["Today"] ? { Today: notifications["Today"] } : {};
    const yesterdayNotifications: { [key: string]: NotificationAttributes[] } = notifications["Yesterday"] ? { Yesterday: notifications["Yesterday"] } : {};
    const sortedNotifications: { [key: string]: NotificationAttributes[] } = {};

    Object.keys(notifications)
      .filter((key) => key !== "Today" && key !== "Yesterday")
      .sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
      })
      .forEach((key) => {
      sortedNotifications[key] = notifications[key];
      });

    return {...todayNotifications, ...yesterdayNotifications, ...sortedNotifications};
  }

  handleNavigation = (component: string) => {
    try {
      this.props.navigation?.navigate(component);
    } catch (error) {
      console.error("Failed to navigate: ", error);
    }
  }

  logout = () =>
    this.setState({ logOut: true });

  handleRoleChange = (event: SelectChangeEvent<string>): void => {
    this.setState({ selectedRole: event.target.value }, () => {
      sessionStorage.setItem("role", event.target.value);
      location.href = event.target.value.split(" ").join("");
    });
  }

  handleClose = () =>
    this.setState({ anchorEl: null });

  openNotifications = (event: React.MouseEvent<HTMLButtonElement>) =>
    this.setState({ notificationsAnchorEl: event.currentTarget }, this.getNotifications);

  closeNotifications = () =>
    this.setState({ notificationsAnchorEl: null });

  markAsRead = (id: number) => {
    const markAsReadMsg = createMessage(
      "PATCH",
      `${configJSON.getNotificationsEndpoint}/${id}/mark_as_read`
    )

    this.markAsReadCallId = markAsReadMsg.messageId;
    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  markAllAsRead = () => {
    const markAllAsReadMsg = createMessage(
      "PATCH",
      `${configJSON.getNotificationsEndpoint}/mark_all_as_read`
    );

    this.markAllAsReadCallId = markAllAsReadMsg.messageId;
    runEngine.sendMessage(markAllAsReadMsg.id, markAllAsReadMsg);
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  convertDate(dateString: string): string {
    const date = moment(dateString);
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");

    if (date.isSame(today, "day")) {
        return "Today";
    } else if (date.isSame(yesterday, "day")) {
        return "Yesterday";
    } else {
        return date.format("ddd, MMM D");
    }
  }

  handleViewAll = () => {
    this.props.navigation?.navigate("POSNotifications");
    this.closeNotifications();
  };
};
