import { RealtimeNotification } from "../../../../blocks/dashboard/src/DashboardController.web";
import { getSessionStorage } from "../../utils";
import { RealtimeOrder } from "../../../../blocks/dashboard/src/DashboardController.web";

const configJSON = require("./config.js");

interface Props {
  subscription: WebSocket | null;
  addNotification: (data: RealtimeOrder) => void;
};

export const notificationConfig = ({ subscription, addNotification }: Props) => {
  const userDetail = getSessionStorage("userDetail");
  const id = userDetail?.id;

  if (subscription) {
    const socket = subscription;

    socket.onopen = () => {
      const message = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockAutomaticreminders::OrderNotificationsChannel",
          store_manager_id: id
        }),
      };

      socket.send(JSON.stringify(message));
    };

    socket.onmessage = (event) => {
      const response = event.data;
      const { message, type } = JSON.parse(response);

      if (type === "ping") return;
      if (message && message.data) addNotification(message.data);
    };

    socket.onerror = (error) => {
      console.log("Notification WebSocket Error: ", error);
    }
  };
};
