import React from "react";

// Customizable Area Start
import {
  StyleSheet,
} from "react-native";

import "./SalesReportingStyles.css"

import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import SalesReportingController, {
  Order,
  Props,
  configJSON,
} from "./SalesReportingController";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { noResulFoundImage } from "./assets";

export default class SalesReporting extends SalesReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  tableHeader = () => {
    return (
      <Grid container style={{ textAlign: 'center', borderBottom: "1px solid #909090", backgroundColor: "#F2F2F2", borderRadius: "4px", padding: "4px 8px" }}>
        {[
          "Staff ID",
          "Rider Name",
          "Cash",
          "Cards",
          "Apple Pay",
          "Google Pay",
          "Total Orders",
          "Total Amount",
          "Total Distance",
        ].map((text, index) => (
          <Grid item lg md sm xs key={index}>
            <div className="orderManagementTableCell">{text}</div>
          </Grid>
        ))}
      </Grid>
    )
  }

  tableHeaderAccordian = () => {
    return (
      <Grid container style={{ textAlign: 'center', borderBottom: "1px solid #909090", backgroundColor: "#FCE8E9", borderRadius: "4px", padding: "4px 8px" }}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">{"Order ID"}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{"Customer Name"}</div>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <div className="orderManagementTableCell">{"Address"}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{"Payment Method"}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{"Total amount"}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{"Total Distance"}</div>
        </Grid>
      </Grid>
    )
  }


  tableBodyAccordian = (orders:Order[]) => {
    return (
      <>
      {orders.map((order,index)=>
        <Grid key={index} container style={{ textAlign: 'center', borderBottom: "1px solid #909090", backgroundColor: "#FFFFFF", borderRadius: "4px", padding: "4px 8px" }}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className="orderManagementTableCell">#{order.order_id}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{order.customer_name}</div>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <div className="orderManagementTableCell">{order.address}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{order.payment_method.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">£{order.total_amount}</div>
        </Grid>
        <Grid item lg md sm xs>
          <div className="orderManagementTableCell">{order.distance?order.distance:"0 Km"}</div>
        </Grid>
      </Grid>
      )}
      </>
    )
  }

  tableContainerBody=()=>{
    return(
  <>
  {this.state.riderReports.map((riderReport,index)=>(
     <Accordion
     style={{ borderRadius: "8px",backgroundColor:this.state.expanded == `panel${index}`?"#F4F4F4BF":"#fff", fontWeight: "500", fontSize: "16px"}}
     expanded={this.state.expanded == `panel${index}`}
     onChange={this.handleExpand(`panel${index}`)} >
     <AccordionSummary
       aria-controls="accordian_spicelevel_summary"
       id="accordian_TableRider_summary"
       style={{
         fontFamily: "Barmeno",
         fontSize: "16px",
         fontWeight: "500",
         height:"50px",
         width:"100%"
       }}>
       <Grid container style={{backgroundColor:"#ffffff", alignItems:"center"}}>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">{riderReport.staff_id}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">{riderReport.rider_name}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">£{riderReport.cash}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">£{riderReport.cards}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">£{riderReport.apple_pay}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">£{riderReport.google_pay}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">{riderReport.total_orders}</div>
         </Grid>
         <Grid item lg md sm xs>
           <div className="orderManagementTableCell">£{riderReport.total_amount}</div>
         </Grid>
         <Grid item lg md sm xs style={{display:"flex",flexDirection:"row",gap:"24px", alignItems:"center", justifyContent:"end"}}>
           <div className="orderManagementTableCell">{riderReport.total_distance?riderReport.total_distance:"0 Km"}</div>  <ExpandMoreIcon />
         </Grid>
       </Grid>
     </AccordionSummary>
     {riderReport.orders.length>0?
         <Grid container >
           {this.tableHeaderAccordian()}
           {this.tableBodyAccordian(riderReport.orders)}
         </Grid>:
         <></>
  }
   </Accordion>
  ))}
      </>
    )
  }

  dialoguePopupContainer = () => {
    return (
      <Dialog open={this.props.riderReportPopup} onClose={this.cancelExport} PaperProps={{
        style: {
          borderRadius: '16px',
        }
      }} >
        <DialogTitle style={{ borderBottom: "1px solid #D4D4D4" }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" padding="0px 16px 0px 32px">
            <div style={{ fontFamily: "Barmeno", fontSize: "16px", fontWeight: "bolder" }}>Filters</div>
            <IconButton onClick={this.cancelExport}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" padding="24px 40px" style={{ gap: "24px", fontFamily: "Barmeno", fontWeight: "bolder" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              Rider report
              <div className='filter' style={{ flexDirection: "column" }}>
                {this.exportOptions.map((item, key) => (
                  <label key={key} style={{ display: "flex", flexDirection: 'row', alignItems: "center", gap: "8px", color: '#747474' }}>
                    <input style={{ display: "none" }} onChange={(event) => this.changeExportOptions(item.value)} className='exportRadio' type="radio" name="allergen" checked={this.state.exportOption === item.value} value={item.value} />
                    <span
                      style={{
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        boxShadow:
                          'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
                        backgroundColor: this.state.exportOption === item.value ? '#2C6F37' : '#f5f8fa',
                        backgroundImage:
                          this.state.exportOption === item.value
                            ? 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))'
                            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {this.state.exportOption === item.value && (
                        <span
                          style={{
                            display: 'block',
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: '#fff',
                          }}
                        />
                      )}
                    </span>
                    {item.name}
                  </label>
                ))}
              </div>
            </div>

          </Box>
        </DialogContent>
                <DialogActions style={{boxShadow: '0px -4px 15px 0px #4848481F', justifyContent:"space-evenly", padding:'16px'}}>
          <Button id='clear' onClick={this.cancelExport}  style={{width: '220px',height: '56px',border: '1px solid #E11B22',color: '#E11B22'}} >
          Cancel
          </Button>
          <Button id='apply' disabled={!this.state.exportOption} onClick={this.exportReporButtonClicked}  style={{width: '220px',height: '56px',backgroundColor:!this.state.exportOption?'#BFBFBF':'#E11B22',color:!this.state.exportOption?"#909090": '#fff'}}>EXPORT</Button>
        </DialogActions>
      </Dialog>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <div>
        
        {this.state.message?
        <div style={{display:"flex",flexDirection:"column",gap:"24px", alignItems:"center"}}>
          <img src={noResulFoundImage} style={{width:"350px",height:"350px"}} />
          <div style={{fontWeight:700,fontSize:"32px"}}>No Result Found</div>
          <div  style={{fontWeight:500,fontSize:"18px",color:"#747474"}}>{this.state.message}</div>
        </div>:<> 
        {this.tableHeader()}
        {this.tableContainerBody()}
        </>
  }
        {this.dialoguePopupContainer()}
        <CustomLoader loading={this.state.loading}/>
      </div>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "75%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: 0,
  },
  bgMobileInput: {
    flex: 1,
  },
  showHide: {
    alignSelf: "center",
  },
  imgShowhide: { height: 30, width: 30 },
  selectPeriodContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    height: 50,
    alignItems: "center",
  },
  overviewContainer: {
    flexDirection: "row",
    width: "100%",
    height: 70,
    justifyContent: "space-around",
    alignItems: "center",
  },
  overviewSubContainer: {
    alignItems: "center",
  },
  overviewText: {
    fontWeight: "bold",
    fontSize: 20,
  },
  frameSelectContainer: {
    backgroundColor: "#d3d3d3",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 100,
    alignItems: "center",
  },
});
// Customizable Area End
