import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { wingoRewards, round2, round1, chickenWhole,score2, score1 } from "./assets";
import './Loyalty.css';

import {
  ContentResponseData,
  RewardsPointsData,
} from "./LoyaltySystemController";
import ImageComponent from "../../../components/src/ImageComponent";
import { Skeleton } from '@material-ui/lab';
import { noItemsInCartPopup } from "../../../components/src/Popups/NoItemsInCartPopup";

// Customizable Area End

import LoyaltySystemController, {
  Props,
  configJSON,
} from "./LoyaltySystemController";

export default class LoyaltySystem extends LoyaltySystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  noRewardComponent = () => {
    return (
      <div className="wingo_no_reward_container">
        <div className="no_points_signed_in_user">
          <div style={{ fontSize: "36px", fontWeight: 700 }}>OOPS! No points to redeem at this moment.</div>
          <div style={{ fontSize: "36px", fontWeight: 700 }}>Start ordering from Wingo’s and collect your points to redeem.</div>
          <button onClick={this.goToMenu} className="join_now_button">START TO ORDER</button>
        </div>
        <ImageComponent imageUrl={chickenWhole} className="chicken_whole" alt="chicken" />
      </div>
    )
  }

  rewardPointComponent = (rewardPoint: RewardsPointsData) => {
    const { minRewardMessage, rewardPointInput, rewardPointConverted, diableButton,minimumPoints } = this.state
    return (
      <div className="wingo_rewardpoint_container" >
        <div className="rewardPointDisplay">
          <div>
            <ImageComponent imageUrl={score1} alt="reward_point" style={{width:"100%"}}/>
            <div className="wingo_points_container">
              <div className="wingo_points_label">Total Wingo’s Reward Points</div>
              <div className="wingo_points_label" style={{ fontSize: "3.33vw", fontWeight: 700 }}>{rewardPoint.total_points}</div>
            </div>
          </div>
          <div>
            <ImageComponent imageUrl={score2} alt="worth_amount" style={{width:"100%"}} />
            <div className="wingo_points_container">
              <div className="wingo_points_label" style={{ color: "white" }}>Points Worth</div>
              <div className="wingo_points_label" style={{ fontSize: "3.33vw", fontWeight: 700, color: "white" }}>£ {rewardPoint.points_worth}</div>
            </div>
          </div>
        </div>
        <div className="rewards_guideline_container">
          <div className="spend_guideline">
            You have to spend a minimum of {minimumPoints} Wingo’s Reward Points at a time.
          </div>
          <div className="redeem_container">
            <div className="wingo_reward_points_container">
              <div className="input_for_wingo_reward">
                <label htmlFor="wingo_rewards" className="enter_wingo_reward_points_label" >Enter Wingo’s Reward Points to Redeem</label>
                <div>
                <input name="wingo_rewards" style={{border:`${minRewardMessage?'1px solid #E42328': '1px solid #BFBFBF'}`}} className="enter_wingo_reward_points_field" type="text" onChange={this.onChangeRewardPointInput} value={rewardPointInput} />
                <div className="errors_rewards">{minRewardMessage}</div></div>
              </div>
              <div className="point_worth_detail"> Points Worth - &nbsp; <span style={{ fontFamily: "Montserrat" }}>{`£ ${rewardPointConverted}`}</span></div>
            </div>
            <button onClick={this.noItemInCart} disabled={diableButton} className={diableButton?"join_now_button_disabled":"join_now_button"} style={{ width: "300px" }}>REDEEM NOW</button>
          </div>
        </div>
      </div>
    )
  }

  nonSigneInUserReward = () => {
    return (
      <div>
        <div className="non_signed_in_user">
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>Join now by Signing In to collect and redeem your points.</div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>Just a click and it’s done!</div>
          </div>
          <button onClick={this.goToLogin} className="join_now_button">JOIN NOW</button>
        </div>
        <ImageComponent imageUrl={chickenWhole} className="chicken_whole" alt="chicken" />
      </div>
    )
  }

  imageSkeleton = () => {
    return (
      <div className="skeleton_container">
        <Skeleton style={{ width: "300px", height: "90px" }} />
        <div className="skeleton-image-container">
          <Skeleton style={{ width: "280px", height: "280px" }} variant="circle" />
          <ImageComponent style={{ height: "150px" }} imageUrl={round1} alt="arrow_1" loading="lazy" />
          <Skeleton style={{ width: "280px", height: "280px" }} variant="circle" />
          <ImageComponent style={{ height: "150px" }} imageUrl={round2} alt="arrow_2" loading="lazy" />
          <Skeleton style={{ width: "280px", height: "280px" }} variant="circle" />
        </div>
      </div>
    )
  }

  howItWorks = (contentData: ContentResponseData[]) => {
    return (
      <div className="how_it_works_reward_container">
        <div className="how_it_works_reward_container_heading" >
          {contentData[0].attributes.title}</div>
        <div className="how_it_works_reward_container_images_container">
          <ImageComponent className="image_rewards" imageUrl={contentData[0].attributes.image1.url} alt="Reward_1" loading="lazy" />
          <ImageComponent style={{width:"100%"}} imageUrl={round1} alt="arrow_1" loading="lazy" />
          <ImageComponent className="image_rewards" imageUrl={contentData[0].attributes.image2.url} alt="Reward_2" loading="lazy" />
          <ImageComponent style={{width:"100%"}}  imageUrl={round2} alt="arrow_2" loading="lazy" />
          <ImageComponent className="image_rewards" imageUrl={contentData[0].attributes.image3.url} alt="Reward_3" loading="lazy" />
        </div>
      </div>
    )
  }

  howToRedeemSkeleton = (skeletonArray: number[]) => {
    return (
      <div className="skeleton_container_content">
        <Skeleton style={{ width: "300px", height: "90px" }} />
        <div className="skeleton-paragraph-container">
          {skeletonArray.map((value, key) => (
            <Typography style={{ width: "100%", height: "22px" }} component="div" key={key} variant={'body1'}>
              <Skeleton style={{ width: "100%", height: "28px" }} />
            </Typography>
          ))}
        </div>
        <div className="skeleton-paragraph-container">
          {skeletonArray.map((value, key) => (
            <Typography style={{ width: "100%", height: "22px" }} component="div" key={key} variant={'body1'}>
              <Skeleton style={{ width: "100%", height: "28px" }} />
            </Typography>
          ))}
        </div>
      </div>
    )
  }

  howToRedeemContent = (contentData: ContentResponseData[]) => {
    return (
      <div className="how_i_redeem_reward_container">
        <div className="how_it_works_reward_container_heading">{contentData[1].attributes.title}</div>
        <span className="how_i_redeem_reward_content" dangerouslySetInnerHTML={{ __html: `${contentData[1].attributes.description}` }} />
      </div>
    )
  }

  rewardOrNoReward = () => {
    if(this.state.rewardPoint.total_points !== 0) {
      return this.rewardPointComponent(this.state.rewardPoint)
    } else {
      return this.noRewardComponent()
    }
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div className="reward_main_page_container" >
        <div style={{ position: "relative", width: "100%" }}>
          <ImageComponent imageUrl={wingoRewards} alt="reward_header" className="header_image_rewards" />
        </div>
        <div className="allContentContainerRewards" id="loyalty_system">
        <div id="join_now" data-scroll-test-id="join_now" style={{ width: '100%' }}>{this.state.isLoggedIn ?
          this.rewardOrNoReward() : this.nonSigneInUserReward()}</div> 

          <div id="how_it_works" data-scroll-test-id="how_it_works">{this.state.contentData.length === 2 ? this.howItWorks(this.state.contentData) : this.imageSkeleton()}</div>

          {this.state.contentData.length === 2 ? this.howToRedeemContent(this.state.contentData) : this.howToRedeemSkeleton(this.state.skeletonArray)}
        </div>
        {this.state.noItem && noItemsInCartPopup(this.state.noItem, this.goToMenu, this.closeNoItemInCart)}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
