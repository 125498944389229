import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SpiceLevelTypes } from "../../ordermanagement/src/types";
import { SpiceLevelType } from "../../catalogue/src/MenuPageController";
import { dietType } from "../../catalogue/src/MenuData";
import { debounce, throttle } from "lodash";
import { AllergenInItem, CartDetails, CartItem, CartItemAttributes, DealProduct, OrderDetails } from "./CfposPrductDescriptionController";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { createMessage } from "../../../components/src/utils";

export interface StoreHours{
  open: string;
  close: string;
};

export interface AllergenData {
  id: number,
  name: string,
  created_at: string,
  updated_at: string,
  description: string
}

export interface RestaurantData{
  id: number;
  title: string;
  location: string;
  contact: string;
  open_time: string;
  close_time: string;
  postcode: string;
  created_at: string;
  updated_at: string;
  order_type: string[];
  tax_reg_no: string;
  estimated_delivery_time: number;
  estimated_collection_time: number;
  latitude: string;
  longitude: string;
  radius: number;
  time_zone: string;
  time_format: string;
  store_hours: {
    Friday: StoreHours;
    Monday: StoreHours;
    Sunday: StoreHours;
    Tuesday: StoreHours;
    Saturday: StoreHours;
    Thursday: StoreHours;
    Wednesday: StoreHours;
  };
  supports_delivery: boolean;
  supports_collection: boolean;
  accepts_card_payment: boolean;
  accepts_cash_payment: boolean;
  primary_phone: string;
  secondary_phone: string;
  country_code: string;
  automatic_printing_instore_order: boolean;
  automatic_printing_online_order: boolean;
  double_printing_all_order: boolean;
  secondary_phone_country_code: string;
};

export interface SubSubCategory{
  id: number;
  sub_category_id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  newly_launched: boolean;
  serves: string;
  spice_level:string;
  product_type: string;
  enable: boolean;
};

export interface CustomizationData {
  id: number, food_category_id: number, title: string, with_price: boolean, mandatory:boolean, multiple:boolean, quantity:number
}

export interface ItemCustomization{
  data: {
    id: string;
    type: string;
    attributes: {
      id: number,
      title:string;
      priority: number,
      customizations: CustomizationData[]
    }
  }
}

export interface CatalogueDataUnSorted {
  id: string;
  type: string;
  attributes: CatalogueDataUnSortedAttributes
}

export interface CatalogueDataUnSortedAttributes{
  restaurant: RestaurantData;
  sub_sub_category: SubSubCategory;
  images: {
    content_type
    : string;
    file_name
    : string;
    url
    : string
  }
  availability: string;
  stock_qty: number;
  on_its_own_price: number;
  reg_sides_price: number | null;
  reg_sides_quantity:number|null;
  allergens: AllergenData[];
  sides_details: { id: number, title: string } | null;
  sub_category: string;
  item_customization: ItemCustomization;
  priority: number;
  deal_products:DealProduct[] | null;
  favourited?: boolean;
  food_category:string;
}

export interface CatalogueDataSorted{
  sub_category: string;
  sub_category_id: number;
  priority:number;
  data: CatalogueDataUnSorted[];
}
type Timer = ReturnType<typeof setTimeout>;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  locationSearch?:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  menuDealsOption:number;
  categoryOption:number;
  catalogueCategoryData:CatalogueDataSorted[];
  filterPopupOpen:boolean;
  allergenList: string[];
  spiceLevelList: SpiceLevelTypes[];
  dietType: SpiceLevelType[];
  dietSelectedPopup:string | null;
  allergenSelected: string[];
  allergenSelectedPopup: string[];
  spiceLevelSelected: string | null;
  spiceLevelSelectedPopup: string | null;
  dietSelected: string | null;
  dietSelectedName: string;
  spiceLevelSelecteName: string;
  searchParameter:string;
  searchValue:string;
  loading:boolean;
  singleCatalogueData:CatalogueDataUnSorted | null;
  orderDescriptionPopupOpen:boolean;
  cartDetails:CartDetails;
  orderDetails:OrderDetails|null;
  emptyCartPopup:boolean;
  cartItemId:number;
  exclude_allergen: string;
  exclude_allergen_word_count: number;
  exclude_allergen_word_count_error: string;
  allergenPopup:boolean;
  allergenOnItem:AllergenInItem[];
  catalogueDataUnfiltered: CatalogueDataUnSorted[];
  cartItemToEdit?:CartItem;
  dealDataUnfiltered:CatalogueDataUnSorted[];
  DealCategoryData:CatalogueDataSorted[];
  applyDiscountModal: {
    open: boolean;
    item: CartItem | null;
  };
  discountTab: number;
  discount: string;
  discountReason: string;
  discountReasons: { id: number; reason: string; default: boolean; }[];
  totalPrice: string | null;
  discountedPrice: string | null;
  discountError: string | null;
  confirmQuantityChangeModal: {
    open: boolean;
    item: CartItem | null;
    action: string | null;
  };
  account_id: string | null;
  // Customizable Area End
  }

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfposordermanagementfeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatalogueItemApiCallId:string=""
  getDealCatalogueApiCallId:string=""
  getSpiceLevelApiCallId:string=""
  getAllergensApiCallId:string=""
  getAllCartItemsApiCallId:string;
  categoryRefs:(HTMLDivElement | null)[]
  catalogueItemContainerRef: HTMLDivElement | null
  token:string;
  debounceTimer: Timer| null = null;
  updateCartItemCount:string;
  deleteCartItem:string;
  cancelOrderApiCallId:string;
  addAllergensApiCallId:string;
  calculateDiscountCallId: string = "";
  discountReasonsCallId: string = "";
  applyDiscountCallId: string = "";
  removeDiscountCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.categoryRefs=[]
    this.catalogueItemContainerRef = null
    this.getAllCartItemsApiCallId=""
    this.token=""
    this.debounceTimer= null
    this.updateCartItemCount=""
    this.deleteCartItem=""
    this.cancelOrderApiCallId=""
    this.addAllergensApiCallId=""
    this.getDealCatalogueApiCallId=""


    this.calculateDiscount = debounce(this.calculateDiscount.bind(this), 500);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      menuDealsOption:1,
      categoryOption:0,
      catalogueCategoryData:[],
      filterPopupOpen:false,
      allergenList: [],
      spiceLevelList:[],
      dietType:dietType,
      dietSelectedPopup: null,
      allergenSelected:[],
      allergenSelectedPopup:[],
      spiceLevelSelected:  null,
      spiceLevelSelectedPopup:  null,
      dietSelected: null,
      dietSelectedName: "",
      spiceLevelSelecteName: "",
      searchParameter:"",
      searchValue:"",
      loading:false,
      singleCatalogueData:null,
      orderDescriptionPopupOpen:false,
      cartDetails:{
        data:[],
        total_price:"0",
      },
      orderDetails:null,
      emptyCartPopup:false,
      cartItemId:0,
      exclude_allergen: "",
      exclude_allergen_word_count: 0,
      exclude_allergen_word_count_error: "",
      allergenPopup:false,
      allergenOnItem:[],
      catalogueDataUnfiltered:[],
      cartItemToEdit:undefined,
      dealDataUnfiltered:[],
      DealCategoryData:[],
      applyDiscountModal:{
        open: false,
        item: null,
      },
      discountTab: 0,
      discount: "",
      discountReason: "",
      discountReasons: [],
      totalPrice: null,
      discountedPrice: null,
      discountError: null,
      confirmQuantityChangeModal: {
        open: false,
        item: null,
        action: null,
      },
      account_id: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))){
      let navigationPayload = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      this.props.navigation.setParam("orderType",navigationPayload.order_type,"custId")
      this.props.navigation.setParam("custId",navigationPayload.cust_id)
      this.getAllCartData(navigationPayload.cust_id)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getCatalogueItemApiCallId){
        this.setCatalogueData(responseJson)
      }if(apiRequestCallId === this.getDealCatalogueApiCallId){
        this.setDealData(responseJson)
      }
      if(apiRequestCallId === this.getSpiceLevelApiCallId){
        this.setSpiceLevel(responseJson)
      }if(apiRequestCallId === this.getAllergensApiCallId){
        this.setAllergens(responseJson)
      }if(apiRequestCallId === this.getAllCartItemsApiCallId){
        this.setCartItemData(responseJson)
      }if(apiRequestCallId === this.updateCartItemCount){
        this.setItemCountUpdate(responseJson)
      } if(apiRequestCallId === this.deleteCartItem){
        this.updateDeletedCart(responseJson)
      } if(apiRequestCallId === this.cancelOrderApiCallId){
        this.setCancelOrder(responseJson)
      } if(apiRequestCallId === this.addAllergensApiCallId){
        this.updateAllergeData(responseJson)
      } if(apiRequestCallId === this.calculateDiscountCallId){
        this.handleDiscountCalculation(responseJson);
      } if(apiRequestCallId === this.discountReasonsCallId){
        this.handleDiscountReasons(responseJson);
      } if (apiRequestCallId === this.applyDiscountCallId) {
        this.handleApplyDiscount(responseJson);
      } if (apiRequestCallId === this.removeDiscountCallId) {
        this.handleRemoveDiscount(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount=async()=>{
    this.token = localStorage.getItem("authToken") ||""
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get('custId');
    if(this.token && account_id){
      this.setState({ account_id });
      this.getAllCartData(account_id)
    }
    this.getDiscountReasons();
    this.getCatalogueItems(0)
      this.getAllergens()
      this.getSpiceLevel()
      this.getCatalogueItems(1)
    if (this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.addEventListener('scroll', throttle(this.handleScroll, 100));
    }
  }
  componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=> {
    const {spiceLevelSelected,allergenSelected,dietSelected,searchParameter} = this.state
    if (
      prevState.spiceLevelSelected!== spiceLevelSelected ||
      prevState.allergenSelected !== allergenSelected ||
      prevState.dietSelected !== dietSelected ||
      prevState.searchParameter !== searchParameter ||
      prevState.menuDealsOption !== this.state.menuDealsOption
    ) {
      this.getCatalogueItems()
    }

    if (
      prevState.discount !== this.state.discount ||
      prevState.discountTab !== this.state.discountTab
    ) this.calculateDiscount();
  }

  handleDiscountCalculation = (response: any) => {
    this.setState({
      totalPrice: response.total_amount,
      discountedPrice: response.discounted_price,
    });
  };

  handleApplyDiscount = (response: any) => {
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get('custId');

    if (response.message) {
      this.resetDiscountPopup();
      this.getAllCartData(account_id ?? "");
    };

    if (response?.error) {
      this.setState({ discountError: response?.error });
    };
  };

  handleRemoveDiscount = (response: any) => {
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get('custId');

    if (response.message) {
      this.resetDiscountPopup();
      this.getAllCartData(account_id ?? "");
    };
  };

  openApplyDiscountModal=(item: CartItem)=>{
    this.setState({
      applyDiscountModal: { open:true, item },
      discount: item.attributes.coupon_details?.discount_value ?? "",
      discountReason: item.attributes.coupon_details?.reason ?? "",
      discountTab: item.attributes.coupon_details?.discount_type === "cash" ? 0 : 1,
      totalPrice: item.attributes.total_item_price.toString(),
      discountedPrice: item.attributes.total_item_price_after_discount?.toString() ?? "0"
    });
  };

  closeApplyDiscountModal=()=>{
    this.setState({applyDiscountModal:{ open:false,item:null }, discountError: null})
  };

  handeDiscountTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ discountTab: newValue, discount: "" });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;

    if (name === "discount" && /[a-zA-Z]/.test(value as string)) return;

    this.setState((prevState => ({ ...prevState, [name]: value })));
  };

  resetDiscountPopup = () => {
    this.setState({ discount: "", discountReason: "" });
    this.closeApplyDiscountModal();
  };

  getCalcDiscountParams = (itemId?: string) => {
    const { discount, discountReason, discountReasons, discountTab, applyDiscountModal } = this.state;
    const discount_type = discountTab === 1 ? "percentage" : "cash";
    const reason_id = discountReasons.filter((reason) => reason.reason === discountReason)[0]?.id;
    const { item } = applyDiscountModal;

    return `?cart_item_id=${itemId ?? item?.id}&discount=${discount}&discount_type=${discount_type}&reason_id=${reason_id}`;
  };

  calculateDiscount = () => {
    if (!this.state.applyDiscountModal.item?.id) return;

    const getDataMsg = createMessage(
      "GET",
      `${configJSON.calculateDiscountEndpoint}${this.getCalcDiscountParams()}`,
      {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token,
      }
    );

    this.calculateDiscountCallId = getDataMsg.messageId;

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getDiscountReasons = () => {
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.discountReasonEndpoint}`,
      {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token,
      }
    );

    this.discountReasonsCallId = getDataMsg.messageId;

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleDiscountReasons = (response: any) => {
    if (response) {
      this.setState({ discountReasons: response, discountReason: response.find((reason: { id: number; reason: string; default: boolean }) => reason?.default) ?? "" })
    }
  };

  applyDiscount = () => {
    if (this.state.discount === "" || this.state.discount === "0") {
      this.setState({ discountError: "Field can't be empty" });
      return;
    } else this.setState({ discountError: null });

    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.applyDiscountEndpoint}${this.getCalcDiscountParams()}`,
      {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token,
      }
    );

    this.applyDiscountCallId = getDataMsg.messageId;

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  removeDiscount = (itemId: string) => {
    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.removeDiscountEndpoint}?cart_item_id=${itemId}`,
      {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token,
      }
    );

    this.removeDiscountCallId = getDataMsg.messageId;

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleMenuDealOpionChange=(event: React.ChangeEvent<{}>, newValue: number)=>{
    this.setState({menuDealsOption:newValue})
  }

  handleCategoryOptionChange=(event:React.ChangeEvent<{}>, newValue:number)=>{
    this.setState({categoryOption:newValue})
  }

  getCategoryOptions =()=>{
    const {DealCategoryData,catalogueCategoryData,menuDealsOption} = this.state
    const filteredData = menuDealsOption === 1 ? catalogueCategoryData : DealCategoryData
    const categoryList = filteredData.length>0 ?
    ["All",...filteredData.map(value=>
     ( value.sub_category)
    )] : []
   return categoryList
  }

  getExpectedCatalogueData = (input: CatalogueDataUnSorted[]): CatalogueDataSorted[] => {
    const result = Object.values(
      input.reduce((acc: { [key: number]: CatalogueDataSorted }, item: CatalogueDataUnSorted) => {
        const subSubCategory = item.attributes.sub_sub_category
        if (!acc[subSubCategory.sub_category_id]) {
          acc[subSubCategory.sub_category_id] = {
            sub_category: item.attributes.sub_category,
            sub_category_id: subSubCategory.sub_category_id,
            priority: item.attributes.priority,
            data: [],
          };
        }
        acc[subSubCategory.sub_category_id].data.push(item);
        return acc;
      }, {})
    ).sort((a, b) => a.priority - b.priority);
    return result
  }

  setDealData =(responseJson:{data?:CatalogueDataUnSorted[],errors?:any}) =>{
    if(responseJson.data){
        this.setState({dealDataUnfiltered:responseJson.data,DealCategoryData:this.getExpectedCatalogueData(responseJson.data),loading:false,categoryOption:0})
    }
    if(responseJson.errors){
        if(responseJson.errors[0].includes("Token")){
          this.showAlert("token","token")
          this.setState({loading:false})
        }
    }
  }

  setCatalogueData=(responseJson:{data?:CatalogueDataUnSorted[],errors?:any})=>{
    if(responseJson.data){
        this.setState({catalogueDataUnfiltered:responseJson.data,catalogueCategoryData:this.getExpectedCatalogueData(responseJson.data),loading:false,categoryOption:0})
    }
    if(responseJson.errors){
        if(responseJson.errors[0].includes("Token")){
          this.showAlert("token","token")
          this.setState({loading:false})
        }
    }
  }

  getCatalogueItems =(data?:number) =>{
    const token = localStorage.getItem("authToken")
    const restaurant_id = localStorage.getItem("restaurantId")
    const diet = this.state.dietSelected
    const spiceLevel =this.state.spiceLevelSelected
    const allergen:string[] = this.state.allergenSelected
    const search =this.state.searchParameter
    const menuDealsOption = data === undefined ? this.state.menuDealsOption : data

    if(token){
      menuDealsOption?this.getCatalogueMenu(token,restaurant_id,diet,spiceLevel,allergen,search) : this.getCatalogueDeal(token,restaurant_id,diet,spiceLevel,allergen,search)
      this.setState({loading:true})

    }
    else{
      this.showAlert("token","token")
    }
  }

  getCatalogueMenu=(token:string,restaurant_id:any,diet:string|null,spiceLevel:string|null,allergen:string[],search:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCatalogueItemApiCallId = requestMessage.messageId;

    const params= {
      product_type: diet || undefined,
      spice_level: spiceLevel || undefined,
      allergens: allergen.length > 0 ? allergen : undefined,
      query: search || undefined,
      restaurant_id: restaurant_id || undefined
    }

    const queryString = this.createQueryString(params)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.menuDataEndPoint}?${queryString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCatalogueDeal=(token:string,restaurant_id:any,diet:string|null,spiceLevel:string|null,allergen:string[],search:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealCatalogueApiCallId = requestMessage.messageId;

    const params= {
      product_type: diet || undefined,
      spice_level: spiceLevel || undefined,
      allergens: allergen.length > 0 ? allergen : undefined,
      query: search || undefined,
      restaurant_id: restaurant_id || undefined
    }

    const queryString = this.createQueryString(params)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dealsDataEndPoint}?${queryString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createQueryString = (params: Record<string, any>): string => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) =>
        Array.isArray(value)
          ? `${key}=${value.join(',')}`
          : `${key}=${encodeURIComponent(value)}`
      )
      .join('&');
  };

  handleFilterPopupClose=()=>{
    this.setState({filterPopupOpen:false})
  }
  handleFilterPopupOpen=()=>{
    this.setState({filterPopupOpen:true})
  }
  getSpiceLevel=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpiceLevelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpiceLevelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllergens=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllergensApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allergensListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    this.setState({loading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSpiceChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({spiceLevelSelectedPopup: event.target.value, spiceLevelSelecteName: event.target.name})
  }

  clearSpiceChange=()=>{
    this.setState({spiceLevelSelected: '',spiceLevelSelectedPopup:null})
  }

  handleDietChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({ dietSelectedPopup: event.target.value ,dietSelectedName: event.target.name})
  }

  clearDietChange= ()=>{
    this.setState({dietSelected: '', dietSelectedPopup:null})
  }

  handleAllergenChange=(value:string):void=>{
    const isAlreadySelected = this.state.allergenSelectedPopup.includes(value);

    if(isAlreadySelected){
      const updatedAllergens = this.state.allergenSelectedPopup.filter(item => item !== value);
      this.setState({ allergenSelectedPopup: updatedAllergens });
    }else{
      this.setState({ allergenSelectedPopup: [...this.state.allergenSelectedPopup, value] });
    }
  }
  handleApplyFilters=():void=>{
    this.setState({filterPopupOpen:false,dietSelected: this.state.dietSelectedPopup, spiceLevelSelected: this.state.spiceLevelSelectedPopup,
    allergenSelected: this.state.allergenSelectedPopup})

  }
  handleClearFilters=():void=>{
    this.setState({spiceLevelSelected:null,allergenSelected:[], dietSelectedPopup:null, dietSelected:'',filterPopupOpen:false, spiceLevelSelectedPopup:null,allergenSelectedPopup:[]})
  }

  setAllergens=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({allergenList:[...responseJson.data.map((value:any)=>(value.name))],loading:false})
    }
  }
  setSpiceLevel=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({spiceLevelList:responseJson.data})
    }
  }

  handleSearchChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({searchValue: event.target.value})
  }
  getSearchResults=(event:React.KeyboardEvent<HTMLInputElement>)=>{
    if(event.key === "Enter"){
      this.setState({spiceLevelSelected:'', allergenSelected:[], dietSelected:'', searchParameter:this.state.searchValue})
    }
    if(event.key === "Backspace" && this.state.searchValue===""){
      this.setState({searchParameter:""})
    }
  }
  handleSearchClear=()=>{
    this.setState({searchParameter:"",searchValue:""})
  }

  scrollToSection = (value: number) => {
    let index:number
    if(value===0){
      index = 1
    }else{
      index = value
    }
    const categoryRef = this.categoryRefs[index];
    if (categoryRef && this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.scrollTo({
        top: categoryRef.offsetTop - this.catalogueItemContainerRef.offsetTop,
        behavior: "smooth",
      });
    }
  };

  componentWillUnmount=async()=>{
    if (this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (!this.catalogueItemContainerRef || this.categoryRefs.length === 0) {
      return;
    }

    const containerTop = this.catalogueItemContainerRef.offsetTop;
    let closestIndex = 0;
    let minDifference = Infinity;

    this.categoryRefs.forEach((ref, index) => {
      if (ref && this.catalogueItemContainerRef) {
        const offsetDifference = Math.abs(
          ref.offsetTop - containerTop - this.catalogueItemContainerRef.scrollTop
        );
        if (offsetDifference < minDifference) {
          minDifference = offsetDifference;
          closestIndex = index;
        }
      }
    });
    if (this.state.categoryOption !== closestIndex) {
      this.setState({ categoryOption: closestIndex });
    }
  };

  setIndividualItemData=(singleCatalogueData:CatalogueDataUnSorted)=>{
      this.setState({singleCatalogueData,orderDescriptionPopupOpen:true})
  }

  closeDescriptionPopup=()=>{
    this.setState({orderDescriptionPopupOpen:false,cartItemToEdit:undefined})
  }

  getAllCartData=(account_id:string)=>{
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCartItemsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCartItemsApiEndPoint}${account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setCartItemData=(responseJson:{cart_details?:CartDetails,order_details?:OrderDetails,error?:any})=>{
    if(responseJson.cart_details && responseJson.order_details){
      this.setState({orderDetails:responseJson.order_details,cartDetails:responseJson.cart_details,loading:false})
    }
  }

  buttonDisabled=()=>{
    return !this.state.cartDetails
  }

  getCartExtraDetails=(cartDetail:CartItem)=>{
    const { sides, drinks, nibbles, toppings,choose_your_type,spice_level } = cartDetail.attributes;
              const items = [
                ...sides.map((value) => `${value.quantity}x ${value.name}`),
                ...drinks.map((value) => `${value.quantity}x ${value.name}`),
                ...nibbles.map((value) => `${value.quantity}x ${value.name}`),
                ...toppings.map((value)=>`${value.quantity}x ${value.name}`)
              ];
              const formattedItems = items.filter(item => item).join(', ');
              const showItems = `${choose_your_type} | ${spice_level}${formattedItems ? ` | ${formattedItems}` : ""}`;
              return showItems
  }

  updateCountItem=(id:any,quantity:number)=> {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const httpBody = {
      account_id: this.state.orderDetails && this.state.orderDetails.data.attributes.account_id,
      cart: {
        quantity
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCartItemCount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.setState({loading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  itemInTimeout(cartItem: CartItem) {
    const { id, quantity } = cartItem.attributes;

    this.updateCountItem(id, quantity);
  }

  debounce(func: (...args: any[]) => void, delay: number) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(func, delay);
  }



  incDecItemCount(
    cartData: CartDetails,
    idx: number,
    action: string
  ) {
    const item = cartData.data[idx];
    const quantityChange = action === "addItem" ? 1 : -1;

    item.attributes.quantity += quantityChange;

    this.setState({ cartDetails: cartData });

    this.debounce(() => this.itemInTimeout(item), 50);
  }

  confirmAddRemoveCartItems = (item: CartItem, action: string | null) => {
    if (item.attributes.is_discounted) {
      this.setState({ confirmQuantityChangeModal: {
        open: true,
        item, action
      } })
    } else {
      this.closeConfirmationModal();
      this.addRemoveCartItems(item, action);
    }
  };

  closeConfirmationModal = () =>
    this.setState({ confirmQuantityChangeModal: {
      open: false,
      item: null,
      action: null,
    } })

  addRemoveCartItems(item: CartItem, action: string | null) {
    if (!action) return;

    const cartData = { ...this.state.cartDetails };
    const idx = cartData.data.findIndex(
      (cartItem) =>
        cartItem.id === item.id
    );

    const cartItem = cartData.data[idx];

    if (action === "addItem") {
      this.incDecItemCount(cartData, idx, action);
    } else if (action === "removeItem") {
      if (cartItem.attributes.quantity === 1) {
        this.setState({
          emptyCartPopup:true,
          cartItemId:cartItem.attributes.id
        });
      } else {
        this.incDecItemCount(cartData, idx, action);
      }
    }

    this.closeConfirmationModal();
  }

  setItemCountUpdate=(responseJson:any)=>{
      if(responseJson.errors){
          this.showAlert('token','token')
      } else{
        this.setState((prevState) => ({
          cartDetails: {
            ...prevState.cartDetails,total_price:responseJson.total_price, data:responseJson.data
          },loading:false
        }))
      }
  }

  deleteCartClicked=(id:number)=>{
      this.setState({cartItemId:id,emptyCartPopup:true})
  }

  deleteCart=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCartItem = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint+`${this.state.cartItemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    this.setState({loading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeEmptyCart=()=>{
    this.setState({emptyCartPopup:false,cartItemId:0})
  }

  updateDeletedCart=(responseJson:any)=>{
    if(responseJson.message && responseJson.message === "cart item deleted succefully"){
     this.setState((prevState) => ({
      cartDetails: {
        ...prevState.cartDetails,
        data: prevState.cartDetails.data.filter(item => item.attributes.id !== this.state.cartItemId), total_price: responseJson.total_price
      }, emptyCartPopup:false,loading:false
    }))
    }
  }
  updateAllergeData=(responseJson:{data?:CartItem[]})=>{
      if(responseJson.data){
        this.setState({cartDetails:{...this.state.cartDetails, data: responseJson.data},allergenPopup:false})
      }
  }
  updateCartItemsOnAdding=(cartDetails:CartDetails,orderDetails?:OrderDetails)=>{
    this.setState({cartDetails})
    if(orderDetails){
      this.setState({orderDetails})
    }
    this.closeDescriptionPopup()
  }
  cancelOrderApiCall=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelOrderApiEndPoint+`/${this.state.orderDetails && this.state.orderDetails.data.id}/discard_order`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.setState({loading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setCancelOrder=(responseJson:{message?:string})=>{
    if(responseJson.message && responseJson.message === "Order cancelled successfully"){
      this.props.navigation.navigate('PosOrderCreation')
      this.setState({loading:false})
    }
  }
  excludeAllergenChange= (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const exclude_allergen = event.target.value
      const exclude_allergen_word_count = event.target.value.length
      if (exclude_allergen_word_count > 20) {
        const exclude_allergen_word_count_error = "Cannot use more than 20 characters."
        this.setState({ exclude_allergen_word_count_error })
      } else {
        this.setState({ exclude_allergen_word_count_error: "" })
      }
      this.setState({ exclude_allergen, exclude_allergen_word_count })
  }
  openAllergenPopup=(cartAttributes:CartItemAttributes)=>{
    this.setState({allergenPopup:true,cartItemId:cartAttributes.id, allergenOnItem:cartAttributes.allergen_in_item,exclude_allergen:cartAttributes.allergen_excluded||""})
  }
  closeAllergenPopup=()=>{
    this.setState({allergenPopup:false,cartItemId:0,allergenOnItem:[],exclude_allergen:""})
  }

  addAllergens=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id,
      cart:{
        allergen_excluded:this.state.exclude_allergen
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAllergensApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint+`${this.state.cartItemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  calculateTotalCount=(data:CartItem[])=>{
    return data.reduce((total, item) => total + (item.attributes.quantity), 0);
  }
  navigateBackToNewOrder=()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationPosOrderCreationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage),{ searchData: this.state.orderDetails ? (this.state.orderDetails.data.attributes.account.attributes.full_phone_number || this.state.orderDetails.data.attributes.account.attributes.email):""})
    this.send(message)
  }

  editCartItem=(cartDetail:CartItem)=>{
    const {catalogue_id} = cartDetail.attributes
    const catalogueData = [...this.state.catalogueDataUnfiltered,...this.state.dealDataUnfiltered]
    const findCatalogueById = catalogueData.find(item => item.id === `${catalogue_id}`);
    if(findCatalogueById){
    this.setState({singleCatalogueData:findCatalogueById,orderDescriptionPopupOpen:true,cartItemToEdit:cartDetail})
    }
  }

  // Customizable Area End
}
