import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
// Customizable Area End

export const configJSON = require("./config");

export default class NewPasswordController extends ForgotPasswordController {
  // Customizable Area Start
  requestMobileOtpCallId:string = "";
  otpConfirmationCallId:string ="";
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  async receive(from: string, message: Message) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const tokenDetails = message.getData(getName(MessageEnum.NavigationPayLoadMessage))
      if (tokenDetails) {
        this.setState({ token: tokenDetails.token })
      }

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestMobileOtpCallId !== null &&
      this.requestMobileOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.mobileOtpReceived(responseJson)
      
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpConfirmationCallId !== null &&
      this.otpConfirmationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.otpIsConfirmed(responseJson)
    }
    else if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.requestEmailOtpCallId !== null &&
    this.requestEmailOtpCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (responseJson.message && responseJson.message.includes("Link is send to your Email")) {
        this.setState({emailValidation:'Valid',openPopup:true,emailExists:true})
        this.handleOpenPopup()
      }
    }

    if (responseJson && responseJson.errors) {
      if (responseJson.errors[0].otp === "Account not found") {
        this.setState({ emailExists: false });
      }
    }
  }
    goToSignInMethod=()=>{
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    mobileOtpReceived=(responseJson:{data:{id:string,type:string,attributes:{full_phone_number: string,pin:string}},meta:{token:string;}})=>{
      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
    ) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken, openOtpPopup: true })

    }
    }

    otpIsConfirmed = (responseJson:{messages?:[{token:string}],errors?:[{error:string}]})=>{
      if (
        responseJson !== undefined &&
        responseJson.messages
    ) {
        this.setState({ token: responseJson.messages[0].token });
        const message: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordSetMessage)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationPayLoadMessage), { token: responseJson.messages[0].token })
        this.send(message);
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.setState({ inValidOtp: true })
        return
    } else {
        return
    }
    }

  sendMobileOtpApi=()=> {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestMobileOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEmailEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
        "attributes":{
            "full_phone_number": `+${this.state.phoneValue}`
    }
    };

    const httpBody = {
      data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  confirmOtpCall = (otp:string)=>{
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.otpConfirmationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      token: this.state.token,
      otp_code: Number(otp)
    };
    const httpBody = {
      data: data,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
