import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Radar from "radar-sdk-js";
import React from "react";
import {calculateRouteMultiple, calculateRouteReturning, DirectionResultResponse } from "../../../components/src/MapsDistanceRouting";
import { driverLocationGreen, driverLocationGrey, driverLocationRed, driverLocationYellow, orderLocationGreen, orderLocationGrey, orderLocationRed, orderLocationYellow } from "./assets";
import { debounce } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  location: DriverData;
  restaurant_location:{latitude:string,longitude:string};
  mapLoaded:boolean;
  directionsResponses:DirectionResultResponse[]
  directionsResponse:null|DirectionResultResponse;
  activeMarker:string;
  deliveryStatusSelected:string[];
  filterPopup:boolean;
  deliveryStatus:{name:string;value:string}[];
  deliverySTatusApplied:string[];
  searchQuery:string;
  openNoResult:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

// DriverLocation.ts
export interface Location {
  id: number;
  latitude: number;
  longitude: number;
  van_id?: any;
  address?: string | null;
  locationable_type?: string | null;
  locationable_id?: number | null;
  order_id?: number | null;
  account_id: number;
  created_at: string;
  updated_at: string;
}

export interface DeliveryAddress {
  id: number;
  account_id: number;
  address: string;
  name: string;
  flat_no?: string | null;
  zip_code: string;
  phone_number: string;
  deleted_at?: string | null;
  latitude: number;
  longitude: number;
  residential: boolean;
  city: string;
  state_code?: string | null;
  country_code?: string | null;
  state?: string | null;
  country: string;
  address_line_2?: string | null;
  address_type: string;
  address_for: string;
  is_default: boolean;
  landmark?: string | null;
  created_at: string;
  updated_at: string;
}

const pathColors = {
  delivering: '#FF0000',
  delayed: '#FFFF00',   
  returning: '#00FF00', 
  untraceable: '#0000FF'
};
export type PathColorType = keyof typeof pathColors

export interface PersonalDetails {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  order_id: number;
  created_at: string;
  updated_at: string;
}

export interface Order {
  order_id: number;
  order_number: string;
  order_status: string;
  order_total: string;
  tracking_status: string;
  delivery_address: DeliveryAddress[];
  personal_details: PersonalDetails;
  distance_km: string;
  estimated_delivery_time: string;
}

export interface Driver {
  id: number;
  full_name: string;
  email: string;
  phone_number?: string | null|number;
  location: Location | null;
  estimated_arrival_time_to_store?: string | null;
  orders: Order[];
  restaurant_location?: {latitude:string,longitude:string} 
}

export interface DriverData {
  non_assigned_drivers: Driver[];
  untraceable_drivers: Driver[];
  returning_drivers: Driver[];
  delivering_drivers: Driver[];
  delayed_drivers: Driver[];
}


interface ILogResult {
  location: {
    latitude: number;
    longitude: number;
    accuracy: number;
  };
  user: {
    location: {
      type: string;
      coordinates: number[];
    };
    live: boolean;
  };
}

interface ILocationResult {
  location: {
    latitude: number;
    longitude: number;
    accuracy: number;
  };
  status: string;
}
// Customizable Area End

export default class GeofenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  riderLocationsApiCallId:string
  intervalId: NodeJS.Timeout | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      location:{
        non_assigned_drivers: [],
        untraceable_drivers: [],
        returning_drivers: [],
        delivering_drivers: [],
        delayed_drivers: []
      },
      restaurant_location:{
        latitude:'28.6509477',longitude:'77.3498989'
      },
      mapLoaded:false,
      directionsResponses:[],
      directionsResponse:null,
      activeMarker:"",
      deliveryStatusSelected:[],
      filterPopup:false,
      deliveryStatus:[{name:"Delivering",value:"delivering"},{name:"Returning",value:"returning"},{name:"Delayed",value:"delayed"},{name:"Un Traceable",value:"untraceable"}
      ],
      deliverySTatusApplied:[],
      searchQuery:"",
      openNoResult:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.riderLocationsApiCallId = ""
    this.intervalId = null
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRiderLocations()
    this.getRestaurantCoordinates()
    this.intervalId = setInterval(this.getRiderLocations, 60000);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.riderLocationsApiCallId){
        this.setRiderLocation(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount=async()=> {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(prevState.location !== this.state.location){

      const directionsPromises: Promise<DirectionResultResponse>[] = this.returnAllTheData(this.state.location)
    .flatMap((driver) => {
      if (driver.type === "returning") {
        const origin = {
          lat: driver.location && Number(driver.location.latitude) || 0,
          long: driver.location && Number(driver.location.longitude) || 0,
        };

        const restaurant_route = {
          lat: driver.restaurant_location ? Number(driver.restaurant_location.latitude) : 0,
          long: driver.restaurant_location ? Number(driver.restaurant_location.longitude) : 0,
        };
        return [calculateRouteReturning(origin, restaurant_route)]
      }

      return driver.orders.map((order) => {
        const origin = {
          lat: driver.location && Number(driver.location.latitude) || 0,
          lng:  driver.location && Number(driver.location.longitude) || 0,
        };

        const waypoints = driver.orders.slice(0, -1).map((order) => ({
          location: {
            lat: Number(order.delivery_address[0].latitude),
            lng: Number(order.delivery_address[0].longitude),
          },
          stopover: true,
        }));

        const finalDestination = {
          lat: driver.orders[driver.orders.length - 1] && Number(driver.orders[driver.orders.length - 1].delivery_address[0].latitude),
          lng: driver.orders[driver.orders.length - 1] && Number(driver.orders[driver.orders.length - 1].delivery_address[0].longitude),
        };

        return calculateRouteMultiple(origin, finalDestination, waypoints);
      });
    });
    
      Promise.all(directionsPromises).then((directionsResponses: DirectionResultResponse[]) => {
        this.setState({ directionsResponses });
      });

      this.getRestaurantCoordinates()
    }
    if(prevState.deliverySTatusApplied !== this.state.deliverySTatusApplied){
      this.getRiderLocations()
    }
  }
  getRiderLocations=()=>{
    let token = localStorage.getItem('authToken')

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };
    const params= {
      filter: this.state.deliverySTatusApplied || undefined,
      search_query: this.state.searchQuery || undefined,
    }

    const queryString = this.createQueryString(params)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.riderLocationsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ridersApiEndPoint}?${queryString}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRestaurantCoordinates=()=>{
    const restaurant_information = JSON.parse(localStorage.getItem("restaurant_information") ||  '{"restaurant":{"latitude":"28.6509477","longitude":"77.3498989"}}');
    this.setState({restaurant_location:{latitude:restaurant_information.restaurant.latitude,longitude:restaurant_information.restaurant.longitude}})
  }

  setRiderLocation = (response: any) => {
    if (response) {
      const {
        non_assigned_drivers = [],
        untraceable_drivers = [],
        returning_drivers = [],
        delivering_drivers = [],
        delayed_drivers = []
      } = response;
  
      // Check if all driver arrays are empty
      const areAllEmpty = [
        untraceable_drivers,
        returning_drivers,
        delivering_drivers,
        delayed_drivers
      ].every(arr => arr.length < 1);
  
      // Update state only if not all driver arrays are empty
      if (!areAllEmpty) {
        this.setState({
          location: {
            non_assigned_drivers,
            untraceable_drivers,
            returning_drivers,
            delivering_drivers,
            delayed_drivers
          },openNoResult:false
        });
      } else{
        this.setState({
          location: {
            non_assigned_drivers:[],
            untraceable_drivers:[],
            returning_drivers:[],
            delivering_drivers:[],
            delayed_drivers:[]
          }
        });this.openNoResultChange()
      }
    }
  };
  

  pathColors = {
    delayed: "#F59E0B",  
    delivering: "#E11B22", 
    returning: "#2C6F37", 
    untraceable: "#909090", 
  };

  setDirectionRederer=(value:DirectionResultResponse)=>{
    this.setState({directionsResponse:value})
  }

  returnAllTheData=(location:DriverData)=>{
    return [...location.delayed_drivers.map((d) => ({ ...d, type: "delayed" as PathColorType })),...location.delivering_drivers.map((d) => ({ ...d, type: "delivering" as PathColorType })),...location.returning_drivers.map((d) => ({ ...d, type: "returning" as PathColorType })),...location.untraceable_drivers.map((d) => ({ ...d, type: "untraceable" as PathColorType }))]
  }

  returnLocationIconDriver=(type:string | number | symbol)=>{
    switch(type){
      case "delayed":
        return driverLocationYellow;
      case "delivering":
        return driverLocationRed;
      case "returning":
        return driverLocationGreen;
      default:
        return driverLocationGrey
    }
  }

  returnAppliedFilterData=(type:string)=>{
    switch(type){
      case "delivering":
        return this.getFilterItem("#E11B22","Delivering");
      case "returning":
        return this.getFilterItem("#2C6F37","Returning");
      case "delayed":
        return this.getFilterItem("#F59E0B","Delayed");
        case "untraceable":
          return this.getFilterItem("#909090","Location error/ Untraceable");
      default:
        return  <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
           {this.getFilterItem("#E11B22", "Delivering")}
          {this.getFilterItem("#2C6F37", "Returning")}
          {this.getFilterItem("#F59E0B", "Delayed")}
          {this.getFilterItem("#909090", "Location error/ Untraceable")}
        </div>
    }
  }

  getFilterItem = (color: string, label: string) => (
    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}>
      <div style={{ backgroundColor: color, borderRadius: "50%", width: "12px", height: "12px", minWidth:"12px" }}></div>
      <div style={{ fontWeight: 500, fontSize: "16px" }}>{label}</div>
    </div>
  );

  returnLocationIconOrder=(type:string | number | symbol)=>{
    switch(type){
      case "delayed":
        return orderLocationYellow;
      case "delivering":
        return orderLocationRed;
      case "returning":
        return orderLocationGreen;
      default:
        return orderLocationGrey

    }

  }

  returnDeliveryStatusHeading=(type:string)=>{
    switch(type){
      case "delayed":
        return "Delayed Order";
      case "delivering":
        return "On Delivery";
      case "returning":
        return "Returning";
      default:
        return "Location Error"

    }

  }


  handleMouseOver = (markerId:string) => {
    this.setState({ activeMarker: markerId });
  };

  handleMouseOut = () => {
    this.setState({ activeMarker: "" });
  };
  changeDeliveryStatus=(value:string)=>{
    const isAlreadySelected = this.state.deliveryStatusSelected.includes(value);

    if(isAlreadySelected){
      const updatedAllergens = this.state.deliveryStatusSelected.filter(item => item !== value);
      this.setState({ deliveryStatusSelected: updatedAllergens });
    }else{
      this.setState({ deliveryStatusSelected: [...this.state.deliveryStatusSelected, value] });
    }
  }
  applyFilter=()=>{
this.setState({deliverySTatusApplied:this.state.deliveryStatusSelected,filterPopup:false})
  }
  clearFilters=()=>{
    this.setState({deliverySTatusApplied:[],filterPopup:false})
  }
  openFilterPopup=()=>{
    this.setState({filterPopup:true})
  }
  closeFilterPopup=()=>{
    this.setState({filterPopup:false})
  }
  createQueryString = (params: Record<string, any>): string => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) =>
        Array.isArray(value)
          ? `${key}=${value.join(',')}`
          : `${key}=${encodeURIComponent(value)}`
      )
      .join('&');
  };

  changeSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  }, 300);

  openNoResultChange=()=>{
    this.setState({openNoResult:true})
  }
  closeNoResultChange=()=>{
    this.setState({openNoResult:false})
  }
  // Customizable Area End
}
