import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface MetaType{
  message: string
}
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}

export interface AddressDataNew {
    name:string;
    full_phone_number: string;
    address_type: string;
    address: string;
    default_address: boolean;
    post_code: string;
    country: string;
    city: string;
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}
export interface AddressDataTypeNew {
  id: string;
  type: string;
  attributes: AddressDataNew;
}
export interface ResponseType{
  errors: any;
  data:AddressDataTypeNew[];
  meta:MetaType
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string |null;
  addressList: AddressType[] | null;
  logoutPopup: boolean;
  addressesNewList: AddressDataTypeNew[];
  deletedSuccess:boolean;
  deleteAddressPopup: boolean;
  addressTodelete: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAllAddressCallId: string = "";
  apiDeleteAddressCallId: string = "";
  token:null| string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addressList: null,
      token: "",
      logoutPopup: false,
      addressesNewList:[],
      deletedSuccess:false,
      deleteAddressPopup:false,
      addressTodelete:"",
      loading: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived Properly", message);
        if(apiRequestCallId === this.apiGetAllAddressCallId)
        {
          this.storeAddress(responseJson)
        }
        if(apiRequestCallId === this.apiDeleteAddressCallId)
        {
          this.addressDeleted(responseJson.message)
        }
        this.setState({ loading: false });
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getAddress=()=>{
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllAddressCallId = requestMessage.messageId;
    this.setState({ loading: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAddress=(address_id:string)=>{
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteAddressCallId = requestMessage.messageId;
    this.setState({ loading: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAddressApiEndPoint}/${address_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMehod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  storeAddress =(responseJson:ResponseType)=>{
    if (responseJson && responseJson.data) {
        this.setState({ addressesNewList: responseJson.data });
    } else if (responseJson && responseJson.errors) {
      if(responseJson.errors[0].token){
        this.showAlert('token','expired')
      }else{
        this.parseApiErrorResponse(responseJson.errors)
      }
    }
  }

  addressDeleted=(message:string)=>{
    this.closeDeleteAddressPopup()
    this.setState({deletedSuccess:true})
        {
          const timer = setTimeout(() => {
            this.setState({deletedSuccess:false})
            window.location.reload();
          }, 3000);

          return () => clearTimeout(timer);
        }
  }

  editAddress=(data:{id:string, name:string,phoneNumber:string, address:string,address_type:string, default_address:boolean, post_code:string,city:string} )=>{
    const editAddressData = {
    id: data.id,
    name:data.name,
    full_phone_number: data.phoneNumber,
    address_type: data.address_type,
    address: data.address,
    default_address: data.default_address,
    post_code: data.post_code,
    city: data.city
    }
    localStorage.setItem("editAddress",JSON.stringify(editAddressData))
    this.navigateToPages("EditAddress")
  }
  getToken = () => {
    this.token = localStorage.getItem("authToken")
    if(this.token){
      this.getAddress()
    }else{
      this.navigateToPages("EmailAccountLoginBlock")
    }
  };

  handlePressAdd = () => {
    this.props.navigation.navigate("AddAddress");
  };
  navigateToPages=(navigator:string)=>{
    this.props.navigation.navigate(navigator)
  }
  logOut =()=>{
    this.setState({logoutPopup: true})
  }
  openDeleteAddressPopup=(id:string)=>{
    this.setState({deleteAddressPopup:true, addressTodelete:id })
  }
  closeDeleteAddressPopup=()=>{
    this.setState({deleteAddressPopup:false})
  }
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
