import React from "react";
import { success,failure } from "../SocialLogin/assets";
import Modal from "../Modal";
import { ContainedButton } from "../Button";
import "../Dashboard/CustomerMarketingInfo/InfoSuccess.css";

interface Props {
  open: boolean;
  onClose: () => void;
  closeWithoutOpen:()=>void
  successMsg: string;
  img:string;
  btnText?: string;
  head?:string;
}

const SuccessFailModal = ({ open, onClose, successMsg,img,btnText ,closeWithoutOpen,head}: Props) => {
  return (
    <Modal
       className="Modal"
      maxWidth="sm"
      open={open}
      onClose={closeWithoutOpen}
    >
      <div className="note_success__wrapper">
        <img
          src={img==="success"?success:failure}
          alt="Not Saved Successfully"
          className="note_success__image"
        />
        <div style={{display:"flex",flexDirection:"column"}}>
        {head && <div style={{textAlign:"center"}} className="note_success__text" >{head}</div>}
        <p style={{textAlign:"center",color:head?"#404040":"#1D1D1D",fontSize:head?"18px":"24px",fontWeight:head?500:700}} className="note_success__text">{successMsg}</p>
        </div>
       { btnText && <ContainedButton onClick={onClose}>
          {btnText}
        </ContainedButton>}
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default SuccessFailModal;
