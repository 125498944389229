import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { collectionImage, deliveryImage } from "./assets";
export const configJSON = require("./config");


export interface OrderManagementDataType {
  in_store_orders: OrderManagementOrderType;
  on_road_orders: OrderManagementOrderType;
  completed_orders: OrderManagementOrderType;
  future_orders: OrderManagementOrderType;
  cancelled_orders: OrderManagementOrderType;
}

export interface DriverListDataType {
  id: number;
  staff_id: null | string | number;
  name: string;
  email: string;
  phone_number: number;
  assigned_orders_count: number
}

export interface DriverDataType {
  success: boolean,
  message: string,
  data: DriverListDataType[]
}

export interface OrderManagementList {
  success: boolean,
  message: string,
  data: OrderManagementDataType
}

export interface OrderManagementOrderType {
  total_count: number,
  total_pages: number,
  current_page: number,
  orders: OrderManagementListOrder[]
}

export interface OrderManagementListOrder {
  id: number;
  order_number: string;
  order_type: string;
  post_code: string;
  status: string;
  refund_amount: null;
  payment_source: string,
  email: string,
  full_phone_number: string,
  user_name: string,
  cashed_off: boolean,
  total: string,
}
type Timer = ReturnType<typeof setTimeout>;

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  searchParameter: string;
  searchParameterOnUpdate: string;
  OpenDriverRequest: boolean;
  OpenDriverRequest1: boolean;
  GetDriverDetailsData: any;
  newOrderIdAssign: any;
  value: number;
  orderMedium: string;
  orderMediumName: string;
  orderTypes: string;
  orderStatuses: string;
  orderpage: number;
  orderPerPage: string;
  headerText: string;
  searchQuery: string;
  searchPreupdateValue: string;
  orderListToDisplay: OrderManagementList;
  statusWiseOrder: OrderManagementOrderType;
  isActive: boolean;
  driverSearchQuery: string;
  driverPreSearchQuery: string;
  driverDataToShow: DriverListDataType[];
  driverDataToShow1: any;
  statusChangeSelected: string[];
  orderTypeSelected: string[];
  openFilter: boolean;
  selectedOrder: OrderManagementListOrder | null;
  cashOffModal: boolean;
  errorPopup: boolean;
  errorPopupMessage: string;
  successPopup: boolean;
  successPopupMessage: string;
  loadingOrder: boolean;
  orderIdForRefund:OrderManagementListOrder[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class PosOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllOrdersDataFilterWiseApiCallId: string;
  getAllDriversDataFilterWiseApiCallId: string;
  getAllDriversDataFilterWiseApiCallId1: string;
  getAllDriversDataFilterWiseApiCallId2: string;
  apiExampleCallId: string;
  cashOffCallId: string;
  orderActionCallId: string;
  bulkRefundApiCallId:string;
  currentRoleParams = "";
  timeoutId: null | Timer
  searchRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getAllOrdersDataFilterWiseApiCallId = "";
    this.timeoutId = null;
    this.getAllDriversDataFilterWiseApiCallId = "";
    this.getAllDriversDataFilterWiseApiCallId1 = "";
    this.getAllDriversDataFilterWiseApiCallId2 = "";
    this.apiExampleCallId = "";
    this.cashOffCallId = "";
    this.orderActionCallId = "";
    this.bulkRefundApiCallId="";
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      // Customizable Area Start
      searchParameter: "",
      OpenDriverRequest: false,
      OpenDriverRequest1: false,
      GetDriverDetailsData: [],
      newOrderIdAssign: "",
      searchParameterOnUpdate: "",
      value: 0,
      orderMedium: "",
      orderMediumName: "All Orders",
      orderTypes: "",
      orderStatuses: "",
      headerText: "Orders in store",
      orderpage: 1,
      orderPerPage: "",
      searchQuery: "",
      searchPreupdateValue: "",
      orderListToDisplay: {
        success: false,
        message: "",
        data: {
          in_store_orders: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            orders: []
          },
          on_road_orders: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            orders: []
          },
          completed_orders: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            orders: []
          },
          future_orders: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            orders: []
          },
          cancelled_orders: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            orders: []
          }
        }
      },
      statusWiseOrder: {
        total_count: 0,
        total_pages: 0,
        current_page: 0,
        orders: []
      },
      isActive: false,
      driverSearchQuery: "",
      driverPreSearchQuery: "",
      driverDataToShow: [],
      driverDataToShow1: [],
      statusChangeSelected: [],
      orderTypeSelected: [],
      openFilter: false,
      selectedOrder: null,
      cashOffModal: false,
      errorPopup: false,
      errorPopupMessage: "",
      successPopup: false,
      successPopupMessage: "",
      loadingOrder: false,
      orderIdForRefund:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getAllOrdersDataFilterWiseApiCallId) {
        this.setAllOrdersData(responseJson)
      }
      if (apiRequestCallId === this.getAllDriversDataFilterWiseApiCallId) {
        this.setAllDriversData(responseJson)
      }
      if (apiRequestCallId === this.getAllDriversDataFilterWiseApiCallId1) {
        this.setState({ driverDataToShow1: responseJson })
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.getAllDriversDataFilterWiseApiCallId2) {
        console.log(responseJson.orders.length, "apiRequestCallId");
        this.setState({ GetDriverDetailsData : responseJson })
        this.setState({ loading: false })
        this.setState({ OpenDriverRequest1: true })
      }
      if (apiRequestCallId === this.apiExampleCallId) {
        this.getDriverRequestsData(this.state.newOrderIdAssign)
      }
      if (apiRequestCallId === this.cashOffCallId) {
        this.closeCashOffModal();

        if (responseJson?.error) {
          this.setState({ loading: false, errorPopup: true, errorPopupMessage: responseJson.error });
          return;
        };

        this.setState({ loading: false, successPopup: true, successPopupMessage: `Order ${responseJson?.order_number} has been Cashed Off successfully` });
        this.getAllOrdersData()
      }

      if (apiRequestCallId === this.orderActionCallId) {
        if (responseJson?.error) {
          this.setState({ loading: false, errorPopup: true, errorPopupMessage: responseJson.error });
          return;
        };

        this.setState({ loading: false, successPopup: true, successPopupMessage: responseJson?.message });
        this.getAllOrdersData()
      }
      if(apiRequestCallId === this.bulkRefundApiCallId){
        if (responseJson && responseJson.error) {
          this.setState({ loading: false, errorPopup: true, errorPopupMessage: responseJson.error });
          return;
        };

        this.setState({ loading: false, successPopup: true, successPopupMessage: responseJson.message });
        this.getAllOrdersData()
      }
    }
  }

  setActiveTab = (index: any, name: string) => {
    this.setState({ value: index, headerText: name, selectedOrder: null }, () => {
      if (this.state.orderpage !== 1) {
        this.setState({ orderpage: 1 })
      }
    });
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ orderpage: page })
  };

  componentDidMount = async () => {
    const currentRole = sessionStorage.getItem("role");
    if (currentRole)
      this.currentRoleParams = `?current_user_role=${currentRole}`

    this.getAllOrdersData()
    this.getAllDriversData()
  }

  componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<S>) => {
    if (this.state.orderStatuses !== prevState.orderStatuses || this.state.orderTypes !== prevState.orderTypes || this.state.orderMedium !== prevState.orderMedium || this.state.orderpage !== prevState.orderpage || prevState.searchParameter !== this.state.searchParameter || this.state.searchQuery !== prevState.searchQuery) {
      this.getAllOrdersData()
    }
    if (this.state.driverSearchQuery !== prevState.driverSearchQuery) {
      this.getAllDriversData()
    }
  }

  createQueryString = (params: Record<string, any>): string => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) =>
        Array.isArray(value)
          ? `${key}=${value.join(',')}`
          : `${key}=${encodeURIComponent(value)}`
      )
      .join('&');
  };

  selectOrder = (order: OrderManagementListOrder) => {
    if (order.id === this.state.selectedOrder?.id) {
      this.setState({ selectedOrder: null });
      return;
    };
    this.setState({ selectedOrder: order });
  };

  openCashOffModal = () => {
    this.setState({
      cashOffModal: true,
    });
  };

  closeCashOffModal = () => {
    this.setState({
      cashOffModal: false,
    });
  };

  closeErrorPopup = () => {
    this.setState({
      errorPopup: false,
      errorPopupMessage: "",
    });
  };

  closeSuccessPopup = () => {
    this.setState({
      successPopup: false,
      successPopupMessage: "",
    });
  };

  generateOrderActionUrl = (orderId: number, action: string, configJSON: any, filterDate?: string) => {
    const endpoint = configJSON.orderActionAPI.endPoint;
    const actionPath = configJSON.orderAction[action];
    const dateRange = filterDate || "day";

    return `${endpoint}/${orderId}/${actionPath}?date_range=${dateRange}`;
  }

  handleCloseDriverRequest = () => {
    this.setState({ OpenDriverRequest: false })
  }

  handleCloseDriverRequest1 = () => {
    this.setState({ OpenDriverRequest1: false })
  }

  handleOpenDriverRequest = (id: number) => {
    this.setState({ newOrderIdAssign: id })
    this.setState({ OpenDriverRequest: true }, () => {
      this.getDriverRequestsData(id)
    })
  }

  getDriverRequestsData = (id: number) => {
    const token = localStorage.getItem("authToken")
    const roles = JSON?.parse(localStorage.getItem("roles") || "[]");
    const requiredRole = roles.find((role: any) => role === "In Store Manager")
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDriversDataFilterWiseApiCallId1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_tasks/riders?order_id=${id}&current_user_role=${requiredRole}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  GetRiderDetails = (id: any) => {
    const token = localStorage.getItem("authToken")
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDriversDataFilterWiseApiCallId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/riders/orders?driver_id=${id.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  NewDriverAssign = (item: any) => {
    const token = localStorage.getItem("authToken")
    this.setState({ newOrderIdAssign: this.state.newOrderIdAssign })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const httpBody = {
      "order_id": this.state.newOrderIdAssign,
      "current_user_role": "In Store Manager",
      "new_driver_id": item.rider_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiExampleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/riders/assign_driver`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  orderAction = (action: "accept" | "cancel" | "skip") => {
    const token = localStorage.getItem("authToken");
    const orderId = this.state.selectedOrder?.id;
    if (!orderId) return;
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.setState({ loading: true })
    this.orderActionCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.generateOrderActionUrl(orderId, action, configJSON) + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: token,
      })
    );
    if (action === "skip") {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    } else {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    }
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  cashOff = () => {
    this.setState({ loading: true });
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.cashOffCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.cashOffApi.method
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.cashOffApi.endpoint}/${this.state.selectedOrder?.id}/cash_off`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.showAlert("token", "token");
    }
  };

  getAllOrdersData = () => {
    const token = localStorage.getItem("authToken")
    const order_medium = this.state.orderMedium
    const statuses = this.state.orderStatuses
    const order_types = this.state.orderTypes
    const search_query = this.state.searchQuery
    const search = this.state.searchParameter
    const page = this.state.orderpage
    const per_page = this.state.orderPerPage

    if (token) {
      this.setState({ loadingOrder: true, isActive: false })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllOrdersDataFilterWiseApiCallId = requestMessage.messageId;

      const params = {
        order_medium,
        statuses,
        order_types,
        search_query,
        search,
        page,
        per_page,
      }

      const queryString = this.createQueryString(params)

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.orderListingApiEndPoint}?${queryString}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    else {
      this.showAlert("token", "token")
    }
  }

  setAllOrdersData = (responseJson: { errors: any } | OrderManagementList) => {
    if ('errors' in responseJson) {
      this.setState({ loadingOrder: false })
      return;
    } else {
      this.setState({ orderListToDisplay: responseJson, loadingOrder: false })
    }

  }

  setAllDriversData = (responseJson: { errors: any } | DriverDataType) => {
    if ('errors' in responseJson) {
      this.setState({ loading: false })
      return;
    } else {
      this.setState({ driverDataToShow: responseJson.data, loading: false })
    }
  }

  getAllDriversData = () => {
    const token = localStorage.getItem("authToken")
    const search = this.state.driverSearchQuery

    if (token) {
      this.setState({ loading: true })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllDriversDataFilterWiseApiCallId = requestMessage.messageId;

      const params = {
        search
      }

      const queryString = this.createQueryString(params)

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.driverListingApiEndPoint}?${queryString}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    else {
      this.showAlert("token", "token")
    }
  }


  getOrderTypeIcon = (order_type: string) => {
    return order_type === "delivery" ? deliveryImage : collectionImage
  }

  getValueForTab = (index: number) => {
    return this.state.value === index
  }

  getOnValueOfStatus = (value: string | number) => {
    switch (value) {
      case 'placed':
        return <div className="orderStatusColoredChip" style={{ color: "#662EB2", backgroundColor: "#D4B6FF" }}>ORDER PLACED</div>
      case 'confirmed':
        return <div className="orderStatusColoredChip" style={{ color: "#662EB2", backgroundColor: "#D4B6FF" }}>CONFIRMED</div>
      case 'ready':
        return <div className="orderStatusColoredChip" style={{ color: "#7C71FE", backgroundColor: "#E2E8FE" }}>READY</div>
      case 'in_transit':
        return <div className="orderStatusColoredChip" style={{ color: "#E73777", backgroundColor: "#FFD6E5" }}>PICKED UP</div>
      case 'collected':
        return <div className="orderStatusColoredChip" style={{ color: "#059669", backgroundColor: "#D1FAE5" }}>COLLECTED</div>
      case 'delivered':
        return <div className="orderStatusColoredChip" style={{ color: "#059669", backgroundColor: "#D1FAE5" }}>DELIVERED</div>
      case 'cooking':
        return <div className="orderStatusColoredChip" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }}>COOKING</div>
      case 'cancelled':
        return <div className="orderStatusColoredChip" style={{ color: "#E11B22", backgroundColor: "rgb(244, 159, 162)" }}>CANCELLED</div>
      case 'cancel_initiated':
        return <div className="orderStatusColoredChip" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }}>CANCEL INITIATED</div>
      case 'refund_initiated':
        return <div className="orderStatusColoredChip" style={{ color: "#D97706", backgroundColor: "#FEF3C7" }}>REFUND INITIATED</div>
      case 'refunded':
        return <div className="orderStatusColoredChip" style={{ color: "#059669", backgroundColor: "#D1FAE5" }}>REFUNDED</div>
      case 0:
        return <div className="orderStatusColoredChip" style={{ color: "#059669", backgroundColor: "#D1FAE5" }}>AVAILABLE</div>
      default:
        if (typeof value === "number" && value > 0) {
          return <div className="orderStatusColoredChip" style={{ color: "#E73777", backgroundColor: "#FFD6E5" }}>ON THE WAY</div>
        }
        return <div className="orderStatusColoredChip" >{value}</div>

    }
  }

  handleSearchChangeWithoutUpdate = (searchParameter: string) => {
    this.setState({ searchParameter })
  }

  handleDeliverySearchChangeWithoutUpdate = (driverSearchQuery: string) => {
    this.setState({ driverSearchQuery })
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchParameterOnUpdate: event.target.value })
    this.debounce(() => this.handleSearchChangeWithoutUpdate(event.target.value), 300);

  }

  handleDriverSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ driverPreSearchQuery: event.target.value })
    this.debounce(() => this.handleDeliverySearchChangeWithoutUpdate(event.target.value), 300);
  }

  debounce(func: (...args: any[]) => void, delay: number) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(func, delay);
  }

  handleSearchClear = () => {
    this.setState({ searchParameter: "", searchParameterOnUpdate: "" })
  }
  handleSearchDriverClear = () => {
    this.setState({ driverPreSearchQuery: "", driverSearchQuery: "" })
  }

  handleFocus = () => {
    this.searchRef.current?.focus();
  }

  handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchPreupdateValue: value })
    this.debounce(() => {
      this.setState({ searchQuery: value })
    }, 300);
  };

  getOrderMediumPlacement = (): any[] => {
    return [{ name: "All Orders", value: "" }, { name: "Instore Orders", value: "instore" }, { name: "Online Orders", value: "online" }, { name: "Telephone Orders", value: "telephone" }]
  }

  handleChange = (value: { name: string, value: string }) => {
    this.setState({ isActive: false, orderMedium: value.value, orderMediumName: value.name })
  }
  toggleDropdown = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleStatusChange = (value: string): void => {
    const isAlreadySelected = this.state.statusChangeSelected.includes(value);

    if (isAlreadySelected) {
      const updatedAllergens = this.state.statusChangeSelected.filter(item => item !== value);
      this.setState({ statusChangeSelected: updatedAllergens });
    } else {
      this.setState({ statusChangeSelected: [...this.state.statusChangeSelected, value] });
    }
  }

  handleOrderTypeChange = (value: string): void => {
    const isAlreadySelected = this.state.orderTypeSelected.includes(value);

    if (isAlreadySelected) {
      const updatedAllergens = this.state.orderTypeSelected.filter(item => item !== value);
      this.setState({ orderTypeSelected: updatedAllergens });
    } else {
      this.setState({ orderTypeSelected: [...this.state.orderTypeSelected, value] });
    }
  }

  cancelIndividualOrder=(event:React.MouseEvent<HTMLButtonElement>,orderId:string,amount:number)=>{
    const token = localStorage.getItem("authToken")
    event.stopPropagation()

    if (token) {
      this.setState({ loading: true })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const body ={
        "order_ids": [orderId],
        "refund_details": [
                            {"order_id": orderId, "refund_amount":amount}
                        ]
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      this.bulkRefundApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.bulkRefundApiEndPoint}`
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  applyFilter = () => {
    this.setState({ orderStatuses: this.state.statusChangeSelected.join(","), orderTypes: this.state.orderTypeSelected.join(",") })
    this.closeFilter()
  }
  clearFilter = () => {
    this.setState({ orderStatuses: "", statusChangeSelected: [], orderTypes: "", orderTypeSelected: [] })
    this.closeFilter()
  }
  openFilter = () => {
    this.setState({ openFilter: true })
  }
  closeFilter = () => {
    this.setState({ openFilter: false })
  }
  navigateToNewPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const id = event.currentTarget.accessKey
    this.props.history.push(`/Dashboard/OrderNumber/${id}`, { id })
  }
  navigateToOrderPrinting = () => {
    const orderId = this.state.selectedOrder?.id;
    if (orderId) {
      this.props.history.push(`/Dashboard/Receipt/${orderId}`, { orderId })
    }
  }

  refundDisabled=(status:string,payment_type:string)=>{
    return status === "refunded" || status === "refund_initiated" || payment_type === "cash" || sessionStorage.getItem("role") !== "In Store Manager"
  }
  changeRefundOrder = (order: OrderManagementListOrder) => {
    this.setState((prevState) => {
      const orderIdForRefund: OrderManagementListOrder[] = Array.isArray(prevState.orderIdForRefund)
          ? prevState.orderIdForRefund
          : [];
      const isSelected = orderIdForRefund.some((o) => o.id === order.id);
      const updatedOrders = isSelected
          ? orderIdForRefund.filter((o) => o.id !== order.id)
          : [...orderIdForRefund, order];

      return { orderIdForRefund: updatedOrders };
  });
};

bulkRefundOrder=()=>{
  const token = localStorage.getItem("authToken")
  if (token) {
    const body ={
      "order_ids": this.state.orderIdForRefund.map((value)=>(value.id)),
      "refund_details": this.state.orderIdForRefund.map((value)=>( {"order_id": value.id, "refund_amount":value.refund_amount}))
  }
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkRefundApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bulkRefundApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

}
