import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

export const customerSchema = z.object({
  firstName: z.string().trim().min(1, "This field should not be left empty"),
  lastName: z
    .string()
    .trim()
    .min(1, "This field should not be left empty")
    .refine((val) => !/\s/.test(val), {
      message: "First name should not contain spaces between characters.",
    }),
  email: z
    .string()
    .trim()
    .refine((val) => {
      // validate email through regex
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!val) return true;
      return emailRegex.test(val);
    }, "The email address you entered is not in a valid format. Please enter a valid email address"),
  phoneNumber: z
    .string()
    .min(1, "This field should not be left empty")
    .refine((value) => {
      return isValidPhoneNumber("+" + value);
    }, "The mobile number you entered is not in a valid format. Please enter a valid mobile number"),
  address: z
    .string()
    .trim()
    .max(100, "This field should not exceed 100 characters. Please adjust the input."),
  postCode: z.string()
  .trim()
  .min(1, "This field should not be left empty")
  .refine((val) => {
    // UK Postcode: Matches formats like "SW1A 1AA", "M1 1AE"
    const ukPostcodeRegex = /^[A-Za-z]{1,2}\d[A-Za-z\d]? \d[A-Za-z]{2}$/;
    // India Postcode (PIN Code): Exactly 6 digits
    const indiaPostcodeRegex = /^\d{6}$/;
    // Dubai Postcode (P.O. Box format): Just numeric, can be 5 digits or more
    const dubaiPostcodeRegex = /^\d+$/;
    return ukPostcodeRegex.test(val) || indiaPostcodeRegex.test(val) || dubaiPostcodeRegex.test(val);
  }, { message: "Invalid postcode format. Please enter a valid postcode" }),
  date_of_birth: z
    .string()
    .refine((date) => {
      if (!date) return true;
      return new Date(date) <= new Date();
    }, {
      message: "Date of birth cannot be in the future.",
    }),
});
