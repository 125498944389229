import React from 'react'
import "./ProductDetails.css"
import OrderHistoryController, { OrderHistoryOrders, Props } from './OrderHistoryController';
import Profile from '../../../components/src/Profile';
import { collectionHead, deliveryHead, helpIcon, imageEuroSign, imageNotes, imageRestaurantSign, noOrderHistory, noOrderHistoryResultFound} from './assets';
import { Accordion, AccordionSummary, Button } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import ImageSeriesStatusOrderHistory from '../../../components/src/ImageSeriesStatusOrderHistory.web';
import DialogPopupWeb from '../../../components/src/DialogPopup.web';
import DialogPopupTasks from '../../../components/src/DialoguePopupTasks.web';
import Task from '../../../blocks/tasks/src/Task.web';
import "./OrderHistory.web.css";
import ViewOrderDetails from './../../../components/src/ViewOrderDetails/ViewOrderDetails.web';
import Search from '../../../blocks/search/src/Search';
import { formatDateandTime } from './../../../components/src/utils';
import CustomLoader from '../../../components/src/CustomLoader.web';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import SuccessFailModal from '../../../components/src/Modal/SuccessFailModal';
import OrderDetails from './OrderDetails';


export default class OrderHistory extends OrderHistoryController {
    constructor(props: Props) {
        super(props);
    }

    accordianToBeshown = (activeOrNot: string, order_history:OrderHistoryOrders[]) => {
        return (
            <Accordion
                expanded={this.state.expandAccordian === activeOrNot}
                onChange={this.handleExpand(activeOrNot)}
                style={{ borderRadius: "8px", margin: "12px 0px", fontWeight: "600", fontSize: "16px", color: "#1D1D1D" }} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="accordian_spicelevel_summary"
                    id="accordian_spicelevel_summary"
                    style={{
                        fontFamily: "Barmeno",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#1D1D1D",
                        padding: "16px",
                    }}
                >
                    {activeOrNot} Orders
                </AccordionSummary>
                {this.state.loading ?<CustomLoader loading={this.state.loading}/> :
                (order_history.length >= 1 ? this.orderHistoryData(activeOrNot,order_history) :
                    this.noOrderHistoryFound(activeOrNot))}
            </Accordion>
        )
    }

    noOrderHistoryFound = (activeOrNot: string) => {
        return (
            <div style={{ backgroundColor: "#F8FAFC", display: "flex", justifyContent: "center", alignItems: "center", height: "460px", boxSizing: "border-box" }}>
                <img src={(noOrderHistory)} alt='no-order-history' />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "16px", fontFamily: "Barmeno", fontSize: "20px", fontWeight: 700 }}>
                    <div>No {(activeOrNot.toLowerCase())} orders here!  </div>
                    <div id="content" >Time to dive in and start your Wingo's feast now!</div>
                    <Button id='buttonTestId' onClick={this.navigateToMenuPage} style={{ color: "#ffffff", backgroundColor: "#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight: 500, fontSize: "16px" }}>
                        ORDER NOW!
                    </Button>
                </div>
            </div>
        )
    }

    orderHistoryData = (activeOrNot: string, order_histories:OrderHistoryOrders[]) => {
        return (
            <div className='order_history_card_container' >
                {order_histories.map((orderHistory: OrderHistoryOrders, index: number) => {
                    const orderStatus = orderHistory.attributes.status;
                    return (
                        <div key={index} className='order_history_card' style={{justifyContent:!this.helpIconDisabled(orderStatus)?"space-between":""}}>
                            <div className='order_number_dates'>
                                <div className='order_details_summary'>
                                    <div>{orderHistory.attributes.order_number}</div>
                                    <div className='order_time_detail'>Ordered on: {formatDateandTime(orderHistory.attributes.placed_at)}</div>
                                </div>
                                <img src={orderHistory.attributes.order_type === 'delivery' ? deliveryHead : collectionHead} alt='delivery' />
                            </div>
                            {activeOrNot === "Active" && this.helpIconDisabled(orderHistory.attributes.status) && 
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div className='estimated_delivery_time'><AccessTimeRoundedIcon /> Estimated Time : <span style={{ color: "#2C6F37" }}>{orderHistory.attributes.estimated_time}</span></div>
                                {!!orderHistory.attributes.otp &&<div className='estimated_delivery_time'>{orderHistory.attributes.order_type ==="collection"?"Collection OTP":"Delivery OTP"}: <span style={{ color: "#2C6F37" }}>{orderHistory.attributes.otp}</span></div>}
                                </div>}
                            <div className='order_status_container'>
                               <div style={{padding: "0 24px", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div
                                    className='order_status_heading'
                                    style={{
                                        color: this.helpIconDisabled(orderHistory.attributes.status) ? "inherit":"#E11B23" , height:"59.25px"
                                    }}>
                                    {orderHistory.attributes.status.replace(/_/g, ' ')}
                                </div>
                                {activeOrNot === "Active" && this.helpIconDisabledForActive(orderHistory.attributes.status) && <div style={{position:"relative", display:"flex",alignItems:"center"}} tabIndex={0} onBlur={this.closeCancelDropDown}> <IconButton onClick={()=>this.openCancelDropDown(orderHistory.id)} className='helpIconButton'> <img src={helpIcon} />Help</IconButton>
                                { this.state.cancelDropdown === `open${orderHistory.id}` &&
                                <div  className='dropDownCancel' onMouseDown={(e) => e.preventDefault()} >
                                    {(orderHistory.attributes.status  === "placed" || orderHistory.attributes.status === "confirmed") &&<div className='dropDownCancelOption' onClick={()=>this.openCancelPopup(orderHistory)} >Cancel Order</div>}
                                </div>}
                                </div>}
                                </div>
                                
                                <div className='order_status_images'>
                                    <ImageSeriesStatusOrderHistory
                                        order_type={orderHistory.attributes.order_type || ""} order_status={orderHistory.attributes.status} imageGroupDelivery={this.state.imageGroupDelivery}
                                        imageGroupCollection={this.state.imageGroupCollection} deliveryStatuses={this.state.orderStatusesDelivery}
                                        collectionStatuses={this.state.orderStatusesCollection} />
                                </div>
                            </div>
                            <div className='restaurant_action_button' >
                                <div className='items_rest_details'>
                                    <div className='items_details'><div className='items_details_heading' ><img src={imageNotes} alt='notes' /> Items</div> <div>{orderHistory.attributes.items_count}x</div></div>
                                    <div className='items_details' style={{ borderLeft: "2px solid #D4D4D4" }}><div className='items_details_heading' ><img src={imageRestaurantSign} alt='notes' /> Restaurant</div> <div>{orderHistory.attributes.restaurant}</div></div>
                                    <div className='items_details' style={{ borderLeft: "2px solid #D4D4D4" }}><div className='items_details_heading' ><img src={imageEuroSign} alt='notes' />Total Amount</div> <div>£{orderHistory.attributes.total}</div></div>
                                </div>
                                <div className='buttonContainerOrderHistory'>
                                {activeOrNot === "Active" ? (
                                <Button disabled={!this.helpIconDisabled(orderHistory.attributes.status)} onClick={() => {this.openTask(orderHistory.id,orderHistory.attributes.status,orderHistory.attributes.estimated_time);this.setCancelData(orderHistory)}} className={!this.helpIconDisabled(orderHistory.attributes.status)?"reorderButtonDisabled":"reorderButton"}>
                                    TRACK ORDER
                                </Button>
                            ) : (
                                (orderHistory.attributes.status === "collected" || orderHistory.attributes.status === "delivered" || this.helpIconDisabled(orderHistory.attributes.status)) ? (
                                    <Button onClick={() => this.reorderItems(orderHistory.id)} className="reorderButton">
                                        REORDER
                                    </Button>
                                ) : null
                            )}
                            <Button onClick={() => this.openViewOrderDetails(orderHistory.id)} className='viewDetailsButton'>VIEW DETAILS</Button>
                                </div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }

    render() {
        const {isActive,reasonString,cancelationOptions} = this.state
        return (
            <>
            <Profile navigateToPages={this.navigateToPage}>
                <div className="gift_card_panel">
                    <Search navigation={this.props.navigation} id="search" token={this.state.token} setOrderDetails={this.setSearchOrderHistoryData} />
                    {this.state.message?
                    <div className='no_result_search_found'>
                    <img src={noOrderHistoryResultFound} alt="no result found" />
                    <div style={{fontFamily:"Barmeno",display:"flex", flexDirection:"column",alignItems:"center", gap:"16px"}}>
                        <div className='heading_no_result'>No results found</div>
                        <div className='content_no_result'>Try searching for other order number or check your input.</div>
                    </div>
                </div>:
                    <>
                    {this.accordianToBeshown("Active",this.state.active_orders)}
                    {this.accordianToBeshown("Past",this.state.past_orders)}
                    </>
    }
                </div>
            </Profile>
             <DialogPopupTasks className='dialog_wh' id={"taskPopup"} closeIcon={true} open={this.state.trackOrder} onClose={this.closeTask}>
                <Task id={this.state.id} token={this.state.token} orderStatus={this.state.orderStatusTosend} orderType={this.state.orderType}
                    imageGroupCollection={this.state.imageGroupCollection}
                    imageGroupDelivery={this.state.imageGroupDelivery} orderStatusesDelivery={this.state.orderStatusesDelivery} estimatedTimeTosend={this.state.estimatedTimeTosend}
                    orderStatusesCollection={this.state.orderStatusesCollection}
                     navigation={this.props.navigation} openCancelPopup={this.openCancelPopupOnly} >
                </Task>
            </DialogPopupTasks>
            <DialogPopupTasks open={this.state.viewOrderDetailsOpen} id={"detailPopup"} onClose={this.closeViewOrderDetails}>
                <ViewOrderDetails OrderDetails={this.state.OrderDetails} formatDate={formatDateandTime} getInvoiceDataDownload={this.downloadInvoice}/>
            </DialogPopupTasks>
            <DialogPopupWeb open={this.state.cancelPopup} closeIcon={true} onClose={this.closeCancelPopup}>
               <div className='cancelPopupContainer'>
                <div className='cancelPopupHeadingContainer'>
                    <div className='cancelPopupMainHeading'>Cancel Complete Order</div>
                    <div className='cancelPopupSubHEading'>Cancelling this order will process a full refund. This action cannot be undone.</div>
                </div>
                <div className='cancelPopupBodyContainer'>
                    <div className='cancelPopupOrderBody'>
                        <div className='cncelOrderInfoContainer'>
                            <div className='orderDescContainer'>
                            <div className='orderDescContainerKey'>Order Number:</div>
                            <div className='orderDescContainerValue'>#{this.state.orderHistoryCancel.attributes.order_number}</div>
                            </div>
                            |
                            <div  className='orderDescContainer'>
                            <div className='orderDescContainerKey'>Total Items:</div>
                            <div className='orderDescContainerValue'>{this.state.orderHistoryCancel.attributes.items_count}x</div>
                            </div>
                        </div>
                                <div className='reasonKeyContainer' style={{position:"relative",display:"flex",flexDirection:"column",gap:"5px"}} >
                                    <div className='reasonKey' >Reason<span style={{ color: "#E11B22" }}>*</span></div>
                                    <div tabIndex={0} onBlur={this.toggleDropdownClose}  className={`${isActive ? 'select-active' : ''}`}
                                    >
                                        <div style={{ color: "#A2A2A2" }} id="selection-drop" className={`select-selected ${isActive && 'select-selected-disabled'}`} onClick={this.toggleDropdown} >
                                            {reasonString ? `${reasonString}` : 'Select Reason'}
                                        </div>
                                        <div className={`select-items ${isActive ? '' : 'select-hide'}`} style={{ top: '58px' }}>
                                            {cancelationOptions.map(value =>
                                                <div onClick={()=>this.setReasonString(value.reason,value.id)} key={value.id} className="optionSelected" >{value.reason}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {reasonString.toLowerCase() === "other" && <div>
                                    <div className='reasonKey' >Reason for declining</div>
                                    <div className='exclude_allergen_container' style={{ paddingTop: "12px" }} >
                                        <textarea
                                            style={{ borderColor: this.state.reasonCancellationCountError ? "#E11B22" : "#BFBFBF" }}
                                            className='notesAccordainTextArea'
                                            placeholder='Reason'
                                            onChange={this.handleChangeProductChefNotes}
                                            value={this.state.reasonCancellationString}
                                        />
                                        <div className='wordCount_excludeAllergen_container'>
                                            <div className='wordCount_excludeAllergen'>{this.state.reasonCancellationCount}/50</div>
                                            <div className='wordCount_excludeAllergen_error'>{this.state.reasonCancellationCountError}</div>
                                        </div>

                                    </div>
                                </div>}

                    </div>
                    <div className='buttonContainer'>
                        <button className='repeatOrderButton1' onClick={this.closeCancelPopup} >No, Don’t Cancel</button>
                        <button className={this.buttonDisabled() ?'newOrderButton1Disabled':'newOrderButton1'} disabled={this.buttonDisabled()} onClick={this.postCancelOrderRequest} >Cancel Order</button>
                    </div>
                </div>
                </div>
            </DialogPopupWeb>
            <SuccessFailModal open={this.state.orderCancellationSuccesFailPopup} onClose={this.openSuccessFailPopup} closeWithoutOpen={this.openSuccessFailPopup} img={'success'}
            head={'Cancel Order Request Sent!'}
            successMsg="Your cancellation request has been sent to Wingo's admin.
            A refund will be initiated once approved, and you'll receive a confirmation email with the details." />
            </>
        )
    }

}
