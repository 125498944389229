import React from "react";
// Customizable Area Start
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import SearchIcon from '@material-ui/icons/Search';
// Customizable Area End

// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div
      data-testid="search"
      className="search__wrapper"
      // onClick={this.handleFocus}
    >
      <SearchIcon fontSize="small" />
      <BaseInput
        // inputRef={this.searchRef}
        placeholder="Search by rider/area"
        // value={this.state.searchPreupdateValue}
        onChange={this.props.changeSearch}
        data-test-id={"searchInput"}
      />
    </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
