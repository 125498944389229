// Customizable Area Start
import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button
} from "@material-ui/core";
import './Gift.css'
import InappgiftingController, {
  Props
} from "./InappgiftingController";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import Profile from "./../../../components/src/Profile";
import GiftCardDetailsPopup from '../../../components/src/GiftCardDetailPopup.web';
import MessageEnum from "../../../framework/src/Messages/MessageEnum";
import ImageComponent from '../../../components/src/ImageComponent';
import { noGiftCardHistory } from './assets';
import CustomLoader from '../../../components/src/CustomLoader.web';

export default class GiftCardHistory extends InappgiftingController {
  constructor(props: Props) {
    super(props);
  }

  getTableBody = () => {
    const { giftCardList} = this.state;
    return (
      <TableBody>
        {giftCardList.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            style={{ cursor: "auto" }}
            className="table_row_with_gap"
          >
            <TableCell
              align="center"
              className="table_cell_giftcard_history"
            >
              {row.attributes.refrence_number}
            </TableCell>
            <TableCell className="table_cell_giftcard_history" align="center">{row.attributes.recipients_name}</TableCell>
            <TableCell className="table_cell_giftcard_history" align="center">£{row.attributes.gift_card_value}</TableCell>
            <TableCell style={{ color: "#E11B22" }} className="table_cell_giftcard_history" align="center">£{row.attributes.redeemed_amount === null ? 0 : row.attributes.redeemed_amount}</TableCell>
            <TableCell style={{ color: "#2C6F37" }} className="table_cell_giftcard_history" align="center">£{row.attributes.balance_amount}</TableCell>
            <TableCell className="table_cell_giftcard_history"><IconButton id='openPopup' onClick={() => this.openPopup(row.id)}><KeyboardArrowRightRoundedIcon /> </IconButton></TableCell>
          </TableRow>
        )
        )}
      </TableBody>
    )
  }

  getHistoryPanel = () => {
    return (
      <div className="giftCardHistoryPanel">
        <div className="giftCardHistoryHeading">Gift Card History</div>
        {this.state.giftCardList.length > 0 ? <button className="giftCardHistoryButton" onClick={() => this.navigateToPage(MessageEnum.NavigationGiftCardBlock)}>Buy New Gift Card</button>: <div></div>}
      </div>
    )
  }

  getTableHeader = () => {
    return (
      <TableHead style={{ position: "relative", top: "10px" }}>
        <TableRow>
          {this.state.headCells.map((headCell, index) => (
            <TableCell
              style={{ fontFamily: "Barmeno", fontWeight: 700, color: "#747474", fontSize: "16px", borderBottom: "1px solid #BFBFBF", backgroundColor: "#fff" }}
              key={index}
              align={"center"}
            >
              {headCell}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  getPagination = () => {
    return (
      <div className='pagination_container'>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={this.state.page === 1}
          aria-label="previous page"
        >
          <KeyboardArrowLeftRoundedIcon />
        </IconButton>
        <div className='pageNumberContainer'>
          <div style={{ color: "#CD191F" }}>{this.state.page}</div>
          {this.state.page + 1 < this.state.totalPage ? <div className="currentPage" onClick={this.navigateToGiftCard} >{this.state.page + 1}</div> : <div></div>}
          <div>...</div>
          <div className="totalPage" onClick={this.navigateToFinalGiftCard}>{this.state.totalPage}</div>
        </div>
        {console.log("page",this.state.page," ",this.state.totalPage)}
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={this.state.page === this.state.totalPage}
          aria-label="next page"
        >
          <KeyboardArrowRightRoundedIcon />
        </IconButton>
      </div>
    )
  }


  render() {
    return (
      <Profile navigateToPages={this.navigateToPage}>
        <div className="gift_card_panel">
          {this.getHistoryPanel()}
          {this.state.loading?  <CustomLoader loading={this.state.loading}/> :( this.state.giftCardList.length > 0 ?
          <div>
          <TableContainer style={{ overflowX: "scroll" }}>
            <Table style={{ minWidth: 780, borderSpacing: '0px 0px', borderCollapse: "separate", width: "100%" }} size="medium">
              {this.getTableHeader()}
              {this.getTableBody()}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={this.state.giftCardList.length}
            rowsPerPage={10}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            ActionsComponent={this.getPagination}
            labelDisplayedRows={({ from, to, count }) => ``}
          />
          </div>:
          <div style={{backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", height:"460px", boxSizing:"border-box", padding:"24px"}}>
            <ImageComponent imageUrl={noGiftCardHistory} alt='no-gift-card' />
            <div style={{display:"flex",flexDirection:"column" ,justifyContent:"center", alignItems:"center", gap:"16px", fontFamily:"Barmeno", fontSize:"20px", fontWeight:700}}>
              <div>No Gift Card activity yet! </div>
              <div>Your gift card history is waiting to be filled with joy! Start gifting now.</div>
              <Button id='buttonTestId' onClick={() => this.navigateToPage(MessageEnum.NavigationGiftCardBlock)} style={{color:"white", backgroundColor:"#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight:500, fontSize:"16px"}}>
                BUY GIFT CARD
              </Button>
            </div>
            </div>)}
        </div>
        <GiftCardDetailsPopup open={this.state.popup} onClose={this.closePopup} giftCardDetails={this.state.giftCardDetails} />  
      </Profile>
    );
  }
}

// Customizable Area End
