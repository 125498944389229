Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getAddressApiContentType = "application/json";
exports.getAddressApiMethod = "GET";
exports.postAddressApiMethod = "POST";
exports.updateAddressApiMethod = "PUT";
exports.patchAddressApiMethod = "PATCH"
exports.findCustomerDetail = "bx_block_order_management/pos_orders/find_customer?search="
exports.customerListEndPoint= "bx_block_cfposcustomermanagementfe/customers"
exports.marketingInfoUpdateEndPoint = "bx_block_cfposcustomermanagementfe/customers"
exports.getOrderDetailsApiEndPoint = "bx_block_order_management/orders"
exports.addItemsToCartApiEndPoint = "bx_block_order_management/pos_orders/create_order_by_pos_user"
// Customizable Area End
