import React from "react";
import { FormControl, FormControlLabel, FormHelperText, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { Add } from "@mui/icons-material";
import CheckoutController, { Props } from "./CheckoutController.web";
import DashboardWrapper from "../../../../components/src/Dashboard/DashboardWrapper";
import DataGrid from "../../../../components/src/Dashboard/DataGrid";
import WingoRadio from "../../../../components/src/Radio/Radio";
import FormLabel from "../../../../components/src/FormLabel";
import CustomGreenCheckbox from "../../../../components/src/CustomGreenCheckBox.web";
import DateInput from "../../../../components/src/DateTimePicker";
import PhoneInputComponent from "../../../../components/src/PhoneInput";
import ReactTimeInput from "../../../../components/src/TimeInput/TimeInput";
import Modal from "../../../../components/src/Modal";
import SuccessPopup from "../../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import { ContainedButton, OutlinedButton, UnStyledButton, WingoButton } from "../../../../components/src/Button";
import { ChevronLeft, ChevronRight, PencilIcon, TrashIcon } from "../../../../components/src/Icons";
import { UnstyledInput, WingoInput } from "../../../../components/src/Inputs/CustomInputs";
import { orderPlacedImage } from "../../../../components/src/assets";
import { deleteAddress } from "../../../addressmanagement/src/assets";
import "./Checkout.css";

export default class PosCheckout extends CheckoutController {
  constructor(props: Props) {
    super(props);
  };

  renderPaymentMethod = () => {
    const { paymentMethod, orderType } = this.state;
    const orderTypeLabel = orderType === "store_type" ? "In store" : `on ${orderType}`;

    return (
      <div className="checkout__cards checkout__payment_method">
        <div className="checkout__card__header">
          <h2>Payment Method</h2>
        </div>

        <RadioGroup
          row
          name="paymentMethod"
          value={paymentMethod}
          onChange={this.handleRadioChange}
          classes={{
            root: "checkout__stack__radio_group",
          }}
        >
          {/* <FormControlLabel
            data-selected={paymentMethod === "Credit/Debit Card"}
            value="Credit/Debit Card"
            label="Credit/Debit Card"
            control={<WingoRadio />}
            classes={{
              root: "checkout__payment_method__radio",
              label: "checkout__payment_method__label",
            }}
          />

          {paymentMethod === "Credit/Debit Card" && this.renderCardDetails()} */}

          <FormControlLabel
            data-selected={paymentMethod === "Delivery/Collection"}
            value="Delivery/Collection"
            label={`Pay ${orderTypeLabel}`}
            control={<WingoRadio />}
            classes={{
              root: "checkout__payment_method__radio",
              label: "checkout__payment_method__label",
            }}
          />

          {(paymentMethod === "Delivery/Collection" && orderType === "delivery") && this.renderPayOnDeliveryRadio()}
        </RadioGroup>
      </div>
    );
  };

  renderCardDetails = () => {
    const { cardNumber, expiryDate, cvv } = this.state;

    return (
      <div className="payment_methods__card_details">
        <FormControl fullWidth>
          <FormLabel>Card Number</FormLabel>
          <WingoInput
            name="cardNumber"
            placeholder="1234 1234 1234 1234"
            value={cardNumber}
            onChange={this.handleInputChange}
          />
          <FormHelperText error={!!this.state.errors.cardNumber}>
            {this.state.errors.cardNumber ?? " "}
          </FormHelperText>
        </FormControl>

        <FormControl className="payment_methods__card_details__short_input">
          <FormLabel>Expiry Date</FormLabel>
          <WingoInput
            name="expiryDate"
            placeholder="MM/YY"
            value={expiryDate}
            onChange={this.handleInputChange}
          />
          <FormHelperText error={!!this.state.errors.expiryDate}>
            {this.state.errors.expiryDate ?? " "}
          </FormHelperText>
        </FormControl>

        <FormControl className="payment_methods__card_details__short_input">
          <FormLabel>CVV</FormLabel>
          <WingoInput
            name="cvv"
            placeholder="123"
            value={cvv}
            onChange={this.handleInputChange}
          />
          <FormHelperText error={!!this.state.errors.cvv}>
            {this.state.errors.cvv ?? " "}
          </FormHelperText>
        </FormControl>
      </div>
    );
  };

  renderPayOnDeliveryRadio = () => {
    const { payOnDelivery } = this.state;

    return (
      <RadioGroup
        row
        name="payOnDelivery"
        value={payOnDelivery}
        onChange={this.handleRadioChange}
        classes={{
          root: "checkout__inline__radio_group radio_group__child",
        }}
      >
        <FormControlLabel
          data-selected={payOnDelivery === "card"}
          value="card"
          label="Card"
          control={<WingoRadio />}
          classes={{
            root: "checkout__radio",
          }}
        />

        <FormControlLabel
          data-selected={payOnDelivery === "cash"}
          value="cash"
          label="Cash"
          control={<WingoRadio />}
          classes={{
            root: "checkout__radio",
          }}
        />
      </RadioGroup>
    );
  };

  renderSelectOrderTime = () => {
    const { orderType, priority, orderTime, restaurantSettings, selectedAddress, addressList, error } = this.state;

    return (
      <div className="checkout__cards checkout__select_order_time">
        <div className="checkout__card__header">
          <h2>Select Order Time</h2>
        </div>

        <div className="checkout__order_type">
          <RadioGroup
            row
            name="orderType"
            value={orderType}
            onChange={this.handleRadioChange}
            classes={{
              root: "checkout__inline__radio_group",
            }}
          >
            <FormControlLabel
              data-selected={orderType === "delivery"}
              value="delivery"
              label="Delivery"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />

            <FormControlLabel
              data-selected={orderType === "collection"}
              value="collection"
              label="Collection"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />

            <FormControlLabel
              data-selected={orderType === "store_type"}
              value="store_type"
              label="In Store"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />
          </RadioGroup>

          <div className="checkout__separator" />

          <FormControlLabel
            label="Priority"
            name="priority"
            control={<CustomGreenCheckbox />}
            value={priority}
            onChange={this.handleCheckboxChange}
          />
        </div>

        {error.minimumValue && (
          <div className="checkout__select_order_time__error">
            <p>{error.minimumValue}</p>
          </div>
        )}

        {orderType === "delivery" && (
          <div className="checkout__order_type__list addresses">
            <h3>Select an address:</h3>
            {addressList.length > 0 ? (
              <RadioGroup
                name="selectedAddress"
                value={selectedAddress}
                onChange={this.handleRadioChange}
                classes={{ root: "checkout__stack__radio_group addresses" }}
              >
                {addressList.map(address => (
                  <div className="checkout__stack__radio_group__addresses__item">
                    <FormControlLabel
                      control={<WingoRadio />}
                      value={address.id}
                      label={`${address.attributes.address}, ${address.attributes.city}, ${address.attributes.country}, ${address.attributes.post_code}`}
                      classes={{
                        root: "checkout__radio",
                      }}
                    />

                    <div>
                      <IconButton>
                        <PencilIcon />
                      </IconButton>

                      <IconButton onClick={() => this.openConfirmDeleteAddress(address.id)}>
                        <TrashIcon color="#E11B22" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </RadioGroup>
            ) : (
              <WingoButton
              startIcon={<Add />}
              onClick={this.openCreateAddress}
              >Add Address</WingoButton>
            )}
          </div>
        )}

        <div className="checkout__order_type__list">
          <h3>Select Order Time</h3>
          <RadioGroup
            name="orderTime"
            value={orderTime}
            onChange={this.handleRadioChange}
            classes={{
              root: "checkout__stack__radio_group",
            }}
          >
            <FormControlLabel
              value="immediately"
              label="Immediately"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />

            {orderTime === "immediately" && (
              <p className="checkout__select_order_time__subtext radio_group__child">Estimated Time: {orderType === "delivery" ? restaurantSettings?.delivery_type.delivery_time : restaurantSettings?.delivery_type.collection_time} minutes</p>
            )}

            <FormControlLabel
              value="later"
              label="Choose Date & Time"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />

            {orderTime === "later" && this.renderDateTimePicker()}
          </RadioGroup>
        </div>
      </div>
    );
  };

  renderDateTimePicker = () => {
    const { laterDate, laterTime } = this.state;

    return (
      <div className="checkout__datetime__wrapper">
        <DateInput
          name="laterDate"
          placeholderText="YYYY-MM-DD"
          value={laterDate}
          onChange={this.handleDateTimeChange}
          className="checkout__datetime__input"
        />

        <ReactTimeInput
          value={laterTime as any}
          onChange={this.handleTimeChange}
        />
      </div>
    );
  };

  showTotalAmount = () => {
    const { deliveryCharges, orderDetails, orderType, isDiscountApplied } = this.state;

    return (
      <div className="checkout__cards checkout__total">
        <UnStyledButton
          endIcon={<ChevronRight />}
          className="checkout__total__button"
          onClick={this.openDiscount}
        >
          {isDiscountApplied ? "Remove" : "Apply"} Discount/Coupon
        </UnStyledButton>

        <div className="checkout__total__row">
          <p className="checkout__total__row__title">Sub Total</p>
          <p className="checkout__total__row__value">{orderDetails?.data.attributes.sub_total}</p>
        </div>

        <div className="checkout__total__row additional_items">
          <p className="checkout__total__row__title">Additional Items Total</p>
          <p className="checkout__total__row__value">{0}</p>
        </div>

        <div className="checkout__total__row discount">
          <p className="checkout__total__row__title">Coupons/Discounts</p>
          <p className="checkout__total__row__value">{orderDetails?.data.attributes.applied_discount}</p>
        </div>

        {orderType === "delivery" && (
          <div className="checkout__total__row">
            <p className="checkout__total__row__title">Delivery Charges</p>
            <UnstyledInput
              name="deliveryCharges"
              value={deliveryCharges}
              onChange={this.handleInputChange}
              startAdornment={<div className="checkout__total__row__input__adornment">&pound;</div>}
              className="checkout__total__row__input"
              inputProps={{
                className: "checkout__total__row__input__pad",
              }}
            />
          </div>
        )}

        <hr className="checkout__total__separator" />

        <div className="checkout__total__row total">
          <p className="checkout__total__row__title">Total</p>
          <p className="checkout__total__row__value">{orderDetails?.data.attributes.total}</p>
        </div>
      </div>
    );
  };

  customerInfo = () => {
    const { orderType, first_name, last_name, mobile_number, selectedAddress, addressList, error } = this.state;
    const address = addressList.find((address) => address.id === selectedAddress);

    return (
      <div className="checkout__cards checkout__customer_info">
        <div className="checkout__card__header">
          <h2>Customer Information</h2>

          <OutlinedButton
            startIcon={<PencilIcon />}
            className="checkout__card__header__button"
            onClick={this.openEditCustomerInfo}
          >
            Edit
          </OutlinedButton>
        </div>

        <div className="checkout__customer_info__details">
          <div className="checkout__customer_info__details__item">
            <p className="checkout__customer_info__details__item title">Name</p>
            <p>{first_name} {last_name}</p>
          </div>

          <div className="checkout__customer_info__details__item">
            <p className="checkout__customer_info__details__item title">Mobile Number</p>
            <p>{mobile_number}</p>
          </div>

          {orderType === "delivery" && (
            <div className="checkout__customer_info__details__item address_list">
              <p className="checkout__customer_info__details__item title">Address</p>
              {!error.selectedAddress && address ? (
                <p>{address?.attributes.address}, {address?.attributes.city}, {address?.attributes.country}, {address?.attributes.post_code}</p>
              ) : (
                <div className="checkout__customer_info__details__address_missing">
                  <p>{error.selectedAddress}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  editCustomerModal = () => {
    const { editCustomerInfo, first_name, last_name, mobile_number } = this.state;

    return (
      <Modal
        closeButton
        maxWidth="sm"
        open={editCustomerInfo}
        onClose={this.closeEditCustomerInfo}
        classes={{ paper: "edit_customer__wrapper" }}
      >
        <h3>Edit Customer</h3>

        <div className="edit_customer__name_fields">
          <FormControl fullWidth>
            <FormLabel>First Name</FormLabel>
            <WingoInput
              name="first_name"
              value={first_name}
              onChange={this.handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>Last Name</FormLabel>
            <WingoInput
              name="last_name"
              value={last_name}
              onChange={this.handleInputChange}
            />
          </FormControl>
        </div>

        <FormControl>
          <FormLabel>Mobile Number</FormLabel>
          <PhoneInputComponent
            fullWidth
            placeholder="Mobile Number"
            country={"gb"}
            inputProps={{
              name: "mobile_number",
              required: true,
            }}
            value={mobile_number}
            onChange={this.handleMobileInputChange}
          />
        </FormControl>

        <div className="edit_customer__buttons">
          <OutlinedButton onClick={this.closeEditCustomerInfo}>
            Cancel
          </OutlinedButton>
          <ContainedButton onClick={this.updateCustomerInfo}>
            Update
          </ContainedButton>
        </div>
      </Modal>
    );
  };

  createAddressModal = () => {
    const { openCreateAddress, addressInfo } = this.state;

    return (
      <Modal
        closeButton
        maxWidth="sm"
        open={openCreateAddress}
        onClose={this.closeCreateAddress}
        classes={{ paper: "create_address__paper" }}
      >
        <h3>Create Address</h3>

        <FormControl>
          <FormLabel>Save Address as</FormLabel>
          <RadioGroup
            row
            name="addressInfo::address_type"
            value={addressInfo?.address_type}
            onChange={this.handleRadioChange}
            classes={{ row: "create_address__address_type" }}
          >
            <FormControlLabel value="Home" label="Home" control={<WingoRadio />} classes={{ root: "create_address__address_type__root" }} />
            <FormControlLabel value="Work" label="Work" control={<WingoRadio />} classes={{ root: "create_address__address_type__root" }} />
            <FormControlLabel value="Other" label="Other" control={<WingoRadio />} classes={{ root: "create_address__address_type__root" }} />
          </RadioGroup>
        </FormControl>

        <div className="create_address__form__grid">
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <WingoInput
              name="addressInfo::name"
              value={addressInfo?.name}
              onChange={this.handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>Mobile Number</FormLabel>
            <PhoneInputComponent
              fullWidth
              placeholder="Mobile Number"
              country={"gb"}
              inputProps={{
                name: "addressInfo::full_phone_number",
                required: true,
              }}
              value={addressInfo?.full_phone_number}
              onChange={this.handleMobileInputChange}
            />
          </FormControl>

          <FormControl fullWidth classes={{ root: "create_address__form__grid__input span" }}>
            <FormLabel>Address</FormLabel>
            <WingoInput
              name="addressInfo::address"
              value={addressInfo?.address}
              onChange={this.handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>City</FormLabel>
            <WingoInput
              name="addressInfo::city"
              value={addressInfo?.city}
              onChange={this.handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>Country</FormLabel>
            <WingoInput
              name="addressInfo::country"
              value={addressInfo?.country}
              onChange={this.handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth classes={{ root: "create_address__form__grid__input span" }}>
            <FormLabel>Post Code</FormLabel>
            <WingoInput
              name="addressInfo::post_code"
              value={addressInfo?.post_code}
              onChange={this.handleInputChange}
            />
          </FormControl>
        </div>

        <div className="confirm_delete_address__buttons">
          <OutlinedButton onClick={this.closeCreateAddress}>CANCEL</OutlinedButton>
          <ContainedButton onClick={this.createAddress}>SAVE</ContainedButton>
        </div>
      </Modal>
    );
  };

  confirmDeleteAddress = () => {
    const { confirmDeleteAddress } = this.state;
    const { open } = confirmDeleteAddress;
    return (
      <Modal
        closeButton
        maxWidth="xs"
        open={open}
        onClose={this.closeConfirmDeleteAddress}
        classes={{ paper: "confirm_delete_address__paper" }}
      >
        <img src={deleteAddress} alt="delete address" className="confirm_delete_address__image" />
        <h3>Delete Address</h3>
        <p>Are you sure you want to delete an address from your profile?</p>

        <div className="confirm_delete_address__buttons">
          <OutlinedButton onClick={this.closeConfirmDeleteAddress}>CANCEL</OutlinedButton>
          <ContainedButton onClick={this.deleteAddress}>DELETE</ContainedButton>
        </div>
      </Modal>
    );
  };

  openDiscountModal = () => {
    const { openDiscountModal, discountCode, code, discountErrors, isDiscountApplied } = this.state;

    return (
      <Modal
        closeButton
        maxWidth="xs"
        open={openDiscountModal}
        onClose={this.closeDiscount}
      >
        <div className="discount__wrapper">
          <h2>{isDiscountApplied ? "Remove" : "Apply"} Discount/Coupon</h2>

          <RadioGroup
            row
            name="discountCode"
            value={discountCode}
            onChange={this.handleRadioChange}
            classes={{
              root: "checkout__inline__radio_group",
            }}
          >
            <FormControlLabel
              disabled={isDiscountApplied}
              label="Gift Card"
              value="giftCard"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />

            <FormControlLabel
              disabled={isDiscountApplied}
              label="Coupon Code"
              value="coupon"
              control={<WingoRadio />}
              classes={{
                root: "checkout__radio",
              }}
            />
          </RadioGroup>

          <FormControl fullWidth>
            <FormLabel>{discountCode === "coupon" ? "Coupon Code" : "Gift Card"}</FormLabel>
            <WingoInput
              disabled={isDiscountApplied}
              name="code"
              placeholder={`Enter the ${discountCode === "coupon" ? "Coupon Code" : "Gift Card Code"}`}
              value={code}
              onChange={this.handleInputChange}
            />
            <FormHelperText error>{discountErrors}</FormHelperText>
          </FormControl>

          <ContainedButton onClick={isDiscountApplied ? this.removeDiscount : this.applyDiscount}>
            {isDiscountApplied ? "REMOVE" : "APPLY"}
          </ContainedButton>
        </div>
      </Modal>
    );
  };

  orderPlacedModal = () => {
    const { orderPlacedSuccess } = this.state;
    const { navigation } = this.props;

    return (
      <Modal
        maxWidth="xs"
        open={orderPlacedSuccess}
        classes={{ paper: "order_placed__wrapper" }}
      >
        <img src={orderPlacedImage} alt="order has been placed" />
        <h3>Order Placed!</h3>
        <p>Thank you for placing your order! Please wait until your order gets accepted by our restaurant.</p>
        <ContainedButton onClick={() => navigation.navigate("PosOrderManagementManager")}>
          Back to Order List
        </ContainedButton>
      </Modal>
    );
  };

  render(): React.ReactNode {
    const { navigation } = this.props;
    const { cartDetails, note } = this.state;

    return (
      <DashboardWrapper hideSidebar gutter={false} navigation={navigation}>
        <div className="bg-img checkout__wrapper">
          <div className="checkout__section checkout__order_info">
            <div className="checkout__cards checkout__items_list">
              <div className="checkout__card__header">
                <h2>Checkout Items</h2>

                <OutlinedButton
                  startIcon={<ChevronLeft />}
                  className="checkout__card__header__button"
                  onClick={() => navigation.goBack()}
                >
                  Back
                </OutlinedButton>
              </div>

              <DataGrid
                data={cartDetails || []}
                columns={this.columns}
              />
            </div>

            {this.customerInfo()}

            <div className="checkout__cards checkout__notes">
              <div className="checkout__card__header">
                <h2>Notes</h2>
              </div>

              <FormControl fullWidth>
                <WingoInput
                  multiline
                  name="note"
                  rows={4}
                  placeholder="Add Notes for Chef"
                  value={note}
                  onChange={this.handleInputChange}
                />

                <FormHelperText error={!!this.state.noteError} classes={{ root: "checkout__card__note_error" }}>
                  <span>{this.state.noteError}</span>
                  <span>{this.state.note.trim().length}/250</span>
                </FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="checkout__section checkout__payment_info">
            {this.renderPaymentMethod()}
            {this.renderSelectOrderTime()}
            {this.showTotalAmount()}

            <div className="checkout__buttons">
              <OutlinedButton>
                CANCEL
              </OutlinedButton>

              <ContainedButton disabled={this.checkErrors()} onClick={this.handleCheckout}>
                CONFIRM
              </ContainedButton>
            </div>
          </div>
        </div>

        {this.openDiscountModal()}
        {this.editCustomerModal()}
        {this.confirmDeleteAddress()}
        {this.createAddressModal()}
        {this.orderPlacedModal()}

        <SuccessPopup
          open={this.state.successPopup.open}
          onClose={this.closeSuccessPopup}
          message={this.state.successPopup.message}
        />
      </DashboardWrapper>
    );
  }
};
