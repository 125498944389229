import React from "react";
import Sidebar from "../../../components/src/Dashboard/Sidebar/Sidebar";
import Header from "../../../components/src/Dashboard/Header/Header";
import OrderManagementController, {
  CartItem,
  OrderData,
  Props,
} from "./OrderManagementController";
import { FormControl, IconButton, Pagination, Table, TableContainer } from "@mui/material";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import CustomLoader from "../../../components/src/CustomLoader.web";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import "./CatalogueStyles.css";
import { invoice_btn } from "../../../components/src/SocialLogin/assets";
import ImageSeriesStatusOrderHistory from "../../../components/src/ImageColumnStatusOrderHistory.web";
import Maps from "../../maps/src/Maps.web";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { avatar } from "../../tasks/src/assets";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import { AllergenIcon } from "../../../components/src/Icons";
import Modal from "../../../components/src/Modal";
import FormLabel from "../../../components/src/FormLabel";
import { WingoInput } from "../../../components/src/Inputs/CustomInputs";
import PhoneInputComponent from "../../../components/src/PhoneInput";
import { ContainedButton, OutlinedButton } from "../../../components/src/Button";
import { cancelledImg, cancelledOrderPos, PrintIcon } from "./assets";
import CustomGreenCheckbox from "../../../components/src/CustomGreenCheckBox.web";
import ErrorPopup from "../../../components/src/ErrorPopup";

export default class OrderNumber extends OrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  orderDropDown = () => {
    const { orderDetails } = this.state
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "24px", justifyContent: "space-between", alignItems:"flex-end" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <IconButton onClick={this.navigateToBack} style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "14px", color: "#525252", cursor: "pointer", width: "fit-content" }}> <ArrowBackRoundedIcon /> Back</IconButton>
          <div style={{ fontSize: "30px" }}>
            Order Number #{orderDetails.attributes.order_number} | £{orderDetails.attributes.total}
          </div>
        </div>
        <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
          <IconButton style={{borderRadius:"8px", border:"1px solid #E11B22", backgroundColor:"#fff", height:"56px", width:"56px",
            padding:"16px"
          }} onClick={this.navigateToOrderPrinting}><img src={PrintIcon} style={{width:"24", height:"24"}} /></IconButton>
        <button className={this.cancelledButtonDisabled2() ?"disabledButtonPosOrder":"cancelOrderButtonPos"} onClick={this.orderToBecnacelled} disabled={this.cancelledButtonDisabled2() } >CANCEL ORDER</button>
        <button className={this.cancelledButtonDisabled() ?"disabledButtonPosOrder":"editOrderButtonPos"} disabled={this.cancelledButtonDisabled() }>EDIT ORDER</button>
        </div>

      </div>
    )
  }

  tableHeader = () => {
    return (
      <Grid container style={{ textAlign: 'center', borderBottom: "1px solid #909090", backgroundColor: "#F8FAFC", borderRadius: "4px" }}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography className="orderManagementTableCell">Number</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Typography className="orderManagementTableCell">Items</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography className="orderManagementTableCell">Quantity</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography className="orderManagementTableCell">Amount</Typography>
        </Grid>
      </Grid>
    )
  }

  tableBody = (data: CartItem[]) => {
    const totalPages = Math.ceil(data.length / this.state.rowsPerPage);
    return (
      <Box>
        <Grid container style={{ textAlign: 'center' }}>
          {this.getPaginatedData(data).map((item: CartItem, index: number) => (
            <Box key={index} style={{ width: "100%" }}>
              <Grid container item lg={12} style={{ height: 'auto', alignItems: 'center' }}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <div style={{display: "flex",flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                  {this.state.orderToBeCancelled && <CustomGreenCheckbox onChange={()=>{this.changeCartIds(item.attributes.id,item.attributes.total_item_price  )}} checked={this.state.cartIds.includes(item.attributes.id)} />}
                  <Typography style={{fontFamily:"barmeno"}}>{this.displayIndex(index)}</Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "8px 24px" }}>
                    <div style={{ fontSize: "14px", fontWeight: 500, alignSelf: "flex-start", color: "#1D1D1D" }}>{item.attributes.product_name}</div>
                    <div style={{ fontSize: "12px", fontWeight: 500, alignSelf: "flex-start", color: "#747474", textAlign: "start" }}>{this.getCartExtraDetails(item)}</div>
                    {item.attributes.allergen_excluded && (
                      <div style={{ display: "flex", alignItems: "center", gap: "4px", fontSize: "12px", fontWeight: 500, alignSelf: "flex-start", color: "#E11B22", textAlign: "start" }}>
                        <AllergenIcon width={16} height={16} />
                        <span>{item.attributes.allergen_excluded}</span>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography style={{fontFamily:"barmeno"}}>{item.attributes.quantity}x</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} className="statusValueChipContainer">
                  {item.attributes.total_item_price_after_discount}
                </Grid>
              </Grid>
              <Divider />
            </Box>
          ))}
        </Grid>
        <Box style={{ float: "right", marginTop: '2px' }}>
          <Pagination

            count={totalPages}
            page={this.state.currentPage}
            onChange={this.handlePageChange} />
        </Box>
      </Box>
    );
  };

  editCustomerDetails = () => {
    const { editPersonalInfo } = this.state;

    return (
      <Modal
        maxWidth="xs"
        open={editPersonalInfo.open}
        onClose={this.closeEditPersonalEdtails}
      >
        <div className="edit_customer__wrapper">
          <h3>Edit Customer Information</h3>

          <div className="edit_customer__form">
            <FormControl fullWidth>
              <FormLabel>First Name</FormLabel>
              <WingoInput
                name="first_name"
                placeholder="First Name"
                value={editPersonalInfo.data?.first_name}
                onChange={this.handleCustomerInfoChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Last Name</FormLabel>
              <WingoInput
                name="last_name"
                placeholder="Last Name"
                value={editPersonalInfo.data?.last_name}
                onChange={this.handleCustomerInfoChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Mobile Number</FormLabel>
              <PhoneInputComponent
                fullWidth
                placeholder="Mobile Number"
                country={"gb"}
                inputProps={{
                  name: "mobile_number",
                  required: true,
                }}
                value={editPersonalInfo.data?.full_phone_number}
                onChange={this.handleMobileInputChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Address</FormLabel>
              <WingoInput
                disabled
                rows={3}
                name="address"
                placeholder="Address"
                value={editPersonalInfo.data?.address}
                onChange={this.handleCustomerInfoChange}
              />
            </FormControl>
          </div>

          <div className="edit_customer__buttons">
            <OutlinedButton onClick={this.closeEditPersonalEdtails}>
              CANCEL
            </OutlinedButton>
            <ContainedButton onClick={this.updateCustomerInfo}>
              UPDATE
            </ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  cancelOrderContainer = () => {
    const { isActive, isActiveRefund,reasonString, cancelationOptions } = this.state
    return (
      <div style={{width:"100%"}}>
        <div style={{display:"flex", flexDirection:"column",gap:"16px"}}>
        <div className='reasonKeyContainerCancel' style={{ position: "relative", display: "flex", flexDirection: "column", gap: "5px",width:"100%" }} >
          <div className='reasonKey' >Reason for Cancellation<span style={{ color: "#E11B22" }}>*</span></div>
          <div tabIndex={0} onBlur={this.toggleDropdownClose} className={`${isActive ? 'select-active' : ''}`}
          >
            <div style={{ color: "#A2A2A2" }} id="selection-drop" className={`select-selected ${isActive && 'select-selected-disabled'}`} onClick={this.toggleDropdown} >
              {reasonString ? `${reasonString}` : 'Select Reason'}
            </div>
            <div className={`select-items ${isActive ? '' : 'select-hide'}`} style={{ top: '58px' }}>
              {cancelationOptions.map(value =>
                <div onClick={() => this.setReasonString(value.reason, value.id)} key={value.id} className="optionSelected" >{value.reason}</div>
              )}
            </div>
          </div>
        </div>
</div>
        {reasonString.toLowerCase() === "other" && <div>
          <div className='reasonKey' >Reason for declining</div>
          <div className='exclude_allergen_container' style={{ paddingTop: "12px" }} >
            <textarea
              style={{ borderColor: this.state.reasonCancellationCountError ? "#E11B22" : "#BFBFBF" }}
              className='notesAccordainTextArea'
              placeholder='Reason'
              onChange={this.handleChangeCancellationOtherReason}
              value={this.state.reasonCancellationString}
            />
            <div className='wordCount_excludeAllergen_container'>
              <div className='wordCount_excludeAllergen'>{this.state.reasonCancellationCount}/50</div>
              <div className='wordCount_excludeAllergen_error'>{this.state.reasonCancellationCountError}</div>
            </div>

          </div>
        </div>}
      </div>
    )
  }

  submitCancelButtonConatiner=()=>{
    return(
      <div className="buttonContainerCancelConfirmStyle">
        <button className={"cancelConfirmOrderButtonPos"} onClick={this.orderToBecnacelledClose} >No, Don’t Cancel</button>
        <button className={(this.confirmCancelButtonDisabled() || this.confrimOrderCancelledDisabledManager())?"disabledButtonPosOrder":"cancelOrderButtonPos"} onClick={this.setOrderCancelInitiation} disabled={(this.confirmCancelButtonDisabled() || this.confrimOrderCancelledDisabledManager())} >Confirm</button>
      </div>
    )
  }

  getInitiateRefundAmount=(orderDetails:OrderData)=>{
    return(
      <div style={{width:"100%",height:"100%",display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"column",gap:"40px"}}>
          
          <div style={{display:"flex",flexDirection:"row",gap:"10px",justifyContent:"space-between", alignItems:"center"}}>
            <div  style={{display:"flex",flexDirection:"row",gap:"24px",fontSize:"20px",fontWeight:700,justifyContent:"space-between"}}>
              <div  style={{color:"#747474"}}>Order Amount</div>
              <div  style={{color:"#1D1D1D"}}>£{orderDetails.attributes.total}</div>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"92px",fontSize:"24px",fontWeight:700,alignItems:"center"}}>
            <div  style={{color:"#747474"}}>Refund Amount</div>
            <div className="totalRefundAmountInput">£{this.getTotalRefundAmount()}</div>
            </div>
          </div>
          <div style={{display:"flex",flexDirection:"row",gap:"10px",justifyContent:"space-between", alignItems:"center"}}>
          <div style={{display:"flex",flexDirection:"row",gap:"24px",fontSize:"16px",fontWeight:700,alignItems:"center"}}>
            <div  style={{color:"#747474"}}>Payment Method Used Amount:</div>
            <input className="totalRefundAmountInput" style={{width:"66px"}} value={`£${this.state.paymentMethodUsedAmount}`} onChange={(event)=>{this.onChangeRefundValue("paymentMethodUsedAmount",event.target.value)}}/>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"24px",fontSize:"16px",fontWeight:700,alignItems:"center"}}>
            <div  style={{color:"#747474"}}>E-Gift Card Amount:</div>
            <input className="totalRefundAmountInput"  style={{width:"66px"}} value={`£${this.state.eGiftCardAmount}`} onChange={(event)=>{this.onChangeRefundValue("eGiftCardAmount",event.target.value)}}/>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"24px",fontSize:"16px",fontWeight:700,alignItems:"center"}}>
            <div  style={{color:"#747474"}}>Reward Point Amount:</div>
            <input className="totalRefundAmountInput"  style={{width:"66px"}} value={`£${this.state.rewardPointAmount}`} onChange={(event)=>{this.onChangeRefundValue("rewardPointAmount",event.target.value)}}/>
            </div>
          </div>
        </div>
        { !this.state.orderToBeCancelled && <div className="buttonContainerCancelConfirmStyle">
        <button className={"cancelConfirmOrderButtonPos"} onClick={this.initiateRefundClose} >No, Don’t Initiate</button>
        <button className={"cancelOrderButtonPos"} onClick={this.setOrderRefundInitiation} >Initiate Refund</button>
      </div>}
      </div>
    )
  }

  mainStatusContainer = (orderDetails: OrderData) => {
    const { image, status } = this.onStatusImages(orderDetails.attributes.status);
    return(
    <Grid item lg={9} md={9} sm={12} xs={12}>
      <Box
        style={{
          width: "100%",
          maxHeight: "480px",
          backgroundColor: "#fff",
          borderRadius: "16px",
          padding: (this.state.orderToBeCancelled || this.state.initiateRefund) ? "40px 24px" : "10px 24px",
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          justifyContent: "space-between",
        }}
      >
        {this.renderOrderStatusContent(orderDetails,image,status)}

        <div style={{ display: "flex", flexDirection: "row", gap: "24px",height:"100%" }}>
          {(!this.helpIconDisabled(orderDetails.attributes.status) && !this.state.orderToBeCancelled) && this.renderRiderDetails(orderDetails,status)}

          {(!this.helpIconDisabled(orderDetails.attributes.status) && !this.state.orderToBeCancelled )&& this.renderTimeDetails(orderDetails)}

          <div style={{ display: "flex", flexDirection: "column", gap: "24px",height:"100%",width:"100%" }}>
          {this.state.orderToBeCancelled && this.cancelOrderContainer()}


          {(this.state.initiateRefund || this.state.orderToBeCancelled) && this.state.role === "In Store Manager" &&
            this.getInitiateRefundAmount(orderDetails)}
            </div>
        </div>

        {this.state.orderToBeCancelled && this.submitCancelButtonConatiner()}
      </Box>
    </Grid>
    )
  }

  renderOrderStatusContent = (orderDetails: OrderData,image:string,status:string) => {
    if (orderDetails.attributes.status === "in_transit") {
      return (
        <Maps
          navigation={this.props.navigation}
          id="maps"
          order_id={Number(orderDetails.id)}
          token={this.state.token}
          origin={{ lat: "51.509865", long: "-0.118092" }}
          destination={{ lat: "51.509865", long: "-0.118092" }}
          messageDriver={""}
          setMessageDriver={() => { }}
        />
      );
    }

    if (this.helpIconDisabled(orderDetails.attributes.status) && !this.state.initiateRefund) {
      return (
        <div className="order_cancelledImageCOntainer">
        <div>
          <img
            className="placed_icon"
            style={{ height: "304px" }}
            src={cancelledOrderPos}
            aria-details={status}
            alt="tracker_icon"
          />
        </div>
        <div>Order Cancelled by the restaurant</div>
        </div>
      );
    }

    if (!this.state.orderToBeCancelled && !this.state.initiateRefund) {
      return (
        <div>
          <img
            className="placed_icon"
            style={{ height: "304px" }}
            src={image}
            aria-details={status}
            alt="tracker_icon"
          />
        </div>
      );
    }

    return null;
  }

  renderRiderDetails = (orderDetails: OrderData,statusToShow:string) => {
    const { status, driver_full_name, driver_details } = orderDetails.attributes;

    if (status === "delivered" || status === "collected") return null;

    return driver_details ? (
      <div className="rider_details" style={{ padding: "24px 40px" }}>
        <img src={avatar} alt="avatar" />
        <div>
          <Typography className="delivery_status">
            {status === "ready"
              ? `${driver_full_name} is on his way to pick your order.`
              : status === "in_transit"
                ? `${driver_full_name} is on his way to deliver your order.`
                : `${driver_full_name} will pick the order when the food is ready`}
          </Typography>
          {status === "in_transit" && (
            <IconButton
              style={{
                color: "#E11B22",
                fontWeight: 700,
                borderRadius: "8px",
                border: "0px solid",
                background: "transparent",
                textTransform: "capitalize",
                display: "flex",
                flexDirection: "row",
                gap: "2px",
                alignItems: "center",
              }}
            >
              Change Rider
              <ArrowForwardIosRoundedIcon style={{ fontSize: "14px" }} />
            </IconButton>
          )}
        </div>
        <IconButton
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "44px",
            width: "44px",
            boxSizing: "border-box",
            boxShadow: "0px 2px 8px 0px #00000014",
            borderRadius: "8px",
          }}
          onClick={() => this.handleCall(driver_details)}
        >
          <CallRoundedIcon style={{ color: "#E11B22" }} />
        </IconButton>
      </div>
    ) : (
      <div className="order_status_container_text">{statusToShow}</div>
    );
  }

  renderTimeDetails = (orderDetails: OrderData) => {
    const { scheduled_time, estimated_time } = orderDetails.attributes;

    return (
      <div className="schedule_time_container">
        <AccessTimeRoundedIcon />
        <div className="schedule_time_text" style={{flexDirection:"row"}}>
          {scheduled_time ? (
            <>
              Scheduled Time:{" "}
              <span style={{ color: "#2C6F37", fontSize: "14px" }}>
                {this.convertToDate(estimated_time)}
              </span>
            </>
          ) : (
            <>
              Estimated Time:{" "}
              <span style={{ color: "#2C6F37", fontSize: "14px" }}>
                {estimated_time}
              </span>
            </>
          )}
        </div>
      </div>
    );
  }

  
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { orderDetails } = this.state
    return (
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main style={{padding:"12px 12px"}} data-testid="dashboard-main" className="dashboard__main">
          <Header fullWidthTitle navigation={this.props.navigation} headerTitle={this.orderDropDown()} notToShow="no_show"/>
          <Box >
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box style={{
                  width: '100%',
                  height: '330px',
                  backgroundColor: '#fff',
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: "8px 0px 0px 0px",
                  gap: "3px",
                  justifyContent: "space-between"
                }}>
                  <Grid container style={{ padding: '12px 24px' }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "24px", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "flex", flexDirection: "row", gap: "8px", width: "fit-content", alignItems: "center", justifyContent: "space-between" }} onClick={this.openEditPersonalDetails}>
                        <div>
                          <PersonOutlineOutlinedIcon />
                        </div>
                        <div style={{ fontWeight: 700 }}>Customer Information</div>
                      </div>
                      <IconButton
                        disabled={!this.state.account_id}
                        style={{
                          textAlign: 'center',
                          width: '34px',
                          height: '32px',
                          backgroundColor: '#fff',
                          boxShadow: '0px 4px 8px 4px #00000014',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        onClick={this.openEditPersonalDetails}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </div>
                    <Divider style={{ width: '100%', marginTop: '8px' }} />
                    <div className="personalInfoOrderDetails">
                      <div>{orderDetails.attributes.personal_detail.first_name}</div>
                      <div>{orderDetails.attributes.personal_detail.full_phone_number}</div>
                      <div>{orderDetails.attributes.delivery_addresses[0]?.address}</div>
                    </div>
                  </Grid>
                  <Box style={{
                    backgroundColor: '#FAF4F4', height: '80px', width: '100%', borderRadius: '15px', display: "flex", flexDirection: "row", padding: "12px 24px", gap: "16px"
                  }}>
                    <Grid item style={{ fontWeight: 700, alignItems: "center", display: "flex" }} lg={1}>
                      <EventNoteOutlinedIcon />
                    </Grid>
                    <Grid item lg={11}>
                      <Typography style={{ fontWeight: 700, fontSize: "16px",fontFamily:"Barmeno" }}>Notes</Typography>
                      <Typography style={{ fontWeight: 700, fontSize: "14px", color: "#525252",fontFamily:"Barmeno" }}>{orderDetails.attributes.notes_to_chef ? orderDetails.attributes.notes_to_chef : "N/A"}</Typography>
                    </Grid>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Box style={{
                  width: '100%',
                  height: '330px',
                  backgroundColor: '#fff',
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: "8px 0px 0px 0px",
                  gap: "3px"
                }}>
                  <div className="orderToShowTable">
                    <div className="orderDeailsContainer">
                      <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", fontWeight: 700, fontSize: "18px" }} >
                        <img src={this.getOrderTypeIcon(orderDetails.attributes.order_type)} />Order Items
                      </div>
                      <Divider style={{ width: "1px", height: "24px" }} />
                      <div style={{ color: "#747474" }}>
                        {this.convertToDate(orderDetails.attributes.placed_at)}
                      </div>
                      <Divider style={{ width: "1px", height: "24px" }} />
                      <div style={{ textTransform: "capitalize" }}>
                        Payment: {orderDetails.attributes.payment_source}
                      </div>
                      {(this.refundIconDisabled(orderDetails.attributes.status) && orderDetails.attributes.payment_source !== "cash" && this.state.role === "In Store Manager") &&<IconButton onClick={this.initiateRefundActive} disabled={this.state.initiateRefund} className={this.state.initiateRefund?"initiateRefundButtonDsabled":"initiateRefundButton"}>INITIATE REFUND</IconButton>}
                    </div>
                    <button disabled={this.disabledDownloadInvoice()} className="invoiceDownloadButton" onClick={this.downloadInvoice}>VAT Receipt <img src={invoice_btn} alt='invoice_btn' /></button>
                  </div>
                  <div>
                    <TableContainer className="tableContainerMaterial">
                      <Table style={{ minWidth: 572 }} aria-label="simple table">
                        {this.tableHeader()}
                        {this.tableBody(orderDetails.attributes.items_detail.data)}
                      </Table>
                    </TableContainer>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box >
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box style={{
                  width: '100%',
                  height: '480px',
                  backgroundColor: '#fff',
                  borderRadius: '16px'
                }}>
                  <Grid container style={{ padding: '12px 10px', alignItems: "center" }}>
                    <Grid style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: "44px",
                      width: "44px",
                      boxSizing: "border-box",
                      boxShadow: " 0px 2px 8px 0px #00000014",
                      borderRadius: "8px"
                    }} item lg={2} md={2} sm={2} xs={2}>
                      <TrackChangesOutlinedIcon />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} >
                      <Typography style={{fontFamily:"Barmeno"}}>Order Status</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <button disabled={this.disabledSkipStatus()} className="order_number__skip" style={{ fontSize: "16px", fontWeight: 700, color: "#E11B22", cursor: "pointer" }} onClick={() => this.orderAction("skip")}>Skip</button>

                    </Grid>
                  </Grid>
                  <Divider style={{ width: '90%', margin: 'auto' }} />
                  <div className='order_status_imagesPos'>
                  {this.statusDisbaled(orderDetails.attributes.status)?
                    <ImageSeriesStatusOrderHistory
                      order_type={orderDetails.attributes.order_type} order_status={orderDetails.attributes.status} imageGroupDelivery={this.getImageGroupDelivery}
                      imageGroupCollection={this.getImageCollection} deliveryStatuses={this.state.orderStatusesDelivery}
                      collectionStatuses={this.state.orderStatusesCollection} />:
                      <div style={{padding:"4px",display:"flex",flexDirection:"row",gap:"16px",fontWeight:700}}><img src={cancelledImg} alt='order_placed' /> {orderDetails.attributes.status.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}</div> }
                  </div>
                </Box>
              </Grid>
              {this.mainStatusContainer(orderDetails)}
            </Grid>
          </Box>
        </main>
        <CustomLoader loading={this.state.loading} />
        {this.state.editPersonalInfo.open && this.editCustomerDetails()}
        <SuccessPopup
          open={this.state.successPopup.open}
          onClose={this.closeSuccessPopup}
          message={this.state.successPopup.message}
        />
        <SuccessPopup
          open={this.state.cancellationSuccess.open}
          onClose={this.closeCancellationSuccess}
          message={this.state.cancellationSuccess.message}
          subMessage={this.state.cancellationSuccess.sub_message}
        />
        <ErrorPopup
          open={this.state.cancellationError.open}
          onClose={this.closeCancelError}
          message=""
          subMessage={this.state.cancellationError.message}
        />
      </div>
    )
  }
  // Customizable Area End
}
