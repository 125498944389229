
import React from "react";
import moment from "moment-timezone";
import { DateLocalizer, Event, Navigate, ViewProps } from "react-big-calendar";
import { EventComponent } from "../../components/Event";
import "./Month.css";

const MonthView = ({ date, onView, events, components, onNavigate }: ViewProps) => {
  const [shifts, setShifts] = React.useState<Event[]>([]);
  const [monthDates, setMonthDates] = React.useState<string[]>([]);
  const MAX_EVENTS_PER_DAY = 3; // Limit of events to display before showing "Show more"

  const startOfMonth = React.useMemo(() => moment(date).startOf("month"), [date]);
  const endOfMonth = React.useMemo(() => moment(date).endOf("month"), [date]);

  const { data } = components;
  const { selectedStaff, selectedRole, activeView, openShiftPopup } = data;

  // Generate dates for the full month view including padding for weeks
const generateMonthDates = React.useCallback(() => {
  const dates: string[] = [];

  let current = startOfMonth.clone().startOf("week"); // Start from Sunday of the first row
  const lastDay = endOfMonth.clone().endOf("week"); // Ensure a full 6-row grid

  while (current <= lastDay) {
    dates.push(current.format("YYYY-MM-DD"));
    current.add(1, "day");
  }

  return dates;
}, [startOfMonth, endOfMonth]);


  const handleShowMore = () => {
    onView && onView("week"); // Trigger week view for the selected date
  };

  // Update month dates when date changes
  React.useEffect(() => {
    setMonthDates(generateMonthDates());
  }, [generateMonthDates]);

  // Filter events for the current month
  React.useEffect(() => {
    events && setShifts(events);
  }, [events, startOfMonth, endOfMonth]);

  return (
    <div className="month__wrapper">
      <div className="month__header">
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
          <div key={index} className={`month__header__day`}>
            {day}
          </div>
        ))}
      </div>
      <div className="month__grid">
        {monthDates.map((day, index) => {
          const isCurrentMonth = moment(day).isBetween(startOfMonth, endOfMonth, "day", "[]");
          const isToday = moment(day).isSame(moment(), "day");
          const dayEvents = shifts.filter(
            (event) => moment(event.start).format("YYYY-MM-DD") === day
          );
          const showMore = dayEvents.length > MAX_EVENTS_PER_DAY;

          return (
            <div
              key={index}
              className={`month__grid__cell`}
            >
              {isCurrentMonth && (
                <>
                  <div
                    data-today={isToday}
                    className={`month__grid__date`}
                  >{moment(day).format("D")}</div>
                  <div className="month__grid__events">
                    {dayEvents.slice(0, MAX_EVENTS_PER_DAY).map((event, i) => (
                      <EventComponent
                        key={i}
                        selectedStaff={selectedStaff}
                        selectedRole={selectedRole}
                        activeView={activeView}
                        openShiftPopup={openShiftPopup}
                        event={event}
                      />
                    ))}
                    {showMore && (
                      <button className="month__grid__show-more" onClick={handleShowMore}>
                        Show {dayEvents.length - MAX_EVENTS_PER_DAY} more
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

MonthView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
  const start = localizer.startOf(date, "week", 0); // Start of the week (Monday)
  const end = localizer.endOf(date, "week", 0);     // End of the week (Sunday)

  const range = [];
  let current = start;

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day"); // Increment by 1 day
  }

  return range;
};

MonthView.navigate = (
    date: Date,
    action: any,
    { localizer }: { localizer: DateLocalizer }
) => {
    if (action instanceof Date) return action;

    switch(action) {
        case Navigate.NEXT:
            return localizer.add(date, 1, "month");
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "month");
        default:
            return date;
    }
};

MonthView.title = (
  date: Date,
  { localizer }: { localizer: DateLocalizer }
) => {
  const start = localizer.startOf(date, "week", 0);

  const month = moment(start).format("MMMM")

  return `${month}`;
};

export default MonthView;
