// Customizable Area Start
import React from "react";
import FaqController, { Props } from "./FaqController.web";
import { faqHeaderBg } from "./assets";
import "./Faq.css";
import { FormControl, FormHelperText } from "@mui/material";
import Popup from "../../../components/src/InteractiveFaq/Popup.web";
import ImageComponent from "../../../components/src/ImageComponent";
import FormLabel from "../../../components/src/FormLabel";
import PhoneInputComponent from "../../../components/src/PhoneInput";
import { WingoInput } from "../../../components/src/Inputs/CustomInputs";
import { ContainedButton } from "../../../components/src/Button";

export default class FaqContactus extends FaqController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const form = this.state.form;
    return (
      <div className="faq-contact-main-component">
        <ImageComponent
          imageUrl={faqHeaderBg}
          style={{ width: "100%" }}
          alt="faq-contact-header"
        />
        <header className="header" />
        <div>
          <div className="mainContactusDiv">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "17px",
                alignItems: "center",
              }}
            >
              <h4 style={{ fontFamily: "Barmeno" }} className="faqConHeading">
                Didn&apos;t find an answer?
              </h4>
              <div style={{ fontFamily: "Barmeno" }} className="faqConContent">
                Our team is just an email away and ready to answer your
                questions!
              </div>
            </div>
            <div className="formContainerContactus">
              <form data-testid="form" onSubmit={this.submitContact} className="contactusform">
                {Object.keys(form).map((field: string) => {
                  const label = field.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase());
                  const value = form[field];

                  return (
                    <FormControl fullWidth key={field} className="contactus_field__wrapper">
                      <FormLabel required htmlFor={field}>{label}</FormLabel>
                      {field === "phoneNumber" ? (
                        <PhoneInputComponent
                          inputProps={{
                            name: field,
                            required: true,
                          }}
                          country={"gb"}
                          value={value}
                          onChange={this.handlePhoneInputChange}
                        />
                      ) : (
                        <WingoInput
                          name={field}
                          value={value}
                          multiline={field === "message"}
                          minRows={field === "message" ? 5 : 1}
                          onChange={this.handleInputChange}
                        />
                      )}
                      <FormHelperText error>
                        {this.state.formErrors[field]}
                      </FormHelperText>
                    </FormControl>
                  )
                })}

                <ContainedButton type="submit">
                  SUBMIT
                </ContainedButton>
              </form>
            </div>
          </div>
        </div>
        <Popup
          open={this.state.openPopup}
          onClose={this.handleClosePopup}
          navigation={this.props.navigation}
        />
      </div>
    );
  }
}

// Customizable Area End
