import React from "react";

// Customizable Area Start
import "./Analytics.css"
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";
import { TopPerformingPaymentData } from "../../visualanalytics/src/VisualAnalyticsController";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  progressbarRetur=(data:TopPerformingPaymentData[])=>{
    const parsedData = data.map((item:TopPerformingPaymentData) => ({
      ...item,
      total_quantity: item.total_quantity !== null ? parseFloat(String(item.total_quantity)) : 0,
    }));
  
    const maxQuantity = Math.max(...parsedData.map((item:any) => item.total_quantity), 1);
  
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
        {parsedData.map((item:any, index:number) => {
          const percentage = (item.total_quantity / maxQuantity) * 100;
          return (
            <div key={index} style={{ display: "flex", flexDirection: "row", gap: "16px", justifyContent:"space-between", alignItems:"center" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth:"251px", width:"100%" }}>
              <span style={{ fontSize: "14px", fontWeight: 500, textTransform:"capitalize" }}>{item.source}</span>
              <div
                style={{
                  width: "100%",
                  height: "3px",
                  borderRadius: "16px",
                  background: "transparent",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: `${percentage}%`,
                    height: "100%",
                    borderRadius: "8px",
                    background: "linear-gradient(114.11deg, #409046 25.39%, rgba(25, 152, 35, 0.4) 94.9%)",
                  }}
                />
              </div>
              </div>
              <div>£{item.total_quantity}</div>
            </div>
          );
        })}
      </div>
      )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{display:"flex", flexDirection:"row",gap:"24px",justifyContent:"space-between"}}>
        <div className="orderStatisticsTopContainer">
          <div className="productTopHeading">Top Selling Items</div>
          <div className="topPerformerDetailContainer">
            {!!this.props.topPermingProducts.length && this.props.topPermingProducts.map((item,index)=>(
              <div key={index} className="topPerformingDetailProduct">
                  <div style={{fontSize:"14px",fontWeight:500}}>{item.title}</div> <div className="itemCountContainer">Sold: {item.total_quantity}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="orderStatisticsTopContainer">
        <div className="productTopHeading">Top Customers</div>
        <div className="topPerformerDetailContainer">
        {!!this.props.topPerformingCustomer.length && this.props.topPerformingCustomer.map((item,index)=>(
              <div key={index} className="topPerformingDetailProduct">
                  <div style={{display:"flex", flexDirection:"column",gap:"4px"}}><div style={{fontSize:"14px",fontWeight:500}}>{item.first_name +" "+item.last_name}</div>
                  <div style={{fontSize:"14px",fontWeight:500}}>{""}</div>
                  </div> <div className="itemCountContainer">Orders: {item.total_quantity}</div>
              </div>
            ))}
        </div>
        </div>
        <div className="orderStatisticsTopContainer">
        <div className="productTopHeading">Top Payments</div>
        <div className="topPerformerDetailContainer">
          <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between",paddingBottom:"16px", borderBottom:"1px solid #D4D4D4", color:"#909090",fontWeight:500, fontSize:"12px"}}>
            <div>Category</div>
            <div>Amount</div>
          </div>
        {this.progressbarRetur(this.props.topPerformingPayments)}
        </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
