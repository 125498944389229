import {
  customerManagementIcon,
  dashboardIcon,
  deliveryAreasIcon,
  kitchenDashboardIcon,
  newOrderIcon,
  orderManagementIcon,
  staffInformationIcon,
  statisticsAndReportsIcon,
  stockReport,
  trackDriverIcon,
  wingosOrder
} from "../../../../blocks/dashboard/src/assets";

export const inStoreOperatorRoutes = [
  { name: "Dashboard", icon: dashboardIcon, href: `/Dashboard/InStoreOperator` },
  { name: "New Order", icon: newOrderIcon, href: `/Dashboard/NewOrderCreation` },
  { name: "Customer Management", icon: customerManagementIcon, href: `/Dashboard/CustomerManagement` },
  { name: "Order Management", icon: orderManagementIcon, href: `/Dashboard/OnlineOrderManagement`, disabled: false },
  { name: "Staff Information", icon: staffInformationIcon, href: `/Dashboard/StaffInformation` },
  { name: "Track Driver", icon: trackDriverIcon, href: `/Dashboard/TrackRider`, disabled: false },
  { name: "Delivery Areas", icon: deliveryAreasIcon, href: `/Dashboard/DeliveryAreas` },
];

export const inStoreManagerRoutes = [
  { name: "Dashboard", icon: dashboardIcon, href: `/Dashboard/InStoreManager` },
  { name: "New Order", icon: newOrderIcon, href: `/Dashboard/NewOrderCreation` },
  { name: "Customer Management", icon: customerManagementIcon, href: `/Dashboard/CustomerManagement` },
  { name: "Order Management", icon: orderManagementIcon, href: `/Dashboard/OnlineOrderManagement`, disabled: false },
  { name: "Staff Information", icon: staffInformationIcon, href: `/Dashboard/StaffInformation` },
  { name: "Track Driver", icon: trackDriverIcon, href: `/Dashboard/TrackRider`, disabled: false },
  { name: "Delivery Areas", icon: deliveryAreasIcon, href: `/Dashboard/DeliveryAreas` },
  { name: "Statistics & Reports", icon: statisticsAndReportsIcon, href: `/Dashboard/StatisticsReports`, disabled: false },
];

export const chefRoutes = [
  { name: "Kitchen Dashboard", icon: kitchenDashboardIcon, href: `/Dashboard/Chef` },
];

export const onlineOrderManagerRoutes = [
  { name: "Dashboard", icon: dashboardIcon, href: `/Dashboard/OnlineOrderManager` },
  { name: "Customer Management", icon: customerManagementIcon, href: `/Dashboard/CustomerManagement` },
  { name: "Order Management", icon: orderManagementIcon, href: `/Dashboard/OnlineOrderManagement`, disabled: false },
];

export const OrderManagerRoutes = [
  { name: "Wingo’s Orders", icon: wingosOrder, href: `/Dashboard/OnlineOrderManagement`,disabled: false },
  { name: "Stock Report", icon: stockReport, href: `/Dashboard/StockManagement`,disabled: false },
];
