import React from "react";
import moment from "moment";
import { IconButton, Popover } from "@mui/material";
import { Event, ToolbarProps } from "react-big-calendar";
import { AddRounded, CloseRounded } from "@material-ui/icons";
import { AddNoteIcon, CalendarOutlined, ChevronLeft, ChevronRight, WarningAmberOutlined } from "../../../../Icons";
import { UnStyledButton } from "../../../../Button";
import useDidUpdate from "../../../../Hooks/useDidUpdate";
import { hasPermission } from "../../../../utils";
import "./Toolbar.css";

interface Props extends ToolbarProps {
  getShift: (id: number, startDate?: string, endDate?: string) => void;
  selectedStaff: number | null;
  selectedRole: string | null;
  openAddShift: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  openWeeklyNotes: (start: string, end: string) => void;
  range: {
    start?: string;
    end?: string;
  };
  setRange: React.Dispatch<React.SetStateAction<{
    start?: string;
    end?: string;
  }>>;
}

const Toolbar = ({ getShift, selectedStaff, selectedRole, openAddShift, totalShiftHours, range, setRange, ...props }: Props) => {
  const enableAddShift = hasPermission("StaffScheduleAccount", "add_shift");
  const enableViewNote = hasPermission("StaffScheduleAccount", "fetch_weekly_notes");
  const { date, label, onNavigate, views, view: activeView, onView, gaps, conflicts, openWeeklyNotes } = props;
  const currentDate = moment(date).format("YYYY-MM-DD");
  const error: { open: boolean; message: string; } = React.useMemo(() => ({
    open: !!gaps || !!conflicts,
    message: "Adjust shift times for continuous operation until store closing."
  }), [gaps, conflicts]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [startOfWeek, setStartOfWeek] = React.useState("");
  const [endOfWeek, setEndOfWeek] = React.useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getViews = () => {
    if (typeof views === "object") {
      return Object.values(views);
    } else {
      return views;
    };
  };

  React.useEffect(() => {
    if (activeView === "week") {
      const dateString = moment(date);
      const startOfWeek = dateString.clone().startOf('week').toISOString();
      const endOfWeek = dateString.clone().endOf('week').toISOString();

      setStartOfWeek(startOfWeek);
      setEndOfWeek(endOfWeek);
    }
  }, [activeView, date, setStartOfWeek, setEndOfWeek]);

  useDidUpdate(() => {
    const dateString = moment(date);
    const isWeek = activeView === "week";
    const start = dateString.clone().startOf(isWeek ? 'W' : 'M').format("YYYY-MM-DD");
    const end = dateString.clone().endOf(isWeek ? 'W' : 'M').format("YYYY-MM-DD");

    setRange({ start, end });
    getShift(selectedStaff ?? 0, start, end);
  }, [currentDate]);

  return (
    <>
      <div className="scheduler__header">
        <div className="scheduler__header__top">
          <div className="scheduler__header__left">
            <div className="scheduler__header__left__title">
              <span>{label}</span>
            </div>
            {!!totalShiftHours && (
              <p className="scheduler__header__left__total_hours">
                Total Hours: {totalShiftHours}
              </p>
            )}
            {!selectedStaff && error && (
              <div className="scheduler__header__left__error">
                <span>{error.message}</span>
                <IconButton
                  aria-describedby="conflicts-gaps-popover"
                  color="error"
                  onClick={handleClick}
                >
                  <WarningAmberOutlined />
                </IconButton>

                <Popover
                  id={open ? "conflicts-gaps-popover" : undefined}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{
                    paper: "scheduler__toolbar__popover"
                  }}
                >
                  <div className="scheduler__toolbar__popover__header">
                    <h1>Warning</h1>
                    <IconButton onClick={handleClose}>
                      <CloseRounded />
                    </IconButton>
                  </div>

                  <div className="scheduler__toolbar__popover__wrapper">
                    {!!gaps && (
                      <div className="scheduler__toolbar__popover__section">
                        <h2>Gaps</h2>
                        {Object.entries(gaps).map(([date, messages]) => (
                          <div key={date} className="scheduler__toolbar__popover__list">
                            <h3>{date}</h3>
                            <ul>
                              {messages.map((message, index) => (
                                <li key={index}>{message}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}

                    {!!conflicts && (
                      <div className="scheduler__toolbar__popover__section">
                        <h2>Conflicts</h2>
                        {Object.entries(conflicts).map(([date, messages]) => (
                          <div key={date} className="scheduler__toolbar__popover__list">
                            <h3>{date}</h3>
                            <ul>
                              {messages.map((message, index) => (
                                <li key={index}>{message}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Popover>
              </div>
            )}
          </div>

          <div className="scheduler__header__right">
            <div className="scheduler__header__right__button_group">
              <IconButton onClick={() => onNavigate("PREV")}>
                <ChevronLeft />
              </IconButton>
              <IconButton onClick={() => onNavigate("NEXT")}>
                <ChevronRight />
              </IconButton>
            </div>

            <IconButton onClick={() => onNavigate("TODAY")}>
              <CalendarOutlined />
            </IconButton>

            {!selectedStaff && activeView === "week" && (
              <IconButton disabled={!enableViewNote} onClick={() => openWeeklyNotes(startOfWeek, endOfWeek)}>
                <AddNoteIcon />
              </IconButton>
            )}

            <div className="scheduler__header__right__tab">
              {getViews().map((view) => (
                <div
                  key={view}
                  data-active={view === activeView}
                  className="scheduler_tab"
                  onClick={() => onView(view)}
                >
                  {view}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="scheduler__header__bottom">
          {selectedStaff && (
            <UnStyledButton
              disabled={!enableAddShift}
              startIcon={<AddRounded />}
              classes={{
                root: "scheduler__header__footer__button",
                text: "scheduler__header__footer__button__text",
              }}
              onClick={() => selectedRole && openAddShift(selectedStaff, selectedRole)}
            >
              Add Shift
            </UnStyledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default Toolbar;
