Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

exports.userDetails = {
  contentType: "application/json",
  method: "GET",
  endPoint: "bx_block_profile_bio/user_deatils",
}
exports.restaurantDetails = {
  contentType: "application/json",
  method: "GET",
  endPoint: "bx_block_cfpossettings1/restaurant_settings/get_setting",
};

exports.contentType = "application/json";
exports.getMethod = "GET";
exports.getUserPermissionsEndpoint = "bx_block_roles_permissions/get_user_permissions";

exports.orderManagerEndpoint = "bx_block_dashboard/online_order_manager";
exports.acceptOrder = "order_accept";
exports.storeManagerEndpoint = "bx_block_dashboard/store_manage_dashboard";
exports.cancelOrder = "order_cancel_by_store";
// Customizable Area End
