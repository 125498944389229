import React from "react";
import { ItemDetailData, ResponseItems } from "../../../../blocks/receiptcustomisation/src/types";
import { billingAllergen, billingNotes, receiptLogo } from "../../../../blocks/receiptcustomisation/src/assets";

interface Props {
  contentRef: React.RefObject<HTMLDivElement>;
  response: ResponseItems;
  items: Array<ItemDetailData>;
};

export default class ChefReceipt extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  };

  createStringSides = (sides: any[], drinks: any[], nibbles: any[], toppings: any[]) => {
    const items = [
      ...sides.map((value) => value.name),
      ...drinks.map((value) => value.name),
      ...nibbles.map((value) => value.name),
      ...toppings.map((value) => value.name)
    ];
    return items
  };

  convertDateTime = (dateString: string,waitTime?:number) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    date.setMinutes(date.getMinutes() + (waitTime ||0));

    const formattedTime = date.toLocaleTimeString('en-GB', options);
    return formattedTime
  };

  convertDateOnly = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formattedDate;
  };

  render(): React.ReactNode {
    const { contentRef, response, items } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "377.95px",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          fontFamily:"Consolas"
        }}
        ref={contentRef}
      >
        <div
          className="storeInformationContainerdiv"
          style={{
            padding: "40px 40px 32px 40px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <img src={receiptLogo} alt="reciept logo" />
        </div>
        <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
        <div
          style={{
            padding: "16px 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
              fontWeight: 700,
              fontSize: "32px",
              maxWidth: "320px",
              width: "100%",
              margin: "auto",
            }}
          >
            <div>{response.id}</div>
            <div style={{ textTransform: "capitalize" }}>{response.attributes.order_type === "store_type" ? "In Store" : response.attributes.order_type}</div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: "calc(100% - 5px)",
              justifyContent: "space-between",
              fontSize: 18
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent:"space-between",
                  width:"100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  {this.convertDateOnly(response.attributes.placed_at)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  {this.convertDateTime(response.attributes.placed_at)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                #{response.attributes.order_number}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px", fontWeight: 700 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "2px",
                }}
              >
                <div >Customer:</div>{" "}
                <div >
                  {`${response.attributes.buyer_details.first_name}` +
                    ` ${response.attributes.buyer_details.last_name}`}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "2px",
                }}
              >
                <div >Mobile Number:</div>{" "}
                <div>
                  {response.attributes.buyer_details.full_phone_number}
                </div>
              </div>
              {response.attributes.order_type === "collection" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "2px",
                  }}
                >
                  <div>Pickup Time:</div>{" "}
                  <div >
                    {this.convertDateTime(
                      response.attributes.placed_at,
                      response.attributes.restaurant.estimated_collection_time
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
        <div
          style={{
            padding: "16px 40px 8px 40px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "12px", fontSize: 18 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: 700,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                <div style={{ width: "30px" }}>Qty </div>
                <div> Product</div>
              </div>
            </div>
            {items.map((value: ItemDetailData) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                  <div
                    style={{ width: "30px", display: "flex", justifyContent: "end" }}
                  >
                    {value.attributes.quantity}{" "}
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                  >
                    {" "}
                    {value.attributes.product_name}
                    <div
                      style={{ display: "flex", flexDirection: "column", gap: "6px" }}
                    >
                      {value.attributes.choose_your_type && (
                        <div style={{ fontSize: "16px", fontWeight: 700 }}>
                          - {value.attributes.choose_your_type}
                        </div>
                      )}
                      {value.attributes.spice_level && (
                        <div style={{ fontSize: "16px", fontWeight: 700 }}>
                          - {value.attributes.spice_level}
                        </div>
                      )}
                      {this.createStringSides(
                        value.attributes.sides,
                        value.attributes.drinks,
                        value.attributes.nibbles,
                        value.attributes.toppings
                      ).map((value: string) => (
                        <div style={{ fontSize: "16px", fontWeight: 700 }}>
                          - {value}
                        </div>
                      ))}
                      {value.attributes.allergen_excluded && <div style={{fontSize:"18px", fontWeight:700, display:"flex", flexDirection:"row",gap:"4px", alignItems:"center" }}>
                      <img src={billingAllergen} alt="allergen" style={{width:"24px",height:"24px"}} /> {value.attributes.allergen_excluded}</div>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div></div>
          {response.attributes.notes_to_chef && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px", fontSize: "18px", fontWeight: 700 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}><img src={billingNotes} alt="notes" style={{ width: "24px", height: "24px" }} /> Notes: </div>
                <div>{response.attributes.notes_to_chef}</div>
              </div>}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: "30px",
              fontWeight: 700,
              padding: "0px 40px",
            }}
          >
            <div>Total</div> <div>£{response.attributes.total}</div>
          </div>
        </div>
        <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
        <div
          style={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            fontSize: "18px",
            fontWeight: 400,
            padding: "40px 40px 32px 40px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <div>Thank you for choosing Wingo’s </div>
            <div>Do visit again!</div>
          </div>
          <div>email: support@wingos.co.uk</div>
        </div>
      </div>
    );
  };
};
