import moment from "moment-timezone";
import validateCard from "card-validator";
import { debounce } from "lodash";
import { CountryData } from "react-phone-input-2";
import { ColumnDef } from "@tanstack/react-table";

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { CartItem, OrderDetails } from "../CfposPrductDescriptionController";
import { createMessage, getSessionStorage } from "../../../../components/src/utils";
import { CheckoutItemColumns } from "../../../../components/src/Dashboard/DataGrid/columns/CheckoutItemColumns";
import { AddressItem } from "../../../../components/src/ConfirmOrder/ConfirmOrder.web";
import { SelectChangeEvent } from "@mui/material";

export const configJSON = require("../config");

type StoreHours = {
  [day: string]: {
    open: string;
    close: string;
  };
};

type DeliveryType = {
  supports_delivery: boolean;
  delivery_time: number;
  supports_collection: boolean;
  collection_time: number;
};

type PaymentType = {
  accepts_card_payment: boolean;
  accepts_cash_payment: boolean;
};

type ContactInfo = {
  country_code: string;
  primary_phone: string;
  secondary_phone_country_code: string;
  secondary_phone: string;
};

export type RestaurantSettings = {
  restaurant: string;
  time_zone: string;
  time_format: string;
  store_hours: StoreHours;
  delivery_type: DeliveryType;
  payment_type: PaymentType;
  contact_info: ContactInfo;
};

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  token: string;
  account_id: string;
  orderId: string;
  loading: boolean;
  cartDetails: CartItem[];
  orderDetails: OrderDetails | null;
  deliveryAddress: any | null;
  note: string;
  noteError?: string;
  paymentMethod: string;
  cardNumber: string;
  cardType?: string;
  expiryDate: string;
  cvv: string;
  errors: Record<string, string>;
  payOnDelivery: string;
  orderType: "collection" | "delivery" | "store_type";
  priority: boolean;
  orderTime: string;
  restaurantSettings: RestaurantSettings | null;
  laterDate: string;
  laterTime: string;
  deliveryCharges: string;
  editCustomerInfo: boolean;
  first_name: string;
  last_name: string;
  mobile_number: string;
  openDiscountModal: boolean;
  discountCode: "coupon" | "giftCard";
  code: string;
  discountErrors: string | null;
  isDiscountApplied: boolean;
  addressList: AddressItem[];
  selectedAddress: string | null;
  confirmDeleteAddress: {
    open: boolean;
    id: string | null;
  };
  addressInfo: {
    name: string;
    full_phone_number: string;
    address: string;
    address_type: "Home" | "Work" | "Other";
    city: string;
    post_code: string;
    default_address: boolean;
    country: string;
  } | null;
  successPopup: {
    open: boolean;
    message: string;
  };
  openCreateAddress: boolean;
  error: {
    selectedAddress: string | null;
    minimumValue: string | null;
    [key: string]: string | null;
  };
  orderPlacedSuccess: boolean;
  [key: string]: any;
};

interface SS {};

export default class CheckoutController extends BlockComponent<Props, S, SS> {
  columns: ColumnDef<CartItem, any>[] = CheckoutItemColumns();
  getCartItemsCallId: string = "";
  getCustomerInfoCallId: string = "";
  updateCustomerInfoCallId: string = "";
  getDeliveryAddressCallId: string = "";
  addDeliveryChargesCallId: string = "";
  updateOrderTypeCallId: string = "";
  applyDiscountCallId: string = "";
  removeDiscountCallId: string = "";
  getAddressListCallId: string = "";
  deleteAddressCallId: string = "";
  updateAddressCallId: string = "";
  addAddressCallId: string = "";
  orderPaymentCallId: string = "";
  getChefNoteCallId: string = "";
  addChefNoteCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.addDeliveryCharges = debounce(this.addDeliveryCharges.bind(this), 300);
    this.updateOrderType = debounce(this.updateOrderType.bind(this), 300);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      account_id: "",
      orderId: "",
      loading: false,
      cartDetails: [],
      orderDetails: null,
      deliveryAddress: null,
      note: "",
      paymentMethod: "Delivery/Collection",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      errors: {},
      payOnDelivery: "cash",
      orderType: "delivery",
      priority: false,
      orderTime: "immediately",
      restaurantSettings: null,
      laterDate: moment().format("YYYY-MM-DD"),
      laterTime: "00:00",
      deliveryCharges: "0",
      editCustomerInfo: false,
      first_name: "",
      last_name: "",
      mobile_number: "",
      openDiscountModal: false,
      discountCode: "giftCard",
      code: "",
      discountErrors: null,
      isDiscountApplied: false,
      addressList: [],
      selectedAddress: null,
      confirmDeleteAddress: {
        open: false,
        id: null,
      },
      successPopup: {
        open: false,
        message: "",
      },
      addressInfo: null,
      openCreateAddress: false,
      error: {
        selectedAddress: null,
        minimumValue: null,
      },
      orderPlacedSuccess: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  async componentDidMount() {
    super.componentDidMount();

    await this.getToken();
    await this.getUrlParams();
    await this.getRestaurantSettings();
    await this.getChefNote();

    if (this.state.token)
      await this.getCartItems();
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (prevState.deliveryCharges !== this.state.deliveryCharges)
      this.addDeliveryCharges();

    if (prevState.note !== this.state.note) {
      this.state.note.trim().length > 250 ?
        this.setState({ noteError: "Note should not exceed 250 characters." }) :
        this.setState({ noteError: "" });
    };

    if (prevState.selectedAddress !== this.state.selectedAddress && this.state.orderType === "delivery") {
      this.state.selectedAddress ?
        this.setState(prevState => ({ error: { ...prevState.error, selectedAddress: null } })) :
        this.setState(prevState => ({ error: { ...prevState.error, selectedAddress: "Please select an address" } }));
    }
  }

  getToken = async () => {
    const token = localStorage.getItem("authToken");

    if (token)
      this.setState({
        token: token,
      });
  };

  getUrlParams = async () => {
    const params = new URLSearchParams(location.search);
    const account_id = params.get("account_id");

    if (account_id)
      this.setState({ account_id });
  };

  getRestaurantSettings = async () => {
    const settings = getSessionStorage("restaurant_settings");

    if (settings)
      this.setState({
        restaurantSettings: settings,
      });
  };

  handleTimeChange = (value: any) => {
    const time = this.state.restaurantSettings?.time_format === "24-hour" ?
      value.formatted24 : value.formatted12;
    this.setState({
      laterTime: time,
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "cardNumber") {
      const cardNumber = value.replace(/\s/g, "");
      if (cardNumber.length > 16) return;

      const formattedCardNumber = cardNumber.match(/.{1,4}/g)?.join(" ") ?? "";

      this.setState({ cardNumber: formattedCardNumber });
      return;
    };

    if (name === "cvv" && value.length > 4) return;

    if (name === "deliveryCharges") {
      if (value === "" || /^\d+$/.test(value)) {
        this.setState({ deliveryCharges: value });
      }
      return; // Added return to prevent further processing
    }

    if (name.includes("::")) {
      const [key, nestedKey] = name.split("::");
      this.setState(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key], // Preserve existing nested properties
          [nestedKey]: value,
        },
      }));
      return; // Added return to prevent further processing
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { name } = event.target;

    if (name === "discountCode") this.setState({ code: "", discountErrors: null });

    if (name.includes("::")) {
      const [key, nestedKey] = name.split("::");
      this.setState(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key], // Preserve existing nested properties
          [nestedKey]: value,
        },
      }));
      return; // Added return to prevent further processing
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }), () => {
      if (name === "selectedAddress") this.updateOrderType();
    });
  };

  handleSelectChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;

    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handleCheckboxChange = (event: React.SyntheticEvent, checked: boolean) => {
    const { name } = event.target as HTMLInputElement;

    if (name.includes("::")) {
      const [key, nestedKey] = name.split("::");
      this.setState(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key], // Preserve existing nested properties
          [nestedKey]: checked,
        },
      }));
      return; // Added return to prevent further processing
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  handleDateTimeChange = (date: Date | null, event: React.SyntheticEvent<any>) => {
    const { name } = event.target as HTMLInputElement;

    if (date) {
      this.setState(prevState => ({
        ...prevState,
        [name]: moment(date).format("YYYY-MM-DD"),
      }));
    }
  };

  handleMobileInputChange = (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
    const { name } = event.target;

    if ("dialCode" in data) {
      if (name.includes("::")) {
        const [key, nestedKey] = name.split("::");
        this.setState(prevState => ({
          ...prevState,
          [key]: {
            ...prevState[key], // Preserve existing nested properties
            [nestedKey]: value,
          },
        }));
        return; // Added return to prevent further processing
      }

      this.setState(prevState => ({ ...prevState, [name]: value }));
    }
  };

  openEditCustomerInfo = () =>
    this.setState({ editCustomerInfo: true });

  closeEditCustomerInfo = () =>
    this.setState({ editCustomerInfo: false });

  cardValidation = () => {
    const { cardNumber, expiryDate, cvv } = this.state;
    const errors: Record<string, string> = {};

    const cardNumberValidation = validateCard.number(cardNumber.replace(/\s/g, ""));
    const expiryDateValidation = validateCard.expirationDate(expiryDate);
    const cvvValidation = validateCard.cvv(cvv);

    if (!cardNumberValidation.isValid) {
      errors.cardNumber = "Invalid card number";
    }

    if (!expiryDateValidation.isValid) {
      errors.expiryDate = "Invalid";
    }

    if (!cvvValidation.isValid) {
      errors.cvv = "Invalid";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({
        cardNumber: cardNumber,
        cardType: cardNumberValidation.card?.niceType,
        expiryDate: `${expiryDateValidation.month}/${expiryDateValidation.year}`,
        cvv,
        errors: {}, // Clear errors if all validations pass
      });
    }
  };

  openDiscount = () =>
    this.setState({ openDiscountModal: true });

  closeDiscount = () =>
    this.setState({ openDiscountModal: false, code: "" });

  applyDiscount = () =>
    this.state.discountCode === "coupon" ? this.applyCoupon() : this.applyGiftCard();

  removeDiscount = () =>
    this.state.discountCode === "coupon" ? this.removeCoupon() : this.removeGiftCard();

  getMessageHandler(callId: string, _response: any) {
    const messageHandlers = {
      [this.getCartItemsCallId]: this.handleCartItems,
      [this.getCustomerInfoCallId]: this.handleCustomerInfo,
      [this.updateCustomerInfoCallId]: this.handleUpdateCustomerInfo,
      [this.getDeliveryAddressCallId]: this.handleDeliveryAddress,
      [this.applyDiscountCallId]: this.handleAppliedDiscount,
      [this.removeDiscountCallId]: this.handleRemoveDiscount,
      [this.addDeliveryChargesCallId]: this.getCartItems,
      [this.updateOrderTypeCallId]: this.getCartItems,
      [this.getAddressListCallId]: this.handleSavedAddresses,
      [this.addAddressCallId]: this.handleCreateAddress,
      [this.deleteAddressCallId]: this.handleDeleteAddress,
      [this.getChefNoteCallId]: this.handleGetChefNote,
      [this.addChefNoteCallId]: this.getChefNote,
      [this.orderPaymentCallId]: this.handlePayment,
    };

    return messageHandlers[callId];
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!response) return;

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler(response);
    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
  };

  getCartItems = async () => {
    const { account_id, token } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.getAllCartItemsApiEndPoint}${account_id}`,
      { "Content-Type": configJSON.validationApiContentType, token }
    );

    this.getCartItemsCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSavedAddresses = async () => {
    const { account_id } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.getAddressListApi}?account_id=${account_id}`,
    );

    this.getAddressListCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleSavedAddresses = (response: any) => {
    if (response && response.data) {
      const addressList = response.data;
      const selectedAddress = addressList.find((address: AddressItem) => address.attributes.default_address);


      this.setState({ addressList, selectedAddress: selectedAddress ? selectedAddress.id : this.state.selectedAddress });
    } else this.setState({ addressList: [], selectedAddress: null });

    if (!this.state.selectedAddress)
      this.setState(prevState => ({ error: { ...prevState.error, selectedAddress: "Please select an address" } }));
  };

  handleCartItems = (response: any) => {
    if (response && response.cart_details && response.order_details)
      this.setState({
        orderId: response.order_details.data.id,
        cartDetails: response.cart_details.data,
        orderDetails: response.order_details,
      }, () => {
        this.getCustomerInfo()
        this.getDeliveryAddress();
        this.setState({ isDiscountApplied: false, discountCode: "giftCard" });

        const promoCode = this.state.orderDetails?.data.attributes.promo_code_name;

        if (promoCode)
          this.setState({ isDiscountApplied: true, code: promoCode, discountCode: "coupon" });
      });
  };

  getCustomerInfo = () => {
    const { token, orderId, account_id } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.customerInfoEndpoint}?account_id=${account_id}`,
      { "Content-Type": configJSON.validationApiContentType, token }
    );

    this.getCustomerInfoCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  handleCustomerInfo = (response: any) => {
    if (response?.data)
      this.setState({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        mobile_number: response.data.full_phone_number,
      });
  };

  updateCustomerInfo = () => {
    const { token, orderId, account_id, first_name, last_name, mobile_number } = this.state;
    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.updateCustomerInfoEndpoint}?account_id=${account_id}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      { personal_detail: { first_name, last_name, full_phone_number: mobile_number }}
    );

    this.updateCustomerInfoCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  handleUpdateCustomerInfo = (response: any) => {
    if (response?.data)
      this.setState({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        mobile_number: response.data.full_phone_number,
      }, this.closeEditCustomerInfo);
  };

  getDeliveryAddress = () => {
    const { token, orderId, account_id } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.deliveryAddressEndpoint}?account_id=${account_id}`,
      { "Content-Type": configJSON.validationApiContentType, token }
    );

    this.getDeliveryAddressCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  handleDeliveryAddress = (response: any) => {
    if (response) {
      if (response.data && response.data.message)
        this.setState(prevState => ({ error: { ...prevState.error, minimumValue: response.data.message } }))
      else this.setState(prevState => ({ error: { ...prevState.error, minimumValue: null } }));

      this.setState({
        orderType: response.order_type,
        orderTime: response.prepare_immediately ? "immediately" : response.schedule_time,
        selectedAddress: response.address_id ? String(response.address_id) : null,
      }, this.getSavedAddresses)
    }
  };

  addDeliveryCharges = () => {
    const { token, orderId, deliveryCharges } = this.state;
    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.posOrderEndpoint}/${orderId}/${configJSON.addDeliveryChargeEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      { delivery_charges: deliveryCharges }
    );

    this.addDeliveryChargesCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  updateOrderType = () => {
    const { token, orderId, orderType, account_id, priority, addressList, selectedAddress } = this.state;
    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.deliveryTypeEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      {
        account_id: parseInt(account_id),
        order_type: orderType,
        is_priority: priority,
        ...orderType === "delivery" && {
          address: addressList.find(address => address.id === selectedAddress),
        }
      }
    );

    this.updateOrderTypeCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  addCollectionTime = () => {
    const { token, orderId, account_id, orderTime, laterDate, laterTime } = this.state;
    const schedule_time = moment(`${laterDate} ${laterTime}`).tz(moment.tz.guess()).toISOString();

    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.collectionTimeEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      {
        account_id: account_id,
        delivery: {
          prepare_immediately: orderTime === "immediately",
          ...(orderTime !== "immediately" && { schedule_time })
        }
      }
    );

    this.updateOrderTypeCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  applyCoupon = () => {
    const { token, orderId, account_id, code } = this.state;

    const getDataMsg = createMessage(
      "POST",
      `${configJSON.orderManagementEndpoint}/${orderId}/${configJSON.couponEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      { account_id: account_id, code }
    );

    this.applyDiscountCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  applyGiftCard = () => {
    const { token, orderId, account_id, code } = this.state;

    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.giftCardEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      {
        account_id: account_id,
        gift_card: true,
        refrence_number: code
      }
    );

    this.applyDiscountCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  handleAppliedDiscount = (response: any) => {
    if (
      response?.message === "Invalid coupon" ||
      response?.message === "You are not authorized to use this gift card" ||
      response?.errors
    ) {
      this.setState({ discountErrors: response?.message ?? "Please enter a valid code." });
      return;
    }

    this.closeDiscount();
    this.getCartItems();
  };

  removeCoupon = () => {
    const { token, orderId, account_id, code } = this.state;

    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${configJSON.removeCouponEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      { account_id: account_id, order_id: orderId, code }
    );

    this.removeDiscountCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  removeGiftCard = () => {
    const { token, orderId, account_id, code } = this.state;

    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderInfoEndpoint}/${orderId}/${configJSON.removeGiftCardEndpoint}`,
      { "Content-Type": configJSON.validationApiContentType, token },
      {
        account_id: account_id,
        gift_card: false,
        refrence_number: code
      }
    );

    this.removeDiscountCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg)
  };

  handleRemoveDiscount = () => {
    this.closeDiscount();
    this.getCartItems();
  };

  orderPayment = () => {
    const orderId = this.state.orderDetails?.data.id;
    const payment_source = this.state.payOnDelivery;
    const getDataMsg = createMessage(
      "PUT",
      `${configJSON.orderPaymentApi}/${orderId}/${configJSON.orderPaymentEndpoint}`,
      { "content-type": configJSON.contentType, token: this.state.token },
      { payment_source },
    );
    this.orderPaymentCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handlePayment = (response: any) => {
    if (response && response.message)
      this.setState({ orderPlacedSuccess: true });
  };

  openConfirmDeleteAddress = (id: string) =>
    this.setState({ confirmDeleteAddress: { open: true, id } });

  closeConfirmDeleteAddress = () =>
    this.setState({ confirmDeleteAddress: { open: false, id: null } });

  closeSuccessPopup = () =>
    this.setState({ successPopup: { open: false, message: "" } });

  deleteAddress = () => {
    const { account_id, confirmDeleteAddress } = this.state;
    const addressId = confirmDeleteAddress.id;

    const deleteDataMsg = createMessage(
      "DELETE",
      `${configJSON.getAddressListApi}/${addressId}?account_id=${account_id}`,
    );
    this.deleteAddressCallId = deleteDataMsg.messageId;
    runEngine.sendMessage(deleteDataMsg.id, deleteDataMsg);
  };

  handleDeleteAddress = (response: any) => {
    if (response && response.message)
      this.setState({
        successPopup: { open: true, message: response.message },
        confirmDeleteAddress: { open: false, id: null },
      }, this.getSavedAddresses);
  };

  createAddress = () => {
    const { account_id, addressInfo } = this.state;

    const createDataMsg = createMessage(
      "POST",
      configJSON.getAddressListApi,
      { "content-type": configJSON.contentType, token: this.state.token },
      {
        account_id: account_id,
        address: addressInfo
      }
    );
    this.addAddressCallId = createDataMsg.messageId;
    runEngine.sendMessage(createDataMsg.id, createDataMsg);
  };

  openCreateAddress = () =>
    this.setState({ openCreateAddress: true })

  closeCreateAddress = () =>
    this.setState({ openCreateAddress: false })

  handleCreateAddress = (response: any) => {
    if (response && response.data) {
      this.setState({ successPopup: { open: true, message: "Address added successfully" } }, this.getSavedAddresses);
    };

    this.closeCreateAddress();
  };

  getChefNote = async () => {
    const { account_id } = this.state;
    const getDataMsg = createMessage(
      "GET",
      `${configJSON.posOrdersApi}/${account_id}/${configJSON.getChefNoteEndpoint}`,
    );
    this.getChefNoteCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleGetChefNote = (response: any) => {
    if (response?.success)
      this.setState({ note: response?.chef_notes ?? "" });
  };

  addChefNote = () => {
    const { account_id, note } = this.state;
    const putDataMsg = createMessage(
      "PUT",
      `${configJSON.posOrdersApi}/${account_id}/${configJSON.addChefNoteEndpoint}`,
      { "content-type": configJSON.contentType, token: this.state.token },
      { notes_to_chef: note },
    );
    this.addChefNoteCallId = putDataMsg.messageId;
    runEngine.sendMessage(putDataMsg.id, putDataMsg);
  };

  checkErrors = (): boolean => {
    const { error, orderType } = this.state;

    for (const key in error) {
      if (key === "selectedAddress" && orderType !== "delivery") {
        continue; // Skip checking selectedAddress if orderType is not delivery
      }
      if (error[key] !== null) {
        return true;
      }
    }
    return false;
  }

  handleCheckout = () => {
    this.addChefNote();
    this.updateOrderType();
    this.addCollectionTime();
    this.orderPayment();
  };

};
