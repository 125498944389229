import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal, setLoggedInUser }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }

  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const setParam = (paramKey, paramValue)=>{
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramKey, paramValue);
    history.push({
      pathname: getPathName(),
      search: searchParams.toString(),
    });
  }

  const paramSearch = ()=>{
   return location.search
  }

  const goBack = () => {
    history.goBack();
  };

  const loggedInUser = (loggedIn) => {
    setLoggedInUser(loggedIn)
  }

  const getPathName = ()=>{
   return location.pathname
  }

  const getToken = () => {
    const regex = /[?&]token=([^&]*)/;

// Extract the token and its value using match method with the regular expression
const match = location.search.match(regex);

// Check if a match is found
if (match) {
  const paramName = 'token';
  const paramValue = match[1]; // Extracted token value
  const tokenObject = {
    [paramName]: paramValue
  };
  return tokenObject;
}
return {token: "" };
}

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, getToken,setParam},
    closeModal,
    loggedInUser: loggedInUser,
    location: getPathName(),
    locationSearch: paramSearch(),
    history
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap, loggedInUser }) => {
  const paths = ["/accountlogin", "/ForgotPassword", "/NewPassword", "/accountregistration", "/Faqs", "/payment_status"];
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <div
              className={`${paths.includes(currentRoute.path) ? "" : "bg-img"}`}>
              <Wrapper element={<Component />} {...props} setLoggedInUser={loggedInUser} routeMap={routeMap} />
            </div>
          )}
        />
      );
    }
  })
  

  
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
