import { customerInformationColumns } from "../../DataGrid/columns/CustomerLIstColumns";
interface CustomerAttributes {
  id: string;
  customer_id: string;
  name: string;
  email: string;
  phone_number: string;
  postcode: string;
}

import React from "react";
import { CellContext } from "@tanstack/react-table";
import { IconButton, Pagination, SvgIcon } from "@mui/material";
import { staffInformationColumns } from "../../../../../components/src/Dashboard/DataGrid/columns/StaffInformationColumns";
import DataGrid from "../../../../../components/src/Dashboard/DataGrid";
import { StaffAttributes } from "../../../../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformationController.web";
import { hasPermission } from "../../../utils";
import "./CustomerList.css";

interface Props {
  data: any;
  totalCount: number;
  prevPage?: number | null;
  nextPage?: number | null;
  totalPages: number;
  currentPage: number;
  handleViewDetails: (id: string) => void;
  handleSelectedRow: (info: CellContext<any, any>) => void;
  handleMarketingInfoOpen: (id: string) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CustomerList = ({
  data,
  totalCount,
  prevPage,
  nextPage,
  totalPages,
  currentPage,
  handlePageChange,
  handleViewDetails,
  handleSelectedRow,
  handleMarketingInfoOpen,
}: Props) => {
  const actionCell = (info: CellContext<any, any>) => {
    const row = info.row.original;
    const enableView = hasPermission("CustomerAccount", "show");
    const enableUpdate = hasPermission("CustomerAccount", "update");
    const enableMarketing = hasPermission("CustomerAccount", "marketing_communication_permission");

    return (
      <div className="action_cell__wrapper">
        <IconButton
          disabled={!enableView}
          onClick={() => handleViewDetails(info.row.original.id.toLocaleString())}>
          <SvgIcon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.001 15.336q1.563 0 2.657-1.093 1.093-1.094 1.093-2.657t-1.093-2.657-2.657-1.093q-1.562 0-2.656 1.093-1.094 1.095-1.094 2.657t1.094 2.657Q10.44 15.336 12 15.336m0-1.5a2.17 2.17 0 0 1-1.593-.657 2.17 2.17 0 0 1-.657-1.593q0-.937.657-1.594A2.17 2.17 0 0 1 12 9.336q.938 0 1.595.656.655.657.655 1.594a2.17 2.17 0 0 1-.655 1.593 2.17 2.17 0 0 1-1.595.657m0 4q-2.895 0-5.291-1.53-2.397-1.532-3.625-4.137A.7.7 0 0 1 3 11.91a2.4 2.4 0 0 1 0-.646.7.7 0 0 1 .084-.26q1.23-2.606 3.626-4.136Q9.105 5.336 12 5.336t5.292 1.532q2.396 1.53 3.625 4.135.063.104.083.26a2.5 2.5 0 0 1 0 .645.7.7 0 0 1-.083.261q-1.23 2.604-3.625 4.136-2.395 1.53-5.292 1.53m0-1.667q2.354 0 4.324-1.24a8.14 8.14 0 0 0 3.01-3.343 8.15 8.15 0 0 0-3.01-3.344A7.96 7.96 0 0 0 12 7.002q-2.354 0-4.323 1.24a8.15 8.15 0 0 0-3.01 3.344 8.14 8.14 0 0 0 3.01 3.343A7.96 7.96 0 0 0 12 16.169"
                fill="#747474"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        <IconButton disabled={!enableUpdate} data-testid="edit-staff-action" onClick={() => handleSelectedRow(row)}>
          <SvgIcon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m13.717 9.517.766.766-7.55 7.55h-.766v-.766zm3-5.017a.83.83 0 0 0-.584.242l-1.525 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95a.82.82 0 0 0-.591-.242m-3 2.658L4.5 16.375V19.5h3.125l9.217-9.217z"
                fill="#747474"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        <IconButton
          disabled={!enableMarketing}
          onClick={() =>
            // handleManageNoteOpen(row.id.toString())
            handleMarketingInfoOpen(row)


          }
        >
          <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_33973_7999)">
                <path d="M19.8125 4.6256C19.5976 4.48658 19.3519 4.40247 19.0968 4.38068C18.8418 4.35888 18.5854 4.40006 18.35 4.5006L10.6375 7.5381C10.4549 7.61241 10.2596 7.65062 10.0625 7.6506H5.125C4.7106 7.6506 4.31317 7.81522 4.02015 8.10825C3.72712 8.40127 3.5625 8.7987 3.5625 9.2131V9.3381H2V13.0881H3.5625V13.2506C3.57229 13.6585 3.74122 14.0463 4.0332 14.3313C4.32517 14.6163 4.71702 14.7757 5.125 14.7756L7 18.7506C7.12696 19.0183 7.32685 19.2447 7.5767 19.4039C7.82656 19.5631 8.11624 19.6486 8.4125 19.6506H9.2C9.61223 19.6473 10.0065 19.4812 10.2968 19.1886C10.5871 18.8959 10.75 18.5003 10.75 18.0881V14.9256L18.35 17.9631C18.5369 18.0375 18.7363 18.0757 18.9375 18.0756C19.2496 18.0706 19.5536 17.975 19.8125 17.8006C20.0181 17.6618 20.1877 17.476 20.3072 17.2586C20.4268 17.0413 20.4929 16.7986 20.5 16.5506V5.9131C20.4989 5.65875 20.4356 5.40852 20.3158 5.18414C20.196 4.95977 20.0232 4.76804 19.8125 4.6256ZM9.1875 9.2131V13.2506H5.125V9.2131H9.1875ZM9.1875 18.0881H8.4L6.8625 14.7756H9.1875V18.0881ZM11.2125 13.4381C11.0641 13.3623 10.9093 13.2995 10.75 13.2506V9.1256C10.9078 9.09304 11.0625 9.04704 11.2125 8.9881L18.9375 5.9131V16.5131L11.2125 13.4381ZM20.5375 9.6506V12.7756C20.9519 12.7756 21.3493 12.611 21.6424 12.318C21.9354 12.0249 22.1 11.6275 22.1 11.2131C22.1 10.7987 21.9354 10.4013 21.6424 10.1082C21.3493 9.81522 20.9519 9.6506 20.5375 9.6506Z" fill="#747474" />
              </g>
              <defs>
                <clipPath id="clip0_33973_7999">
                  <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        </IconButton>
      </div>
    );
  };

  const columns = customerInformationColumns({ actionCell });

  return (
    <div
      data-testid="staff-list"
      className="staff_information__staff_list"
    >
      <DataGrid
        data={data}
        columns={columns}
        emptyRowsText="No Customer found. Please add a new staff."
      />
      <div className="staff_information__pagination__wrapper">
        <span className="staff_information__results">
          Showing {data.length} of {totalCount} results
        </span>

        <Pagination
          hidePrevButton={prevPage === null}
          hideNextButton={nextPage === null}
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CustomerList;

