import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { collectionImage, collectionImageDone, cookingImage, cookingImageDone, deliveredImage, orderAcceptedImage, orderAcceptedImageDone, orderPickedImage, orderPickedImageDone, orderPlacedImage, orderPlacedImageDone, orderReadyImage, orderReadyImageDone } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON1 = require("../../../framework/src/config")
import {actionCableConfig, actionCableConfigCloseConnection, webSocketFunction} from "../../../components/src/websocketConfig"
import { createRef } from "react";
  // Customizable Area Start

export interface ImageArray{
  status: string;
  active: string;
  inactive: string;
  name?:string;
}
export interface CancelOrderOption{
  id:number;
  reason:string
}
export interface OrderHistoryAttributes{
  id: number;
  order_number: string;
  placed_at: string;
  order_type: string|null;
  status: string;
  items_count: number;
  restaurant: string;
  total: string;
  estimated_time: string;
  reward_points	:string;
  otp:number;
}
export interface OrderHistoryOrders {
  id: string;
  type: string;
  attributes: OrderHistoryAttributes
}

export interface OrderHistoryDetailRestaurant{
  id: number,
  title: string;
  location: string;
  contact: string;
  open_time: string;
  close_time: string;
  postcode: string;
  created_at: string;
  updated_at: string;
  order_type: string[];
}

export interface  OrderHistoryDetailPayment{
  id: number,
  account_id: number,
  charge_id: string;
  amount: number,
  currency: string;
  charge_status: string;
  created_at: string;
  updated_at: string;
  transactionable_type: string;
  transactionable_id: number,
  payment_method_type: string;
  transaction_number: string;
}

export interface OrderHistoryDetailItems{
  id: string;
  type: string;
  attributes: {
      allergen_excluded:string|null,
      id: number,
      cart_id: null,
      catalogue_id: number,
      spice_level: string;
      choose_your_type: string;
      quantity: number,
      price: number,
      product_name: string;
      images: {
          url: string;
          content_type: string;
          file_name: string;
      },
      sides: ProductSideDetais[],
      drinks: ProductSideDetais[],
      nibbles: ProductSideDetais[],
      toppings: ProductSideDetais[],
      wraps_product: ProductSideDetais[]
  }
}

export interface ProductSideDetais{
  catalogue_id: number;
  name: string;
}

export interface DeliveryAddresses{
  "id": number,
  "account_id": number,
  "address": string,
  "name": string,
  "flat_no": null|string,
  "zip_code": string,
  "phone_number": string,
  "deleted_at": null|string,
  "latitude": null|string,
  "longitude": null|string,
  "residential": boolean,
  "city": string,
  "state_code": null|string,
  "country_code": null|string,
  "state": null|string,
  "country": string,
  "address_line_2": null|string,
  "address_type": string,
  "address_for": string,
  "is_default": boolean,
  "landmark": null|string,
  "created_at": string,
  "updated_at": string
}

export interface OrderHistoryDetailsAttributes{
  id: number,
  order_number: string;
  placed_at: string;
  delivered_at: null,
  collected_at: null,
  order_type: null,
  cancellation_reason?: string;
  decline_reason?: string | null;
  estimated_time: string;
  status: string;
  items_count: number,
  redeem_point: string;
  points_worth: string;
  gift_card_amount: string;
  applied_discount: string;
  sub_total: string;
  total: string;
  restaurant: OrderHistoryDetailRestaurant;
  delivery_addresses: DeliveryAddresses[],
  payment_transaction:OrderHistoryDetailPayment | null;
  items_detail: {
      data: OrderHistoryDetailItems[]
  }
}
export interface OrderHistoryDetails{
  id: string;
            type: string;
            attributes: OrderHistoryDetailsAttributes;
}
  // Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expandAccordian: string |boolean;
  orderHistoryDataPresent: boolean;
  orderStatus: string;
  orderType: "delivery"|"collection";
  imageGroupCollection: ImageArray[];
  imageGroupDelivery: ImageArray[];
  orderStatusesDelivery: string[];
  orderStatusesCollection:string[];
  token:string
  active_orders: OrderHistoryOrders[];
  past_orders: OrderHistoryOrders[];
  viewOrderDetailsOpen: boolean;
  OrderDetails: OrderHistoryDetails | null;
  message:string;
  trackOrder: boolean;
  id: string;
  orderStatusTosend:string;
  estimatedTimeTosend:string;
  loading:boolean;
  cancelDropdown:string;
  cancelPopup:boolean;
  isActive:boolean;
  reasonString:string;
  cancelationOptions:CancelOrderOption[];
  reasonId:number;
  orderHistoryCancel:OrderHistoryOrders;
  reasonCancellationCountError:string;
  reasonCancellationCount:number;
  reasonCancellationString:string;
  orderCancellationSuccesFailPopup:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      expandAccordian:"Active",
      orderHistoryDataPresent: true,
      orderStatus: "ready",
      orderType: "delivery",
      imageGroupCollection:[{status: 'placed',active:orderPlacedImageDone, inactive:orderPlacedImage},
      {status: 'confirmed',active:orderAcceptedImageDone, inactive:orderAcceptedImage},
      {status: 'cooking',active:cookingImageDone, inactive:cookingImage},
      {status: 'ready',active:orderReadyImageDone, inactive:orderReadyImage},
      {status: 'collected',active:collectionImageDone, inactive:collectionImage}],
      imageGroupDelivery:[{status: 'placed',active:orderPlacedImageDone, inactive:orderPlacedImage},
      {status: 'confirmed',active:orderAcceptedImageDone, inactive:orderAcceptedImage},
      {status: 'cooking',active:cookingImageDone, inactive:cookingImage},
      {status: 'ready',active:orderReadyImageDone, inactive:orderReadyImage},
      {status: 'in_transit',active:orderPickedImageDone, inactive:orderPickedImage},
      {status: 'delivered',active:deliveredImage, inactive:deliveredImage}],
      orderStatusesDelivery: ['placed','confirmed','cooking','ready','in_transit','delivered'],
      orderStatusesCollection: ['placed','confirmed','cooking','ready','collected'],
      token: "",
      active_orders: [],
      past_orders: [],
      viewOrderDetailsOpen: false,
      OrderDetails: null,
      message:"",
      trackOrder: false,
      id: "",
      orderStatusTosend:"",
      estimatedTimeTosend:"",
      loading:false,
      cancelDropdown:"",
      cancelPopup:false,
      isActive:false,
      reasonString:"",
      cancelationOptions:[],
      reasonId:0,
      orderHistoryCancel: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          order_number: "",
          placed_at: "",
          order_type: null,
          status: "",
          items_count: 0,
          restaurant: "",
          total: "",
          estimated_time: "",
          reward_points: "",
          otp: 0,
        }
      },
      reasonCancellationCountError:"",
      reasonCancellationCount:0,
      reasonCancellationString:"",
      orderCancellationSuccesFailPopup:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  setOrderHistoryApiCallId:string="";
  setOrderDetailsApiCallId:string="";
  setInvoiceDownloadCallId: string ="";
  setReorderItemsCallId: string = ""
  getCancelOrderOptionApiCallId: string=""
  postCancelOrderRequestApiCallId:string=""
  dropDownRef= createRef<HTMLDivElement>()
  private cableSubscription: any | null = null;

  async componentDidMount(){
    this.getToken()
  }

  async componentDidUpdate(prevProps:Props,prevState:S){
    if(this.state.active_orders !== prevState.active_orders){
      if(this.state.id){
        const order = this.state.active_orders.find(order => order.id === this.state.id)
        if(order){
          const status = order.attributes.status
          this.setState({orderStatusTosend:status})
        }
      }
    }
    if(this.state.past_orders !== prevState.past_orders){
      if(this.state.id){
        const order = this.state.past_orders.find(order => order.id === this.state.id)
        if(order){
          const status = order.attributes.status
          this.setState({orderStatusTosend:status})
        }
      }
    }
  }
  async componentWillUnmount() {
    this.unsubscribeFromStatusUpdates();
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.setOrderHistoryApiCallId){
        this.setOrderHistoryData(responseJson)
      } else if(apiRequestCallId === this.setOrderDetailsApiCallId){
        this.setOrderDetails(responseJson)
      } else if(apiRequestCallId === this.setReorderItemsCallId){
        this.reorderItemsResponse(responseJson)
      } else if(apiRequestCallId === this.getCancelOrderOptionApiCallId){
        this.setCancelationOptions(responseJson)
      } else if(apiRequestCallId === this.postCancelOrderRequestApiCallId){
        this.getCancellationOrderStatus(responseJson)
      }
    }
    }

  // Customizable Area Start

  setActiveOrderState = (orderHistory: OrderHistoryOrders[], oldOrderHistory:OrderHistoryOrders[]) => {
    this.setState({active_orders: orderHistory, past_orders:oldOrderHistory})
  }

  getToken = () => {
    getStorageData('authToken',false).then(data => {
       this.setState({token: data})
       this.getOrderHistoryData(data)
       this.subscribeToStatusUpdates(data)
       this.getCancelOrderOptions(data)
       return data
     })
};
reorderItemsResponse=(responseJson:{meta?:{message:string}})=>{
  if(responseJson.meta && responseJson.meta.message === "Order has been reordered successfully"){
    this.props.navigation.navigate("MenuPage")
  }
  else{
    this.showAlert("token","token")
  }
}

setCancelationOptions=(responseJson:CancelOrderOption[]|any)=>{
  if(responseJson && (!responseJson.errors || !responseJson.error) ){
    this.setState({cancelationOptions:responseJson})
  }
}

getCancellationOrderStatus=(responseJson:{message?:string,error?:any,errors?:any})=>{
  if(responseJson.message){
    this.openSuccessFailPopup()
    this.setState({cancelPopup:false,reasonString:"",reasonId:0,reasonCancellationString:""})
  }
  this.getOrderHistoryData(this.state.token);
  this.setState({loading:false});
}

downloadInvoice=(id:string)=> {
  const url = `${configJSON1.baseURL}/${configJSON.getOrderDetailsApiEndPoint}/${id}/download_invoice?token=${this.state.token}`
  location.href= url
}

  navigateToMenuPage = ()=>{
    this.props.navigation.navigate('MenuPage')
  }

  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleExpand =
    (expand: string) => (event: React.ChangeEvent<{}>, expandAccordian: boolean) => {
      this.setState({ expandAccordian: expandAccordian? expand : false });
    };

    getOrderHistoryData=(token:string)=>{
      this.setState({loading:true})
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setOrderHistoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrderHistoryApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setOrderHistoryData=(responseJson:{errors?:[{"token":string}], data?:{active_orders:OrderHistoryOrders[],past_orders:OrderHistoryOrders[]}})=>{
      if(responseJson.errors){
        this.showAlert("token","token")
      } else if(responseJson.data){
        const {active_orders, past_orders} = responseJson.data
        this.setState({active_orders,past_orders,message:"",loading:false})
      }
    }
    setSearchOrderHistoryData=(responseJson:{errors?:[{"token":string}], data?:{active_orders:OrderHistoryOrders[],past_orders:OrderHistoryOrders[]},message?:string})=>{
      if(responseJson && !responseJson.message){
        this.setOrderHistoryData(responseJson)
      } else if(responseJson.message){
        this.setState({active_orders:[],past_orders:[],message:responseJson.message})
       }
    }

    closeViewOrderDetails=()=>{
      this.setState({viewOrderDetailsOpen:false})
    }
    openViewOrderDetails=(id:string)=>{
      this.getOrderDetails(id)
    }

    getOrderDetails = (id:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": this.state.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setOrderDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetailsApiEndPoint}${id}/order_detail`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    setOrderDetails=(responseJson:{data?:{data:OrderHistoryDetails},errors?:[{"token":string}]})=>{
     if(responseJson.errors){
      this.showAlert("token","token")
     } else if(responseJson.data){
      this.setState({OrderDetails:responseJson.data.data,viewOrderDetailsOpen:true,orderStatus:responseJson.data.data.attributes.status})
     }
     this.setState({loading:false})
    }

    openTask = (id: string,status:string,time:string) => {
      this.setState({trackOrder: true, id, orderStatusTosend:status,estimatedTimeTosend:time});
    }

    closeTask = () => {
      this.setState({trackOrder: false,id:""});
    }
    reorderItems=(id:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": this.state.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setReorderItemsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetailsApiEndPoint}${id}/reorder`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createOrderMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    subscribeToStatusUpdates(token:string) {
      this.cableSubscription = webSocketFunction(token)
      actionCableConfig(this.cableSubscription, this.setActiveOrderState)
    }

    unsubscribeFromStatusUpdates() {
      if(this.cableSubscription ){
        actionCableConfigCloseConnection(this.cableSubscription)
      }
    }

    closeCancelDropDown=()=>{
      this.setState({cancelDropdown:""})
     }
     openCancelDropDown=(cancelId:string)=>{
      this.setState({cancelDropdown:`open${cancelId}`})
     }

     openCancelPopup=(orderHistoryCancel:OrderHistoryOrders)=>{
      this.setState({cancelPopup:true,orderHistoryCancel})
     }

     setCancelData=(orderHistoryCancel:OrderHistoryOrders)=>{
      this.setState({orderHistoryCancel})
     }

     openCancelPopupOnly=()=>{
      this.setState({cancelPopup:true,trackOrder:false})
     }
     closeCancelPopup=()=>{
      this.setState({cancelPopup:false,orderHistoryCancel:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          order_number: "",
          placed_at: "",
          order_type: null,
          status: "",
          items_count: 0,
          restaurant: "",
          total: "",
          estimated_time: "",
          reward_points: "",
          otp: 0,
        }
      },reasonString:"",reasonId:0,reasonCancellationString:""})
     }
     toggleDropdown = () => {
      this.setState({isActive:true});
    };
    toggleDropdownClose =()=>{
      this.setState({isActive:false})
    }

    getCancelOrderOptions=(token:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCancelOrderOptionApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfposordermanagementfe/cancellation_reasons`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setReasonString=(reasonString:string,reasonId:number)=>{
      this.setState({reasonString,reasonId,isActive:false})
    }
    handleChangeProductChefNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const reasonCancellationString = event.target.value
      const reasonCancellationCount = event.target.value.length
      if (reasonCancellationCount > 50) {
        const reasonCancellationCountError = "Cannot use more than 50 characters."
        this.setState({ reasonCancellationCountError })
      } else {
        this.setState({ reasonCancellationCountError: "" })
      }
      this.setState({ reasonCancellationString, reasonCancellationCount })
    }

  postCancelOrderRequest = () => {
    this.setState({loading:true})
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCancelOrderRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/cancel_order`
    );

    let body: Record<string, any> = {
      "order_id": this.state.orderHistoryCancel.attributes.id,
      "cancel_reason_id": this.state.reasonId,
    }

    if (this.state.reasonString.toLowerCase() === "other") {
      body.decline_reason = this.state.reasonCancellationString;
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openSuccessFailPopup=()=>{
    this.setState({orderCancellationSuccesFailPopup:!this.state.orderCancellationSuccesFailPopup})
  }
  buttonDisabled=()=>{
    return !this.state.reasonId || (this.state.reasonString.toLowerCase()==="other" && !this.state.reasonCancellationString)
  }

  helpIconDisabled=(status:string)=>{
    return !(status === 'cancel_initiated' || status === 'cancelled' || status === 'refund_initiated')
  }
  helpIconDisabledForActive=(status:string)=>{
    return status === 'placed' || status === 'confirmed'
  }
// Customizable Area End
}
