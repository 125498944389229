import React from "react";
import { TimerIcon } from "../../../Icons";
import "./Timer.css";
import moment from "moment";

interface Props {
  start: string;
};

const Timer = ({ start }: Props) => {
  const [elapsedTime, setElapsedTime] = React.useState("");
  const [color, setColor] = React.useState("#2C6F37");

  React.useEffect(() => {
    let startTime = moment(start, moment.ISO_8601, true); // Parse as ISO-8601

    if (!startTime.isValid()) {
      console.error("Invalid start time:", start);
      return;
    }

    startTime = startTime.utc(); // Ensure UTC parsing

    const updateTimer = () => {
      const now = moment.utc();
      const diff = moment.duration(now.diff(startTime));

      const days = diff.days();
      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();

      setElapsedTime(
        (days > 0 ? `${days} day${days > 1 ? "s" : ""}, ` : "") +
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      );

      setColor(getElapsedTimeColor(start));
    };

    updateTimer(); // Initial update
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [start]);

  const getElapsedTimeColor = (start: string) => {
    const startTime = new Date(start).getTime();
    const now = Date.now();
    const diffInMinutes = (now - startTime) / (1000 * 60);

    if (diffInMinutes >= 0 && diffInMinutes < 5) {
      return "#2C6F37"; // Green for 0 to 5 mins
    } else if (diffInMinutes >= 5 && diffInMinutes < 10) {
      return "#F59E0B"; // Yellow for 5 to 10 mins
    } else if (diffInMinutes >= 10) {
      return "#E11B22"; // Red for above 10 mins
    }
    return "#2C6F37"; // Default color
  };

  return (
    <div className="timer__wrapper" style={{ backgroundColor: color }}>
      <TimerIcon />
      <p className="timer__label">{elapsedTime}</p>
    </div>
  );
};

export default Timer;
