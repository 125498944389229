import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment, unitOfTime } from "moment";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface Reporting {
  overview: { unit: number; revenue: number };
  sales: { [label: string]: number };
  salesCumulative: { [label: string]: number };
  revenueBreakdown: { [label: string]: number };
  salesComparison: { [label: string]: number };
  currency: string;
}
interface RevenueBreakdownUnit {
  name: string;
  value: number;
  color: string;
  legendFontColor: string;
  legendFontSize: number;
}

export interface Order {
  order_id: string;
  customer_name: string;
  address: string;
  payment_method: string;
  cash_off: string;
  total_amount: string;
  distance: string | null;
}


export interface RiderReport {
  staff_id: string | null;
  rider_name: string;
  cash: string;
  cards: string;
  apple_pay: string;
  google_pay: string;
  total_orders: number;
  total_amount: string;
  total_distance: string | null;
  orders: Order[];
}


export interface Totals {
  cash: string;
  cards: string;
  apple_pay: string;
  google_pay: string;
  total_orders: string;
  total_amount: string;
}

export interface Report {
  start_date: string;
  end_date: string;
  rider_reports: RiderReport[];
  totals: Totals;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  riderReportPopup:boolean;
  closeRiderReportPopup:()=>void;
  searchQuery:string;
  selectedDate1:Date|null;
  disbleExportRiderReport:(value:boolean)=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  selectedPeriod: { pkey: string; value: string };
  selectedDate: { from: Moment; through: Moment };
  selectedFrame: string;
  totalSaleUnit: number;
  totalSaleRevenue: number;
  revenueBreakdown: number;
  mobileSalesData: any;
  mobileSalesCumulativeData: { labels: string[]; data: number[] };
  webSalesData: (string | number)[][];
  webSalesCumulativeData: (string | number)[][];
  webRevenueBreakdown: (string | number)[][];
  currency: string;
  expanded:boolean|string;
  riderReports: RiderReport[];
  exportOption:string;
  message:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  salesReportApiCallId: string = "";
  getRiderReportDataApiCallId:string;
  exportRiderReportApiCallId:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      // Customizable Area Start
      loading: false,
      selectedPeriod: configJSON.periods[1],
      selectedDate: {
        from: moment().startOf("M"),
        through: moment().endOf("M"),
      },
      selectedFrame: "week",
      totalSaleUnit: 0,
      totalSaleRevenue: 0,
      revenueBreakdown: 0,
      mobileSalesData: 0,
      mobileSalesCumulativeData: { labels: [], data: [] },
      webSalesData: [],
      webSalesCumulativeData: [],
      webRevenueBreakdown: [],
      currency: "0",
      expanded:"",
      riderReports:[],
      exportOption:"",
      message:"",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getRiderReportDataApiCallId="";
    this.exportRiderReportApiCallId="";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId === this.getRiderReportDataApiCallId){
        this.setRiderReportData(responseJson)
      }
      if(apiRequestCallId===this.exportRiderReportApiCallId){
        if(!responseJson){
        this.downloadExportedReport()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRiderReportData();
    // Customizable Area End
  }
  handleExpand =
  (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
    this.setState({ expanded: expanded ? panel : false });
  };

 componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=> {
   if(prevProps.searchQuery!==this.props.searchQuery || prevProps.selectedDate1 !== this.props.selectedDate1){
      this.getRiderReportData()
   }
 }

  getRiderReportData = () => {
    let token = localStorage.getItem('authToken')
    this.setState({loading:true})
    let formattedDate = this.props.selectedDate1 ?  this.props.selectedDate1.toLocaleDateString("en-GB") : ""
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRiderReportDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.riderReportApiEndPoint}&search=${this.props.searchQuery||""}&start_date=${formattedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setRiderReportData=(responseJson:any)=>{
      if(responseJson.start_date){
        this.setState({riderReports:responseJson.rider_reports,loading:false,message:""})
        this.props.disbleExportRiderReport(false)
      }else if(responseJson.error && responseJson.error === "You must cash off at least one order to view the rider report."){
        this.setState({riderReports:[],loading:false})
        if(this.props.searchQuery.length > 0){
          this.setState({message:"Try searching for other riders and check the Riders report."})
        }else{
          this.setState({message:responseJson.error})
        }
        this.props.disbleExportRiderReport(true)
      }
  }
  exportOptions=[
    {name:"Export Summary Report (Total Orders)",value:"only_riders"}, {name:"Export Detailed Report (With Individual Delivered Orders by Rider)",value:"riders_with_order"}
  ]

  changeExportOptions=(value:string)=>{
    this.setState({exportOption:value})
  }

  cancelExport=()=>{
    this.setState({exportOption:""})
    this.props.closeRiderReportPopup()
  }

  exportReporButtonClicked=()=>{
    let token = localStorage.getItem('authToken')
    this.setState({loading:true})

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exportRiderReportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.riderReportApiEndPoint}&export=true&export_type=${this.state.exportOption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadExportedReport=()=> {
    let formattedDate = this.props.selectedDate1 ?  this.props.selectedDate1.toLocaleDateString("en-GB") : ""
    const token = localStorage.getItem("authToken") || "";
    const url = `${baseURL}/bx_block_salesreporting/rider_reports?date_range=year&export=true&export_type=${this.state.exportOption}&token=${token}&start_date=${formattedDate}&search=${this.props.searchQuery||""}`
    location.href= url
    this.setState({loading:false,exportOption:""})
  }
componentWillUnmount=async()=> {
  this.props.disbleExportRiderReport(false)
}
  // Customizable Area End
}
