import React, { RefObject } from "react";
import { Accordion, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, RadioGroup, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded'
import { textbutton_edit, image_add, delete_icon, customLoaderGif } from './../assets';
import './ConfirmOrder.css';
import CustomRadioBox from "../CustomRadioBox.web";
import CustomAccordion from "../CustomAccordion";
import CustomMobileBox from "../CustomMobileBox.web";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DialogPopupWeb from "../DialogPopup.web";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AddAddress from "./../../../blocks/addressmanagement/src/AddAddress.web";
import { IBlock } from "../../../framework/src/IBlock";
import EditRoundedIcon from "@material-ui/icons/EditRounded"
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { EditAddress } from "./../../../blocks/addressmanagement/src/AddAddressController";
import CustomCalendar from "../CustomCalendar.web";
import TimeDropdown from "../TimeDropdown/TimeDropdown";
import { capitalizeFirstLetter, formatCVC, setStoreHours, setStoreHoursAccordingToDate } from "../utils";
import { RestaurantList, StoreHoursAttribute } from "../../../blocks/storelocator/src/StoreLocatorController";
import RestaurantPopup from "../../../blocks/storelocator/src/RestaurantPopup.web";
import { format } from 'date-fns';
import { applePay, googlePay, imagePound, messageCart } from "../../../blocks/shoppingcart/src/assets";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import ControlPointRoundedIcon from '@material-ui/icons/ControlPointRounded';
import CardOptions from "./CardOptions.web";
import CloseIcon from "@material-ui/icons/Close"
import { DeliverInstruction, OrderCreatedData, PromoCodeData, RestaurantFacts } from "../../../blocks/shoppingcart/src/ShoppingCartOrdersController";
import Paymentadmin2 from "../../../blocks/paymentadmin2/src/Paymentadmin2.web";
import RemoveCodeAlert from "../EmptyCartItemAlert/RemoveCodeAlert.web";
import GooglePay from "../../../blocks/paymentadmin2/src/GooglePay.web";
import PaymentPopup from "../PaymentPopup.web";
import OrderConfirmedPopup from "./OrderConfirmedPopup.web";
import GetCardBrand from "./GetCardBrand.web";
import LocationSearchingRoundedIcon from '@material-ui/icons/LocationSearchingRounded';
import { PostCodeType } from "../../../blocks/storelocator/src/StoreLocatorPopupController";
import "../../../blocks/storelocator/src/StoreLocator.css";
import { geolocationCoordinates } from "../GeolocationCoordinates.web";
import { distanceImage, nearestImage, noHistory } from "../../../blocks/storelocator/src/assets";
import RestaurantChangePopup from "./RestaurantChangePopup.web";
import CustomGreenCheckbox from "../CustomGreenCheckBox.web";
import { actionCableConfigCloseConnection, paymentWebsocketFunc, webSocketFunction } from "../websocketConfig";
import DeliveryInstructionAccordian from "./DeliveryInstructionAccordian.web"
import ApplePayIntegration from "../../../blocks/applepayintegration/src/ApplePayIntegration.web";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
export interface PersonalDetails {
  firstName: string;
  lastName: string;
  fullPhoneNumber: string;
  order_id: number
}

type Timer = ReturnType<typeof setTimeout>;

export interface AddressItemAttribute {
  name: string,
  full_phone_number: string,
  address_type: string,
  address: string,
  default_address: boolean,
  post_code: string,
  country: string,
  city: string;
  id?: string;
}

export interface AddressItem {
  id: string;
  type: string;
  attributes: AddressItemAttribute;
}

export interface CardDataAttributes {
  billing_details: CardDataBillingDetails;
  card: CardDataDetails;
  customer: string;
  created: number
}

export interface CardDataBillingDetails {
  address: {
    city: null | string;
    country: null | string;
    line1: null | string;
    line2: null | string;
    postal_code: null | string;
    state: null | string
  };
  email: null | string;
  name: null | string;
  phone: null | string
}

export interface CardDataDetails {
  brand: string;
  checks: {
    cvc_check: string
  };
  country: string;
  display_brand: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  networks: {
    available: string[]
  };
  three_d_secure_usage: {
    supported: boolean;
  };
  wallet: null

}

export interface CardData {
  id: string;
  type: string;
  attributes: CardDataAttributes
}


interface Props {
  personalDetails: PersonalDetails
  handleInputChangeFirstName: (value: string) => void
  handleInputChangeLastName: (value: string) => void
  handleCountryCodeChange: (value: string) => void
  handleMobileChange: (value: string) => void
  updateProfileOrder: () => void
  personalDetailsRead: PersonalDetails
  editProfileOpenClose: () => void
  editProfile: boolean
  restaurantList: RestaurantList[]
  navigation: any;
  restaurant_id: string;
  restaun_fact: RestaurantFacts;
  cart_id: number;
  orderDetails: OrderCreatedData | null
  setOrderDetails: (value: number | null) => void
  setGiftCardCouponPointsAmount: (coupons: string, redeem_points: string, gift_card: string, sub_total: string, total: string) => void
  save_value_message: string | null
  showMinimumCartMessage: (message: string) => void
  getRestaurantDataToCatalogue: (token: string) => void
  deliveryInstructionList: DeliverInstruction[];
  deliveryInstruction: number[];
  changeDeliveryInstruction: (value: number) => void
  setChangeDeliveryInstructions: (value: number[]) => void
  isRestaurantChanged: (value: boolean) => void
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  isActive: boolean;
  deliveryShow: boolean;
  selectedValue: string;
  addressPopup: boolean;
  addressList: AddressItem[];
  editAddressDetails: null | EditAddress;
  paymentSuccessful: boolean;
  deletePopup: boolean;
  addressId: string;
  postCodeSelected: string;
  delivery_date: string;
  prepare_immediately: boolean;
  selectedTime: string;
  restaurantSelected: RestaurantList;
  address_id: number;
  applyCouponCode: boolean;
  promocodeData: PromoCodeData[];
  orderSummary: {
    sub_total: string;
    total_price: string;
    coupons: string;
    redeem_points: string,
    gift_card: string,
    message: string,
    meta: string | null,
    delivery_charges: string
  }
  coupon_code: string | null;
  pointRewards: {
    "data": {
      "total_points": number;
      "points_worth": number;
    },
    "minimum_spend_points": number;
  };
  rewardInput: string;
  points_worth: number | string;
  reward_point_error: string;
  promocodeError: string;
  promocodeSuccess: string;
  gift_card_ref_num: string | null;
  gift_card_id: null | number;
  gift_card_error: string;
  gift_card_success: string;
  giftCardOptionSelected: string;
  addCardPopup: boolean;
  paymentType: string;
  removeCouponOpen: boolean;
  heading_remove: string;
  content_remove: string;
  value1: boolean;
  value2: string;
  card_id: string;
  cvc_for_payment: string;
  card_network: string;
  card_data_list: CardData[];
  paymentPopup: boolean;
  img: string;
  heading: string;
  content: string;
  button: string;
  attempts: number | undefined;
  walletType: string;
  messageCartItems: string;
  orderConfirmPopup: boolean;
  couponCodeAppliedPopup: boolean;
  GiftCardMessage: string;
  postCodeArray: PostCodeType[];
  post_code: string;
  restaurantList: any[];
  openRestaurantChange: boolean;
  unAvailableCartItems: { catalogue_name: string }[]
  restaurantData: {
    restaurant_id: string;
    order_type: string;
    title: string;
  };
  newRestaurantSelected: string;
  noRestaurantFound: boolean;
  addressIdToBeSelected: number;
  deliveryInstruction: string[];
  paymentProcessing: boolean;
  payment3DsecurePopup: boolean;
  urlToNavigate: string;
};

export const configJSON = require("./config");
export const configJSON1 = require("../../../blocks/paymentadmin2/src/config");
const stripePromise = loadStripe(configJSON1.stripePromise)
class ConfirmOrder extends BlockComponent<
  Props,
  S,
  SS
> {
  token: null | string = ""
  apigetAddressCallId: string = ""
  apideleteAddressCallId: string = ""
  apiPutCollectionTime: string = ""
  apigetDeliveryDetails: string = ""
  apiAddDeliveryMethodCallId: string = ""
  apiSetPromoDetailsList: string = ""
  createOrderApiCallId: string = ""
  orderSummaryApiCallId: string = ""
  removeCouponCodeCallId: string = ""
  rewardPointApiCallId: string = ""
  rewardPointWorthApiCallId: string = ""
  rewardRedeemApiCallId: string = ""
  applyGiftCardCallId: string = ""
  getCardListApiCall: string = ""
  googlePayButtonRef: RefObject<HTMLDivElement>
  getRewarPointApiCallId: string = ""
  cardPayButtonRef: RefObject<HTMLDivElement>
  cashPayButtonRef: RefObject<HTMLDivElement>
  cvcInput: RefObject<HTMLInputElement>
  private debounceTimeout: Timer | null = null;
  getPostCodeSuggestionCallId: string = "";
  getPostCodeFromPositionCallId: string = "";
  getRestaurantListId: string = "";
  getRestaurantDetailAndCartApiCallId: string = "";
  setRestaurantDataToCatalogueId: string = "";
  setDeliveryInstructionApiCallId: string = "";
  getDeliveryInstructionSelectedApiCallId: string = "";
  cableSubscription: any | null = null;

  constructor(props: Props) {
    super(props)
    this.googlePayButtonRef = React.createRef<HTMLDivElement>();
    this.cardPayButtonRef = React.createRef<HTMLDivElement>();
    this.cashPayButtonRef = React.createRef<HTMLDivElement>();
    this.cvcInput = React.createRef<HTMLInputElement>();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      isActive: false,
      deliveryShow: false,
      addressPopup: false,
      selectedValue: this.props.orderDetails?.attributes.order_type || "",
      addressList: [],
      editAddressDetails: null,
      deletePopup: false,
      paymentSuccessful: false,
      addressId: "",
      postCodeSelected: "",
      delivery_date: "",
      prepare_immediately: true,
      selectedTime: "",
      address_id: 0,
      addressIdToBeSelected: 0,
      restaurantSelected: {
        id: '1',
        type: "restaurant",
        attributes: {
          id: 1,
          title: 'title',
          contact: 'contact',
          open_time: 'open',
          close_time: 'close',
          postcode: 'postcode',
          order_type: [],
          location: 'location',
          distance: "",
          primary_phone: "",
          secondary_phone: "",
          store_hours: [],
          country_code: "",
        }

      },
      applyCouponCode: false,
      promocodeData: [],
      orderSummary: {
        sub_total: this.props.orderDetails?.attributes.sub_total || "",
        total_price: this.props.orderDetails?.attributes.total || "",
        coupons: this.props.orderDetails?.attributes.applied_discount || "",
        redeem_points: this.props.orderDetails?.attributes.points_worth || "",
        gift_card: this.props.orderDetails?.attributes.gift_card_amount || "",
        delivery_charges: this.props.orderDetails?.attributes.delivery_charges || "",
        message: "",
        meta: null,
      },
      coupon_code: this.props.orderDetails?.attributes.promo_code_name || "",
      pointRewards: {
        data: {
          total_points: 0,
          points_worth: 0,
        },
        minimum_spend_points: 0,
      },
      rewardInput: `${this.props.orderDetails?.attributes.redeem_point}`,
      points_worth: this.props.orderDetails?.attributes.points_worth || "0",
      reward_point_error: "",
      promocodeError: "",
      promocodeSuccess: "",
      gift_card_ref_num: this.props.orderDetails?.attributes.gift_card_ref_num || "",
      gift_card_id: this.props.orderDetails?.attributes.gift_card_id || 0,
      gift_card_error: "",
      gift_card_success: "",
      giftCardOptionSelected: "Gift Card Code",
      addCardPopup: false,
      paymentType: "",
      removeCouponOpen: false,
      heading_remove: "",
      content_remove: "",
      value1: false,
      value2: "",
      card_id: "",
      cvc_for_payment: "",
      card_network: "",
      card_data_list: [],
      paymentPopup: false,
      img: "",
      heading: "",
      content: "",
      button: "",
      walletType: "",
      attempts: 0,
      messageCartItems: '',
      orderConfirmPopup: false,
      couponCodeAppliedPopup: false,
      GiftCardMessage: "",
      postCodeArray: [],
      post_code: "",
      restaurantList: [],
      openRestaurantChange: false,
      unAvailableCartItems: [],
      restaurantData: { restaurant_id: "", order_type: "", title: "" },
      newRestaurantSelected: "",
      noRestaurantFound: false,
      deliveryInstruction: [],
      paymentProcessing: false,
      payment3DsecurePopup: false,
      urlToNavigate: "",
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = localStorage.getItem("authToken");
    const redeemRewards = sessionStorage.getItem("redeemPoints")
    if (redeemRewards) {
      this.applyRewardPoints(true, redeemRewards)
    }
    this.getSavedAddresses();
    this.getOrderSummary()
    if (this.props.orderDetails?.attributes.total) {
      this.updateMessage(this.props.orderDetails?.attributes.total)
    }
    this.getRewardPoints(localStorage.getItem("authToken") || "")
    this.getDeliveryInstructionSelected()
    // Customizable Area End
  }

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    if (prevState.rewardInput !== this.state.rewardInput) {
      this.getRewardPointsConversion()
    } if (this.state.paymentType === 'Credit/Debit Card' && this.state.paymentType !== prevState.paymentType) {
      this.getCardList()
    } if (this.state.orderSummary.total_price !== prevState.orderSummary.total_price) {
      this.updateMessage(this.state.orderSummary.total_price)
    } if (prevProps.deliveryInstruction !== this.props.deliveryInstruction) {
      this.setDeliveryInstruction()
    }
  }

  componentWillUnmount = async () => {
    this.unsubscribeFromStatusUpdates()
  }

  subscribeToStatusUpdates = (token: string, paymentIntent: string) => {
    this.cableSubscription = webSocketFunction(token)
    paymentWebsocketFunc(this.cableSubscription, paymentIntent, this.openPaymentWaiting, this.openPaymentPopup, this.changePaymentStatus)
  }
  unsubscribeFromStatusUpdates() {
    if (this.cableSubscription) {
      actionCableConfigCloseConnection(this.cableSubscription)
    }
  }

  open3DsecurePopup = (value: string, open: boolean) => {
    this.setState({ payment3DsecurePopup: open, urlToNavigate: value })
  }

  onChangeRewardPoint = (value: string) => {

    const numericValue = value.replace(/[^0-9]/g, '');
    this.setState({ rewardInput: numericValue, reward_point_error: "" })
  }

  disabledRewardRedeem = () => {
    return this.state.rewardInput === '0' || this.state.reward_point_error;
  }

  handleGooglePayButtonClick = () => {
    if (this.googlePayButtonRef.current) {
      const googlePayButton = this.googlePayButtonRef.current.querySelector<HTMLButtonElement>('button');
      if (googlePayButton) {
        googlePayButton.click();
      } else {
        console.error("Google Pay button not found");
      }
    } else {
      console.error("Google Pay button container not found");
    }
  };

  handleCardPayButtonClick = () => {
    if (this.cardPayButtonRef.current) {
      const cardPayButton = this.cardPayButtonRef.current.querySelector<HTMLButtonElement>('button');
      if (cardPayButton) {
        cardPayButton.click();
      } else {
        console.error("pay via card button not found");
      }
    } else {
      console.error("pay via card button container not found");
    }
  }

  handleCashPayButtonClick = () => {
    if (this.cashPayButtonRef.current) {
      const cashPayButton = this.cashPayButtonRef.current.querySelector<HTMLButtonElement>('button');
      if (cashPayButton) {
        cashPayButton.click();
      } else {
        console.error("pay via cash button not found");
      }
    } else {
      console.error("pay via cash button container not found");
    }
  }

  closeFailedOrderPopup = () => {
    this.setState({ paymentPopup: false });
    this.cvcInput.current?.focus();
  }

  maxAttemptsReached = () => {
    this.props.navigation.navigate("MenuPage")
  }

  onClosePaymentPopup = () => {
    if (this.state.paymentSuccessful) { this.setState({ paymentPopup: false, orderConfirmPopup: true }) }
    else {
      this.setState({ paymentPopup: false })
    }
  }
  onCloseCouponAppliedPopup = () => {
    this.setState({ couponCodeAppliedPopup: false })
  }
  navigateToMenuPopupClose = () => {
    this.props.navigation.navigate("MenuPage")
  }
  onCloseConfirmOrderPopup = () => {
    this.setState({ orderConfirmPopup: false })
    this.props.navigation.navigate("OrderHistory")
  }
  openPaymentPopup = (img: string, button: string, heading: string, content: string, attempts?: number) => {
    this.setState({ payment3DsecurePopup: false, paymentPopup: true, img, button, heading, content, attempts })
  }

  openPaymentWaiting = (popup: boolean) => {
    this.setState({ paymentProcessing: popup })
  }

  updateMessage = (amount: string | undefined) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewarPointApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRewardPointApiEndPoint}${amount}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPromoCodeData = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSetPromoDetailsList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCouponCodeApiEndpoint}?restaurant_id=${this.props.restaurant_id}&cart_id=${this.props.cart_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCardList = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardListApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListOfCard
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeRewardPoints = (redeem: boolean, rewards_points: string) => {
    this.setState({ content_remove: "Are you sure you want to remove the redeemed Wingo’s Reward points on this order?", heading_remove: "Remove the reward points", value1: redeem, value2: rewards_points, removeCouponOpen: true })
  }

  removeGiftCard = (remove: boolean) => {
    this.setState({ content_remove: "Are you sure you want to remove the applied gift card on this order?", heading_remove: "Remove the gift card", value1: remove, removeCouponOpen: true })
  }

  removeCouponCodePopup = (coupon: string) => {
    this.setState({ content_remove: "Are you sure you want to remove the applied coupon on this order?", heading_remove: "Remove the coupon", value2: coupon, removeCouponOpen: true })
  }
  addCouponCodePopup = (coupon: string) => {
    this.setState({ content_remove: "Are you sure you want to remove the applied coupon on this order?", heading_remove: "Remove the coupon", value2: coupon, removeCouponOpen: true })
  }
  applyRewardPoints = (redeem: boolean, rewards_points: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rewardRedeemApiCallId = requestMessage.messageId;

    const httpBody = {
      cart: {
        order_id: Number(this.props.orderDetails?.id),
        redeem: redeem,
        reward_points: Number(rewards_points)
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putWingoRewardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );
    if (Number(rewards_points) % 100 !== 0) {
      this.setState({ reward_point_error: "Please enter reward points in multiple of 100" })
    }
    else {
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.apigetAddressCallId) {
        if (responseJson && responseJson.data) {
          const addressId = this.getDefaultAddressId(responseJson.data);
          this.setState({ addressList: responseJson.data });
        }
      } else if (apiRequestCallId === this.apideleteAddressCallId) {
        if (responseJson && responseJson.message === "Address deleted succesfully!") {
          this.getSavedAddresses();
          this.closeDeletePopup();
        }
      } else if (apiRequestCallId === this.apigetDeliveryDetails) {
        if (responseJson.order_type) {
          const { order_type, address_id = 0, prepare_immediately = false, schedule_time } = responseJson;
          if (schedule_time) {
            const date = new Date(schedule_time);
            const formattedDate = format(date, 'hh:mm aa');
            this.setState({ selectedValue: order_type, address_id: address_id, prepare_immediately, delivery_date: format(date, 'dd/MM/yyyy'), selectedTime: formattedDate })
          } else {
            this.setState({ selectedValue: order_type, address_id: address_id, prepare_immediately })
          }
        }
      } else if (apiRequestCallId === this.apiAddDeliveryMethodCallId) {
        if (responseJson.message === "Address added successfully") {
          this.setState({ address_id: this.state.addressIdToBeSelected }, () => {
            this.getOrderSummary();
          })
          this.props.getRestaurantDataToCatalogue(this.token || "")
        } else if (responseJson.id) {
          this.setState({ address_id: this.state.addressIdToBeSelected })
          this.props.getRestaurantDataToCatalogue(this.token || "")
        } if (typeof responseJson.gift_card_message === "string" && responseJson.gift_card_message.trim() !== "") {
          this.applyGiftCard(false);
          this.setState({ couponCodeAppliedPopup: true })
          this.setState({ GiftCardMessage: responseJson.gift_card_message })
        }
      } else if (apiRequestCallId === this.apiSetPromoDetailsList) {
        if (responseJson.errors) {
          this.setState({ promocodeData: [] })
        } else {
          this.setState({ promocodeData: responseJson.data })
        }
      } else if (apiRequestCallId === this.createOrderApiCallId) {
        if (responseJson.message === "Invalid coupon") {
          this.setState({ promocodeError: "Enter a valid Coupon Code" })
        } else if (responseJson.message) {
          this.setState({ promocodeError: responseJson.message })
        } else {
          this.setState({ applyCouponCode: false, promocodeError: "", promocodeSuccess: "Coupon Code has been applied on this order!", content: `Your coupon code "${responseJson.data?.coupon.data.attributes.promo_code_name}" has been applied. Enjoy £${responseJson.data.coupon.data.attributes.applied_discount} off on your order!` })
          this.props.setOrderDetails(responseJson.data.coupon.data.attributes.promo_code_id)
          this.getOrderSummary()
        }
      } else if (apiRequestCallId === this.removeCouponCodeCallId) {
        this.props.setOrderDetails(null)
        this.setState({ coupon_code: "", promocodeSuccess: "" })
        this.getOrderSummary()
      } else if (apiRequestCallId === this.orderSummaryApiCallId) {
        this.setState({ orderSummary: responseJson.data, applyCouponCode: false })
        this.props.setGiftCardCouponPointsAmount(responseJson.data.coupons, responseJson.data.redeem_points, responseJson.data.gift_card, responseJson.data.sub_total, responseJson.data.total_price)
        if (responseJson.data.meta) {
          this.props.showMinimumCartMessage(responseJson.data.meta)
        }
      } else if (apiRequestCallId === this.rewardPointApiCallId) {
        this.setState({ pointRewards: responseJson })
      } else if (apiRequestCallId === this.rewardPointWorthApiCallId) {
        const points_worth = String(responseJson.data.points_worth)
        this.setState({ points_worth })
      } else if (apiRequestCallId === this.rewardRedeemApiCallId) {
        if (responseJson.error) {
          this.setState({ reward_point_error: responseJson.error })
        } else {
          sessionStorage.removeItem("redeemPoints")
          this.props.showMinimumCartMessage(responseJson.data.message || "")
          this.setState({ reward_point_error: "", orderSummary: { ...this.state.orderSummary, sub_total: responseJson.data.sub_total, total_price: responseJson.data.total_amount, redeem_points: responseJson.data.points_worth, meta: responseJson.data.message || "" }, rewardInput: `${responseJson.data.redeem_points}` })
          this.props.setGiftCardCouponPointsAmount(this.state.orderSummary.coupons, `${responseJson.data.points_worth}`, this.state.orderSummary.gift_card, responseJson.data.sub_total, responseJson.data.total_amount)
        }
      } else if (apiRequestCallId === this.applyGiftCardCallId) {
        if (responseJson.errors) {
          this.setState({ gift_card_error: responseJson.errors })
        } else if (responseJson.message) {
          this.setState({ gift_card_error: responseJson.message })
        }
        else {
          const data = responseJson.data.attributes
          this.setState({ orderSummary: { ...this.state.orderSummary, sub_total: data.sub_total, total_price: data.total, gift_card: data.gift_card_amount }, gift_card_success: responseJson.meta.message })
          this.props.setGiftCardCouponPointsAmount(this.state.orderSummary.coupons, `${this.state.orderSummary.redeem_points}`, data.gift_card_amount, responseJson.data.sub_total, responseJson.data.total_amount)
        }
        if (responseJson.meta) {
          if (responseJson.meta.message === "Gift card removed successfully") {
            this.setState({ gift_card_ref_num: "" })
            this.props.setGiftCardCouponPointsAmount(this.state.orderSummary.coupons, `${this.state.orderSummary.redeem_points}`, "0", responseJson.data.sub_total, responseJson.data.total_amount)
          }
        }
      } else if (apiRequestCallId === this.getCardListApiCall) {
        this.setCardList(responseJson)
      } else if (apiRequestCallId === this.getRewarPointApiCallId) {
        this.setState({ messageCartItems: responseJson.data.message })
      } else if (apiRequestCallId === this.getPostCodeSuggestionCallId) {
        if (responseJson.error) {
          this.setState({ postCodeArray: [{ main_text: "No suggestions found", secondary_text: "" }] })
        } else {
          if (responseJson.data.length >= 1) {
            this.setState({ postCodeArray: responseJson.data })
          } else {
            this.setState({ postCodeArray: [{ main_text: "No suggestions found", secondary_text: "" }] })
          }
        }
      } else if (apiRequestCallId === this.getPostCodeFromPositionCallId) {
        if (responseJson.message === "Postcode found successfully") {
          this.setState({ post_code: responseJson.postcode })
        }
      } else if (apiRequestCallId === this.getRestaurantListId) {
        if (responseJson.errors) {
          this.setState({ restaurantList: [], noRestaurantFound: true })
        } else if (responseJson.data) {
          this.setState({ restaurantList: responseJson.data })
          if (this.state.selectedValue === 'delivery') {
            this.setState({ restaurantList: [] })
            if (this.props.restaurant_id === responseJson.data[0].id) {
              this.handleChangeDeliveryAddress(this.state.selectedValue, this.state.addressIdToBeSelected);
            } else {
              this.handleClickWithRoute(responseJson.data[0].id, responseJson.data[0].attributes.title)
            }
          }
        }
      } else if (apiRequestCallId === this.getRestaurantDetailAndCartApiCallId) {
        if (responseJson.message && responseJson.message === "All selected items are present in this restauarnt") {
          this.setState({ openRestaurantChange: true })
        } else if (responseJson.unavailable_items) {
          this.setState({ openRestaurantChange: true, unAvailableCartItems: responseJson.unavailable_items })
        }
      } else if (apiRequestCallId === this.setRestaurantDataToCatalogueId) {
        if (responseJson.restaurant) {
          if (this.state.selectedValue === 'delivery') {
            this.handleChangeDeliveryAddress(this.state.selectedValue, this.state.addressIdToBeSelected);
          }
          this.props.isRestaurantChanged(true)
          localStorage.setItem("restaurant_details", JSON.stringify({ restaurantId: responseJson.restaurant.data.attributes.id, orderType: responseJson.order_type }));
          if (this.state.unAvailableCartItems.length >= 1) {
            const message: Message = new Message(
              getName(MessageEnum.NavigationMenuMessage)
            );
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
          }
        }
      }
      if (apiRequestCallId === this.setDeliveryInstructionApiCallId) {
        if (responseJson.data) {
          console.log("added")
        } else {
          this.showAlert("token", "token")
        }
      }
      if (apiRequestCallId === this.getDeliveryInstructionSelectedApiCallId) {
        if (responseJson.data) {
          const deliveryInstructionSelectedId = responseJson.data.map((value: DeliverInstruction) => {
            return value.attributes.id
          })
          this.props.setChangeDeliveryInstructions(deliveryInstructionSelectedId)

        }
      }

    }
    // Customizable Area End
  }

  onChangeCouponCode = (value: string) => {
    this.setState({ coupon_code: value, promocodeError: "" })
  }
  onChangeGiftCode = (value: string) => {
    this.setState({ gift_card_ref_num: value, gift_card_error: "", gift_card_success: "" })
  }

  closeRemoveCoupon = () => {
    this.setState({ removeCouponOpen: false })
  }

  disableGiftCardRedeem = () => {
    return this.state.gift_card_error || this.state.orderSummary.gift_card !== "0.00";
  }

  disablePromoCodeRedeem = () => {
    return this.state.promocodeError;
  }

  setCardList = (responseJson: { data?: CardData[], errors?: [{ stripe: string }] }) => {
    if (responseJson.data) {
      this.setState({ card_data_list: responseJson.data })
    } else if (responseJson.errors) {
      // this.getCardList()
    }
  }

  toggleDropdown = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  getRewardPointsConversion = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rewardPointWorthApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getWingoRewardsPointWorthEndPoint}?points=${this.state.rewardInput}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDefaultAddressId = (addressList: AddressItem[] = this.state.addressList) => {
    let addressId = 0;
    addressList.forEach((address: AddressItem) => {
      if (address.attributes.default_address) {
        addressId = Number(address.id);
      }
    })
    return addressId;
  }

  handleSelectChange = (value: string, selectedAddressString?: string) => {
    const selectedAddress: AddressItem = JSON.parse(selectedAddressString || "{}")
    console.log(selectedAddress.type, "deliveryShowdeliveryShow");
    if (selectedAddress.type === "address") {
      this.setState({ deliveryShow: true })
    }
    const address_id = value === "collection" ? 0 : (selectedAddress.id && Number(selectedAddress.id)) || this.state.address_id;
    this.setState({ selectedValue: value, isActive: false, addressIdToBeSelected: address_id });
    if (selectedAddress.attributes && selectedAddress.attributes.post_code) {
      this.getAllRestaurantList(selectedAddress.attributes.post_code, 'delivery')
    }
    if (value === "collection") {
      this.handleChangeDeliveryAddress(value);
      this.getOrderSummary();
    }
  };

  handleAddressPopup = (value: boolean) => {
    this.getSavedAddresses();
    this.setState({ addressPopup: value, editAddressDetails: null })
  }

  editAddress = (item: AddressItem) => {
    this.setState({ editAddressDetails: { ...item.attributes, id: item.id } });
    this.setState({ addressPopup: true })
  }

  closeDeletePopup = () => {
    this.setState({ deletePopup: false, addressId: "" });
  }

  openDeletePopup = (id: string) => {
    this.setState({ deletePopup: true, addressId: id });
  }

  handlePostCodeChange = (value: string) => {
    this.setState({ postCodeSelected: value });
  }

  handleDeliveryTime = (value: string) => {
    this.setState({ prepare_immediately: value === 'true', delivery_date: "", selectedTime: "" });
    if (value === 'true') {
      this.putCollectionTime(value === 'true');
    }
  }

  handleDateChange = (field: string | number, value: string | null) => {
    this.setState({ delivery_date: value || "", selectedTime: "" })
  }

  handleTimeChange = (time: string) => {
    this.setState({ selectedTime: time });
    this.putCollectionTime(this.state.prepare_immediately, `${this.state.delivery_date} ${time}`);
  };

  putCollectionTime = (immediate: boolean, schedule_time?: string) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutCollectionTime = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putCollectionApiEndPoint}/${this.props.personalDetailsRead.order_id}/add_collection_time`
    );
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const attrs = {
      "prepare_immediately": `${immediate}`,
      "schedule_time": schedule_time
    };

    const httpBody = {
      delivery: attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  savePersonalDetailDisabled = () => {
    return !this.props.personalDetails.firstName || !this.props.personalDetails.lastName || this.props.personalDetails.fullPhoneNumber.length < 13 || !!this.props.personalDetails.firstName.match(configJSON.specialChar) || !!this.props.personalDetails.lastName.match(configJSON.specialChar)
  }

  navigateToMenu = (id: string, order_type: string, title: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMenuMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { restaurant_id: id, order_type: order_type, title: title })
    this.send(message);
  }

  changeOpenCoupoonPopup = () => {
    this.setState({ applyCouponCode: !this.state.applyCouponCode })
  }

  changeOpenAddPaymentPopup = () => {
    this.setState({ addCardPopup: !this.state.addCardPopup })
  }
  deleteAddress = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAddressAPiEndPoint + `/${this.state.addressId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ deletePopup: false, addressList: this.state.addressList.filter((item: AddressItem) => item.id !== this.state.addressId) });
  }

  applyCouponCode = (couponName: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.applyCouponCodeEndPoint}/${this.props.orderDetails?.id}/apply_coupon`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = {
      "code": couponName
    }

    this.setState({ coupon_code: couponName })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAdressApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeCouponCode = (couponName: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeCouponCodeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/remove_coupon_code`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = {
      "code": couponName,
      "order_id": this.props.orderDetails?.attributes.id
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyGiftCard = (apply: boolean) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyGiftCardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putGiftCardApiEndPoint}/${this.props.orderDetails?.id}/apply_gift_card`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = {
      refrence_number: this.state.gift_card_ref_num,
      gift_card: apply
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrderSummary = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.orderSummaryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${this.props.orderDetails?.id}/order_summary`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setGiftCardOption = (value: string) => {
    this.setState({ giftCardOptionSelected: value })
  }

  getDeliveryDetails = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetDeliveryDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDeliveryAdressApiEndPoint}/${this.props.personalDetailsRead.order_id}/get_delivery_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSavedAddresses = () => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeDeliveryAddress = (deliveryType: string, address_id?: number) => {
    if (deliveryType === 'delivery' && !address_id) {
      return;
    }
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddDeliveryMethodCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );
    const params = deliveryType === "collection" ? `order_type=${deliveryType}` : `order_type=${deliveryType}&address_id=${address_id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDeliveryAdressApiEndPoint}/${this.props.personalDetailsRead.order_id}/add_address_to_order_delivery?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangePaymentType: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    return new Promise((resolve) => {
      this.setState({ paymentType: event.target.value }, resolve);
    });
  }

  handleCardSelected: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    return new Promise((resolve) => {
      this.setState({ card_id: event.target.value, cvc_for_payment: "" }, resolve);
    });
  }

  handleWalletTypeSelect: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    return new Promise((resolve) => {
      this.setState({ walletType: event.target.value }, resolve);
    });
  }

  handleCvcChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    return new Promise((resolve) => {
      this.setState({ cvc_for_payment: formatCVC(event.target.value) }, resolve);
    });
  }

  proceedToPaymentButtonDisabled = () => {
    const conditions = [
      !this.props.personalDetailsRead.fullPhoneNumber,
      this.checkAddresses(),
      this.props.editProfile,
      !!this.state.orderSummary.meta,
      !this.props.personalDetailsRead.firstName,
      !this.props.personalDetailsRead.lastName,
      !this.state.paymentType,
      this.disableCardProceed(),
      this.enableDeliveryLaterOption()
    ];

    return conditions.some(condition => condition);
  }

  handlePayment = () => {
    switch (this.state.paymentType) {
      case "Pay on Delivery":
        this.handleCashPayButtonClick();
        break;
      case "Credit/Debit Card":
        this.handleCardPayButtonClick();
        break;
      case "Wallet":
        if (this.state.walletType === "googlePay") this.handleGooglePayButtonClick();
        else if (this.state.walletType === "applePay")
        this.onApplePayButtonClicked()
        break;
    }
  }

  checkAddresses(): boolean {
    if (this.state.selectedValue === "delivery") {
      if (!this.state.address_id || this.state.addressList.length === 0) return true;
    }

    return false;
  }

  disableCardProceed = () => {
    if (this.state.paymentType === "Credit/Debit Card") {
      return !this.state.card_id || !this.state.cvc_for_payment
    } else if (this.state.paymentType === "Wallet") {
      return !this.state.walletType
    }
  }

  getRewardPoints = (token: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rewardPointApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWingoRewardsPointsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closePostCodeArray = () => {
    this.setState({ postCodeArray: [] })
  }

  personalDetails = () => {
    const { personalDetails, handleMobileChange, handleCountryCodeChange, handleInputChangeFirstName, handleInputChangeLastName, updateProfileOrder, personalDetailsRead, editProfileOpenClose, editProfile } = this.props
    return (
      <div className="personal_details">
        <div className="header">
          <Typography className="sec-title">Personal Details</Typography>
          <IconButton aria-label="edit" disabled={editProfile} onClick={editProfileOpenClose}>
            <img src={textbutton_edit} alt="edit_personal_details"></img>
          </IconButton>
        </div>
        {editProfile ?
          <div className="confirm_order_personalDetais_form_action_container">
            <div className="confirm_order_personalDetais_form_container">
              <div className="confirm_order_personalDetais_text_label_container">
                <label className="confirm_order_personalDetais_label">First Name:</label>
                <input onChange={(event) => { handleInputChangeFirstName(event.target.value) }}
                  className={"confirm_order_personalDetais_text_input"} name="edit_first_name" type="text" value={personalDetails.firstName || ""} />
                {!personalDetails.firstName && <div className="error_personal_details" >Name field cannot be empty.</div>}
                {personalDetails.firstName && personalDetails.firstName.match(configJSON.specialChar) && <div className="error_personal_details" >Name field cannot contain special character or numbers.</div>}
              </div>
              <div className="confirm_order_personalDetais_text_label_container">
                <label className="confirm_order_personalDetais_label">Last Name:</label>
                <input onChange={(event) => { handleInputChangeLastName(event.target.value) }}
                  className={"confirm_order_personalDetais_text_input"} name="edit_last_name" type="text" value={personalDetails.lastName || ""} />
                {!personalDetails.lastName && <div className="error_personal_details" >Name field cannot be empty</div>}
                {personalDetails.lastName && personalDetails.lastName.match(configJSON.specialChar) && <div className="error_personal_details" >Name field cannot contain special character or numbers.</div>}
              </div>
              <CustomMobileBox value={personalDetails.fullPhoneNumber} onCountryCodeChange={handleCountryCodeChange} onMobileNumberChange={handleMobileChange} borderColorText="2px solid #1d1d1d" />
              {(personalDetails.fullPhoneNumber.length < 13 && personalDetails.fullPhoneNumber.length >= 4) && <div className="error_personal_details" >Please enter a valid Mobile Number.</div>}
              {personalDetails.fullPhoneNumber.length < 4 && <div className="error_personal_details" >This field is required and can't be empty! Mobile number is requiredto place an order.</div>}
            </div>
            <div className="confirm_order_personalDetais_action_container">
              <Button className="confirm_order_personalDetais_button" style={{ color: "#E11B22", border: "2px solid #E11B22" }} onClick={editProfileOpenClose}>CANCEL</Button>
              <Button disabled={this.savePersonalDetailDisabled()} className="confirm_order_personalDetais_button" style={{ color: this.savePersonalDetailDisabled() ? "#909090" : "#FFFFFF", backgroundColor: this.savePersonalDetailDisabled() ? "#BFBFBF" : "#E11B22" }} onClick={updateProfileOrder}>SAVE</Button>
            </div>
          </div> :
          <div className="form">
            <div>
              <div className="field">
                <InputLabel className="label">First Name:</InputLabel>
                <Typography className="value">{personalDetailsRead.firstName}</Typography>
              </div>
              {!personalDetailsRead.firstName && <div style={{ marginTop: "-9px", fontSize: "14px" }} className="error_personal_details"> First name is required to place an order</div>}
            </div>
            <div>
              <div className="field">
                <InputLabel className="label">Last Name:</InputLabel>
                <Typography className="value">{personalDetailsRead.lastName}</Typography>
              </div>
              {!personalDetailsRead.lastName && <div style={{ marginTop: "-9px", fontSize: "14px" }} className="error_personal_details"> Last name is required to place an order</div>}
            </div>
            <div>
              <div className="field">
                <InputLabel className="label">Mobile Number:</InputLabel>
                <Typography className="value">{personalDetailsRead.fullPhoneNumber && personalDetailsRead.fullPhoneNumber.replace("++", "+")}</Typography>
              </div>
              {!personalDetailsRead.fullPhoneNumber && <div style={{ marginTop: "-9px", fontSize: "14px" }} className="error_personal_details"> Mobile number is required to place an order</div>}
            </div>
          </div>
        }

      </div>)
  }

  getRestaurantDetailAndCart = (id: string) => {
    const headers = {
      "Content-Type": configJSON.getAddressApiContentType,
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRestaurantDetailAndCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRestaurantDetailAndCartApiEndPoint}?restaurant_selection[restaurant_id]=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickWithRoute = (id: string, title: string) => {
    this.getRestaurantDetailAndCart(id)
    this.setState({ newRestaurantSelected: id, restaurantData: { restaurant_id: id, order_type: this.state.selectedValue, title } })
  }

  closeRestaurantPopup = () => {
    this.setState({ openRestaurantChange: false })
  }

  setRestaurantDataToCatalogue = (id: number, order_type: string) => {
    this.props.isRestaurantChanged(false)
    const headers = {
      "Content-Type": configJSON.getAddressApiContentType,
      "token": this.token
    };

    const httpBody = {
      restaurant_selection: {
        restaurant_id: id,
        order_type: order_type
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setRestaurantDataToCatalogueId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setRestaurantApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAdressApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  setOrderType = () => {
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddDeliveryMethodCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putCollectionApiMethod
    );
    const params = this.state.selectedValue === "collection" ? `order_type=${this.state.selectedValue}` : `order_type=${this.state.selectedValue}&address_id=${this.state.addressIdToBeSelected}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDeliveryAdressApiEndPoint}/${this.props.personalDetailsRead.order_id}/add_address_to_order_delivery?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  selectRestaurant = () => {
    const { restaurantList } = this.state
    return (
      restaurantList.length >= 1 &&
      <div className="restaurant_selector_container" style={{ padding: "10px" }}>
        {this.state.restaurantList.map((item: RestaurantList, index: number) => (
          <div
            key={item.id}
            className="restaurant-tile"
            style={{ backgroundColor: (item.attributes.distance && index === 0) ? "#FFF4F4" : "#FFFFFF", maxWidth: "100%", width: "100%" }}
          >
            {item.attributes.distance && index === 0 && <img src={nearestImage} alt="nearest" style={{ position: "absolute", top: "-3px", left: "-9px" }} />}
            <div className="restaurant-option">
              <div className="restaurant_title_distance_container" style={{ gap: "8px" }} >
                <h2 style={{ whiteSpace: "nowrap" }}> {item.attributes.title}</h2>
                {item.attributes.distance && <div className="restaurant_distance_container" style={{ whiteSpace: "nowrap" }} ><img src={distanceImage} alt="distance" /> {item.attributes.distance} </div>}</div>
              <div style={{ minHeight: '130px' }} className="restaurant-content">
                <div className="restaurant-detail"> <LocationOnRoundedIcon className="location" /> <div> {item.attributes.location} </div> </div>
                <div className="restaurant-detail"> <PhoneInTalkIcon className="location" /> <div> {item.attributes.contact}</div>  </div>
                <div className="restaurant-detail"> <ScheduleIcon className="location" /> <div>Open {item.attributes.open_time} to {item.attributes.close_time} </div> </div>
              </div>
            </div>
            {this.props.restaurant_id === item.id ?
              <div style={{ maxWidth: "140px", height: "56px", backgroundColor: "#2C6F37", border: "2px solid #2C6F37", display: "flex", alignItems: "center", justifyContent: "center", color: "white", fontWeight: 500, borderRadius: "8px" }}>SELECTED</div> :
              <Button className="restaurant_select_action_button" onClick={() => this.handleClickWithRoute(item.id, item.attributes.title)}>Select</Button>
            }
          </div>
        ))}
      </div>

    )
  }

  wingoRewardSection = () => {
    return (
      <div className="wingoRewardsContainer">
        <div className="wingoRewardsContainerHeading" >Wingo’s Reward Points <div className="wingoRewardsContainerPoints" >{this.state.pointRewards.data.total_points} points</div></div>
        <Accordion
          className="accordianWingoRewards"
          style={{ border: "0px solid", fontWeight: 700, fontSize: "20px", color: "#1D1D1D", fontFamily: "Barmeno", boxShadow: "none" }} >
          <AccordionSummary
            style={{ padding: "0px" }}
            expandIcon={<ExpandMoreIcon style={{ height: "40px", width: "40px", color: "#1D1D1D" }} />}>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px", width: "100%" }}>
              <img style={{ width: "40px" }} src={imagePound} alt="pound_image" />
              <div><Typography style={{ fontWeight: 700 }} className="wingoRewardsAccordian">Redeem Wingo’s Reward Points</Typography>
                <Typography style={{ color: "#747474" }} className="wingoRewardsAccordian">Get your points redeemed right away</Typography></div></div>
          </AccordionSummary>
          <div className="rewardReedemption">
            <div className="divWingoRewards">
              <div className="input_for_wingo_reward" style={{ width: "400px", gap: "12px", color: "#1d1d1d" }}>
                <label htmlFor="wingo_rewards" className="" >Wingo’s Reward Points</label>
                <div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "15px" }}>
                    <input disabled={this.state.orderSummary.redeem_points !== "0.00"} onChange={(event) => { this.onChangeRewardPoint(event.target.value) }} name="wingo_rewards" style={{ border: '1px solid #BFBFBF' }} className="enter_wingo_reward_points_field" type="text" value={this.state.rewardInput} />
                    {this.state.orderSummary.redeem_points !== "0.00" ? <div onClick={() => { this.removeRewardPoints(false, '0') }} style={{ color: "#E11B22", cursor: "pointer" }}>Remove</div> :
                      <div onClick={!this.disabledRewardRedeem() ? () => { this.applyRewardPoints(true, this.state.rewardInput) } : undefined} style={{ color: this.disabledRewardRedeem() ? "#A2A2A2" : "#E11B22", cursor: "pointer" }}>Redeem</div>}
                  </div>
                  <div style={{ color: ' #E11B22', fontSize: "14px", fontWeight: 700, width: "320px" }}>{this.state.reward_point_error}</div>
                  <div className="points-worth-class">Points Worth - £{this.state.points_worth}</div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    )
  }
  wingoGiftCardCouponSection = () => {
    return (
      <div className="wingoRewardsContainer">
        <div className="wingoRewardsContainerHeading" >Gift Cards & Coupons {this.state.giftCardOptionSelected !== "Gift Card Code" && <div style={{ color: "#E11B22", cursor: "pointer" }} onClick={() => { this.changeOpenCoupoonPopup(); this.getPromoCodeData() }} >View all</div>} </div>
        <Accordion
          className="accordianWingoRewards"
          style={{ border: "0px solid", fontWeight: 700, fontSize: "20px", color: "#1D1D1D", fontFamily: "Barmeno", boxShadow: "none" }} >
          <AccordionSummary
            style={{ padding: "0px" }}
            expandIcon={<ExpandMoreIcon style={{ height: "40px", width: "40px", color: "#1D1D1D" }} />}>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px", width: "100%" }}>
              <LocalOfferOutlinedIcon style={{ width: "40px", height: "40px", alignSelf: "center", color: "#2C6F37" }} />
              <div><Typography style={{ fontWeight: 700 }} className="wingoRewardsAccordian">Apply Coupon or Gift Card Code</Typography>
                <Typography style={{ color: "#747474" }} className="wingoRewardsAccordian">You can use both on an order!</Typography></div></div>
          </AccordionSummary>
          <div style={{ display: "flex", flexDirection: "column", gap: "40px", marginTop: "40px" }}>
            <RadioGroup
              row
              style={{ gap:"120px"}}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={'Gift Card Code'}
              onChange={(event) => { this.setGiftCardOption(event.target.value) }}
            >
              <FormControlLabel
                value={'Gift Card Code'}
                control={<CustomRadioBox />}
                label={<div style={{ fontFamily: "Barmeno", fontWeight: 500, fontSize: "18px", color: "#1D1D1D" }}>Gift Card Code</div>} />
              <FormControlLabel
                style={{ marginRight: "0px" }}
                value={'Coupon Code'}
                control={<CustomRadioBox />}
                label={<div style={{ fontFamily: "Barmeno", fontWeight: 500, fontSize: "18px", color: "#1D1D1D" }}>Coupon Code</div>} />
            </RadioGroup>
            {this.state.giftCardOptionSelected === "Gift Card Code" ?
              <div className="rewardReedemption">
                <div className="input_for_wingo_reward" style={{ gap: "12px", color: "#1d1d1d" }}>
                  <label htmlFor="wingo_rewards" className="" >Gift Card Code</label>
                  <div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                      <input disabled={this.state.orderSummary.gift_card !== "0.00"} onChange={(event) => { this.onChangeGiftCode(event.target.value) }} name="wingo_rewards" style={{ border: '1px solid #BFBFBF' }} className="enter_wingo_reward_points_field" type="text" placeholder="Enter the gift card code" value={this.state.gift_card_ref_num || ""} />
                      {this.state.orderSummary.gift_card === "0.00" ?
                        <div onClick={!this.disableGiftCardRedeem() ? () => { this.applyGiftCard(true) } : undefined} style={{ color: this.disableGiftCardRedeem() ? "#A2A2A2" : "#E11B22", cursor: "pointer", marginLeft: "15px" }}>Apply</div> :
                        <div onClick={() => { this.removeGiftCard(false) }} style={{ color: "#E11B22", cursor: "pointer", marginLeft: "15px" }}>Remove</div>}
                    </div>
                    <div style={{ color: ' #E11B22', fontSize: "14px", fontWeight: 700, width: "320px" }}>{this.state.gift_card_error}</div>
                    <div style={{ color: ' #2C6F37', fontSize: "14px", fontWeight: 700, width: "320px" }}>{this.state.gift_card_success}</div>
                  </div>
                </div>
              </div> :
              <div className="rewardReedemption">
                <div className="input_for_wingo_reward" style={{ gap: "12px", color: "#1d1d1d" }}>
                  <label htmlFor="wingo_rewards" className="" >Coupon Code</label>
                  <div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <input disabled={!!this.props.orderDetails?.attributes.promo_code_id} onChange={(event) => { this.onChangeCouponCode(event.target.value) }} name="wingo_rewards" style={{ border: '1px solid #BFBFBF' }} className="enter_wingo_reward_points_field" type="text" placeholder="Enter the discount code" value={this.state.coupon_code || ""} />
                      {this.props.orderDetails?.attributes.promo_code_id ?
                        <div onClick={() => { this.removeCouponCodePopup(this.state.coupon_code || '') }} style={{ color: "#E11B22", cursor: "pointer", marginLeft: "15px" }}>Remove</div> :
                        <div onClick={!this.disablePromoCodeRedeem() ? () => { this.applyCouponCode(this.state.coupon_code || '') } : undefined} style={{ color: this.disablePromoCodeRedeem() ? "#A2A2A2" : "#E11B22", cursor: "pointer", marginLeft: "15px" }}>Apply</div>}
                    </div>
                    <div style={{ marginTop: "8px", color: ' #E11B22', fontSize: "14px", fontWeight: 700, width: "320px" }}>{this.state.promocodeError}</div>
                    <div style={{ marginTop: "8px", color: ' #2C6F37', fontSize: "14px", fontWeight: 700, width: "320px" }}>{this.state.promocodeSuccess}</div>

                  </div>
                </div>
              </div>
            }


          </div>
        </Accordion>
      </div>
    )
  }

  orderSummary = () => {
    const { orderSummary } = this.state
    return (
      <div className="wingoRewardsContainer" style={{ padding: "24px", fontWeight: 700, color: "#1D1D1D" }} >
        <div className="wingoRewardsContainerHeading" >Order Summary</div>
        <div className="subTotalSummary">
          <div className="subTotalEachItem">
            <div className="subTotalKey">Sub Total</div> <div className="subTotalValue">£{orderSummary.sub_total}</div>
          </div>
          <div className="subTotalEachItem">
            <div className="subTotalKey">Coupons / Discounts</div> <div style={{ color: orderSummary.coupons !== '0.00' ? '#E11B22' : "#1D1D1D" }} className="subTotalValue"> {orderSummary.coupons !== '0.00' && '-'}£{orderSummary.coupons}</div>
          </div>
          <div className="subTotalEachItem">
            <div className="subTotalKey">Redeem Points</div> <div style={{ color: orderSummary.redeem_points !== '0.00' ? '#E11B22' : "#1D1D1D" }} className="subTotalValue">{orderSummary.redeem_points !== '0.00' && '-'}£{orderSummary.redeem_points}</div>
          </div>
          <div className="subTotalEachItem">
            <div className="subTotalKey">E-gift Card</div> <div style={{ color: orderSummary.gift_card !== '0.00' ? '#E11B22' : "#1D1D1D" }} className="subTotalValue">{orderSummary.gift_card !== '0.00' && '-'}£{orderSummary.gift_card}</div>
          </div>
          <div className="subTotalEachItem">
            {this.state.deliveryShow ? <> <div className="subTotalKey">Delivery Charges</div> <div style={{ color: orderSummary.gift_card !== '0.00' ? '#E11B22' : "#1D1D1D" }} className="subTotalValue">{orderSummary.delivery_charges !== '0.00'}£{orderSummary.delivery_charges}</div></> : ""}
          </div>
        </div>
        <div className="subTotalSummary">
          {this.props.save_value_message && <div className="savings">*{this.props.save_value_message}</div>}
          {orderSummary.meta && <div className="minimumCartValue">{orderSummary.meta}</div>}
          <div className="subTotalEachItem" style={{ borderTop: "1px solid #BFBFBF", paddingTop: "12px" }}>
            <div className="subTotalKey">Total</div> <div className="subTotalValue">£{orderSummary.total_price}</div>
          </div>
          <div className="shoppingCartMessage"><img src={messageCart} style={{ height: "24px", width: "24px" }} />{this.state.messageCartItems}</div>
        </div>
      </div>
    )
  }

  paymentOptions = () => {
    return (
      <div className="wingoRewardsContainer" style={{ padding: "24px", fontWeight: 700, color: "#1D1D1D" }} >
        <div className="wingoRewardsContainerHeading" >Payment Method</div>
        <RadioGroup
          row
          style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          defaultValue={'Credit/Debit Card'}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={this.handleChangePaymentType}
          value={this.state.paymentType}
        >
          {this.props.restaun_fact.attributes.accepts_card_payment && <FormControlLabel className='form_control_label_payment_option'
            style={{ borderBottom: this.state.paymentType === 'Credit/Debit Card' ? "0px solid" : "" }}
            name={'Credit/Debit Card'}
            value={'Credit/Debit Card'}
            control={<CustomRadioBox />}
            label={<div className='payment_option_label'><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Credit/Debit Card</div><CardOptions /></div>} />}
          {this.state.paymentType === 'Credit/Debit Card' &&
            <RadioGroup
              row
              style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={this.handleCardSelected}
              value={this.state.card_id}
            >
              {this.state.card_data_list.map((value, index) =>
                <FormControlLabel className='form_control_label_card_option'
                  value={value.id}
                  control={<CustomRadioBox />}
                  label={<div className='payment_option_label'>
                    <GetCardBrand brand={value.attributes.card.brand} />
                    <div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Card ending in {value.attributes.card.last4}</div>
                    {this.state.card_id === value.id && <input ref={this.cvcInput} className="cvcInputStyle" value={this.state.cvc_for_payment} onChange={this.handleCvcChange} />}
                  </div>} />
              )}
              <div className="addNewCard" onClick={this.changeOpenAddPaymentPopup}><ControlPointRoundedIcon /> Add New Card</div>
            </RadioGroup>
          }

          {this.props.restaun_fact.attributes.accepts_card_payment && <FormControlLabel className='form_control_label_payment_option'
            name={'Wallet'}
            value={'Wallet'}
            control={<CustomRadioBox />}
            label={<div className='payment_option_label'><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Wallet</div></div>} />}
          {this.state.paymentType === "Wallet" &&
            <RadioGroup
              row
              style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={this.handleWalletTypeSelect}
              value={this.state.walletType}
            >
              <FormControlLabel className='form_control_label_card_option'
                value={'googlePay'}
                control={<CustomRadioBox />}
                label={<div className='payment_option_label'><img style={{ width: "32px", height: "32px" }} src={googlePay} alt="paymentType" /><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Google Pay</div></div>} />
              <FormControlLabel className='form_control_label_card_option'
                value={'applePay'}
                control={<CustomRadioBox />}
                label={<div className='payment_option_label'><img style={{ width: "32px", height: "32px" }} src={applePay} alt="paymentType" /><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Apple Pay</div></div>} />
            </RadioGroup>
          }
          {this.props.restaun_fact.attributes.accepts_cash_payment && <FormControlLabel className='form_control_label_payment_option'
            style={{ borderBottom: "0px Solid" }}
            name={'Pay on Delivery'}
            value={'Pay on Delivery'}
            control={<CustomRadioBox />}
            label={<div className='payment_option_label'><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >Pay on {this.state.selectedValue}</div></div>} />}
        </RadioGroup>
        <Button className={this.proceedToPaymentButtonDisabled() ? "proceedPaymentDisableButton" : "proceedPaymentButton"} disabled={this.proceedToPaymentButtonDisabled()} onClick={this.handlePayment}>PROCEED WITH PAYMENT</Button>
        <GooglePay walletType={this.state.walletType} navigation={this.props.navigation} id="googlePay" googlePayButtonRef={this.googlePayButtonRef} token={this.token} order_id={this.props.orderDetails?.id} openPaymentPopup={this.openPaymentPopup} amount={this.state.orderSummary.total_price} cardPayButtonRef={this.cardPayButtonRef} cashPayButtonRef={this.cashPayButtonRef} cvv={this.state.cvc_for_payment} payment_method_id={this.state.card_id} orderConfirmPopup={this.onClosePaymentPopup} isPaymentPopupOpen={this.state.paymentPopup} setPaymentStatus={this.changePaymentStatus}
          openPaymentWaiting={this.openPaymentWaiting} subscribeToStatusUpdates={this.subscribeToStatusUpdates} open3DsecurePopup={this.open3DsecurePopup} />
      </div>
    )
  }
  onApplePayButtonClicked = async () => {
    console.log("onApplePayButtonClickedonApplePayButtonClicked");
    const initStripe = async () => {
      const stripeInstance = await stripePromise;

      if (stripeInstance) {
        const pr = stripeInstance.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Total Amount",
            amount: 5000,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
 
        pr.canMakePayment().then((result:any) => {
          if (result) {
            console.log("onApplePay payment:",result);
            // setCanMakePayment(true);
            // setPaymentRequest(pr);
          }
        });
      }
    };

    initStripe();
    
  };

  onChangePostCode: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const upperCaseValue = event.target.value.toUpperCase()
    this.setState({ post_code: upperCaseValue })
    if (upperCaseValue.length >= 3) {
      this.debounce(upperCaseValue)
    } else {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.setState({ postCodeArray: [] })
    }
  }

  debounce = (postcode: string) => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.getSuggestionForPostcode(postcode)
    }, 300)
  }

  getSuggestionForPostcode = (postcode: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostCodeSuggestionCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postcodeSuggestionEndpoint}${postcode}${configJSON.typeQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  preventOnBlurInsideSuggestionBox = (event: React.MouseEvent) => {
    event.preventDefault()
  }
  onClickingItem = (postCode: string) => {
    const post_code = postCode.replace(/\s+/g, '')
    this.setState({ post_code, postCodeArray: [] })
    this.getAllRestaurantList(postCode, 'collection')
  }
  getCurrentPosition = () => {
    geolocationCoordinates(this.getPostCodeFromPosition)
  }
  getPostCodeFromPosition = (latitude: number, longitude: number) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostCodeFromPositionCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.positionLocationEndpoint}?latitude=${latitude}&longitude=${longitude}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );
    if (latitude && longitude) {
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  adjustedOpenTime = (delivery_date: string, open_time: string) => {
    const parseDate = (dateString: string): Date => {
      const [day, month, year] = dateString.split("/").map(Number);
      return new Date(year, month - 1, day);
    };
    const currentDate = new Date();
    const currentTime = currentDate.toTimeString().slice(0, 5);

    const deliveryDate = parseDate(delivery_date);
    const isToday = deliveryDate.toDateString() === currentDate.toDateString()
    return isToday && open_time < currentTime ? currentTime : open_time;
  }

  getAllRestaurantList = (postcode?: string | null, order_type?: string | null): void => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRestaurantListId = requestMessage.messageId

    const params = {
      postcode: postcode,
      order_type: order_type,
    }
    let queryParams = []
    if (params.postcode) {
      queryParams.push(`postcode=${params.postcode}`);
    }
    if (params.order_type) {
      queryParams.push(`order_type=${params.order_type}`);
    }
    let restaurantListApiEndPoint = configJSON.restaurantListApi
    if (queryParams.length > 0) {
      restaurantListApiEndPoint += '?' + queryParams.join('&');
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      restaurantListApiEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  changePaymentStatus = (status: boolean) => {
    this.setState({ paymentSuccessful: status });
  }
  setDeliveryInstruction = () => {
    const headers = {
      "Content-Type": configJSON.getAddressApiContentType,
      "token": this.token
    };

    const httpBody = {
      "delivery_instruction_ids": this.props.deliveryInstruction
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setDeliveryInstructionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDeliveriInstructionsApiEndpoint}${this.props.orderDetails?.id}/add_order_delivery_instructions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAdressApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getDeliveryInstructionSelected = () => {
    const headers = {
      "Content-Type": configJSON.getAddressApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeliveryInstructionSelectedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDeliveriInstructionsApiEndpoint}${this.props.orderDetails?.id}/order_delivery_instructions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getDeliveryInstructionsView = () => {
    return (
      <div className="time_slot">
        <DeliveryInstructionAccordian changeDeliveryInstruction={this.props.changeDeliveryInstruction} deliveryInstructionList={this.props.deliveryInstructionList} deliveryInstruction={this.props.deliveryInstruction} />
      </div>
    )
  }

  enableDeliveryLaterOption = () => {
    return (!this.state.prepare_immediately ? (!this.state.delivery_date || !this.state.selectedTime) : false)
  }

  getOpenData = (store_hours: StoreHoursAttribute[], openTime: string, date: string) => {
    const { open, close } = setStoreHoursAccordingToDate(store_hours, date)
    return open || openTime
  }
  getCloseData = (store_hours: StoreHoursAttribute[], closeTime: string, date: string) => {
    const { open, close } = setStoreHoursAccordingToDate(store_hours, date)
    return close || closeTime
  }

  render() {
    const { isActive, selectedValue, postCodeSelected, delivery_date, selectedTime, prepare_immediately } = this.state;
    return (
      <>
        <div className="confirm_order_container">
          <Typography className="title">Confirm Order</Typography>
          <div className="order_sections">
            <Grid container spacing={2}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className="contact_details">
                  {this.personalDetails()}
                  <div className="address_details">
                    <RadioGroup
                      row
                      defaultValue={this.state.selectedValue}
                      value={this.state.selectedValue}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className=""
                      onChange={(event) => { this.handleSelectChange(event.target.value) }}
                    >
                      <FormControlLabel disabled={!this.props.restaun_fact.attributes.order_type.includes('delivery')} className="radio_btn" value="delivery" control={<CustomRadioBox />} label={<Typography variant="body1" >Delivery</Typography>} />
                      <FormControlLabel disabled={!this.props.restaun_fact.attributes.order_type.includes('collection')} className="radio_btn" value="collection" control={<CustomRadioBox />} label={<Typography variant="body1">Collection</Typography>} />
                    </RadioGroup>
                    {selectedValue === 'delivery' ?
                      (<>
                        <div className="address_list">
                          <Typography className="address_title">Address List</Typography>

                          {this.state.addressList && <RadioGroup
                            row
                            defaultValue={`${this.state.address_id}`}
                            value={`${this.state.address_id}`}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="column-adress-list"
                            onChange={(event) => { this.handleSelectChange(this.state.selectedValue, event.target.name) }}
                          >
                            {this.state.addressList.map((item, index) => {
                              return (<FormControlLabel key={item.id} className="address_radio_btn" name={JSON.stringify(item)} value={item.id} control={<CustomRadioBox />}
                                label={
                                  <div className="address_text" style={{ display: 'flex', alignItems: 'center', width: "100%", margin: "4px 0 0 0" }}>
                                    <Grid container>
                                      <Grid item lg={2} md={2} sm={6} xs={6}>
                                        <Typography className="add_type" variant="body1">{`${item.attributes.address_type} - `}</Typography>
                                      </Grid>
                                      <Grid item lg={8} md={8} sm={6} xs={6}>
                                        <Typography className="add_address" variant="body1"> &nbsp;{`${item.attributes.address}, ${item.attributes.city}, ${item.attributes.post_code}`}</Typography>
                                      </Grid>
                                      <Grid item lg={1} md={1} sm={2} xs={2}>
                                        <IconButton onClick={() => { this.editAddress(item) }} className='address_edit_button'><EditRoundedIcon style={{ color: "#2C6F37" }} /></IconButton>
                                      </Grid>
                                      <Grid item lg={1} md={1} sm={2} xs={2}>
                                        <IconButton onClick={() => { this.openDeletePopup(item.id) }} className='address_edit_button'><DeleteOutlineRoundedIcon style={{ color: "#E11B22" }} /></IconButton>
                                      </Grid>
                                    </Grid>
                                  </div>
                                } />);
                            })}
                          </RadioGroup>}

                          <div className="add_new_title" onClick={() => this.handleAddressPopup(true)}>
                            <img src={image_add} alt="image_add" />
                            <Typography className="add_new">Add New Address</Typography>
                          </div>
                        </div>
                      </>) :
                      <div className="popup_postalcode_fields" style={{ width: "100%" }}>
                        <label htmlFor='postal_code' className="custom_giftAmount_label">Enter your Postcode</label>
                        <OutlinedInput onBlur={this.closePostCodeArray} autoComplete="off" classes={{ root: "storeLocationInput" }} style={{ fontFamily: 'Barmeno', border: '2px solid #231F20', borderRadius: '8px', maxWidth: "350px" }} onChange={this.onChangePostCode} value={this.state.post_code}
                          className={"gift-amount-form-field"} name="postal_code" placeholder='Please enter your postcode' endAdornment={
                            <InputAdornment position="end">
                              <IconButton onClick={this.getCurrentPosition} >
                                <LocationSearchingRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          } />
                        {this.state.postCodeArray.length > 0 &&
                          <div data-id="suggestionBox" style={{ width: "350px" }} onMouseDown={this.preventOnBlurInsideSuggestionBox} className="suggestions-dropdown">
                            {
                              this.state.postCodeArray.map((val) => {
                                return (
                                  <div onClick={() => { this.onClickingItem(val.main_text) }} className="suggestions-dropdown-item">
                                    <div style={{ fontSize: "16px", fontWeight: 600 }}>{val.main_text}</div>
                                    {val.secondary_text && <div style={{ fontSize: "12px" }}>,{val.secondary_text}</div>}
                                  </div>
                                )
                              })
                            }

                          </div>}
                        {this.selectRestaurant()}
                      </div>}
                    <CustomAccordion restaurantDetails={this.props.restaun_fact?.attributes} ></CustomAccordion>
                  </div>
                  {selectedValue === "delivery" && this.getDeliveryInstructionsView()}
                  <div className="time_slot">
                    <Typography className="time_title">Choose {capitalizeFirstLetter(this.state.selectedValue)} time</Typography>
                    <RadioGroup
                      row
                      defaultValue={prepare_immediately.toString()}
                      value={prepare_immediately.toString()}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="time_slot_radio_group"
                      onChange={(event) => { this.handleDeliveryTime(event.target.value) }}
                    >
                      <FormControlLabel className="time_for_delivery" value="true" control={<CustomRadioBox />}
                        classes={{ label: "choose_dt_time_label" }}
                        label={<div className="immediate_ready">
                          <Typography className="prep_title" variant="body1" >Prepare Immediately</Typography>
                          <Typography className="est_title" variant="body1" >Estimated time: {`${this.state.selectedValue === 'delivery' ? this.props.restaun_fact.attributes.delivery_time : this.props.restaun_fact.attributes.collection_time}`} minutes</Typography>
                        </div>} />
                      <FormControlLabel className="time_for_delivery" value="false" control={<CustomRadioBox />}
                        classes={{ label: "choose_dt_time_label" }}
                        label={<div className="choose_dt_time">
                          <Typography className="date_time" variant="body1">Choose Date & Time</Typography>
                          <div className="date_time_dropdown_confirmOrder" style={{ display: "flex", alignItems: "center", gap: "72px" }}>
                            <CustomCalendar dateFormat="dd/MM/yyyy" name="DD/MM/YYYY" immediate={!!prepare_immediately} onDateChange={this.handleDateChange} dateFromParent={delivery_date} />
                            <TimeDropdown value={selectedTime} dateSelected={this.state.delivery_date} open_time={this.adjustedOpenTime(this.state.delivery_date, this.getOpenData(this.props.restaun_fact?.attributes.store_hours, this.props.restaun_fact?.attributes.open_time, this.state.delivery_date))} close_time={this.getCloseData(this.props.restaun_fact?.attributes.store_hours, this.props.restaun_fact?.attributes.close_time, this.state.delivery_date)} onChange={this.handleTimeChange} />
                          </div>
                        </div>} />
                    </RadioGroup>
                  </div>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className="payment_details">
                  {this.wingoRewardSection()}
                  {this.wingoGiftCardCouponSection()}
                  {this.orderSummary()}
                  {this.paymentOptions()}
                </div>
              </Grid>
            </Grid>



          </div>
          <DialogPopupWeb className={location.pathname === "/CartOrderItem" ? "confirm_order__add_address" : "addAddressPopup"} open={this.state.addressPopup} onClose={() => this.handleAddressPopup(false)}>
            <AddAddress addressToedit={this.state.editAddressDetails} onClose={() => this.handleAddressPopup(false)} orderId={`${this.props.personalDetailsRead.order_id}`} navigation={''} id=""></AddAddress>
          </DialogPopupWeb>
          <DialogPopupWeb className="addAddressPopup" open={this.state.deletePopup} onClose={() => this.closeDeletePopup()}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" }}>
              <img style={{ width: "327px", height: "327px" }} src={delete_icon} alt="delete_icon" />
              <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Typography className="dlt_title">Delete Address</Typography>
                <Typography className="dlt_desc">Are you sure you want to delete an address from your profile?</Typography>
              </div>
              <div className="address_dlt_popup" style={{ margin: "0 0 40px 0", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}>
                <Button className="cancelButton" onClick={() => { this.closeDeletePopup() }} id="submit" style={{ fontFamily: "Barmeno" }} >
                  Cancel
                </Button>
                <Button className="deleteButton" onClick={() => this.deleteAddress()}>Delete</Button>
              </div>
            </div>
          </DialogPopupWeb>
          <Dialog
            open={this.state.payment3DsecurePopup}
            onClose={() => this.setState({ payment3DsecurePopup: false })}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                width: "90%",
                height: "90%",
              },
            }}
          >
            <DialogContent style={{ display: "flex", justifyContent: "center" }}>
              {this.state.urlToNavigate ? (
                <iframe
                  style={{ border: "none", width: "100%", minHeight: "600px" }}
                  src={this.state.urlToNavigate}
                  title="3D Secure Redirect"
                />
              ) : (
                <p>Loading secure payment page...</p>
              )}
            </DialogContent>
          </Dialog>

          <DialogPopupWeb open={this.state.addCardPopup} onClose={this.changeOpenAddPaymentPopup} closeIcon={false}>
            <DialogTitle style={{ margin: "4px 0px 24px 0px", padding: "0px" }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px solid #D4D4D4">
                <div style={{ fontFamily: "Barmeno", fontSize: "24px", fontWeight: 700 }}> Add New Card</div>
                <IconButton onClick={this.changeOpenAddPaymentPopup} >
                  <CloseIcon style={{ color: "#1D1D1D" }} />
                </IconButton>
              </Box>
            </DialogTitle>
            <Paymentadmin2 navigation={this.props.navigation} id="payment" changeOpenAddPaymentPopup={this.changeOpenAddPaymentPopup} token={this.token} getCardList={this.getCardList} />
          </DialogPopupWeb>

          <DialogPopupWeb open={this.state.applyCouponCode} onClose={this.changeOpenCoupoonPopup} closeIcon={false}>
            <DialogTitle style={{ padding: "0px" }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px solid #D4D4D4">
                <div style={{ fontFamily: "Barmeno", fontSize: "24px", fontWeight: 700 }}>Coupon Codes</div>
                <IconButton onClick={this.changeOpenCoupoonPopup} >
                  <CloseIcon style={{ color: "#1D1D1D" }} />
                </IconButton>
              </Box>
            </DialogTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              {this.state.promocodeData.length > 0 ?
                this.state.promocodeData.map((value: PromoCodeData, index: number) =>
                  <div key={index} className="couponCodeOption" style={{ border: this.props.orderDetails?.attributes.promo_code_id === Number(value.id) ? "2px solid #409046" : "0px", borderRadius: "8px" }}>
                    <div className="couponCodeOptionDetails">
                      <div className="couponCodeOptionHeading">{value.attributes.name}<div style={{ color: "#2C6F37", fontSize: "14px" }}>{value.attributes.save_value}</div> </div>
                      <div style={{ color: "#747474" }}>{value.attributes.description}</div>
                    </div>
                    {this.props.orderDetails?.attributes.promo_code_id === Number(value.id) ?
                      <div onClick={() => { this.removeCouponCode(value.attributes.name) }} style={{ color: "#E11B22", fontSize: "16px", cursor: "pointer" }}> Remove</div> :
                      <div onClick={() => this.applyCouponCode(value.attributes.name)} style={{ color: "#E11B22", fontSize: "16px", cursor: "pointer" }}>{this.props.orderDetails?.attributes.coupon_code_id === Number(value.id) ? "Remove" : "Apply"}</div>}
                  </div>
                ) :
                <div className="couponCodeOption" style={{ border: "0px", borderRadius: "8px" }}>
                  <div className="couponCodeOptionDetails">
                    <div className="couponCodeOptionHeading">No Coupon Code Found </div>
                    <div style={{ color: "#747474" }}>Promo code Not available</div>
                  </div>
                </div>}
            </div>

          </DialogPopupWeb>
        </div>
        <DialogPopupWeb open={this.state.orderConfirmPopup} onClose={this.navigateToMenuPopupClose} closeIcon={true}>
          <OrderConfirmedPopup close={this.onCloseConfirmOrderPopup} />
        </DialogPopupWeb>
        <DialogPopupWeb closeIcon={false} className="restaurantChangePopup" open={this.state.openRestaurantChange} onClose={this.closeRestaurantPopup}>
          <RestaurantChangePopup unAvilableItems={this.state.unAvailableCartItems} restaurantData={this.state.restaurantData} closeRestaurantPopup={this.closeRestaurantPopup} setRestaurantUsingNavPayoad={this.setRestaurantDataToCatalogue} />
        </DialogPopupWeb>
        <DialogPopupWeb open={this.state.removeCouponOpen} onClose={this.closeRemoveCoupon}><RemoveCodeAlert removePoints={this.applyRewardPoints} removeGiftCard={this.applyGiftCard} removeCouponCode={this.removeCouponCode} onClose={this.closeRemoveCoupon} heading_remove={this.state.heading_remove} content_remove={this.state.content_remove} value1={this.state.value1} value2={this.state.value2} /></DialogPopupWeb>
        <PaymentPopup attempts={this.state.attempts} open={this.state.paymentPopup} onClose={this.onClosePaymentPopup} img={this.state.img} heading={this.state.heading} content={this.state.content} button={this.state.button} tryAgain={this.closeFailedOrderPopup} />
        <PaymentPopup attempts={this.state.attempts} open={this.state.couponCodeAppliedPopup} onClose={this.onCloseCouponAppliedPopup} img={'success'} heading={'YAY! Coupon Code applied'} content={this.state.content} button={'Close'} tryAgain={this.closeFailedOrderPopup} />
        <PaymentPopup attempts={this.state.attempts} open={this.state.couponCodeAppliedPopup} onClose={this.onCloseCouponAppliedPopup} img={'failure'} heading={this.state.GiftCardMessage} content={this.state.content} button={'Close'} tryAgain={this.closeFailedOrderPopup} />
        <DialogPopupWeb closeIcon={false} open={this.state.noRestaurantFound} onClose={() => { this.setState({ noRestaurantFound: false }) }}>
          <div className="no_result_restaurant" style={{ flexDirection: "column", height: "fit-content", justifyContent: "space-between", margin: "auto" }}>
            <img src={noHistory} alt="no_result_found" />
            <div className="display_noresult_store_items">
              <div className="main_content_no_store">
                <div style={{ color: "#1D1D1D" }}>No restaurant found!</div>
                <div style={{ color: "#747474", textAlign: "center" }}>Sorry, we couldn't find any restaurants for the entered postcode. Please try entering another postcode.</div>
              </div>
            </div>
          </div>
        </DialogPopupWeb>

        <Dialog open={this.state.paymentProcessing}>
          <Box textAlign="center" style={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: "20px", padding: "20px" }}>
            <img src={customLoaderGif} alt="loading..." style={{ width: "250px", height: "250px" }} />
            <Typography style={{ fontFamily: 'Barmeno', fontSize: '24px', fontWeight: 700, textAlign: "center" }}>
              Payment Processing
            </Typography>
          </Box>
        </Dialog>
      </>
    )
  }
}

export default ConfirmOrder;
