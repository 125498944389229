import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { createRef } from "react"

// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config")

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  googlePayButtonRef:any
  token:string|null
  order_id:string|undefined
  openPaymentPopup:(img:string,button:string,heading:string,content:string,attempts?:number)=>void
  isPaymentPopupOpen?:boolean;
  amount:string;
  cardPayButtonRef:any
  payment_method_id?:string;
  cvv?:string;
  cashPayButtonRef:any;
  orderConfirmPopup:()=>void;
  setPaymentStatus: (status: boolean) => void;
  openPaymentWaiting:(value:boolean)=>void;
  subscribeToStatusUpdates:(token:string,paymentItent:string)=>void;
  open3DsecurePopup:(value:string, open:boolean)=>void;
  walletType: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  payment_id: string
  openStripe: boolean
  location: string
  loading: boolean
  stripe: any,
  clientSecret: any,
  customer_id: string,
  paymentRequest1: any;
  canMakePayment1: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GooglePayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  gPayPaymentCallId: string = ""
  doPaymentViaCardApiCallId:string=""
  doPaymentViaCashApiCallId:string=""
  googlePayButtonRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      payment_id: "",
      openStripe: false,
      location: "",
      loading: false,
      stripe: null,
      clientSecret: "",
      customer_id:"",
      paymentRequest1: null,
      canMakePayment1: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.googlePayButtonRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);   
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId===this.gPayPaymentCallId){
        this.setState({loading:false})
        if (responseJson && responseJson.message) {
          if (responseJson.message === "Transaction successful") {
            this.props.openPaymentPopup("success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!")
            this.props.setPaymentStatus(true)
          }
        } else if (responseJson && responseJson.error) {
          this.props.openPaymentPopup("failure", "TRY AGAIN", 'Payment Failed!', "Unfortunately, your payment has failed. Please try again.", 0)
        }
    }
    if(apiRequestCallId === this.doPaymentViaCardApiCallId){
      this.setCardPaymentResponse(responseJson)
      }
    if(this.doPaymentViaCashApiCallId === apiRequestCallId){
      this.setCashPaymentResponse(responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setPaymentId=(tokenString:string)=>{
    const tokenObject = JSON.parse(tokenString)
    const id:string = tokenObject.id
    this.setState({payment_id:id})
  }
  setCardPaymentResponse = (responseJson: { meta?: { payment: {id:string, status: string, next_action?:{redirect_to_url:{return_url:string, url:string}} } }, errors?: { stripe: string }[], error?: string, payment_attempts?: number  }) => {
    this.setState({loading:false})
    if (!responseJson) return;

    if (responseJson.meta) {
      this.handlePaymentStatus(responseJson.meta.payment.status, responseJson?.payment_attempts, responseJson.meta.payment.next_action?.redirect_to_url.url, responseJson.meta.payment.id);
    } else if (responseJson.errors) {
      this.handlePaymentError(responseJson.errors[0].stripe, responseJson?.payment_attempts);
    } else if (responseJson.error) {
      if(responseJson.error === "The coupon code is expired, please remove it to proceed a payment"){
        this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"The coupon code is expired, please remove it to proceed a payment", 0)
      }
        else{
          this.handlePaymentFailure(responseJson?.payment_attempts)
        }
    }
  }

  handlePaymentStatus = (status: string, attempts?: number, url?:string, payment_intent_id?:string) => {
   if (status === "requires_action") {
      this.props.openPaymentWaiting(true)
      this.props.subscribeToStatusUpdates(`${this.props.token}`,payment_intent_id||"")
    this.props.open3DsecurePopup(`${url}`,true)
    }
    else if(status === "succeeded") {
      this.props.setPaymentStatus(true);
      this.props.openPaymentPopup(
        "success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!", attempts);
        this.props.open3DsecurePopup('',false)
    } 
  }

  handlePaymentError = (error: string, attempts?: number) => {
    this.props.setPaymentStatus(false);
    this.props.openPaymentPopup(
      "failure", "TRY AGAIN", 'Payment Failed!', error, attempts);
      this.props.open3DsecurePopup('',false)
  }

  handlePaymentFailure = (attempts?: number) => {
    this.props.setPaymentStatus(false);
    this.props.openPaymentPopup(
      "failure", "TRY AGAIN", 'Payment Failed!', "Payment unsuccessful. Your order has been canceled.", attempts);
      this.props.open3DsecurePopup('',false)
  }

  setCashPaymentResponse=(responseJson:{message?:string,error?:string})=>{
    this.setState({loading:false})
      if(responseJson.message ){
          this.props.setPaymentStatus(true)
          this.props.orderConfirmPopup()
      }else if(responseJson.error){
        if(responseJson.error === "The coupon code is expired, please remove it to proceed a payment"){
          this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"The coupon code is expired, please remove it to proceed a payment", 0)
        }
          else{
            this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"Unfortunately, your payment has failed. Please try again.", 0)
          }
        
      }
  }

  doGpayPayment=()=>{
    this.setState({loading:true})
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token:this.props.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.gPayPaymentCallId = requestMessage.messageId
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.gpayEndPoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const httpBody = {
          order_id: this.props.order_id,
          payment: {
              id: this.state.payment_id,
              currency: configJSON.currencyCode,
              status: configJSON.paymentStatus,
              payment_method_type: configJSON.paymentMethodType
          }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doPaymentViaCard=()=>{
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    
    const httpBody = {
      payment: {
      order_id:Number(this.props.order_id) ,
      payment_method_id: this.props.payment_method_id,
      cvv: Number(this.props.cvv)
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doPaymentViaCardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.doPaymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doPaymentViaCash=()=>{
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doPaymentViaCashApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCODApiEndPoint}${this.props.order_id}/pay_on_cash`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
