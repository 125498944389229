import React from "react";

// Customizable Area Start
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Header from "../../../components/src/Dashboard/Header/Header";
import Sidebar from "../../../components/src/Dashboard/Sidebar/Sidebar";
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import SearchIcon from '@material-ui/icons/Search';
import { anchor, MapsMap, origin, scaledSizeRestaurant,scaledDriverLocation,scaledOrderLocation, originDriver, anchorDriver } from "../../../components/src/MapsDistanceRouting";
import "./GeofenceStyles.css"
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CloseIcon from "@mui/icons-material/Close"
// Customizable Area End

import GeofenceController, {
  Props,
  configJSON,
} from "./GeofenceController.web";
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";
import { MapLoader } from "../../../components/src/MapLoader";
import { restaurantLocation } from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";

export default class Geofence extends GeofenceController {
  // Customizable Area Start
  // Sample data for riders and paths

  orderDropDown=()=>{
    return(
        <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
          <div>Track Driver</div>
        </div>
    )
  }
  searchStaff = () => {
    return (
      <AdvancedSearch navigation={this.props.navigation} id={this.props.id} searchQuery={this.state.searchQuery} changeSearch={this.changeSearch}/>
    );
  };

  renderPaths = () => {
  return this.state.directionsResponses.map((directions, index) => {
    const driver = this.returnAllTheData(this.state.location).flat().find((d, i) => i === index);
    const strokeColor = this.pathColors[driver ? driver.type : 'delivering'] || '#CD191F';
    return(
    <DirectionsRenderer
      key={`direction-${index}`}
      directions={directions}
      options={{
        suppressMarkers: true,
        polylineOptions: {
          strokeColor,
          strokeWeight: 4,
          strokeOpacity: 1.0,
          geodesic: true,
        },
      }}
    />
  )}) || null;
  };

  renderMarkers = () => {
    return this.returnAllTheData(this.state.location).flatMap((driver) => [
      <Marker
        key={`driver-${driver.id}`}
        position={{
          lat:Number(driver.location?.latitude), lng:Number(driver.location?.longitude)
        }}
        label={{
          text:`${driver.id}`,
          color: "#1D1D1D",
          fontSize: "16px",
          fontWeight: "500",
        }}
        icon={this.customDriverIcon(driver.type)}
        onMouseOver={() => this.handleMouseOver(`order-${driver.id}`)}  // Show tooltip
      >
        {this.state.activeMarker === `order-${driver.id}` && driver.type !=="returning" && (
          <InfoWindow >
            <div style={{ width: "300px", padding: "5px" }}>
              {/* Header with Title and Close Button */}
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ccc", marginBottom: "5px" }}>
                <h4 style={{ margin: 0, fontSize: "14px", color: "#E11B22", fontWeight:500}}>{this.returnDeliveryStatusHeading(driver.type)}</h4>
                <IconButton
                  onClick={this.handleMouseOut}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    color: "#555",
                  }}
                  title="Close"
                >
                  ✖
                </IconButton>
              </div>

              {/* Rider Details */}
              <div style={{display:"flex",flexDirection:"column",gap:"8px" }}>
                {driver.type === "untraceable"?<div style={{color:"#1D1D1D",fontSize:"16px"}}>Unable to fetch the rider’s location</div>:<></>}
                <div style={{ color: "#747474",fontSize:"14px", fontWeight:500}}>{driver.full_name}</div>
                {driver.orders.map((order, index) => (
                  <div key={index} style={{display:"flex",flexDirection:"row",gap:"4px",justifyContent:"space-between",fontSize:"14px", fontWeight:500}}>
                  <div style={{display:"flex",flexDirection:"row",gap:"4px"}}>
                    <div style={{color: "#747474" }}>Order Number:</div>
                    <div style={{ color: "#1D1D1D" }}>#{order.order_id}</div>
                  </div>
                  <div  style={{display:"flex",flexDirection:"row",gap:"4px"}}>
                    <div style={{color: "#747474" }}>ET:</div>
                    <div style={{color: "#1D1D1D" }}>{order.estimated_delivery_time}</div>
                  </div>
                  </div>
                ))}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>,
      ...driver.orders.map((order, index) => (
        <Marker
          key={`order-${driver.id}-${index}`}
          position={{
            lat: order.delivery_address[0].latitude, lng: order.delivery_address[0].longitude
          }}
          icon={this.customOrderLocation(driver.type)}
          onMouseOver={() => this.handleMouseOver(`order-${order.order_id}-${index}`)}  // Show tooltip
      >
        {this.state.activeMarker === `order-${order.order_id}-${index}` && (
          <InfoWindow >
            <div style={{ width: "300px", padding: "5px" }}>
              {/* Header with Title and Close Button */}
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ccc", marginBottom: "5px" }}>
                <h4 style={{ margin: 0, fontSize: "14px", color: "#E11B22", fontWeight:500 }}>Customer Information</h4>
                <IconButton
                  onClick={this.handleMouseOut}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    color: "#555",
                  }}
                  title="Close"
                >
                  ✖
                </IconButton>
              </div>

              {/* customer Details */}
              <div style={{display:"flex",flexDirection:"column",gap:"8px" }}>
                <div style={{ color: "#747474",fontSize:"14px", fontWeight:500}}>{`${order.personal_details.first_name} ${order.personal_details.last_name}`}</div>
                  <div style={{display:"flex",flexDirection:"row",gap:"4px",fontSize:"14px", fontWeight:500}}>
                    <div style={{color: "#747474" }}>Order Number:</div>
                    <div style={{ color: "#1D1D1D" }}>#{order.order_id}</div>
                  </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
      )),
    ]);
  };

  customEndIcon = {
    url: restaurantLocation, scaledSizeRestaurant,
    origin,
    anchor
  };

  customDriverIcon = (type:string | number | symbol)=>{
    return {
      url: this.returnLocationIconDriver(type),
      scaledDriverLocation,
      originDriver,
      anchorDriver
    }
  }

  customOrderLocation = (type:string | number | symbol)=>{
    return{
      url: this.returnLocationIconOrder(type),
      scaledOrderLocation,
      originDriver,
      anchorDriver
    }
  }

  renderFilterContainer=()=>{
    return(
      <div className="filterContainerMap">
        <div style={{color:"#747474",justifyContent:"space-between",display:"flex",flexDirection:"row",gap:"24px",alignItems:"center"}}>Route Indicators <IconButton style={{backgroundColor:"#FAF4F4",borderRadius:"4px"}} onClick={this.openFilterPopup}> <FilterListRoundedIcon style={{color:"#E11B22"}}/></IconButton></div>
        
        {this.state.deliverySTatusApplied.length>0?<div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
        {this.state.deliverySTatusApplied.map((value,index)=>
                this.returnAppliedFilterData(value)
        )}
        </div>:this.returnAppliedFilterData("All")}
      </div>
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main" style={{padding:"12px 24px"}}>
          <Header navigation={this.props.navigation} headerTitle={this.orderDropDown()}
            search={this.searchStaff()} />
        <div style={{position:"relative", width:"100%",height:"100vh"}}>
        <MapLoader>
          {this.renderFilterContainer()}
        <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }} 
          center={{ lat: Number(this.state.restaurant_location.latitude), lng: Number(this.state.restaurant_location.longitude)}}
          zoom={12}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true, 
            scaleControl: false, 
             mapTypeId:"hybrid"
          }}
        >
            <Marker
          position={{ lat: Number(this.state.restaurant_location.latitude), lng: Number(this.state.restaurant_location.longitude)}}
          icon={this.customEndIcon}
        />
            {this.renderMarkers()}
            {this.renderPaths()}
        </GoogleMap>
      </MapLoader>
      </div>
      <Dialog open={this.state.filterPopup} onClose={this.closeFilterPopup}
        PaperProps={{ 
          style: { 
            borderRadius: '16px',
          } 
        }} >
          <DialogTitle style={{borderBottom:"1px solid #D4D4D4"}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding="0px 16px 0px 16px">
                <div style={{fontFamily:"Barmeno", fontSize:"24px", fontWeight:"bolder"}}>Filters</div>
              <IconButton onClick={this.closeFilterPopup}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{padding: "0px"}}>
            <Box display="flex" flexDirection="column" padding="24px 40px" style={{gap:"24px", fontFamily:"Barmeno",fontWeight:"bolder"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
                Route Indicators
                  <div className='filter' style={{flexDirection:"column"}}>
                      {this.state.deliveryStatus.map((item,key)=>(
                          <label key={key} style={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", color:'#747474'}}>
                              <input onChange={(event)=>this.changeDeliveryStatus(item.value)} className='allergenCheckbox' type="checkbox" name="allergen" checked={this.state.deliveryStatusSelected.includes(item.value)} value={item.value}/>
                              {item.name}
                          </label>
                      ))}
                  </div>
                </div>

            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent:"space-between", padding:'16px 40px', gap:"40px"}}>
          <Button id='clear' onClick={this.clearFilters}  style={{width: '200px',height: '56px',border: '1px solid #E11B22',color: '#E11B22'}} >
          CLEAR FILTERS
          </Button>
          <Button id='apply' onClick={this.applyFilter}  style={{width: '200px',height: '56px',backgroundColor:'#E11B22',color: '#fff'}}>APPLY FILTERS</Button>
        </DialogActions>
        </Dialog>

        <Dialog open={this.state.openNoResult} onClose={this.closeNoResultChange}
        PaperProps={{ 
          style: { 
            borderRadius: '16px',
          } 
        }} >
            <Box display="flex" alignItems="center" justifyContent="end" padding="16px 24px 0px 24px">
              <IconButton onClick={this.closeNoResultChange}>
                <CloseIcon />
              </IconButton>
            </Box>
                <div style={{padding:"0px 24px 40px 24px", display:"flex", flexDirection:"column", gap:"12px", alignItems:"center"}}>
                  <div style={{fontWeight:700, fontSize:"24px"}}>No Results found</div>
                  <div style={{fontWeight:500, fontSize:"18px", color:"#747474"}}>Try searching for other riders or check your input.</div>
                </div>
          </Dialog>
        </main>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  pStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
// Customizable Area End
