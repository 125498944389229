
import React from "react";
import { IconButton } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { TrashIcon } from "../../Icons";
import { Holiday } from "./types";

const ActionCell = (
  info: CellContext<Holiday, any>,
  deleteHolidayPopup: (data: { id: number; date: string; reason: string; }) => void,
  readOnly: boolean
) => {
  const { row } = info;
  const { original } = row;
  const { id, date, reason } = original;

  return (
    <div className="action_cell__wrapper">
        <IconButton disabled={readOnly} onClick={() => deleteHolidayPopup({ id, date, reason })}>
          <TrashIcon />
        </IconButton>
    </div>
  );
};

export default ActionCell;
