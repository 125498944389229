import { ImageArray } from '../../blocks/ordermanagement/src/OrderHistoryController';
import React, { Component } from 'react'

interface Props {
    order_type:string;
    order_status:string;
    imageGroupDelivery:ImageArray[];
    imageGroupCollection:ImageArray[];
    deliveryStatuses: string[];
    collectionStatuses: string[]
  }

export default class ImageSeriesStatusOrderHistory extends Component<Props> {
    render() {
      const imageGroup: ImageArray[] = this.props.order_type === "delivery" ? this.props.imageGroupDelivery : this.props.imageGroupCollection
      const statuses: string[] = this.props.order_type === "delivery" ? this.props.deliveryStatuses : this.props.collectionStatuses
      const isStatusActive = (statusKey: string, statuses: string[]) => {
        const currentIndex = statuses.indexOf(this.props.order_status);
        const statusIndex = statuses.indexOf(statusKey);
        return statusIndex <= currentIndex;
      };

    return(
        <>
        {imageGroup.map((value,index)=>
        <>
        {index===0?"":<div style={{height:"4px",backgroundColor:isStatusActive(value.status, statuses)?"#2C6F37":"#D4D4D4",width:"40px",alignSelf:"flex-start",transform: "rotate(90deg)",marginLeft:"-4px"}}></div>}
        <div key={index} style={{padding:"4px",display:"flex",flexDirection:"row",gap:"16px",fontWeight:700}}><img src={isStatusActive(value.status,statuses)?value.active:value.inactive} alt='order_placed' /> {value.name}</div>
        </>     
        )}
        </>
    )
  }
}
