// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.contentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ReceiptCustomisation";
exports.labelBodyText = "ReceiptCustomisation Body";

exports.receiptAPiMethod = "POST";
exports.receiptAPiEndPoint = "bx_block_receiptcustomisation/receipts";

exports.btnExampleTitle = "CLICK ME";

exports.getQzTrayKeysApi = "bx_block_receiptcustomisation/qz_tray_keys";
exports.getOrderDetailsApi = "bx_block_receiptcustomisation/order_print_receipt?id=";
exports.getPrintSettingsApi = "bx_block_print2/print_settings/get_print_setting";
// Customizable Area End
