import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CartItem } from "../../../../../blocks/cfposordermanagementfe/src/CfposPrductDescriptionController";

export const CheckoutItemColumns = () => {
  const columns: ColumnDef<CartItem, any>[] = [
    {
      header: "Quantity",
      accessorKey: "quantity",
      cell: (info) => {
        const { quantity } = info.row.original.attributes;
        return quantity;
      },
    },
    {
      header: "Items Name",
      accessorKey: "product_name",
      cell: (info) => {
        const { product_name } = info.row.original.attributes;
        return product_name;
      }
    },
    {
      header: "Amount",
      accessorKey: "amount",
      cell: (info) => {
        const { total_item_price, is_discounted, total_item_price_after_discount } = info.row.original.attributes;
        return (
          <div className="checkout_columns__amount__wrapper">
            {is_discounted ? (
              <>
                <span className="checkout_columns__amount__price discounted">
                  {total_item_price_after_discount}
                </span>
                <span className="checkout_columns__amount__price slashed">
                  {total_item_price}
                </span>
              </>
            ) : (
              <span className="checkout_columns__amount__price">
                {total_item_price}
              </span>
            )}
          </div>
        );
      }
    }
  ];

  return columns;
};
