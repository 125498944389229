Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.mockPlacedOrder = {
  "id": 476,
  "order_number": "0012-0202250304-0476",
  "amount": "nil",
  "created_at": "2025-03-04 13:56:39.800025000 +0000",
  "updated_at": "2025-03-04 13:56:42.316592000 +0000",
  "account_id": 268,
  "coupon_code_id": "nil",
  "delivery_address_id": "nil",
  "sub_total": 300,
  "total": 100,
  "status": "placed",
  "applied_discount": 0,
  "cancellation_reason": "nil",
  "order_date": "nil",
  "is_gift": false,
  "placed_at": "2025-03-04 13:52:08.988143000 +0000",
  "confirmed_at": "nil",
  "in_transit_at": "nil",
  "delivered_at": "nil",
  "cancelled_at": "2025-03-04 13:56:39.812971000 +0000",
  "refunded_at": "2025-03-04 13:56:42.316442000 +0000",
  "source": "cash",
  "shipment_id": "nil",
  "delivery_charges": "nil",
  "tracking_url": "nil",
  "schedule_time": "2025-03-04 13:56:39.812971000 +0000",
  "customer_name": "nil",
  "payment_failed_at": "nil",
  "returned_at": "nil",
  "tax_charges": 0,
  "deliver_by": "nil",
  "tracking_number": "nil",
  "is_error": false,
  "delivery_error_message": "nil",
  "payment_pending_at": "nil",
  "order_status_id": 6,
  "is_group": true,
  "is_availability_checked": false,
  "shipping_charge": "nil",
  "shipping_discount": "nil",
  "shipping_net_amt": "nil",
  "shipping_total": "nil",
  "total_tax": "nil",
  "razorpay_order_id": "nil",
  "charged": "nil",
  "invoiced": true,
  "invoice_id": "INV-0012-0200250304-0475",
  "custom_label": "nil",
  "promo_code_id": "nil",
  "order_type": "collection",
  "prepare_immediately": true,
  "redeem_point": "nil",
  "points_worth": 0,
  "notes_to_chef": "price desscriptionn sdfdsfds",
  "gift_card_amount": "nil",
  "gift_card_id": "nil",
  "collected_at": "2025-03-04 13:56:38.986859000 +0000",
  "order_medium": "online",
  "payment_attempts": 0,
  "ready_at": "nil",
  "order_completed_at": "2025-03-04 13:56:42.293389000 +0000",
  "restaurant_id": 12,
  "receipt_printed": false,
  "receipt_count": 0,
  "cooking_at": "nil",
  "is_allocated_to_driver": false,
  "pos_user_id": "nil",
  "is_priority": false,
  "cancel_reason_id": 1,
  "partial_cancel": true,
  "cancel_initiated_at": "nil",
  "refund_initiated_at": "2025-03-04 13:56:39.794521000 +0000",
  "refund_amount": 100,
  "original_total": 300,
  "refund_payment_type": "nil",
  "request_sent_to_manager": "nil",
  "cashed_off": false,
  "parent_id": 475,
  "payment_method_used_amount": 0,
  "refund_reward_point_amount": 0,
  "refund_e_gift_card_amount": 100
}

exports.mockCancellationOrder = {
  "id": 476,
  "order_number": "0012-0202250304-0476",
  "amount": "nil",
  "created_at": "2025-03-04 13:56:39.800025000 +0000",
  "updated_at": "2025-03-04 13:56:42.316592000 +0000",
  "account_id": 268,
  "coupon_code_id": "nil",
  "delivery_address_id": "nil",
  "sub_total": 300,
  "total": 100,
  "status": "cancel_initiated",
  "applied_discount": 0,
  "cancellation_reason": "Order took too long",
  "order_date": "nil",
  "is_gift": false,
  "placed_at": "2025-03-04 13:52:08.988143000 +0000",
  "confirmed_at": "nil",
  "in_transit_at": "nil",
  "delivered_at": "nil",
  "cancelled_at": null,
  "refunded_at": null,
  "source": "cash",
  "shipment_id": "nil",
  "delivery_charges": "nil",
  "tracking_url": "nil",
  "schedule_time": "2025-03-04 13:56:39.812971000 +0000",
  "customer_name": "nil",
  "payment_failed_at": "nil",
  "returned_at": "nil",
  "tax_charges": 0,
  "deliver_by": "nil",
  "tracking_number": "nil",
  "is_error": false,
  "delivery_error_message": "nil",
  "payment_pending_at": "nil",
  "order_status_id": 6,
  "is_group": true,
  "is_availability_checked": false,
  "shipping_charge": "nil",
  "shipping_discount": "nil",
  "shipping_net_amt": "nil",
  "shipping_total": "nil",
  "total_tax": "nil",
  "razorpay_order_id": "nil",
  "charged": "nil",
  "invoiced": true,
  "invoice_id": "INV-0012-0200250304-0475",
  "custom_label": "nil",
  "promo_code_id": "nil",
  "order_type": "collection",
  "prepare_immediately": true,
  "redeem_point": "nil",
  "points_worth": 0,
  "notes_to_chef": "price desscriptionn sdfdsfds",
  "gift_card_amount": "nil",
  "gift_card_id": "nil",
  "collected_at": "2025-03-04 13:56:38.986859000 +0000",
  "order_medium": "online",
  "payment_attempts": 0,
  "ready_at": "nil",
  "order_completed_at": "2025-03-04 13:56:42.293389000 +0000",
  "restaurant_id": 12,
  "receipt_printed": false,
  "receipt_count": 0,
  "cooking_at": "nil",
  "is_allocated_to_driver": false,
  "pos_user_id": "nil",
  "is_priority": false,
  "cancel_reason_id": 1,
  "partial_cancel": true,
  "cancel_initiated_at": "2025-03-04 13:56:39.794521000 +0000",
  "refund_initiated_at": "2025-03-04 13:56:39.794521000 +0000",
  "refund_amount": 100,
  "original_total": 300,
  "refund_payment_type": "nil",
  "request_sent_to_manager": "nil",
  "cashed_off": false,
  "parent_id": 475,
  "payment_method_used_amount": 0,
  "refund_reward_point_amount": 0,
  "refund_e_gift_card_amount": 100
}
