import React from "react";
import Modal from "../../../Modal";
import { ContainedButton } from "../../../Button";
import { success } from "../../../SocialLogin/assets";
import "./SuccessPopup.css";

interface Props {
  open: boolean;
  onClose: () => void;
  message: string;
  subMessage?: string;
};

const SuccessPopup = ({ open, onClose, message, subMessage }: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="flag_area_success__wrapper">
        <img src={success} alt="success" />
        <p>{message}</p>
       { subMessage ? <div style={{fontSize:"18px",fontWeight:500,textAlign:"center",color:"#404040"}} >{subMessage}</div>:null}
        <ContainedButton onClick={onClose}>
          CLOSE
        </ContainedButton>
      </div>
    </Modal>
  );
};

export default SuccessPopup;
