import { useJsApiLoader } from "@react-google-maps/api";
import React, { ReactNode } from "react";

interface MapLoaderProps {
    children: ReactNode;
  }

export const MapLoader: React.FC<MapLoaderProps>=({children}) => {
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyBsGUpsaF5LIgUdM-sCGNGmvIkEy83oD7E",
    });
  
    if (loadError) {
      return <div>Error loading maps</div>;
    }
    if (!isLoaded) {
      return <div>Loading Maps...</div>;
    }
    return <>{children}</>;
  };