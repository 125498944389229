import React from "react";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { EarningReport, StatisticsGraphData } from "../../../../blocks/visualanalytics/src/VisualAnalyticsController";

interface Props {
  orderStatistics: StatisticsGraphData;
  activeTab : number
}

const prepareEarningData = (earning_report:EarningReport,activeTab: number) => {

    switch (activeTab) {
      case 3:
        return Object.entries(earning_report.today_report).map(([time, value]) => ({
          time,
          Earnings: Number(value),
        }));
      case 2:
        return Object.entries(earning_report.week_array).map(([day, value]) => ({
          time: day,
          Earnings: Number(value),
        }));
      case 1:
        return Object.entries(earning_report.month_array).map(([date, value]) => ({
          time: date,
          Earnings: Number(value),
        }));
      case 0:
        return Object.entries(earning_report.year_array).map(([month, value]) => ({
          time: month,
          Earnings: Number(value),
        }));
      default:
        return [];
    }
  };

  export const getXAxisInterval = (dataLength: number) => {
    if (dataLength > 12) {
      return Math.ceil(dataLength / 12);
    }
    return 0;
  };

  const formatDateLabel = (label: string) => {
    const parts = label.split("-"); // Splitting by '-'
    if (parts.length === 3) {
      const [day, month, weekday] = parts;
      return `${weekday.substring(0, 3)}`; // Convert to "10 Mar, Mon"
    }
    return label; // Fallback in case of unexpected format
  };

  export const renderCustomAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text 
          x={0} 
          y={0} 
          dy={10} 
          textAnchor="end" 
          transform="rotate(-15)"
          fontSize={12} 
          fill="#666"
        >
          {formatDateLabel(payload.value)}
        </text>
      </g>
    );
  };


const EarningStatistics = ({ orderStatistics,activeTab }: Props) => {
    const orders = prepareEarningData(orderStatistics.earning_report,activeTab)
    const isDecreasingTrend = orders.length >= 2 && orders[orders.length - 1].Earnings < orders[orders.length - 2].Earnings;
    const gradientId = isDecreasingTrend ? 'colorOrdersRed' : 'colorOrdersGreen';
    const strokeColor = isDecreasingTrend ? '#E11B22' : '#2C6F37';
  return (
    <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={orders}>
              <defs>
                <linearGradient id="colorOrdersGreen" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#2C6F37" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#2C6F37" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorOrdersRed" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E11B22" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#E11B22" stopOpacity={0} />
          </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis minTickGap={5}  dataKey="time" interval={getXAxisInterval(orders.length)}
              tick={renderCustomAxisTick}/>
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="Earnings" stroke={strokeColor} fill={`url(#${gradientId})`} />
            </AreaChart>
          </ResponsiveContainer>
  );
};

export default EarningStatistics;
