export const headerImage = require("../assets/franchise_header.png");
export const backgroudImage = require("../assets/wingo_bg_comp.webp");
export const progressImage1 = require("../assets/progress1.png");
export const progressImage2 = require("../assets/progress2.png");
export const franchisePopup = require("../assets/francise_popup.png");
export const bad = require("../assets/bad-inactive.png");
export const good =  require("../assets/good-inactive.png");
export const moderate = require("../assets/moderate-inactive.png");
export const happy = require("../assets/happy-inactive.png");
export const happyActive = require ("../assets/happy.png");
export const badActive = require ("../assets/bad-active.png");
export const moderateActive = require ("../assets/moderate-active.png");
export const goodActive = require ("../assets/good-active.png");
export const thankyou = require("../assets/thankyou.png")

export const unsubscribe = require("../assets/unsubscribe.svg")
export const unsubscribeNewsLetterImage = require("../assets/unsubscribeNewsLetter.png")

export const completedForm = require("../assets/completedForm.svg")
export const unOpenedForm = require("../assets/unOpenedForm.svg")
export const openedForm = require("../assets/openedForm.svg")
export const completedProgressLine = require("../assets/completedProgress.svg")
export const unCompletedProgressLine = require("../assets/unCompletedProgress.svg")