import React from "react";
import { IconButton } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { DeliveryArea } from "../../../../blocks/cfdeliveryareamanagement1/src/types";
import { FlaggedIcon, FlagIcon, PencilIcon, TrashIcon } from "../../Icons";
import { hasPermission } from "../../utils";
import "./ActionCell.css";

const ActionCell = (
  info: CellContext<DeliveryArea, any>,
  handleFlagAreaPopupOpen: (address: DeliveryArea) => void,
  handleUpdateAddressPopup: (data: Partial<DeliveryArea>) => void,
  handleDeleteAreaPopupOpen: (id: number) => void,
  role: string
) => {
  const { row } = info;
  const { original } = row;
  const { id, flagged } = original;
  const enableUpdate: boolean = hasPermission("DeliveryArea", "update");
  const enableDelete: boolean = hasPermission("DeliveryArea", "destroy");
  const enableFlag: boolean = hasPermission("DeliveryArea", "flag");

  return (
    <div className="action_cell__wrapper">
      <IconButton
        disabled={!enableUpdate}
        onClick={() => handleUpdateAddressPopup(original)}
      >
        <PencilIcon />
      </IconButton>

      <IconButton disabled={!enableDelete} onClick={() => handleDeleteAreaPopupOpen(id)}>
        <TrashIcon />
      </IconButton>

      <IconButton
        disabled={!enableFlag}
        className="delivery_areas__flagged_icon"
        data-flagged={flagged}
        onClick={() => handleFlagAreaPopupOpen(original)}
      >
        {flagged ? <FlaggedIcon width={16} height={16} /> : <FlagIcon width={16} height={16} />}
      </IconButton>
    </div>
  );
};

export default ActionCell;
