import React from "react";
import { FormControl, FormHelperText, IconButton, MenuItem, MenuList, Tab, Tabs } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import { GoogleMap, Marker } from '@react-google-maps/api';
import { DeliveryArea } from "../../../../../blocks/cfdeliveryareamanagement1/src/types";
import { DeliveryAreaSchema } from "../../../Schemas/DeliveryAreaSchema";
import { WingoInput } from "../../../Inputs/CustomInputs";
import { ContainedButton, OutlinedButton } from "../../../Button";
import { MapLoader } from "../../../MapLoader";
import useDidUpdate from "../../../Hooks/useDidUpdate";
import Modal from "../../../Modal";
import AddAddressForm from "../Form";
import "./AddAddressPopup.css";

interface Props {
  open: boolean;
  onClose: () => void;
  submit: (data: Partial<DeliveryArea>) => void;
  getPostCodes: (postcode: string) => void;
  postCodeOptions: { id: number; main_text: string; secondary_text: string }[];
}

type Location = {
  latitude: number;
  longitude: number;
};

const defaultValues = {
  postcode: "",
  area: "",
  district: "",
  sector: "",
  minimum_order: "",
  delivery_charge: "",
  flagged: false,
  flag_reason: "",
}

const AddAddressPopup = ({ open, onClose, submit, getPostCodes, postCodeOptions }: Props) => {
  const [data, setData] = React.useState<Partial<DeliveryArea>>(defaultValues);
  const [openSuggestions, setOpenSuggestions] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [errors, setErrors] = React.useState<Record<string, any>>({});
  const [location, setLocation] = React.useState<Location | null>(null);

  const closeSuggestions = () => setOpenSuggestions(false);

  const handleReset = () => setData(defaultValues);
  const handleClose = () => {
    handleReset();
    onClose();
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue);

  const handleSubmit = () => {
    const { success, error } = DeliveryAreaSchema.safeParse(data);
    let errorMap: Record<string, any> = {};

    if (error) {
      error.errors.forEach((err: any) => {
        errorMap[err.path[0]] = err.message;
      });

      setErrors(errorMap);
      return;
    }

    if (success) {
      submit(data);
      handleClose();
    }
  };

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }

    () => {
      handleReset();
    };
  }, []);

  React.useEffect(() => console.log("v-9796", location), [location]);

  React.useEffect(() => {
    handleReset();
  }, [onClose]);

  useDidUpdate(() => {
    if (data.postcode) {
      getPostCodes(data.postcode);
    };
  }, [data.postcode]);

  useDidUpdate(() => {
    if (postCodeOptions.length > 1) setOpenSuggestions(true);
  }, [postCodeOptions]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <div className="add_address__wrapper">
        <div className="add_address__header">
          <h2>Add New Delivery Area</h2>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div className="add_address__content">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            classes={{
              root: "add_address__tab__wrapper",
              indicator: "add_address__tab__hidden_indicator",
            }}
          >
            <Tab
              label="Postcode"
              classes={{ root: "add_address__tab", selected: "add_address__tab__selected" }}
            />
            <Tab
              label="Map"
              classes={{ root: "add_address__tab", selected: "add_address__tab__selected" }}
            />
          </Tabs>

          {selectedTab === 0 && (
            <FormControl
              required
              className="add_address__form__input"
            >
              <div data-required={true} className="add_address__form__label">Enter Your Postcode</div>
              <WingoInput
                name="postcode"
                placeholder="Enter your postcode"
                value={data.postcode}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setData({ ...data, postcode: event.target.value })}
              />
              {openSuggestions && (
                    <MenuList dense
                        classes={{
                            root: "add_address__postcode__options_list"
                        }}
                    >
                        {postCodeOptions.map(option => (
                            <MenuItem
                                key={option.id}
                                selected={option.main_text === data.postcode}
                                classes={{
                                    root: "add_address__autocomplete__menu__item",
                                    selected: "add_address__autocomplete__menu__item__selected",
                                }}
                                onClick={() => {
                                    setData({ ...data, postcode: option.main_text });
                                    closeSuggestions();
                                }}
                            >
                                {option.secondary_text}, {option.main_text}
                            </MenuItem>
                        ))}
                    </MenuList>
              )}
              <FormHelperText error>{errors.postcode}</FormHelperText>
            </FormControl>
          )}

          {selectedTab === 1 && (
            <div className="add_address__map">
              <MapLoader>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={{ lat: location?.latitude ?? 0, lng: location?.longitude ?? 0 }}
                  zoom={15}
                  options={{
                    zoomControl: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    scaleControl: false,
                    mapTypeId:"hybrid"
                  }}
                />
              </MapLoader>
            </div>
          )}

          <AddAddressForm
            data={data}
            setData={setData}
            errors={errors}
          />
        </div>

        <div className="add_address__footer">
          <OutlinedButton onClick={handleReset}>RESET</OutlinedButton>

          <ContainedButton onClick={handleSubmit}>ADD DELIVERY AREA</ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddAddressPopup;
