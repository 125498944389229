import React from "react";

// Customizable Area Start
import moment from "moment";
import { Skeleton } from "@mui/material";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import { ReceiptIcon } from "../../../components/src/Icons";
import { WingoButton } from "../../../components/src/Button";
import "./POSNotifications.web.css";
// Customizable Area End


import NotificationsController, {
  Props,
} from "./NotificationsController";

export default class POSNotifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    const { notifications, loadingNotifications } = this.state;

    return (
      <DashboardWrapper
        navigation={navigation}
      >
        <Header
          navigation={navigation}
          headerTitle="Notifications"
        />

        <div className="pos_notifications__wrapper">
          <div className="pos__notifications__header">
            <h2>All Notifications</h2>
            <WingoButton disabled={Object.keys(notifications).length === 0} onClick={this.markAllAsRead}>Mark all as read</WingoButton>
          </div>

          <div className="pos_notifications__body">
            {loadingNotifications && (
              <div data-testid="loading-skeleton" className="pos__notifications__list_wrapper">
                <div className="pos__notifications__timeline">
                  <Skeleton variant="text" width={100} />
                </div>
                <div className="pos__notifications__list">
                  <Skeleton variant="rounded" width="100%" height={100} />
                  <Skeleton variant="rounded" width="100%" height={100} />
                  <Skeleton variant="rounded" width="100%" height={100} />
                </div>
              </div>
            )}
            {(Object.keys(notifications).length === 0 && !loadingNotifications) ? (
              <div data-testid="no-notifications" className="pos__notifications__empty">
                <p>No Notifications Found</p>
              </div>
            ) : Object.keys(notifications).map((key) => (
              <div data-testid="notifications-list" key={key} className="pos__notifications__list_wrapper">
                <div className="pos__notifications__timeline">
                  <p>{key}</p>
                </div>
                <div className="pos__notifications__list">
                  {notifications[key].map((notification, index) => (
                    <div key={index} className="pos__notifications__item" onClick={() => !notification.is_read && this.markAsRead(notification.id)}>
                      <div className="pos__notifications__item__image" data-unread={!notification.is_read}>
                        <ReceiptIcon width={24} height={24} />
                      </div>
                      <div className="pos__notifications__item__content">
                        <p>{notification.headings}</p>
                        <span className="pos__notifications__item__content__time">{key === "Today" ? moment(notification.created_at).fromNow() : moment(notification.created_at).format(this.time_format)}</span>
                      </div>
                      {!!notification.order_details && (
                        <div data-testid="notification-action" className="pos__notifications__item__action">
                          <WingoButton onClick={() => this.props.navigation?.navigate("OrderNumber", { id: notification.order_details?.order_id })}>View Details</WingoButton>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </DashboardWrapper>
    );
    // Customizable Area End
  }
}
