import React, { useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import ReactDom from 'react-dom';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import "./SnackBar.css";

interface Props {
    name:string;
    isVisible: boolean;
    handleClose: () => void;
    action : string;
    goToFavourites: ()=>void;
}

export default function SnackBarFav({ isVisible, handleClose, action, goToFavourites }: Props) {
    useEffect(() => {
        const rootElementContainer = document.createElement('div');
        rootElementContainer.id = 'root-element-container';
        rootElementContainer.style.zIndex = '1400';
        document.body.appendChild(rootElementContainer);

        return () => {
            const targetElement = document.getElementById('root-element-container');
            if (targetElement) {
                ReactDom.unmountComponentAtNode(targetElement);
                targetElement.remove();
            }
        };
    }, []);

    useEffect(() => {
        const targetElement = document.getElementById('root-element-container');
        if (targetElement) {
            ReactDom.render(
                <Snackbar
                    style={{ borderRadius: "8px", position: "sticky" }}
                    open={isVisible}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message={action === "remove" ? <span style={{ display: "flex", gap: "8px" }}><CancelRoundedIcon style={{ color: "#F87171" }} /> Item removed from Favourites</span> : "Item added to Favourites"}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    ContentProps={{
                        style: {marginRight: '24px', borderRadius: "4px", fontSize: "16px", color: "#313131", backgroundColor: "white", fontFamily: "Barmeno", fontWeight: 400, minWidth: "fit-content"
                        }
                    }}
                    action={action === "remove" ? "":<Button className="snackbar-action-button" onClick={goToFavourites}>
                                View Favourites <ChevronRightRoundedIcon/>  </Button> }
                />,
                targetElement
            );
        }
    }, [isVisible, handleClose]);

    return null;
}
