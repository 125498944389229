import React from "react";
import { z } from "zod";
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import { convertLogsToMessages, createMessage, getCurrentRole, hasPermission } from "../../../../../../components/src/utils";
import { PermitSchema, StaffGeneralInfoSchema } from "../../../../../../components/src/Schemas/AddNewStaff";
import { ReceivedFile, Role } from "../StaffInformationController.web";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
}

interface S {
  token: string;
  currentRole: string | null;
  data?: any;
  activityLog: {
    message: string;
    timestamp: string;
    updatedAttributes: Record<string, any>;
    changedAttributes: any;
    changes: any;
    timeAgo: string;
  }[];
  account_status: boolean;
  openUpdateStaff: boolean;
  availableIdentityTypes: { id: number; identity_type: string }[];
  availableRoles: { id: number; name: string }[];
  errors?: string[];
  validationErrors: Record<string, string>;
  notes: any[];
  openConfirmation: boolean;
  openManageNote: boolean;
  openNoteSuccess: boolean;
  editNote: {
    id: number;
    note: string;
  } | null;
  expiryPopupAnchorEl: HTMLElement | null;
}

interface SS {}

export default class ViewStaffController extends BlockComponent<
  Props,
  S,
  SS> {
  staffDetailsCallId: string = "";
  accountStatusCallId: string = "";
  toggleStatusCallId: string = "";
  activityLogCallId: string = "";
  updateStaffCallId: string = "";
  availableIdentityCallId: string = "";
  availableRolesCallId: string = "";
  notesCallId: string = "";
  addNoteCallId: string = "";
  updateNoteCallId: string = "";
  currentRoleParams: string = "";

  enableView: boolean = hasPermission("StaffAccount", "show");
  enableActivate: boolean = hasPermission("StaffAccount", "activate");
  enableDeactivate: boolean = hasPermission("StaffAccount", "deactivate");
  enableUpdate: boolean = hasPermission("StaffAccount", "update");
  enableNotes: boolean = hasPermission("StaffAccount", "staff_notes");
  enableNoteAdd: boolean = hasPermission("StaffAccount", "add_note");
  enableNoteUpdate: boolean = hasPermission("StaffAccount", "edit_note");

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      currentRole: null,
      data: undefined,
      activityLog: [],
      account_status: false,
      openUpdateStaff: false,
      availableIdentityTypes: [],
      availableRoles: [],
      validationErrors: {},
      notes: [],
      openConfirmation: false,
      openManageNote: false,
      openNoteSuccess: false,
      editNote: null,
      expiryPopupAnchorEl: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();

    const currentRole = getCurrentRole();
    this.setState({ currentRole });

    if (!this.enableView) {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }

    this.getAvailableRoles();
    this.getAccountStatus();
    this.getActivityLog();
    this.enableNotes && this.getNotes();
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (prevState.currentRole !== this.state.currentRole) {
      this.currentRoleParams = this.state.currentRole ? `?current_user_role=${this.state.currentRole}` : "";
    }
  }

  getMessageHandler(callId: string, apiResponse: any) {
    const messageHandlers = {
      [this.staffDetailsCallId]: () => this.handleStaffDetails(apiResponse),
      [this.accountStatusCallId]: () => this.handleAccountStatus(apiResponse),
      [this.toggleStatusCallId]: () => this.handleToggleStatus(apiResponse),
      [this.activityLogCallId]: () => this.handleActivityLog(apiResponse),
      [this.notesCallId]: () => this.handleNotes(apiResponse),
      [this.updateStaffCallId]: () => this.handleUpdateStaff(apiResponse),
      [this.availableIdentityCallId]: () => this.handleAvailableIdentity(apiResponse),
      [this.availableRolesCallId]: () => this.handleAvailableRoles(apiResponse),
      [this.addNoteCallId]: () => this.handleNote(apiResponse),
      [this.updateNoteCallId]: () => this.handleNote(apiResponse),
    };

    return messageHandlers[callId];
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!apiResponse) return;

    const handler = this.getMessageHandler(callId, apiResponse);
    if (handler) handler();
    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
  }

  async getToken() {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  }

  handleNote = (apiResponse: any) => {
    if (apiResponse.message) {
      this.setState({ openNoteSuccess: true });
      this.getNotes();
      this.getActivityLog();
    }

    this.setState({ editNote: null });
    this.handleManageNoteClose();
  }

  handleUpdateStaff(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleUpdateStaffClose();
    this.getStaffDetails();
  }

  handleNotes = (apiResponse: any) => {
    if (apiResponse.notes) this.setState({ notes: apiResponse.notes });
  }

  handleToggleStatus = (apiResponse: any) => {
    if (apiResponse.success) {
      this.getAccountStatus();
    }
  }

  handleStaffDetails = (apiResponse: any) => {
    if (apiResponse.staff_member) {
      const staff = apiResponse.staff_member;
      const attributes = staff.attributes;
      const { identity_document, visa } = attributes;
      if (attributes.user_roles) {
        attributes.user_roles = attributes.user_roles
          .map((role: any) => {
            const foundRole = this.state.availableRoles.find((availableRole) => availableRole.name === role);

            return foundRole ? { id: foundRole.id, name: foundRole.name } : null;
          })
          .filter(Boolean) as Role[];
      }
      if (identity_document) {
        attributes.identity_document = [identity_document] as ReceivedFile[];
      }
      if (visa) {
        attributes.visa = [visa] as ReceivedFile[];
      } else {
        attributes.visa = [] as ReceivedFile[];
      }

      this.setState({ data: attributes });
    }
  };

  handleAccountStatus = (apiResponse: any) => {
    this.setState({ account_status: !Boolean(apiResponse.disabled) });
  };

  handleActivityLog = (apiResponse: any) => {
    if (apiResponse.activity_logs) {
      const logs = convertLogsToMessages(apiResponse.activity_logs);
      this.setState({ activityLog: logs });
    }
  };

  getStaffDetails = () => {
    const id = this.props.navigation?.getParam("id");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffDetailsCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}` + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  addNote = (id: string, note: string) => {
    const getDataMsg = createMessage(
      configJSON.postMethod,
      configJSON.staffInformationAPI.endPoint + `/${id}` + `/add_note` + this.currentRoleParams,
      { "Content-Type": configJSON.contentType, token: this.state.token },
      { note_text: note },
    );
    this.addNoteCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateNote = (id: string, note: string, note_id?: number) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateNoteCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}` + `/edit_note` + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ note_text: note, note_id }));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getAccountStatus = () => {
    const id = this.props.navigation?.getParam("id");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.accountStatusCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}` + "/check_account_status" + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  toggleAccountStatus = (activate: boolean) => {
    const id = this.props.navigation?.getParam("id");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.toggleStatusCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}/${activate ? "activate" : "deactivate"}` + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
    return { success: true };
  }

  getActivityLog = () => {
    const id = this.props.navigation?.getParam("id");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.activityLogCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}/` + "activity_logs" + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getNotes = () => {
    const id = this.props.navigation?.getParam("id");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.notesCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}/` + "staff_notes"
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  updateStaff = (data: any) => {
    const id = this.props.navigation?.getParam("id");
    const validation = this.validateData(data);
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data);

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateStaffCallId = postDataMsg.messageId;
    postDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateStaffAPI.endPoint + `/${id}` + this.currentRoleParams);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateStaffAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  getAvailableIdentityTypes = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.availableIdentityCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableIdentityTypesAPI.endPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableIdentityTypesAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getAvailableRoles = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.availableRolesCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.availableRolesAPI.endPoint);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableRolesAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleAvailableIdentity(data: any) {
    if (data.identity_types) {
      this.setState({ availableIdentityTypes: data.identity_types });
    }
  }

  handleAvailableRoles(data: any) {
    if (data.roles) {
      this.setState({ availableRoles: data.roles }, () => {
        this.getStaffDetails();
      });
    }
  }

  validateData = (data: any) => {
    let errorMap: Record<string, string> = {};

    const schemas = [
      { schema: StaffGeneralInfoSchema, tab: 0 },
      { schema: PermitSchema, tab: 0, condition: data.permitRequired },
    ];

    for (let { schema, tab, condition } of schemas) {
      if (condition === false) continue;
      const validation = schema.safeParse(data);
      if (!validation.success) {
        if (validation.error instanceof z.ZodError) {
          validation.error.errors.forEach((err) => {
            errorMap[err.path[0]] = err.message;
          });
        }
        this.setState({ validationErrors: errorMap });
        return { success: false, tab };
      }
    }

    return { success: true };
  };

  createFormData = (data: any) => {
    const formData = new FormData();
    formData.append(
      "account[staff_id]",
      data.staffId
    );
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    formData.append("account[first_name]", data.firstName);
    formData.append("account[last_name]", data.lastName);
    formData.append("account[country_code]", data.countryCode);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, ""));
    formData.append("account[full_phone_number", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType);

    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);

    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode);
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[email]", data.email);
    formData.append("account[password]", data.password);

    return formData;
  };

  handleUpdateStaffOpen = () => {
    this.getAvailableIdentityTypes();
    this.setState({ openUpdateStaff: true });
  };

  handleUpdateStaffClose = () => {
    this.setState({ openUpdateStaff: false, errors: undefined, validationErrors: {} });
  };

  confirmClose = () => {
    this.setState({ openConfirmation: true });
  }

  handleConfirmationClose = () => {
    this.setState({ openConfirmation: false });
  }

  handleEditNote = (note: { id: number; note: string }) => {
    this.setState({ editNote: note });
    this.handleManageNoteOpen();
  };

  discardChanges = () => {
    this.handleUpdateStaffClose();
    this.handleConfirmationClose();
  }

  handleManageNoteOpen = () => {
    this.setState({ openManageNote: true });
  }

  handleManageNoteClose = () => {
    this.setState({ openManageNote: false, editNote: null });
  }

  handleNoteSuccessClose = () => {
    this.setState({ openNoteSuccess: false });
  }

  formatDateTime(dateTimeStr: string): string {
    const date = new Date(dateTimeStr);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month} ${day}, ${year} at ${hours}:${formattedMinutes} ${ampm}`;
  }

  openExpiryPopup = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ expiryPopupAnchorEl: event.currentTarget });
  };

  closeExpiryPopup = () => {
    this.setState({ expiryPopupAnchorEl: null });
  }
}
