import React from "react";
import { IconButton, Popover } from "@mui/material";
import { CloseRounded, ErrorOutline } from "@mui/icons-material";
import PosProfileController, { Props } from "./PosProfileController.web";
import { PencilIcon, ReloadIcon, UserOutlinedIcon } from "../../../components/src/Icons";
import { WingosPreview } from "../../../components/src/Dropzone";
import { ReceivedFile } from "../../dashboard/src/dashboards/StaffInformation/StaffInformationController.web";
import { isWithinThreeMonths } from "../../../components/src/utils";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import CustomLoader from "../../../components/src/CustomLoader.web";
import UpdateStaffInfo from "../../../components/src/Dashboard/UpdateStaffInfo";
import ConfirmationPopup from "../../../components/src/Dashboard/ConfirmationPopup";
import "./PosProfile.css";

export default class PosProfile extends PosProfileController {
  constructor(props: Props) {
    super(props);
  };

  showNearExpiryAlert = () => {
    const open = Boolean(this.state.expiryPopupAnchorEl);

    return (
      <div data-testid="expiry-alert">
        <IconButton data-testid="expiry-alert-button" size="small" color="error" onClick={this.openExpiryPopup}>
          <ErrorOutline />
        </IconButton>
        <Popover
          open={open}
          anchorEl={this.state.expiryPopupAnchorEl}
          onClose={this.closeExpiryPopup}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          classes={{
            paper: "pos_profile__expiry_popup__popover"
          }}
        >
          <div className="pos_profile__expiry_popup">
            <div className="pos_profile__expiry_popup__header">
              <span className="pos_profile__expiry_popup__title">Visa Renewal</span>
              <IconButton size="small" onClick={this.closeExpiryPopup}>
                <CloseRounded fontSize="small" />
              </IconButton>
            </div>
            <span className="pos_profile__expiry_popup__message">
              Visa expiration is approaching. Renew to avoid complications.
            </span>
          </div>
        </Popover>
      </div>
    );
  };

  personalInformation = (): React.ReactNode => {
    const { details } = this.state;
    const generalInfo = [
      { label: "Staff ID", value: details?.staff_id },
      { label: "Role", value: details?.user_roles?.map((role: any) => role.name).join(", ") },
      { label: "Name", value: details?.full_name },
      { label: "Email Address", value: details?.email },
      { label: "Mobile Number", value: details?.full_phone_number },
      { label: "Address", value: "" },
      {
        label: "Identity Type",
        value: details?.identity_type,
        attachment: {
          document: details?.identity_document[0],
        },
      },
      {
        label: "Attachments",
        attachment: {
          document: details?.visa !== "" ? details?.visa[0] : null,
          expiryDate: details?.expiry_date,
        },
        hidden: !details?.permit_require
      },
    ];

    return (
      <>
        {generalInfo.map((info) => (
          <div
            key={info.label}
            className={`pos_profile__info__general_info__row ${info.hidden ? "hidden" : ""}`}
          >
            <span className="pos_profile__info__general_info__row__label">{info.label}:</span>
            <div className="pos_profile__info__general_info__row__value">
              {info.attachment?.document ? (
                <>
                  <WingosPreview files={[info.attachment?.document as ReceivedFile]} />
                  {info.attachment?.expiryDate && (
                    <div className="pos_profile__info__general_info__row__value__expiry">
                      <span>Expiry Date: {info.attachment?.expiryDate}</span>

                      {isWithinThreeMonths(info.attachment?.expiryDate) && this.showNearExpiryAlert()}
                    </div>
                  )}
                </>
              ) : (
                <>{info.value}</>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  activityLog = (): React.ReactNode => {
    const { activityLog } = this.state;

    return (
      <div className="pos_profile__log__stack">
        {activityLog.map((log, index) => (
          <div
            key={index}
            className="pos_profile__log"
          >
            <div className="pos_profile__log__decorator">
              <div className="pos_profile__log__decorator__circle" />
              <div className="pos_profile__log__decorator__line" />
            </div>
            <div className="pos_profile__log__time">{log.timeAgo}</div>
            <div className="pos_profile__log__message_wrapper">
              <span className="pos_profile__log__message">{log.message}</span>

              {log.changes && (
                <span className="pos_profile__log__change">
                  {Object.keys(log.changes).map((key) => (
                    <div key={key}>
                      Changed {key} from {log.changes[key].from} to {log.changes[key].to}
                    </div>
                  ))}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render(): React.ReactNode {
    const { navigation } = this.props;
    const { loading, details, openUpdateStaff, availableIdentityTypes, availableRoles, errors, validationErrors } = this.state;

    return (
      <DashboardWrapper
        navigation={navigation}
      >
        <Header
          navigation={navigation}
          headerTitle="Profile"
        />

        <CustomLoader loading={loading}/>

        <main className="pos_profile__wrapper">
          <section className="pos_profile__section main_content">
            <div className="pos_profile__block personal_info">
              <div className="pos_profile__block__header">
                <div className="pos_profile__title">
                  <UserOutlinedIcon width={18} height={18} />
                  <h2>Personal Information</h2>
                </div>

                <IconButton color="error" onClick={this.handleUpdateStaffOpen}>
                  <PencilIcon width={18} height={18} />
                </IconButton>
              </div>

              <div className="pos_profile__block__content">
                <h3>General Information</h3>
                {this.personalInformation()}
              </div>
            </div>
          </section>

          <section className="pos_profile__section sidebar">
            <div className="pos_profile__block activity_log">
              <div className="pos_profile__block__header">
                <div className="pos_profile__title">
                  <IconButton>
                    <ReloadIcon width={18} height={18} />
                  </IconButton>
                  <h2>Activity Log</h2>
                </div>
              </div>
              {this.activityLog()}
            </div>
          </section>
        </main>

        {openUpdateStaff && details && (
          <UpdateStaffInfo
            isUserProfile
            data-testid="update-staff-info-modal"
            open={openUpdateStaff}
            onClose={this.handleUpdateStaffClose}
            identityTypes={availableIdentityTypes}
            roles={availableRoles}
            onSubmit={this.updateStaff}
            errors={errors}
            validations={validationErrors}
            data={details}
            confirmClose={this.confirmClose}
          />
        )}

        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.discardChanges}
          goBack={this.handleConfirmationClose}
        />
      </DashboardWrapper>
    );
  };
};
