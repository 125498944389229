import React from "react";
// Customizable Area Start
import moment from "moment-timezone";
import { Toaster } from "sonner";
import { NewOrderIcon, WarningFilledIcon } from "../../../components/src/Icons";
import { ContainedButton, OutlinedButton } from "../../../components/src/Button";
import Rolesandpermissions2 from "../../rolesandpermissions2/src/Rolesandpermissions2.web";
import Modal from "../../../components/src/Modal";
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import ErrorPopup from "../../../components/src/ErrorPopup";
import "./Dashboard.css";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  orderNotification = () => {
    const { notificationModal, notificationsQueue } = this.state;
    const { open } = notificationModal;
    const order = notificationsQueue[0];
    const created_at = moment(order?.schedule_time).tz(moment.tz.guess(), true).format(`YYYY-MM-DD ${this.settings?.time_format === "24-hour" ? "HH:mm" : "hh:mm A"}`);
    const cancellation_reason = order?.cancellation_reason;
    const status = order?.status;

    return (
      <Modal
        closeButton
        maxWidth="sm"
        open={open}
        onClose={this.closeNotification}
        classes={{
          paper: `realtime_notification ${status === "placed" ? "new_order" : "warning"}`
        }}
      >
        <div className="realtime_notification__wrapper">
          {
            status === "placed" ?
              <NewOrderIcon width={96} height={96} className="realtime_notification__icon new_order" /> :
              <WarningFilledIcon width={96} height={96} className="realtime_notification__icon" />
          }

          <div className="realtime_notification__header">
            <h2>{status === "placed" ? "Order Request" : "Order Cancellation Request"}</h2>
            <p>{status === "placed" ? "Please review the order request and proceed." : "Please review the cancellation request and proceed."}</p>
          </div>

          {this.orderDetails()}

          <div className="realtime_notification__title">
            <p>{status === "placed" ? "Order Scheduled for" : "Cancellation Reason:"}</p>
            <p>{status === "placed" ? created_at : cancellation_reason}</p>
          </div>

          <div className="realtime_notification__buttons_group">
            <OutlinedButton onClick={status === "placed" ? this.acceptOrder : this.cancelOrder}>
              {status === "placed" ? "Accept Order" : "Cancel Order"}
            </OutlinedButton>

            <ContainedButton onClick={this.viewDetails}>
              View Details
            </ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  orderDetails = () => {
    const order = this.state.notificationsQueue[0];

    return (
      <div className="realtime_notification__order_details">
        <div className="realtime_notification__order_details__item">
          <p className="realtime_notification__order_details__label">Order Number</p>
          <p className="realtime_notification__order_details__value">{order?.order_number}</p>
        </div>

        <div className="realtime_notification__order_details__item">
          <p className="realtime_notification__order_details__label">Total Amount</p>
          <p className="realtime_notification__order_details__value order_amount">{order?.total}</p>
        </div>

        <div className="realtime_notification__order_details__item">
          <p className="realtime_notification__order_details__label">Customer Name</p>
          <p className="realtime_notification__order_details__value">{order?.customer_name}</p>
        </div>

        <div className="realtime_notification__order_details__item">
          <p className="realtime_notification__order_details__label">Order Type</p>
          <p className="realtime_notification__order_details__value order_type">{order?.order_type}</p>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.orderNotification()}
        <Toaster
          visibleToasts={5}
          toastOptions={{
            duration: Infinity
          }}
        />

        <SuccessPopup
          open={this.state.successModal.open}
          onClose={this.closeSuccessModal}
          message={this.state.successModal.message}
        />

        <ErrorPopup
          open={this.state.errorModal.open}
          onClose={this.closeErrorModal}
          message={this.state.errorModal.message}
        />

        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
