import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneInput.css";

interface Props extends PhoneInputProps {
  fullWidth?: boolean
}

const PhoneInputComponent = (props: Props) => {
  const { fullWidth, ...rest } = props;

  return (
    <PhoneInput
      inputStyle={{
        fontWeight: 400,
        color: "#1D1D1D",
        fontFamily: "Barmeno",
        width: fullWidth ? "100%" : "300px",
        height: "46px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #1D1D1D",
      }}
      containerClass="phone-input-container"
      inputClass="phone-input"
      buttonClass="phone-input-button"
      dropdownClass="phone-input-dropdown"
      searchClass="phone-input-search"
      {...rest}
    />
  );
};

export default PhoneInputComponent;
