import React from "react";
import Modal from "../Modal";
import { ContainedButton } from "../Button";
import { errorIcon } from "../../../blocks/dashboard/src/assets";
import "./ErrorPopup.css";

interface Props {
  open: boolean;
  onClose: () => void;
  message?: string | string[];
  subMessage?: string;
};

const ErrorPopup = ({
  open,
  onClose,
  message = "An error occurred. Please try again later.",
  subMessage
}: Props) => {
  const renderMessage = () => {
    if (typeof message === "string") {
      return <p>{message}</p>
    } else {
      return (
        <div className="error_popup__messages">
          <h2>Errors</h2>
          <ul>
            {message.map((item, index) => (
              <li key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="error_popup__wrapper">
        <img src={errorIcon} alt="error" />
        {renderMessage()}
        { subMessage ? <div style={{fontSize:"18px",fontWeight:500,textAlign:"center",color:"#404040"}} >{subMessage}</div>:null}
        <ContainedButton onClick={onClose}>
          Close
        </ContainedButton>
      </div>
    </Modal>
  )
};

export default ErrorPopup;
